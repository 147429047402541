import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { List, Button, Tooltip, Paper, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

import ConfigContext from '../../../../../context/snackbar-context';
import useSmartRequest from '../../../../../hooks/useSmartRequest';
import { getCollection, updateCollection } from '../../../../../api/valueset/collection';
import CollectionEditRow from './EntityRow';
import { set, makeCancelable } from '../../../../../shared/util';
import CollectionAddEntity from './CollectionAddEntity';
import { useTranslator } from '../../../../../shared/translate';
import Close from '../../../../../assets/icons/closeX.svg';

import Popup from '../../../../../components/UI/Popup/Popup';
import SuccessSnackbar from '../../../../../components/UI/Snackbar/Success';
import { getItemFromRecord } from '../../../../../shared/recordUtil';

const useStyles = makeStyles({
    inputStyle: {
        padding: 10,
    },

    cardHeader: {
        fontSize: 18,
        fontWeight: 600,
        color: '#000',
        margin: "20px 0",
        textAlign: 'center',
        borderBottom: '1px solid #c6c6c6',
        a: {
            fontWeight: 400,
            fontSize: 16,
            color: '#2b75bb',
            textDecoration: 'none',
        }
    },
    Image: {
        position: 'relative',
        maxHeight: 25,
        marginRight: '.25rem',
        borderRadius: 6,
    },
    myTableHeadStyle: {
        backgroundColor: '#e6e6e6',
    },
    tableHoverStyle: {
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: 'rgba(37, 118, 195, 0.1)',
        }
    },
    myBlueButton: {
        backgroundColor: '#2b75bb',
        borderRadius: 6,
        color: 'white',
        fontSize: 14,
        textTransform: 'capitalize',
        padding: '4px 14px',
        fontFamily: 'Source Sans Pro',
        fontWeight: 500,
        marginRight: 10,
        marginTop: 20,
        zIndex: 'inherit',
        textDecoration: 'none',
        '&:hover': {
            background: '#2b75bb',
        },
    },
    myRedButton: {
        backgroundColor: '#990000',
        borderRadius: 6,
        color: 'white',
        fontSize: 14,
        textTransform: 'capitalize',
        padding: '4px 14px',
        fontFamily: 'Source Sans Pro',
        fontWeight: 500,
        marginRight: 10,
        marginTop: 20,
        zIndex: 'inherit',
        textDecoration: 'none',
        '&:hover': {
            background: '#990000',
        },
    },
    myStickyTop: {
        textAlign: 'right',
        position: 'sticky',
    },
});

const CollectionEdit = (props) => {
    const classes = useStyles();

    const { id: collectionId } = props.match.params;
    const { history } = props;

    const { setShowSnackbar } = React.useContext(ConfigContext);

    const [collection, setCollection] = useState(null);
    const [, setCollectionOriginal] = useState(null);
    const [ showEntityAddedSnackbar, setShowEntityAddedSnackbar ] = React.useState(false);

    const [showPopup, setShowPopup] = useState(false);

    const trans = useTranslator()

    //collection
    const { sendRequest: getCollectionRequest } = useSmartRequest(getCollection);
    //update
    const { sendRequest: sendUpdateRequest, isSending: isUpdateSending } = useSmartRequest(updateCollection);

    useEffect(() => {
        const {cancel, promise} = makeCancelable(getCollectionRequest(collectionId));

        promise.then(res => {
            setCollection(res)
            setCollectionOriginal(res)
        })

        return () => {
            cancel();
        }
    }, [collectionId, getCollectionRequest])

    const updateRecordHandler = () => {
        sendUpdateRequest(collection.id, collection).then(response => {
            setShowSnackbar(true);
            history.push('/config/value-set/collection');
        });
    }

    const handleNameChange = (event) => { setCollection(set(collection, event.target.value, 'name')) }

    if (collection === null || collection.entities === null) { return null; }
    console.log(collection);

    return (
        <React.Fragment>
            <Grid container justify="center" spacing={5} className={classes.cardHeader} style={{ marginTop: "0px" }}>
                <Grid item sm={11}>
                    <Tooltip title={trans('back-to-value-set-collection')}>
                        <Link to={'/config/value-set/collection/'}>&#8630; </Link>
                    </Tooltip>{trans('edit-collection')}
                </Grid>
                <Grid item sm={1}>
                    <Link to={'/config'} ><img className={classes.Image} src={Close} alt="" /></Link>
                </Grid>
            </Grid>
            <div className={classes.myStickyTop}>
                <Button onClick={updateRecordHandler} disabled={isUpdateSending} className={classes.myBlueButton}>{trans('save')}</Button>
                <Button onClick={() => setShowPopup(true)} className={classes.myRedButton}>{trans('dismiss')}</Button>
            </div>
            <Grid container justify="center" spacing={2} style={{ marginTop: "50px" }}>
                <Grid item sm={10}>
                    <Paper>
                        <Table>
                            <TableBody>
                                <TableRow className={classes.tableHoverStyle}>
                                    <TableCell variant={'body'}>{trans('collection-id')}:</TableCell>
                                    <TableCell variant={'body'}>
                                        <TextField
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            disabled
                                            inputProps={{
                                                className: classes.inputStyle,
                                            }}
                                            defaultValue={collection.id}
                                        /></TableCell>
                                </TableRow>
                                <TableRow className={classes.tableHoverStyle}>
                                    <TableCell variant={'body'}>{trans('collection-name')}:</TableCell>
                                    <TableCell variant={'body'}><TextField
                                        variant="outlined"
                                        type="text"
                                        fullWidth
                                        inputProps={{
                                            className: classes.inputStyle
                                        }}
                                        onChange={handleNameChange}
                                        value={collection.name ? collection.name : ''}

                                    /></TableCell>
                                </TableRow>
                                <TableRow className={classes.tableHoverStyle}>
                                    <TableCell variant={'body'}>{trans('entities')}</TableCell>
                                    <TableCell variant={'body'}>
                                        <CollectionAddEntity
                                            entities={collection.entities.map(elem => ({
                                                type: elem.type,
                                                localId: elem.id,
                                                onnId: getItemFromRecord(elem.record, '035', 'a')
                                            }))}
                                            addEntity={input => {
                                                setShowEntityAddedSnackbar(true);
                                                setCollection(prevState => ({
                                                    ...prevState,
                                                    entities: Array.isArray(input) ? [
                                                            ...prevState.entities,
                                                            ...input.map(elem => ({...elem})), 
                                                        ] : [
                                                            ...prevState.entities,
                                                            {...input},
                                                        ]
                                                }))
                                            }}
                                        />
                                        <div>
                                            <List dense>
                                                {collection.entities.map((entity, key) => {
                                                    return (
                                                        <CollectionEditRow
                                                            key={key}
                                                            entity={entity}
                                                            removeEntity={() => setCollection(prevState => ({
                                                                ...prevState,
                                                                entities: prevState.entities.filter(elem => elem.id !== entity.id)
                                                            }))}
                                                        />
                                                    )
                                                })}
                                            </List>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Paper>


                    <Popup
                        show={showPopup}
                        cancel={() => setShowPopup(false)}
                        save={() => props.history.push('/config/value-set/collection')}
                        title={trans('dismiss_confirmation')}
                    />
                </Grid>
            </Grid>
            <SuccessSnackbar
                message={<span>{trans('entity_added')}</span>}
                showSnackbar={showEntityAddedSnackbar}
                setShowSnackbar={setShowEntityAddedSnackbar}
            />
        </React.Fragment>

    )
}

export default withRouter(CollectionEdit);