import React, { useEffect } from 'react';
import { Select, MenuItem, TextField, Button } from '@material-ui/core';
import { useTranslator } from '../../../../../shared/translate';
import Pagination from '../../../../../components/UI/Pagination/Pagination';

const EntitySearch = props => {
  const { 
    type,
    limit,
    filters,
    entityCount,
    setEntityCount,
    setEntities,
    currentPage,
    setCurrentPage,
    setLoading,
    setError,
    fetchRecords,
  } = props;

  const [filter, setFilter] = React.useState('');
  const [searchText, setSearchText] = React.useState('');

  useEffect(() => {
    if (filters.length && !filters.find(elem => elem.value === filter)) {
      setFilter(filters[0].value);
    }
  }, [filter, filters]);

  const trans = useTranslator();

  const handleSearch = page => {
    setCurrentPage(page);
    setLoading(true);
    setError(null);
    fetchRecords(type, {
      filters: {
        [filter]: searchText,
      },
      limit: limit,
      offset: (page-1)*limit
    }).then( response => {
      setLoading(false);
      setEntityCount(response.count);
      setEntities(response.result)
    }).catch(error => {
      setLoading(false);
      setError(error.message);
    })
  }

  return (
    <React.Fragment>
      <div style={{
          width: '100%', 
          display: 'flex', 
          marginTop: '1rem',
          marginBottom: '1rem',
          justifyContent: 'space-between',
        }}>
        <Select
          onChange={event => {setFilter(event.target.value)}}
          value={filters.find(elem => elem.value === filter) ? filter : (filters.length ? filters[0].value : '')}
          // fullWidth
          variant="outlined"
          // inputProps={{
          //     className: classes.inputStyle,
          // }}
        >
          {filters.map((option, key) => {
              return (
                  <MenuItem key={key} value={option.value} >
                    {trans(option.label)}
                  </MenuItem >
              )
          })}
        </Select>
        <TextField
          autoFocus
          placeholder={trans('search')}
          variant="outlined"
          type="text"
          fullWidth
          value={searchText}
          onChange={event => setSearchText(event.target.value)}
          onKeyPress={event => {
            if ('Enter' === event.key) {handleSearch(1)}
          }}
          // inputProps={{
          //     value: filterKeyword,
          //     onChange: (event) => setFilterKeyword(event.target.value),
          //     className: classes.inputStyle
          // }}
        />
        <Button
            onClick={() => handleSearch(1)}
            variant={'contained'}
            color={'primary'}
        >{trans('search')}</Button>
      </div>
      <Pagination 
        currentPage={currentPage}
        handleCurrentPageChange={newPage => {
          handleSearch(newPage);
        }}
        recordCount={entityCount}
        itemPerPage={limit}
        // disabled
      />
    </React.Fragment>
  );
};

export default EntitySearch;