import { useEffect, useState } from 'react';

export default httpClient => {
  const [ error, setError ] = useState();
  
  useEffect(() => {
    const requestInterceptor = httpClient.interceptors.request.use(request => {
      setError(null);
      return request
    });
    const responseInterceptor = httpClient.interceptors.response.use(
      response => response,
      error => {
        setError(error);
        throw error;
      }
    );
    return () => {
      httpClient.interceptors.request.eject(requestInterceptor);
      httpClient.interceptors.request.eject(responseInterceptor);
    }
  }, [httpClient]);

  const errorConfirmedHandler = () => {
    setError(null);
  };

  return [error, errorConfirmedHandler];
}