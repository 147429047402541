import React, { useState } from 'react'

import { Grid, Typography, List } from '@material-ui/core';

import BaseDataApi from '../../../api/basedata/baseDataApi';

import CurrencyFilter from './CurrencyFilter';
import Pagination from '../../../components/UI/Pagination/Pagination';

const PAGE_SIZE = 5;

const CurrencyList = (props) => {
  // filter
  const [ currentFilter, setCurrentFilter ] = useState({});

  // pagination
  const [ currentPage, setCurrentPage ] = useState(1);

  // result
  const [ currencies, setCurrencies ] = useState(null);
  const [ currencyCount, setCurrencyCount ] = useState(0);

  const searchParameterChanged = async (parameter = {}) => {
    console.log('Currency list parameter changed', parameter);

    const finalFilter = {
      offset: (currentPage - 1) * PAGE_SIZE,
      count: PAGE_SIZE,
      ...currentFilter,
      ...parameter,
    };

    const currenciesLoaded = await BaseDataApi.getCurrencies(finalFilter);
    
    console.log('Currencies loaded', currenciesLoaded);

    if (Array.isArray(currenciesLoaded.items)) {
      setCurrencyCount(currenciesLoaded.count);
      setCurrencies(currenciesLoaded.items);
    }
  };

  const filterChanged = (filter) => {
    setCurrentFilter(filter);
    setCurrentPage(1);

    searchParameterChanged({
      ...filter,
      offset: 0,
    });
  }

  const currentPageChanged = (newPage) => {
    setCurrentPage(newPage);
    searchParameterChanged({
      offset: (newPage - 1) * PAGE_SIZE,
    });
  };

  const renderCurrencies = (currencies) => {
    if (!Array.isArray(currencies)
        || currencies.length === 0
    ) {
      return (
        <Grid item key="no-elements-available" xs={12}>
          <span>No elements</span>
        </Grid>
      );
    }

    return currencies.map((c) => {
      return (
        <React.Fragment key={c.id}>
          <Grid container>
              <Grid item key="code" xs={3}>
                <Typography>{c.code}</Typography>
              </Grid>
              <Grid item key="name" sm={9}>
                <Typography>{c.name}</Typography>
              </Grid>
          </Grid>
        </React.Fragment>
      );
    });
  };

  // load initial values
  if (!Array.isArray(currencies)) {
    searchParameterChanged();
  }

  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          <CurrencyFilter
            onFilterChange={filterChanged}
          />
          <List>
            {renderCurrencies(currencies)}
          </List>
          <Pagination
            currentPage={currentPage}
            handleCurrentPageChange={currentPageChanged}
            recordCount={currencyCount}
            itemPerPage={PAGE_SIZE}
            // disabled={isRequestSending()}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CurrencyList;
