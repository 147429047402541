// !!! Ne töröld ki a komenteket, OKP-ben majd az kell :)

import React from 'react';
import {
    Button,
    MenuList,
    MenuItem,
    Paper,
    ButtonGroup,
    Popper,
    Grow,
    ClickAwayListener
} from '@material-ui/core/';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Link } from 'react-router-dom'
import { useTranslator } from '../../../shared/translate';
import { makeStyles } from '@material-ui/core/styles';

export default function SelectEdit(props) {
    const t = useTranslator()
    const [open, setOpen] = React.useState(false);

    const {
        recordId,
        showPopupHandler,
        cloneRecordHandler,
        setShowHandler
    } = props;


    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };


    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const useStyles = makeStyles(theme => ({

        menuStyle: {
            fontSize: 14,
            fontWeight: 500,
            color: 'black',
            minHeight: 40,
            fontFamily: 'Source Sans Pro',
            padding: theme.spacing(0, 3),
            '&:hover': {
                color: 'white',
                backgroundColor: '#909090',
                textDecoration: 'none',
            },
        },

        mainBlueButton: {
            backgroundColor: '#2b75bb',
            borderRadius: 6,
            color: 'white',
            fontSize: 14,
            textTransform: 'capitalize',
            padding: '2px 14px',
            fontFamily: 'Source Sans Pro',
            fontWeight: 500,
            zIndex: 'inherit',
            '&:hover': {
                background: '#169bd5',
            },
        },
        linkStyle: {
            color: 'black',
            '&:hover': {
                color: 'white',
                backgroundColor: '#909090',
                textDecoration: 'none',
            },
        },
    }));

    const classes = useStyles();


    return (
        <React.Fragment>
            <ButtonGroup variant="contained" color="primary" ref={anchorRef}>
                <Button className={classes.mainBlueButton} aria-haspopup="true" >
                    {t('operations')}
                </Button>
                <Button className={classes.mainBlueButton} aria-haspopup="true" onClick={handleToggle}>
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper open={open} transition disablePortal anchorEl={anchorRef.current} style={{ zIndex: '100' }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper id="menu-list-grow">
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    <MenuItem className={classes.menuStyle} onClick={() => showPopupHandler(recordId)}>{t('view')}</MenuItem>
                                    <Link className={classes.linkStyle} to={'/cataloging/edit/' + recordId}><MenuItem className={classes.menuStyle}>{t('edit')}</MenuItem></Link>
                                    <MenuItem className={classes.menuStyle} onClick={() => cloneRecordHandler(recordId)}>{t('duplicate')}</MenuItem>
                                    <MenuItem className={classes.menuStyle} onClick={() => setShowHandler(true, recordId)}>{t('delete')}</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
}