import React from 'react';

import ControlfieldTableRow from './ControlfieldTableRow';
import { mutateAndSortPositionsArray, getPositionsByMaterialType } from '../../../../../shared/recordUtil';
import * as EditorButtons from '../../../Buttons/Buttons';
import { isEqual, createPropertySubstringSetter } from '../../../../../shared/util';

const ControlfieldTable = props => {
  const { value, setValue, metadataProfile, type } = props;

  const [isOpen, setIsOpen] = React.useState(false);
  const [positions, setPositions] = React.useState([]);

  React.useEffect(() => {
    if ("000" === metadataProfile.tag) {
      setPositions(mutateAndSortPositionsArray([...metadataProfile.positions]));
    } else {
      switch (metadataProfile.tag) {
        case '007':
          const newPositions = mutateAndSortPositionsArray(metadataProfile.types.find(elem => elem[0].codes[0].code === type) || metadataProfile.types[0]);
          setPositions(newPositions);
          break;
        case '008':
          if (!metadataProfile.types[type]) {
            throw new Error('Invalid material type: ' + type);
          }
          setPositions(getPositionsByMaterialType(metadataProfile, type));
          break;
        case '006':
          // TODO
          break;
        default:
          throw new Error('Invalid controlfield tag: ' + metadataProfile.tag);
      }
    }
  }, [type, metadataProfile]);

  // const onChangeHandler = position => value => setValue(prevData => 
  //   prevData.substring(0, position.start) +
  //   (
  //     value.substring(0, position.length) + 
  //     prevData.substring(position.start, position.start + position.length)
  //   ).substring(0, position.length) +
  //   prevData.substring(position.start + position.length)
  // );


  return (
    <React.Fragment>
      {/* <div>{JSON.stringify(type)}</div> */}
      <EditorButtons.ToggleTableButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
      {!isOpen ? null : positions.map((position, index) => {
        return (
          <ControlfieldTableRow
            key={index}
            data={{ ...position }}
            value={value.substring(position.position.start, position.position.start + position.position.length)}
            setValue={createPropertySubstringSetter(setValue, position.position.start, position.position.length)}
            tag={metadataProfile.tag}
            position={position.position.string}
          />
        )
      })}
    </React.Fragment>
  );
};

export default React.memo(ControlfieldTable, (prev, next) => (
  isEqual(prev.value, next.value) &&
  isEqual(prev.metadataProfile, next.metadataProfile) &&
  prev.type === next.type
));