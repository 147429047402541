import React from 'react';
import { TextField, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslator } from '../../../../shared/translate';
import PopupModal from '../../../../components/UI/Popup/PopupModal';
import List from '../../../Catalog/List/List';

const useStyles = makeStyles({
    mainBlueButton: {
        backgroundColor: '#2b75bb',
        borderRadius: 6,
        color: 'white',
        fontSize: 14,
        textTransform: 'capitalize',
        padding: '4px 14px',
        fontFamily: 'Source Sans Pro',
        fontWeight: 500,
        zIndex: 'inherit',
        '&:hover': {
            background: '#169bd5',
        },
    },
    inputStyle: {
        padding: 10,
        fontSize: 14,
        fontWeight: 500,
    }
});

const CatalogSearch = (props) => {

    const classes = useStyles();
    const [showPopup, setShowPopup] = React.useState(false);
    const [titleState, setTitleState] = React.useState('');
    //const [recordId, setRecordId] = React.useState('');

    const trans = useTranslator();

    const { 
        linkEntry
    } = props;

    const onChangeHandler = () => {
        setShowPopup(true);
    };

    const cancelPopupHandler = () => {
        setShowPopup(false);
    };

    const linkEntryHandler = (entry) => {
        if(linkEntry){
            linkEntry(entry);
        }

        setTitleState(entry.recordTitle);
        //setRecordId(entry.id);

        setShowPopup(false);
    }

    return (
        <React.Fragment>
            <Grid container spacing={5}>
                <Grid item md={8} sm={8}>
     
                    <TextField
                        autoFocus
                        placeholder={trans("title")}
                        // variant="outlined"
                        type="text"
                        fullWidth
                        inputProps={{
                            readOnly: true,
                            value: titleState,
                            className: classes.inputStyle
                        }}
                    />

                </Grid>
                <Grid item md={4} sm={4}>
                    <Button
                        onClick={onChangeHandler}
                        className={classes.mainBlueButton}
                    >{trans("search")}</Button>
                </Grid>
            </Grid>

            <PopupModal
                style={{ height: 0 }}
                show={showPopup}
                setShow={setShowPopup}
                cancel={cancelPopupHandler}
                size={'medium'}
                body={<List linkEntry={linkEntryHandler} />}
          />

        </React.Fragment>
    );
};

export default CatalogSearch;