import React from 'react'
import EntityList from '../../EntityList/EntityList'
import { authorityRecordType } from '../../../../../../shared/parameters/authorityRecordTypes'

import classes from './AuthorityCorporate.module.scss'
import { useTranslator } from '../../../../../../shared/translate'
import { addHelperFields } from '../../../../../../shared/listUtil'

const AuthorityCorporate = props => {
  const { 
    newlySelectedEntities,
    setNewlySelectedEntities, 
    alreadySelectedEntities,
  } = props;
  const [onnSelected, setOnnSelected] = React.useState(false);
  const t = useTranslator()
  const prototype = addHelperFields({
    record: {
      leader: '',
      controlfields: [],
      datafields: [
        {
          tag: '110',
          indicator1: '0',
          indicator2: '0',
          subfields: [
            {
              code: 'a',
              value: ''
            }
          ]
        }
      ]
    }
  })
  return (
    <EntityList
      title={<h3 className={classes.myFontStyle}>{t('namespace-corporate')}</h3>}
      type={authorityRecordType.CORPORATE}
      prototype={prototype}
      listCols={[
        {
          label: t('authority.corporate.record-id'),
          tag: onnSelected ? '035' : '001',
          code: onnSelected ? 'a' : '',
        },
        {
          label: t('authority.corporate.name'),
          tag: '110',
          code: 'a',
        }
      ]}
      newlySelectedEntities={newlySelectedEntities}
      setNewlySelectedEntities={setNewlySelectedEntities}
      alreadySelectedEntities={alreadySelectedEntities}
      onnSelected={onnSelected}
      setOnnSelected={setOnnSelected}
      searchOnn
    />
  )
}

export default AuthorityCorporate