import React from 'react'
import { Route, Switch } from 'react-router-dom';
import AuthorityCorporate from './PropertyNamespace/Corporate/AuthorityCorporate';
import AuthorityPerson from './PropertyNamespace/Person/AuthorityPerson';
import AuthorityGeo from './PropertyNamespace/Geo/AuthorityGeo';
import AuthoritySubject from './PublicNamespace/Subject/AuthoritySubject';

const Entity = () => {
    let routes = (
            <Switch>
                <Route path="/config/value-set/entity/person" exact component={AuthorityPerson} />
                <Route path="/config/value-set/entity/geo" exact component={AuthorityGeo} />
                <Route path="/config/value-set/entity/corporate" exact component={AuthorityCorporate} />
                <Route path="/config/value-set/entity/subject" exact component={AuthoritySubject} />
            </Switch>
    );
    
    return routes
}

export default Entity
