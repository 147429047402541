import React, { useEffect, useState } from 'react'
import { getFormats } from '../../../../api/marc21/formats';
import useSmartRequest from '../../../../hooks/useSmartRequest';
import { Link } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import FormatTemplates from './FormatTemplates';
import { Paper } from '@material-ui/core';
import Add from '../../../../assets/icons/add-blue.svg';
import classes from '../Mainpage.module.scss';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import { makeCancelable } from '../../../../shared/util';

const Body = () => {
    const { sendRequest: sendGetFormatsRequest } = useSmartRequest(getFormats);
    const [formats, setFormats] = useState([]);
    const [marc21DefaultTemplateId, setMarc21DefaultTemplateId] = useState(null);

    useEffect(() => {
        const {cancel, promise} = makeCancelable(sendGetFormatsRequest());
          
        promise
            .then(response => {
                console.log(response);
                setFormats(response);
            })
            .catch(error => {
                console.log(error);
            });
        
        return () => {
            cancel();
        }
    }, [sendGetFormatsRequest])

    if (!formats || !formats.length) { return <Spinner /> }
    return (
        <Paper>
            <Table>
                <TableBody>
                    <TableRow className={classes.myTableHeadStyle}>
                        <TableCell className={classes.fontStyle} component="th" scope="row">Formátum</TableCell>
                        <TableCell className={classes.fontStyle} component="th" scope="row">Rekord</TableCell>
                        <TableCell className={classes.fontStyle} component="th" scope="row" style={{ width: '70%' }}>Sablon</TableCell>
                    </TableRow>
                    {formats.map((p, pkey) => {
                        if (p.profile !== 'marc21.bibliographic') {
                            return null
                        }
                        return (
                            <TableRow key={pkey} >
                                <TableCell component="td" scope="row">{p.metadataFormat}</TableCell>
                                <TableCell component="td" scope="row">
                                    {marc21DefaultTemplateId
                                        ? <Link to={'/cataloging/new/template/' + marc21DefaultTemplateId}>
                                            <img className={classes.myIconStyle} src={Add} alt="" />
                                        </Link>
                                        : null
                                    }
                                </TableCell>
                                <TableCell className={classes.tableHoverStyle}>
                                    <FormatTemplates profileId={'marc21'} setMarc21DefaultTemplateId={setMarc21DefaultTemplateId} />
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </Paper>
    )
}

export default Body;
