import React from 'react'

import styles from './RecordConnections.module.scss';
import { isEqual } from '../../../../../shared/util';
import ShowRecordMarc from '../../../../Catalog/List/ShowRecordMarc';
import { recordsSearchId, recordsSearchTitle } from '../../../../../api/catalog/recordsSearch';
import Spinner from '../../../../../components/UI/Spinner/Spinner';
import { SYMBOL_ANTICLOCKWISE_TOP_SEMICIRCLE_ARROW } from '../../../../../shared/symbols';
import { Button } from '@material-ui/core';
import { RecordEntityLinks } from '../../../../../shared/recordUtil';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';


const RecordConnections = props => {
    const {
        record,
        entityLinks,
    } = props;

    const [recordSelected, setRecordSelected] = React.useState(false);
    const [showRecord, setShowRecord] = React.useState();
    const [lastShowRecord, setLastShowRecord] = React.useState();

    if (!entityLinks) { return null; }

    const links = RecordEntityLinks(record, entityLinks);

    const showRecordHandler = elem => () => {
        setRecordSelected(true);
        if (isEqual(lastShowRecord, elem)) { return; }
        setLastShowRecord(elem);
        setShowRecord(undefined);
        if (elem.strict && elem.code === 't') {
            recordsSearchTitle(elem.value)
                .then(response => {
                    setShowRecord(response.record);
                })
                .catch(error => {
                    setRecordSelected(false);
                    setLastShowRecord(undefined);
                })
                ;
        } else {
            recordsSearchId(elem.value)
                .then(response => {
                    setShowRecord(response.record);
                })
                .catch(error => {
                    setRecordSelected(false);
                    setLastShowRecord(undefined);
                })
                ;
        }
    }

    return (
        <React.Fragment>
            {recordSelected
                ? <React.Fragment>
                    <Button className={styles.mainBlueButton} onClick={() => setRecordSelected(false)}>{SYMBOL_ANTICLOCKWISE_TOP_SEMICIRCLE_ARROW}</Button>
                    {showRecord
                        ? <ShowRecordMarc record={showRecord} />
                        : <Spinner />
                    }
                </React.Fragment>
                :
                <React.Fragment>
                    <Table>
                        <TableBody>
                            {
                                links.map((elem, index) => (
                                    <TableRow key={index} className={[elem.value ? styles.RecordConnection : null , styles.tableHoverStyle].join(' ')} onClick={showRecordHandler(elem)}>
                                        <TableCell variant={'body'}>{elem.tag}</TableCell>
                                        <TableCell align="right" variant={'body'}>${elem.code}</TableCell>
                                        <TableCell align="right" variant={'body'}>{elem.value}</TableCell>
                                    </TableRow>

                                ))}
                        </TableBody>
                    </Table>
                </React.Fragment>
            }
        </React.Fragment>
    );

}

export default React.memo(RecordConnections, (prev, next) => (
    isEqual(prev.record, next.record) &&
    isEqual(prev.entityLinks, next.entityLinks)
));
