import { useContext } from 'react'
import { LanguageContext } from '../context/language-context';

export const Translate = (props) => {
    const langContext = useContext(LanguageContext);
    if (typeof props === 'string') {
        //functional usage
        return langContext.translate(props)
    } else if (typeof props === 'object' && props !== null) {
        //for jsx usage
        return langContext.translate(props.children)
    }
}

export const useTranslator = () => useContext(LanguageContext).translate;