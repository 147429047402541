import React from 'react';
import { Button } from '@material-ui/core';
import { useTranslator } from '../../../shared/translate';
import ErrorSnackbar from '../Snackbar/ErrorSnackbar';

const SaveButton = props => {
  const {
    error,
    onSuccess,
    onFailure,
    className,
    disabled,
  } = props;
  const [ showSnackbar, setShowSnackbar ] = React.useState(false);
  const trans = useTranslator();
  const onClickHandler = () => {
    if (error) {
      onFailure();
      setShowSnackbar(true);
    } else {
      onSuccess();
    }
  }
  return (
    // errors ? trans('record_has_errors') : ''
    <React.Fragment>
      <div>
        <Button
          onClick={onClickHandler}
          className={className}
          disabled={disabled}
        >{trans('save')}</Button>
      </div>
      <ErrorSnackbar 
        isShown={showSnackbar}
        hide={() => setShowSnackbar(false)}
        message={trans('record_has_errors')}
      />
    </React.Fragment>
  );
};

export default SaveButton;