import React, { useContext } from 'react';

import Navigation from '../../components/Navigation/Navigation';
import styles from './Layout.module.css';
import { LanguageContext } from '../../context/language-context';

const Layout = (props) => {

  const langContext = useContext(LanguageContext)

  return (
    // column-reverse so shadow is above main
    <div style={{ display: (langContext.languages === null ? 'none' : 'flex'), flexDirection: 'column-reverse' }}>
      <main className={styles.Container}>
        {props.children}
      </main>
      <Navigation className={styles.Navigation} />
    </div>
  )
}

export default Layout
