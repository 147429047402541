import React from 'react';
import { Snackbar, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useTranslator } from '../../../shared/translate';


const SuccessSnackbar = props => {
  const {showSnackbar, setShowSnackbar, message} = props;
  const trans = useTranslator();

  const msg = message ? message : 'save_successful';

  return (
    <Snackbar
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      key={'top,center'}
      open={showSnackbar}
      onClose={() => setShowSnackbar(false)}
      ContentProps={{
        'style': {backgroundColor: 'green'},
      }}
      message={<span>{trans(msg)}</span>}
      autoHideDuration={5000}
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={() => setShowSnackbar(false)}>
          <CloseIcon style={{fontSize: '20'}} />
        </IconButton>,
      ]}
    />
  );
};

export default SuccessSnackbar;