import axios from '../settings/axios-backend';
import urls from '../settings/urls';

// create reader
export const createReader = (data) => {
    return axios.post(urls.READER_CREATE, data)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};


// get reader
export const getReader = (id) => {
    return axios.get(urls.READER.replace('{id}', id))
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

// get readers
export const getReaders = (id) => {
    return axios.get(urls.READERS)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

// update reader
export const updateReader = (id, data) => {
    console.log(JSON.stringify(data))
    const url = urls.READER.replace('{id}', id);
    return axios.put(url, data)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
}

// search
export const searchReader = (postData) => {
    console.log(postData)
    return axios.post(urls.READER_SEARCH, postData)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
}

// delete
export const deleteRecord = (id) => {
    const url = urls.READER.replace('{id}', id);
    return axios.delete(url)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
}

export const getLoans = (readerId) => {
    const url = urls.READER_LOANS.replace('{readerId}', readerId);
    return axios.get(url)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
}


export const getReturnedLoans = (readerId) => {
    const url = urls.READER_RETURNS.replace('{readerId}', readerId);
    return axios.get(url)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
}

export const getRequests = (readerId) => {
    const url = urls.READER_REQUESTS.replace('{readerId}', readerId);
    return axios.get(url)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
}


// loan
export const loanItem = (postData) => {
    console.log(postData)
    return axios.post(urls.LOAN, postData)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
}

// return
export const returnItem = (postData) => {
    console.log(postData)
    return axios.post(urls.RETURN, postData)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
}

// request
export const requestItem = (postData) => {
    console.log(postData)
    return axios.post(urls.REQUEST, postData)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
}






