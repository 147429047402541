import { useContext } from 'react'
import { AuthContext } from '../context/auth-context';
import { storageKeys } from '../shared/localStorageKeys';
import { redirect, objectIsEmpty } from './util';
import jwt from 'jsonwebtoken';

export const useAuth = () => useContext(AuthContext);
export const logout = () => {
    removeAccessToken();
    redirect('/')
};
export const isLoggedIn = () => {
    return !objectIsEmpty(getAccessToken())
}

export const getAccessToken = () => {
    return localStorage.getItem(storageKeys.ACCESS_TOKEN);
}

export const getAccessTokenDecoded = () => {
    return jwt.decode(getAccessToken());
}

export const setAccessToken = (x) => {
    return localStorage.setItem(storageKeys.ACCESS_TOKEN, x);
}
export const removeAccessToken = () => {
    return localStorage.removeItem(storageKeys.ACCESS_TOKEN);
}


export const user = {
    commonName: getAccessTokenDecoded() ? getAccessTokenDecoded().user.commonName : null
}
