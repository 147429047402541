import React from 'react'
import classes from './Popup.module.scss';
import { Button, Grid } from '@material-ui/core';
import Modal from '../Modal/Modal';
import { SYMBOL_MULTIPLICATION_X } from '../../../shared/symbols';

export const PopupModal = (props) => {

    const {
        title,
        body,
        footer,
        setShow,
        show,
        onClose,
    } = props;
    const size = props.size || "medium";

    const handleClose = () => {
        setShow(false);
        if (onClose instanceof Function) {
            onClose();
        }
    };
    return (
        <Grid container>
            <Grid item md={10}>
                <Modal size={size} show={show} modalClosed={handleClose}>
                    <div className={classes.Header}>
                        <div className={classes.Title}>
                            <h3>{title}</h3>
                        </div>
                        <Button className={classes.closeButton} onClick={handleClose}>
                            {SYMBOL_MULTIPLICATION_X}
                        </Button>
                    </div>
                    <div className={[classes.Body, classes.myCollectionField].join(' ')}>{body}</div>
                    <div className={classes.Footer}>
                        {footer}
                    </div>
                </Modal>
            </Grid>
        </Grid>
    );
}
export default PopupModal;
