import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import ItemListOperationButton from './ItemListOperationButton/ItemListOperationButton';
import { useTranslator } from '../../../../shared/translate';
import Spinner from '../../../../components/UI/Spinner/Spinner';

const ItemsList = (props) => {
    const { items, isLoading, setShowDeletePopupHandler } = props
    const t = useTranslator()
    if (isLoading === true) {
        return <Spinner />
    }
    return (
        <React.Fragment>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('item.status')}</TableCell>
                        <TableCell>{t('item.condition')}</TableCell>
                        <TableCell>{t('item.inventoryNumber')}</TableCell>
                        <TableCell>{t('item.barcode')}</TableCell>
                        <TableCell>{t('item.location')}</TableCell>
                        <TableCell>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item, key) => (
                        <TableRow hover key={key}>
                            <TableCell>{item.status}</TableCell>
                            <TableCell>{item.condition}</TableCell>
                            <TableCell>{item.inventoryNumber}</TableCell>
                            <TableCell>{item.barcode}</TableCell>
                            <TableCell>{item.location}</TableCell>
                            <TableCell>
                                <div style={{ textAlign: 'right' }}>
                                    <ItemListOperationButton
                                        item={item}
                                        setShowDeletePopupHandler={setShowDeletePopupHandler}
                                    />
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    )
}

export default ItemsList
