import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button
  } from '@material-ui/core';

import TableGrid from '../TableGrid';
import { getRequests, requestItem } from '../../../../api/reader_service/readerService';
import { dateFormatString } from '../../../../shared/util';
import CatalogSearch from './CatalogSearch';
import { useTranslator } from '../../../../shared/translate';
import { makeStyles } from '@material-ui/core/styles';
import ConfigContext from '../../../../context/snackbar-context';
import { useAuth } from '../../../../shared/auth';
import { permissions } from '../../../../shared/permissions';

const TABLE_COLUMNS = [
    { label: "title", map: "title" },
    { label: "barcode", map: "barcode" },
    { label: "request_date", map: "requestDate", format: dateFormatString },
    { label: "expiry_date", map: "expiryDate", format: dateFormatString }
];

const useStyles = makeStyles({
    mainBlueButton: {
        backgroundColor: '#2b75bb',
        borderRadius: 6,
        color: 'white',
        fontSize: 14,
        textTransform: 'capitalize',
        padding: '4px 14px',
        fontFamily: 'Source Sans Pro',
        fontWeight: 500,
        zIndex: 'inherit',
        '&:hover': {
            background: '#169bd5',
        },
    },
    inputStyle: {
        padding: 10,
        fontSize: 14,
        fontWeight: 500,
    }
});


const RequestTab = (props) => {

    const { readerId } = props;

    const auth = useAuth();
    const classes = useStyles();
    const trans = useTranslator();

    const { setShowSnackbar } = React.useContext(ConfigContext);

    const [returnedRequests, setReturnedRequests] = useState([]);
    const [recordId, setRecordId] = React.useState('');

    const loadRecords = () => {
        getRequests(readerId).then(res => {
            setReturnedRequests(res);
        });
    }

    useEffect(() => {
        loadRecords();
    }, []);

    const requestHandler = () => {
        requestItem({
            bibId: recordId,
            readerId: readerId
        }).then(res => {
            setShowSnackbar(true);
            loadRecords();
        });
    }

    const linkEntry = (entry) => {
        setRecordId(entry.id);
    }

    return (
        <React.Fragment>
            {auth.isGranted(permissions.READER_SERVICE_REQUEST_CREATE) ?
                <Grid container style={{ marginTop: "20px" }}>
                    <Grid item md={4} sm={4} style={{ margin: "0 auto" }}>
                        <CatalogSearch linkEntry={linkEntry} />
                    </Grid>
                    <Grid item md={8} sm={8} style={{ margin: "0 auto" }}>
                        <Button
                                onClick={requestHandler}
                                className={classes.mainBlueButton}
                        >{trans('request')}</Button>
                    </Grid>
                </Grid>
            : null }
            <Grid container justify="center" style={{ marginTop: "20px" }}>
                <Grid item md={12} sm={12} style={{ margin: '0 auto' }}>
                    <TableGrid 
                        records={returnedRequests}
                        columns={TABLE_COLUMNS}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default RequestTab;