import React from 'react';
import { Grid, Tooltip } from '@material-ui/core'
import Header from '../Header/Header';
import List from './List/List';
import classes from './Reader.module.scss';
import Add from '../../../assets/icons/add-blue.svg';
import { Link } from 'react-router-dom';
import { useTranslator } from '../../../shared/translate';
import { useAuth } from '../../../shared/auth';
import { permissions } from '../../../shared/permissions';

const Reader = () => {

    const trans = useTranslator();
    const auth = useAuth();

    return (
        <React.Fragment>
            <Header title="reader_service" />
            
                <Grid container justify="center" spacing={5} style={{marginTop: "50px"}}>
                    <Grid item sm={12}>
                        {auth.isGranted(permissions.READER_SERVICE_READER_CREATE) ?
                            <div className={classes.alignRight}>
                                <Tooltip title={trans('add_new_reader')}>
                                <Link to='/reader_service/new/reader'>
                                    <img className={classes.myIconStyle} src={Add} alt="" />
                                </Link>
                                </Tooltip>
                            </div>
                        : null}

                        <List />
                    </Grid>
                </Grid>
            
        </React.Fragment>
    )
}

export default Reader;