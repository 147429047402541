const urls = {
    //global
    GET_LANGUAGES: 'language',

    //metadata
    GET_METADATA_PROFILES: 'metadata-profiles',
    GET_METADATA_PROFILE_MARC21: 'metadata-profiles/marc21',
    METADATA_PROFILE_MARC21_CALLSIGN_DATA: 'metadata-profiles/marc21/update-callsign',

    //template
    TEMPLATE: 'template/id/{id}',
    TEMPLATES: 'templates',
    TEMPLATE_COUNT: 'templates/count',
    TEMPLATE_OPTIONS: 'templates/options',

    //record
    RECORDS: 'records',
    RECORD_CONVERT_MARCXML: 'record/convert/marcxml',
    RECORD_CONVERT_BIBFRAME: 'record/convert/bibframe',
    RECORDS_SEARCH_SIMPLE: 'records/search-simple',
    RECORDS_SEARCH_COMPLEX: 'records/search-complex',
    RECORDS_SEARCH_FILTER_OPTIONS: 'records/search-filter-options',
    RECORDS_SEARCH_SORT_OPTIONS: 'records/search-sort-options',
    RECORDS_SEARCH_ID: 'records/search-id',
    RECORDS_SEARCH_TITLE: 'records/search-title',

    DOCUMENT_FORMS: 'document_forms',

    //collection
    COLLECTION: 'collections/id/{id}',
    COLLECTION_CREATE: 'collections/create',
    COLLECTIONS: 'collections/all',

    //entities
    ENTITY: 'entities/id/{id}',
    ENTITIES: 'entities/all',
    ENTITIES_BY_COLLECTION: 'entities/collection/{id}',

    //authority schema
    MARC21_AUTHORITY_SCHEMA: 'marc21/authority_schema',
    MARC21_AUTHORITY_SCHEMA_UPDATE_CALLSIGN: 'marc21/authority_schema/update/{type}',

    MARC21_AUTHORITY_SCHEMA_BY_TYPE: 'marc21/authority_schema/type/{type}',

    //authority
    AUTHORITY_RECORD: '/marc21/authority_records/type/{type}/id/{id}',
    AUTHORITY_RECORDS: '/marc21/authority_records/type/{type}',
    AUTHORITY_RECORDS_FILTERS: '/marc21/authority_records/filters/{type}',
    AUTHORITY_RECORDS_BY_COLLECTION: 'entities/collection/{collectionId}',

    //holding
    MARC21_HOLDING_SCHEMA: 'marc21/holding_schema',
    MARC21_HOLDING_SCHEMA_UPDATE_CALLSIGN: 'marc21/holding_schema/update',

    MARC21_HOLDING: '/holdings',
    MARC21_HOLDING_ID: '/holdings/{id}',
    HOLDINGS_BY_BIBLOGRAPHIC_ID: '/bibliographic/{id}/holdings',

    //item
    ITEM: '/items',
    ITEM_ID: '/items/{id}',
    ITEM_HISTORY: '/items/{id}/history',
    ITEMS_BY_HOLDING_ID: '/holdings/{id}/items',

    //login
    LOGIN: 'login',

    //reader
    READER_CREATE: '/reader_service/create',
    READER: 'reader_service/reader/id/{id}',
    READERS: 'reader_service/readers',
    READER_SEARCH: 'reader_service/search',

    READER_LOANS: '/reader_service/loans/{readerId}',
    READER_RETURNS: '/reader_service/returns/{readerId}',
    READER_REQUESTS: '/reader_service/requests/{readerId}',

    LOAN: '/reader_service/loan',
    RETURN: '/reader_service/return',
    REQUEST: '/reader_service/request',
};

export default urls;
