import React, { Suspense } from 'react';

import Layout from './hoc/Layout/Layout';

import { Route, Switch, Redirect } from 'react-router-dom';
import Config from './containers/Config/Config';
import Catalog from './containers/Catalog/Catalog';
import Cataloging from './containers/Cataloging/Cataloging';
import Acquisition from './containers/Acquisition/Acquisition';
import { useAuth, isLoggedIn } from './shared/auth';
import { permissions } from './shared/permissions';
import Login from './containers/Login/Login';
import CssBaseline from '@material-ui/core/CssBaseline';
import Profile from './containers/Profile/Profile';
import ReaderService from './containers/ReaderService/ReaderService';

import SuccessSnackbar from './components/UI/Snackbar/Success';
import ConfigContext from './context/snackbar-context'

function App() {
    const auth = useAuth();
    
    const { showSnackbar, setShowSnackbar, message } = React.useContext(ConfigContext);

    if (isLoggedIn()) {
        let routes = (
            <Switch>
                <Route path="/config" component={Config} />
                {auth.isGranted(permissions.CATALOGING_READ) ? <Route path="/cataloging" component={Cataloging} /> : null}
                {auth.isGranted(permissions.CATALOG_READ) ? <Route path="/catalog" component={Catalog} /> : null}
                {true /* auth.isGranted(permissions.ACQUISITION_READ) */ ? <Route path="/acquisition" component={Acquisition} /> : null}
                <Route path="/profile" component={Profile} />
                <Route path="/login" component={Login} />
                {auth.isGranted(permissions.READER_SERVICE_READ) ? <Route path="/reader_service" component={ReaderService} /> : null}
                <Redirect to="/catalog" />
            </Switch>
        );
        return (
            <Layout>
                <CssBaseline />
                <Suspense fallback={<p>Loading...</p>}>
                    {routes}

                    <SuccessSnackbar
                        message={message}
                        showSnackbar={showSnackbar}
                        setShowSnackbar={setShowSnackbar}
                    />
                    
                </Suspense>
            </Layout>
        );
    } else {
        return <Login />
    }
}

export default App;
