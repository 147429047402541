import React, { useState, useEffect, useContext } from 'react'
import Select from '@material-ui/core/Select';

import MenuItem from '@material-ui/core/MenuItem';
import { CardContent, Card, Grid, Typography } from '@material-ui/core';
import { useTranslator } from '../../shared/translate';
import getLanguages from '../../api/languages';
import { LanguageContext } from '../../context/language-context';

const Profile = () => {
    const t = useTranslator();
    const langContext = useContext(LanguageContext);

    const [selectedLanguage, setSelectedLanguage] = useState(langContext.locale)
    const [languageKeys, setLanguageKeys] = useState([])
    const handleLanguageChange = event => {
        setSelectedLanguage(event.target.value)
        langContext.handleLocaleChange(event.target.value)
    };

    useEffect(() => {
        getLanguages().then(res => {
            setLanguageKeys(Object.keys(res))
        })
    }, [])

    if (languageKeys.length === 0) {
        return null
    }
    return (
        <Card>
            <h1 style={{ marginLeft: '30px' }}>{t('profile')}</h1>
            <CardContent>
                <Grid container>
                    <Grid item md={12}>
                        <Card>
                            <CardContent>
                                <Grid item md={3}>

                                    <Typography gutterBottom variant="h5" component="h2">
                                        {t('settings')}
                                    </Typography>
                                    <br />
                                    {t('language')}
                                    <Select
                                        value={selectedLanguage}
                                        onChange={handleLanguageChange}
                                        variant="outlined"
                                        fullWidth
                                    >
                                        {languageKeys.map((lk, key) => (
                                            <MenuItem key={key} value={lk}>{t('language.' + lk)}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default Profile
