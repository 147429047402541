import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';

import ConfigContext from '../../../../../../context/snackbar-context';
import Marc21CallsignEdit from '../../../../../../components/MetadataProfiles/Marc21/Callsign/Edit/Marc21CallsignEdit';
import useSmartRequest from '../../../../../../hooks/useSmartRequest';
import { deepCopy, findItem, isEqual } from '../../../../../../shared/util'
import { useTranslator } from '../../../../../../shared/translate';
import { Popup } from '../../../../../../components/UI/Popup/Popup';
import metadataProfileMarc21 from '../../../../../../api/marc21/bibliographic/bibliographicSchema';
import bibliographicSchemaUpdateCallsign from '../../../../../../api/marc21/bibliographic/bibliographicSchemaUpdateCallsign';
import { useAuth } from '../../../../../../shared/auth';
import { permissions } from '../../../../../../shared/permissions';
import {
  Button
} from '@material-ui/core';
import Close from '../../../../../../assets/icons/closeX.svg';
import classes from './Marc21Callsign.module.scss';
import Marc21CallsignShow from '../../../../../../components/MetadataProfiles/Marc21/Callsign/Show/Marc21CallsignShow';
import { getAuthoritySchemaByType } from '../../../../../../api/marc21/authority/authoritySchema';
import { marc21Profiles } from '../../../../../../shared/parameters/marc21Profiles';
import { getHoldingSchema, holdingSchemaUpdateCallsign } from '../../../../../../api/marc21/holding/holdingSchema';
import { authoritySchemaUpdateCallsign } from '../../../../../../api/marc21/authority/authority';
import BackLink from '../../../../../../components/UI/Link/BackLink';

const Marc21Callsign = (props) => {
  const { isEditableDefault } = props;
  const callsign = props.match.params.callsign;

  const profileFormat = props.match.params.format
  const profileFormatType = props.match.params.type

  const trans = useTranslator();

  const { setShowSnackbar } = React.useContext(ConfigContext);

  const [callsignData, setCallsignData] = useState(null)
  const [isEditable] = useState(isEditableDefault)

  const [callsignDataOriginal, setCallsignDataOriginal] = useState(null)

  const { sendRequest: bibliographicUpdateRequest, isSending: bibliographicUpdateRequestIsSending } = useSmartRequest(bibliographicSchemaUpdateCallsign);
  const { sendRequest: authorityUpdateRequest, isSending: authorityUpdateRequestIsSending } = useSmartRequest(authoritySchemaUpdateCallsign);
  const { sendRequest: holdingUpdateRequest, isSending: holdingUpdateRequestIsSending } = useSmartRequest(holdingSchemaUpdateCallsign);

  const [showPopup, setShowPopup] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    const parseCallsign = (data) => {
      let field;
      if ('000' === callsign) {
        field = data.leader;
      } else if (Number.parseInt(callsign) < 10) {
        field = findItem(data.controlfields, 'tag', callsign);
      } else {
        field = findItem(data.datafields, 'tag', callsign);
      }
      return field
    }

    switch (profileFormat) {
      case marc21Profiles.bibliographic:
        metadataProfileMarc21()
          .then(data => {
            setCallsignData(parseCallsign(data));
            setCallsignDataOriginal(parseCallsign(data));
          });
        break;
      case marc21Profiles.authority:
        getAuthoritySchemaByType(profileFormatType)
          .then(data => {
            setCallsignData(parseCallsign(data));
            setCallsignDataOriginal(parseCallsign(data));
          });
        break;
      case marc21Profiles.holding:
        getHoldingSchema()
          .then(data => {
            setCallsignData(parseCallsign(data));
            setCallsignDataOriginal(parseCallsign(data));
          });
        break;
      default: console.log('Undefined profileFormat: ' + profileFormat)
    }

  }, [callsign, profileFormat, profileFormatType])



  const updateCallsignDataHandler = (event) => {
    let callsignDataNew = updateLastModified(callsignData);
    callsignDataNew = filterCollectionsIfNotSelectField(callsignDataNew)
    const setCallsighHandler = () => {
      setShowSnackbar(true);
      setCallsignData(callsignDataNew);
      setCallsignDataOriginal(callsignDataNew);
      props.history.push(backLink());
    }
    console.log(profileFormatType)
    switch (profileFormat) {
      case marc21Profiles.bibliographic: bibliographicUpdateRequest(callsignDataNew).then(() => setCallsighHandler()); break;
      case marc21Profiles.authority: authorityUpdateRequest(callsignDataNew, profileFormatType).then(() => setCallsighHandler()); break;
      case marc21Profiles.holding: holdingUpdateRequest(callsignDataNew).then(() => setCallsighHandler()); break;
      default: console.log('Undefined profileFormat: ' + profileFormat)
    }
  }

  const updateLastModified = (callsignDataInput) => {
    const callsignDataNew = deepCopy(callsignDataInput);

    //set modified dates if neccessary
    if (callsignDataNew.indicator1) {
      addLastModified(callsignDataNew.indicator1.codes, callsignDataOriginal.indicator1.codes);
    }
    if (callsignDataNew.indicator2) {
      addLastModified(callsignDataNew.indicator2.codes, callsignDataOriginal.indicator2.codes);
    }
    if (callsignDataNew.subfields) {
      addLastModified(callsignDataNew.subfields, callsignDataOriginal.subfields);
    }

    //set modified dates to whole callsign if something modified
    if (isEqual(callsignDataNew, callsignDataOriginal) === false) {
      callsignDataNew.lastModified = new Date().toISOString();
    }

    return callsignDataNew;
  }

  const addLastModified = (data, dataOriginal) => {
    if (typeof data !== 'object' || data === null) {
      return;
    }

    data.map(item => {
      if (!isEqual(item, dataOriginal.find(originalItem => item.code === originalItem.code))) {
        item.lastModified = new Date().toISOString();
      }
      return item;
    });
  }

  const filterCollectionsIfNotSelectField = (inputCallsignData) => {
    inputCallsignData = deepCopy(inputCallsignData)
    if (typeof inputCallsignData.subfields === 'undefined') {
      return inputCallsignData
    }

    let subfields = inputCallsignData.subfields

    for (const [key, value] of Object.entries(subfields)) {
      //if not select anymore, remove collections
      if (value.input !== 'select' && typeof value.collections === 'object') {
        delete inputCallsignData.subfields[key].collections
      }
      //if select but not selected anything yet, create empty array instead of undefined
      if (value.input === 'select' && typeof value.collections === 'undefined') {
        inputCallsignData.subfields[key].collections = []
      }
    }
    return inputCallsignData
  }

  // const savePopupHandler = (event) => {
  //   setShowPopup(false);
  //   updateCallsignDataHandler()
  // }

  const backLink = () => {
    switch (profileFormat) {
      case marc21Profiles.bibliographic: return '/config/metadata-profile/marc21/' + profileFormat
      case marc21Profiles.authority: return '/config/metadata-profile/marc21/' + profileFormat + '/' + profileFormatType
      case marc21Profiles.holding: return '/config/metadata-profile/marc21/' + profileFormat
      default: return '/'

    }
  }

  const isSendingHandler = () => {
    switch (profileFormat) {
      case marc21Profiles.bibliographic: return bibliographicUpdateRequestIsSending
      case marc21Profiles.authority: return authorityUpdateRequestIsSending
      case marc21Profiles.holding: return holdingUpdateRequestIsSending
      default: console.log('Undefined profileFormat: ' + profileFormat); return false;
    }
  }

  if (callsignData === null || typeof callsignData === 'undefined') { return (''); }
  else {
    return (
      <React.Fragment>
        <Grid container justify="center" className={classes.cardHeader} >
          <Grid item sm={11}>
            <BackLink to={backLink()} title={'back-to-marc21-profile-page'} /> {trans('callsign-data-page')}: {callsign}, {trans(callsignData.label)}
          </Grid>
          <Grid item sm={1}>
            <div>
              <Link to={'/config/'} ><img className={classes.Image} src={Close} alt="" /></Link>
            </div>
          </Grid>
        </Grid>

        <Grid container justify="center" style={{ marginTop: "50px" }}>
          <Grid item md={10} sm={12}>
            {isEditable
              ? <Marc21CallsignEdit callsignData={callsignData} setCallsignData={setCallsignData} />
              : <Marc21CallsignShow callsignData={callsignData} />
            }

            {auth.isGranted(permissions.CONFIG_METADATAPROFILE_UPDATE) ?
              <div className={classes.myStickyTop}>
                {!isEditable
                  ? null
                  : <React.Fragment>
                    <Grid container justify="flex-end" className={classes.marginTop} >
                      <Grid item md={3} sm={4} style={{ textAlign: "right" }}>
                        <Button
                          onClick={updateCallsignDataHandler}
                          className={classes.mainBlueButton}
                          disabled={isSendingHandler()}
                        >
                          {trans('save')}
                        </Button>
                        <Button onClick={() => setShowPopup(true)} className={classes.myRedButton}>{trans('dismiss')}</Button>
                      </Grid>
                    </Grid>
                    <Popup
                      show={showPopup}
                      cancel={() => setShowPopup(false)}
                      save={() => props.history.push(backLink())}
                      title={trans('dismiss_confirmation')}
                    />
                  </React.Fragment>
                }
              </div>
              : null}

          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default withRouter(Marc21Callsign);