import React from 'react';

import { IconButton } from '@material-ui/core';
import {
  DeleteOutlined as DeleteOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
  AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon,
  Search as SearchIcon,
  Clear as ClearIcon,
} from '@material-ui/icons';


export const ToggleTableButton = props => (
  <IconButton aria-label="visibility" size="medium" onClick={props.onClick}>
    {props.isOpen ? <VisibilityOutlinedIcon fontSize="default" /> : <VisibilityOffOutlinedIcon fontSize="default" />}
  </IconButton>
);

export const AddFieldButton = props => (
  <div style={{ paddingTop: '25px', paddingBottom: '10px' }}>
    <div style={{ textAlign: "right", height: '2px', backgroundColor: '#2b75bb', }}>
      <IconButton style={{ zIndex: 1, position: 'relative', bottom: '20px', left: '50px', color: '#2b75bb' }} aria-label="file_copy" size="small" onClick={props.onClick}>
        <AddCircleOutlineOutlinedIcon fontSize="large" />
      </IconButton>
    </div>
  </div>
);

export const CloneFieldButton = props => (
  <IconButton size="medium" onClick={props.onClick}>
    <FileCopyOutlinedIcon fontSize="default" />
  </IconButton>
);

export const DeleteFieldButton = props => (
  <IconButton size="medium" color="primary" onClick={props.onClick}>
    <DeleteOutlinedIcon fontSize="default" />
  </IconButton>
)

export const AddSubfieldButton = props => (
  <IconButton size="medium" onClick={props.onClick}>
    <AddCircleOutlineOutlinedIcon fontSize="default" />
  </IconButton>
);

export const CloneSubfieldButton = props => (
  <IconButton size="small" onClick={props.onClick}>
    <FileCopyOutlinedIcon fontSize="small" />
  </IconButton>
);

export const DeleteSubfieldButton = props => (
  <IconButton size="small" onClick={props.onClick}>
    <DeleteOutlinedIcon fontSize="small" />
  </IconButton>
)

export const SearchButton = props => (
  <IconButton size="small" onClick={props.onClick}>
    <SearchIcon fontSize="small" />
  </IconButton>
)

export const ClearButton = props => (
  <IconButton size="small" onClick={props.onClick}>
    <ClearIcon fontSize="small" />
  </IconButton>
)
