import React from 'react'

import Grid from '@material-ui/core/Grid';

import classes from './Header.scss';

const Header = (props) => {
  let header = (
    <Grid container justify="center" className={classes.cardHeader} style={{marginTop: "0px"}} spacing={5} >
      <Grid item>
        {props.children}
      </Grid>
    </Grid>
  );
  return header;
};

export default Header;
