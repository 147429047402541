import React from 'react'
import { Switch, Route, Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import ValueSetMenu from './ValueSetMenu/ValueSetMenu';
import CollectionList from './Collection/CollectionList/CollectionList';
import Entity from './Entity/Entity';
import { useTranslator } from '../../../shared/translate';
import {
    Container
} from '@material-ui/core';

import Close from '../../../assets/icons/closeX.svg';
import classes from './Selector.module.scss';

const Selector = (props) => {
    const currentPath = props.location.pathname;
    const t = useTranslator();
    let routes = (
        <Switch>
            <Route path="/config/value-set/entity" render={() => <Entity />} />
            <Route path="/config/value-set/collection" render={() => <CollectionList />} />
        </Switch>
    );
    
    return (
        <React.Fragment>

            <Grid container justify="center" spacing={5} className={classes.cardHeader} style={{ marginTop: "0px" }}>
                <Grid item sm={11}>
                    {t('value-set')}
                </Grid>
                <Grid item sm={1}>

                    <Link to="/">
                        <img className={classes.Image} src={Close} alt="" />
                    </Link>

                </Grid>
            </Grid>


            <Container maxWidth="lg">
                <Grid container spacing={5} style={{marginTop: "50px"}}>
                    <Grid item sm={3} className={classes.myBorderStyle} >
                        <ValueSetMenu currentPath={currentPath} />
                    </Grid>
                    <Grid item sm={9}>
                        {routes}
                    </Grid>
                </Grid>
            </Container>

        </React.Fragment>

    )
}




export default Selector
