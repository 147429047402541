import React from 'react'

import Grid from '@material-ui/core/Grid';

import ItemForm from '../../../Item/Item/ItemForm';


const AddItemForm = (props) => {
    const { saveHandler } = props;

    return (
        <React.Fragment>
            <Grid
                container
                justify="center"
            >
                <Grid
                    item
                    xs={6}
                >
                    <ItemForm buttonClick={saveHandler} buttonText={'save'} />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default AddItemForm;
