import React from 'react'
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import PopupModal from '../../../../UI/Popup/PopupModal'
import { Button } from '@material-ui/core';
import { useTranslator } from '../../../../../shared/translate';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    myTableHeadStyle: {
        backgroundColor: '#e6e6e6',
    },
    tableHoverStyle: {
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: 'rgba(37, 118, 195, 0.1)',
        }
    },
    paddingStyle: {
        padding: '10px !important',
      },
});


const ValuesetPopup = (props) => {
    const classes = useStyles();
    const {
        showInputPopup,
        collections,
        defaultSelectedCollection,
        saveInputPopupHandler,
        cancelInputPopupHandler
    } = props

    const [currentCollections, setCurrentCollections] = React.useState([]);

    const loadDefaultCollectionsToCurrent = React.useCallback(
        () => {
            const arr = [];
            for (const coll of collections) {
                arr.push({
                    id: coll.id,
                    name: coll.name,
                    checked: coll.id === defaultSelectedCollection,
                });
            }
            setCurrentCollections(arr)
        },
        [collections, defaultSelectedCollection],
    )

    React.useEffect(() => {
        loadDefaultCollectionsToCurrent()

    }, [loadDefaultCollectionsToCurrent])

    const trans = useTranslator();

    const getPopupContent = () => {
        return (
            <React.Fragment>
                <Table aria-label="a dense table">
                    <TableHead className={classes.myTableHeadStyle}>
                        <TableRow>
                            <TableCell variant={'head'}> </TableCell>
                            <TableCell variant={'head'}>{trans('id')}</TableCell>
                            <TableCell variant={'head'}>{trans('name')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentCollections.map((c, key) => {
                            return (
                                <React.Fragment key={key}>
                                    <TableRow className={classes.tableHoverStyle}>
                                        <TableCell variant={'body'}>
                                            <Button
                                                disabled={c.checked}
                                                variant={'contained'}
                                                color={'primary'}
                                                onClick={() => saveInputPopupHandler(c.id)}
                                            >{c.checked ? trans('selected') : trans('select')}</Button>
                                        </TableCell>
                                        <TableCell className={classes.paddingStyle} variant={'body'}>{c.id}</TableCell>
                                        <TableCell className={classes.paddingStyle} variant={'body'}>{c.name}</TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
                <div style={{ textAlign: 'right' }}>
                    <Button
                        onClick={() => {
                            loadDefaultCollectionsToCurrent()
                            cancelInputPopupHandler()
                        }}
                    >{trans('dismiss')}</Button>
                </div>
            </React.Fragment>
        )
    }

    return (
        <PopupModal
            show={showInputPopup}
            setShow={cancelInputPopupHandler}
            cancel={cancelInputPopupHandler}
            title={trans('change-valueset')}
            body={getPopupContent()}
        />
    )
}

export default ValuesetPopup
