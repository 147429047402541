import React, { useState, useEffect } from 'react';
import { 
    Grid, 
    TextField, 
    Button, 
    Select,  
    InputLabel, 
    FormControl 
} from '@material-ui/core';
import Header from '../../Header/Header';
import { Translate as T, useTranslator } from '../../../../shared/translate';
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
  } from "@material-ui/pickers";
import { createReader, getReader, updateReader } from '../../../../api/reader_service/readerService';
import ConfigContext from '../../../../context/snackbar-context';
import { withRouter } from 'react-router';
import { useAuth } from '../../../../shared/auth';
import { permissions } from '../../../../shared/permissions';

const ReaderForm = (props) => {

    const { id: readerId } = props.match.params;
    const { setShowSnackbar } = React.useContext(ConfigContext);

    const trans = useTranslator();
    const auth = useAuth();

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const useStyles = makeStyles(theme => ({
        formControl: {
          margin: theme.spacing(1),
          minWidth: 120
        },
      }));

    
    const classes = useStyles();

    const date = new Date();

    const id = useState()[0];
    const [lastname, setLastname] = useState('');
    const [firstname, setFirstname] = useState('');
    const [gender, setGender] = useState('male');
    const [birthName, setBirthName] = useState('');
    const [birthPlace, setBirthPlace] = useState('');
    const [birthDate, setBirthDate] = useState(date);
    const [motherName, setMotherName] = useState('');
    const [idCardNumber, setIdCardNumber] = useState('');
    const [barcode, setBarcode] = useState('');

    const [readerCardValidity, setReaderCardValidity] = useState(date);
    const [readerType, setReaderType] = useState('alkalmazott');
    const [language, setLanguage] = useState('hungarian');

    const [country, setCountry] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [comment, setComment] = useState('');


    const textFields = {
        lastname: [trans('lastname'), lastname, setLastname],
        firstname: [trans('firstname'), firstname, setFirstname],
        birthName: [trans('birth_name'), birthName, setBirthName],
        birthPlace: [trans('birth_place'), birthPlace, setBirthPlace],
        motherName: [trans('mother_name'), motherName, setMotherName],
        idCardNumber: [trans('idcard_number'), idCardNumber, setIdCardNumber],
        barcode: [trans('barcode'), barcode, setBarcode],

        country: [trans('country'), country, setCountry],
        zipCode: [trans('zip_code'), zipCode, setZipCode],
        city: [trans('city'), city, setCity],
        address: [trans('address'), address, setAddress],
        email: [trans('email'), email, setEmail],
        phone: [trans('phone'), phone, setPhone],
    };

    const selectFields = {
        language: [trans('language'), language, setLanguage],
        readerType: [trans('reader_type'), readerType, setReaderType],
        gender: [trans('gender'), gender, setGender],
    };

    const dateFields = {
        birthDate: [trans('birth_date'), birthDate, setBirthDate],
        readerCardValidity: [trans('reader_card_validity'), readerCardValidity, setReaderCardValidity],
    };

    const readerTypes = [
        { value: 'alkalmazott', label: 'Alkalmazott' },
        { value: 'gyerek', label: 'Gyerek' },
        { value: 'diak', label: 'Diák' },
        { value: 'felnott', label: 'Felnőtt' },
        { value: 'nyugdijas', label: 'Nyugdíjas' },
    ];

    const setDataToState = (data) => {

        for (var prop in data) {
            if (Object.prototype.hasOwnProperty.call(data, prop)) {
                if(textFields.hasOwnProperty(prop)){
                    const field = textFields[prop];
                    field[2](data[prop]);
                }else if(selectFields.hasOwnProperty(prop)){
                    const field = selectFields[prop];
                    field[2](data[prop]);
                }else if(dateFields.hasOwnProperty(prop)){
                    const field = dateFields[prop];
                    field[2](data[prop]);
                }
            }
        }

    };

    useEffect(() => {
        if(readerId){
            getReader(readerId).then(res => {
                setDataToState(JSON.parse(res));
            });
        }
    }, []);

    const generateTextField = (fieldName, required = false) => {
        const field = textFields[fieldName];

        // const validation = validateRequired(field[1]);
        let errorHelper = '';
        let error = false;
        // if(validation !== true){
        //     errorHelper = validation;
        //     error = true;
        // }
        
        return (
            <TextField key={fieldName}
                required={required}
                label={field[0]}
                value={field[1] || ''}
                onChange={(event) => field[2](event.target.value)}
                margin="normal"
                variant="outlined"
                fullWidth
                error={error}
                helperText={errorHelper}

            />
        );
    };

    // const validateRequired = (value) => {
    //     if(!value){
    //         return false;
    //     }
    //     return true;
    // }

    const generateDateField = (fieldName) => {

        const field = dateFields[fieldName];

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container>
                    <KeyboardDatePicker
                        disableToolbar
                        required
                        variant="outline"
                        format="yyyy.MM.dd"
                        margin="normal"
                        label={field[0]}
                        value={field[1]}
                        onChange={(date) => field[2](date)}
                        KeyboardButtonProps={{
                            "aria-label": "change date"
                        }}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
        );
    }

    const generateSelect = (fieldName, options) => {

        const field = selectFields[fieldName];
        const fieldId = fieldName + '-select';

        return (
            <FormControl 
                variant="outlined" 
                fullWidth 
                margin="normal"
            >
                <InputLabel ref={inputLabel} htmlFor={fieldId}>
                    {field[0]}
                </InputLabel>
                <Select 
                    native
                    fullWidth
                    inputProps={{
                        name: fieldName,
                        id: fieldId,
                        className: classes.inputStyle
                    }}
                    labelWidth={labelWidth}
                    value={field[1]}
                    onChange={(event) => field[2](event.target.value)}
                >
                    {
                        options.map((type, key) => {
                            return <option key={type.value} value={type.value}>{trans(type.label)}</option>
                        })
                    }
                </Select>
            </FormControl>
        );
    };

    const saveHandler = () => {

        const data = {
            lastname: lastname, firstname: firstname, gender, birthName, birthPlace, birthDate, motherName,idCardNumber,
            barcode, readerCardValidity, readerType, language, country, zipCode, city, address, 
            email, phone, comment
        };

        if(id){
            updateReader(id, data).then(res => {
                setShowSnackbar(true);
            });
        }else{
            createReader(data).then(res => {
                props.history.push('/reader_service');
                setShowSnackbar(true);
            });
        }

    };

    return (
        <React.Fragment>
            <Header title={ readerId ? "edit_reader" : "new_reader"} backTo="/reader_service" backToTitle="reader_service" />
            <Grid container justify="center" spacing={5} style={{marginTop: "50px"}}>
                <Grid item sm={6} >
                    <form>
                        {auth.isGranted(permissions.READER_SERVICE_READER_UPDATE) ?
                            <Button
                                variant="contained"
                                color={'primary'}
                                onClick={() => saveHandler({})}
                            >
                                <T>save</T>
                            </Button>
                        : null }

                        {generateTextField('lastname', true)}
                        {generateTextField('firstname', true)}

                        {generateSelect('gender', [
                            {label: 'male', value: 'male'},
                            {label: 'female', value: 'female'}
                        ])}

                        {generateTextField('birthName')}
                        {generateTextField('birthPlace', true)}
                        
                        {generateDateField('birthDate')}

                        {generateTextField('motherName', true)}
                        {generateTextField('idCardNumber')}
                        {generateTextField('barcode', true)}

                        {generateDateField('readerCardValidity')}

                        {generateSelect('readerType', readerTypes)}

                        {generateSelect('language', [
                            {label: 'language.hu', value: 'hungarian'},
                            {label: 'language.en', value: 'english'}
                        ])}

                        {generateTextField('country', true)}
                        {generateTextField('zipCode', true)}
                        {generateTextField('city', true)}
                        {generateTextField('address', true)}
                        {generateTextField('email')}
                        {generateTextField('phone')}

                        <TextField key='comment'
                            label={trans('comment')}
                            value={comment || ''}
                            onChange={(event) => setComment(event.target.value)}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            multiline={true}
                            rows={4}
                            rowsMax={6}
                        />
                        
                    </form>
                </Grid>
            </Grid>
        </React.Fragment>
    )
};

export default withRouter(ReaderForm);