import React from 'react'
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom'
import Close from '../../../../assets/icons/closeX.svg';
import classes from './Header.module.scss';

const Header = () => {
    return (
        <Grid container justify="center" spacing={5} className={classes.cardHeader} style={{marginTop: "0px"}}>
            <Grid item sm={11}>
                Katalogizálás
            </Grid>
            <Grid item sm={1}>
            <div >
              <Link to="/">
              <img className={classes.Image} src={Close} alt="" />
              </Link>
            </div>
          </Grid>
        </Grid>
    )
}

export default Header
