import React from 'react'
import { NavLink } from 'react-router-dom'

import classes from './Navigation.module.css';
import { useTranslator } from '../../shared/translate';

import Items from '../../assets/icons/Items.svg';
import Search from '../../assets/icons/Imported files.svg';
import Pref from '../../assets/icons/Preferences.svg';
import Usericon from '../../assets/icons/usericon.svg';

import { useAuth, user, logout } from '../../shared/auth';
import { permissions } from '../../shared/permissions';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

import {
  Menu,
  MenuItem,
  Tooltip,
  Divider
} from '@material-ui/core/';

const Navigation = props => {
  const auth = useAuth();
  const t = useTranslator();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElConfig, setAnchorElConfig] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClickConfig(event) {
    setAnchorElConfig(event.currentTarget);
  }
  function handleCloseConfig() {
    setAnchorElConfig(null);
  }

  const isConfigPath = () => {
    return props.history.location.pathname.startsWith('/config')
  }

  const gotoProfileAndClose = (url) => {
    props.history.push(url)
    handleClose()
  }

  return (
    <div className={props.className}>
      <div className={classes.Navbar}>
        <Link to='/'>
          <div className={classes.Brand}>MKDNY</div>
        </Link>
        <div className={classes.Right}>
          <nav className={classes.Nav}>

            {auth.isGranted(permissions.READER_SERVICE_READ) ?
              <NavLink className={classes.NavLink} activeClassName={classes.active} to="/reader_service" >
                <span className={classes.Center}>
                  <img className={classes.Image} src={Search} alt="" />
                  <span className={classes.iconText}>
                    {t('navigation.loan')}
                  </span>
                </span>
              </NavLink>
              : null}

            {/* {auth.isGranted(permissions.INVENTORY_READ) ?
              <NavLink className={classes.NavLink} activeClassName={classes.active} to="/holdings" >
                <span className={classes.Center}>
                  <img className={classes.Image} src={Search} alt="" />
                  <span className={classes.iconText}>
                    {t('navigation.inventory')}
                  </span>
                </span>
              </NavLink>
              : null} */}

            {auth.isGranted(permissions.CATALOG_READ) ?
              <NavLink className={classes.NavLink} activeClassName={classes.active} to="/catalog" >
                <span className={classes.Center}>
                  <img className={classes.Image} src={Search} alt="" />
                  <span className={classes.iconText}>
                    {t('navigation.catalog')}
                  </span>
                </span>
              </NavLink>
              : null}
            {auth.isGranted(permissions.CATALOGING_READ) ?
              < NavLink className={classes.NavLink} activeClassName={classes.active} to="/cataloging" >
                <span className={classes.Center}>
                  <img className={classes.Image} src={Items} alt="" />
                  <span className={classes.iconText}>
                    {t('navigation.cataloging')}
                  </span>
                </span>
              </NavLink>
              : null}

            {auth.isGranted(permissions.ACQUISITION_READ) ?
              < NavLink className={classes.NavLink} activeClassName={classes.active} to="/acquisition" >
                <span className={classes.Center}>
                  <img className={classes.Image} src={Items} alt="" />
                  <span className={classes.iconText}>
                    {'Gyarapítás'}
                  </span>
                </span>
              </NavLink>
              : null}

            <span
              className={isConfigPath() ? classes.NavLink + ' ' + classes.NavLinkActive : classes.NavLink}
              onClick={handleClickConfig}
              style={{ cursor: 'pointer' }}
            >
              <span className={classes.Center}>
                <img className={classes.Image} src={Pref} alt="" />
                <span className={classes.iconText}>
                  {t('navigation.config')}
                </span>
              </span>
            </span>
            <Menu
              style={{ marginTop: "35px" }}
              anchorEl={anchorElConfig}
              keepMounted
              open={Boolean(anchorElConfig)}
              onClose={handleCloseConfig}
              onClick={() => setAnchorElConfig(null)}
            >
              <Link to='/config/metadata-profile'><MenuItem className={classes.hoverStyle}>{t('metadata-profiles')}</MenuItem></Link>
              <Link to='/config/value-set/collection'><MenuItem className={classes.hoverStyle}>{t('value-set')}</MenuItem></Link>
              <Link to='/config/template'><MenuItem className={classes.hoverStyle}>{t('templates')}</MenuItem></Link>
            </Menu>


          </nav>
          <Divider orientation="vertical" className={[classes.dividerStyle, "dividerStyle"].join(' ')} />
          <span className={classes.Center} onClick={handleClick} style={{ marginRight: '20px', cursor: 'pointer' }}>
            <Tooltip title={user.commonName ? user.commonName : ''} >
              <img className={classes.Image} src={Usericon} alt="" />
            </Tooltip>
          </span>
          <Menu
            style={{ marginTop: "35px" }}
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => gotoProfileAndClose('/profile')}>{t('profile')}</MenuItem>
            <MenuItem onClick={logout}>{t('logout')}</MenuItem>
          </Menu>
        </div>
      </div>
    </div >
  );
}

export default withRouter(Navigation)
