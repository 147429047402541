import React from 'react'
import { Grid, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom'
import Close from '../../../assets/icons/closeX.svg';
import classes from './Header.module.scss';
import { Translate as T, useTranslator } from '../../../shared/translate';


const Header = (props) => {

    const trans = useTranslator();

    let backTo = null;
    if(props.backTo && props.backToTitle){
      backTo = <Tooltip title={trans(props.backToTitle)}><Link to={props.backTo}>&#8630;&nbsp;</Link></Tooltip>;
    }

    return (
        <Grid container justify="center" spacing={5} className={classes.cardHeader} style={{marginTop: "0px"}}>
            <Grid item sm={11}>
                {backTo}
                <T>{props.title}</T>
            </Grid>
            <Grid item sm={1}>
            <div >
              <Link to="/">
              <img className={classes.Image} src={Close} alt="" />
              </Link>
            </div>
          </Grid>
        </Grid>
    )
}

export default Header
