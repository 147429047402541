import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button';
import classes from './AddHoldingForm.module.scss'
import Grid from '@material-ui/core/Grid';
import Editor from '../../../../../components/Editor/Editor';
import { useTranslator } from '../../../../../shared/translate';
import { getHoldingSchema } from '../../../../../api/marc21/holding/holdingSchema';
import Spinner from '../../../../../components/UI/Spinner/Spinner';


const AddHoldingForm = (props) => {
    const { saveHandler, prototype } = props;
    const [holdingSchema, setHoldingSchema] = useState(null)

    const [entity, setEntity] = useState(prototype)

    useEffect(() => { getHoldingSchema().then(res => setHoldingSchema(res)) }, [])

    const setAuthorityRecordHandler = input => setEntity(prev => {
        if (input instanceof Function) {
            return { ...prev, record: input(prev.record) };
        } else {
            return { ...prev, record: { ...input } };
        }
    });

    const trans = useTranslator();

    if (holdingSchema === null) {
        return <Spinner />
    }
    return (
        <React.Fragment>
            <Grid container>
                <Grid item sm={12}>
                    <Editor
                        style={{ width: 'calc(100% - 3rem)' }}
                        record={entity.record}
                        setRecord={setAuthorityRecordHandler}
                        metadataProfile={holdingSchema}
                    />
                </Grid>
                <Grid item sm={12} className={classes.alignRight}>
                    <Button className={classes.mainBlueButtonWithTop}
                        onClick={() => saveHandler(entity)}
                    >{trans('create')}
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default AddHoldingForm;
