import React from 'react'
import ReactSelectOriginal, {createFilter as createFilterOriginal} from 'react-select';
import { useTranslator } from '../../../shared/translate';

const ReactSelect = (props) => {
    const t = useTranslator();
    return (
        <ReactSelectOriginal
            {...props}
            placeholder={t('please-select')}
            noOptionsMessage={() => t('no-options')}
            style={{cursor: 'pointer'}}
        />
    )
}

export const createFilter = createFilterOriginal;

export default ReactSelect
