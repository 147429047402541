import React, { useState, useEffect, useCallback } from 'react'
import useSmartRequest from '../../../../hooks/useSmartRequest';
import { getTemplates, updateTemplate } from '../../../../api/templates';
import { Link } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Switch from '@material-ui/core/Switch';

import { IconButton } from '@material-ui/core';
import { deepCopy, makeCancelable } from '../../../../shared/util';
import classes from '../Mainpage.module.scss'
import Spinner from '../../../../components/UI/Spinner/Spinner';

const FormatTemplates = (props) => {
    const { setMarc21DefaultTemplateId } = props
    const { sendRequest: sendRequestGetTemplates } = useSmartRequest(getTemplates);

    const [templates, setTemplates] = useState([]);
    const [isOpen, setIsOpen] = useState(true);
    const [defaultTemplateId, setDefaultTemplateId] = useState(null);

    const getDefaultTemplateId =
        useCallback(
            (pTemplates) => {
                for (const template of pTemplates) {
                    if (template.isDefault === true) {
                        setDefaultTemplateId(template.id);
                        setMarc21DefaultTemplateId(template.id)
                        break;
                    }
                }
            },
            [setMarc21DefaultTemplateId],
        )

    useEffect(() => {
        const { cancel, promise } = makeCancelable(sendRequestGetTemplates());
        
        promise.then(res => {
            setTemplates(res)
            getDefaultTemplateId(res)
        });

        return () => {
            cancel();
        }
    }, [sendRequestGetTemplates, getDefaultTemplateId])



    const updateDefaultTemplateId = (templateId) => {
        const newTemplates = deepCopy(templates);
        for (const [templateKey, template] of Object.entries(newTemplates)) {
            if (template.id === templateId) {
                newTemplates[templateKey].isDefault = true;
            } else {
                newTemplates[templateKey].isDefault = false;
            }
            updateTemplate(template.id, newTemplates[templateKey]);
        }
        getDefaultTemplateId(newTemplates);
        setTemplates(newTemplates);
    }



    // console.log(templates)
    if (!templates || !defaultTemplateId) { return <Spinner /> }


    const expandHandler = () => {
        setIsOpen(prevValue => setIsOpen(!prevValue));
    }

    const expandButton = () => {
        if (isOpen) {
            return (
                <IconButton onClick={expandHandler}>
                    <ExpandLessIcon />
                </IconButton>
            );
        } else {
            return (
                <IconButton onClick={expandHandler}>
                    <ExpandMoreIcon  />
                </IconButton>
            );
        }
    }

    return (
        <React.Fragment>
            {expandButton()}
            {isOpen
                ?
                <Paper>
                <Table>
                    <TableBody>
                        <TableRow className={classes.myTableHeadStyle}>
                            <TableCell className={classes.fontStyle} component="th" scope="row">Név</TableCell>
                            <TableCell className={classes.fontStyle} component="th" scope="row">Alapértelmezett</TableCell>
                            <TableCell className={classes.fontStyle} component="th" scope="row">Leírás</TableCell>
                            <TableCell className={classes.fontStyle} component="th" scope="row">Típus</TableCell>
                        </TableRow>
                        {templates.map((t, tkey) => (
                            <TableRow key={tkey}>
                                <TableCell component="td" scope="row">
                                    <Link to={'/cataloging/new/template/' + t.id }>
                                        {t.title}
                                    </Link>
                                </TableCell>
                                <TableCell component="td" scope="row">
                                    <Switch
                                        onClick={() => updateDefaultTemplateId(t.id)}
                                        checked={defaultTemplateId === t.id}
                                        color="primary"
                                    />
                                </TableCell>
                                <TableCell component="td" scope="row">{t.description}</TableCell>
                                <TableCell component="td" scope="row">Bibliográfia</TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                </Paper>
                : null}
        </React.Fragment>
    )
}

export default FormatTemplates;
