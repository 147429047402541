import React from 'react';

import styles from './Backdrop.module.css';

const backdrop = props => {
  let classes = [styles.Backdrop];
  if (props.error) {
    classes.push(styles.Error);
  }
  return props.show ? <div className={classes.join(' ')} onClick={props.clicked}></div> : null
};

export default backdrop;