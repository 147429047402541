import React, { useState } from 'react'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';

import { useTranslator } from '../../../../shared/translate';

const ItemForm = (props) => {

    const { item, buttonClick, buttonText } = props;

    const t = useTranslator();

    const [status, setStatus] = useState(item ? item.status : '')
    const [condition, setCondition] = useState(item ? item.condition : '')
    const [inventoryNumber, setInventoryNumber] = useState(item ? item.inventoryNumber : '')
    const [barcode, setBarcode] = useState(item ? item.barcode : '')
    const [location, setLocation] = useState(item ? item.location : '')
    const [warehouseSign, setWarehouseSign] = useState(item ? item.warehouseSign : '')
    const [purchaseOrder, setPurchaseOrder] = useState(item ? item.purchaseOrder : '')
    const [purchaseOrderLine, setPurchaseOrderLine] = useState(item ? item.purchaseOrderLine : '')
    const [arrivalTime, setArrivalTime] = useState(item ? item.arrivalTime : '')
    const [comment, setComment] = useState(item ? item.comment : '')

    const fields = {
        status: [t('item.status'), status, setStatus],
        condition: [t('item.condition'), condition, setCondition],
        inventoryNumber: [t('item.inventoryNumber'), inventoryNumber, setInventoryNumber],
        barcode: [t('item.barcode'), barcode, setBarcode],
        location: [t('item.location'), location, setLocation],
        warehouseSign: [t('item.warehouseSign'), warehouseSign, setWarehouseSign],
        purchaseOrder: [t('item.purchaseOrder'), purchaseOrder, setPurchaseOrder],
        purchaseOrderLine: [t('item.purchaseOrderLine'), purchaseOrderLine, setPurchaseOrderLine],
        arrivalTime: [t('item.arrivalTime'), arrivalTime, setArrivalTime],
        comment: [t('item.comment'), comment, setComment],
    }

    const generateField = (field) => {
        return (
            <TextField
                label={field[0]}
                value={field[1]}
                onChange={(event) => field[2](event.target.value)}
                margin="normal"
                variant="outlined"
                fullWidth
            />
        )
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <h3>{t('item.tab.general.general')}</h3>
                            {generateField(fields.status)}
                            {generateField(fields.condition)}
                        </Grid>
                        <Grid item xs={6}>
                            <h3>{t('item.tab.general.ids')}</h3>
                            {generateField(fields.inventoryNumber)}
                            {generateField(fields.barcode)}
                        </Grid>
                        <Grid item xs={6}>
                            <h3>{t('item.tab.general.purchase')}</h3>
                            {generateField(fields.purchaseOrder)}
                            {generateField(fields.purchaseOrderLine)}
                            {generateField(fields.arrivalTime)}
                        </Grid>
                        <Grid item xs={6}>
                            <h3>{t('item.tab.general.location')}</h3>
                            {generateField(fields.location)}
                            {generateField(fields.warehouseSign)}
                        </Grid>
                        <Grid item xs={12}>
                            <h3>{t('item.tab.general.comment')}</h3>
                            {generateField(fields.comment)}
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={12} >
                    <br />
                    <Button
                        variant="contained"
                        color={'primary'}
                        onClick={() => buttonClick({
                            status,
                            condition,
                            inventoryNumber,
                            barcode,
                            location,
                            warehouseSign,
                            purchaseOrder,
                            purchaseOrderLine,
                            arrivalTime,
                            comment,
                        })}
                    >{t(buttonText || '')}
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default ItemForm
