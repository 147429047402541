import axios from '../settings/axios-backend';
import urls from '../settings/urls';

export const getCollections = () => {
    return axios.get(urls.COLLECTIONS)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

export const getCollection = (id) => {
    const url = urls.COLLECTION.replace('{id}', id);
    return axios.get(url)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

export const updateCollection = (id, data) => {
    console.log(JSON.stringify(data))
    const url = urls.COLLECTION.replace('{id}', id);
    return axios.put(url, data)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
}

export const updateCollectionsFromEditor = metadataProfile => {
    const collections = metadataProfile.datafields.reduce((collections, datafield) => {
        datafield.subfields.forEach(subfield => {
            if (subfield.updateCollectionOnSave &&
                'select' === subfield.input &&
                subfield.collection &&
                'object' === typeof subfield.collection) {
                    if (-1 === collections.findIndex(elem => elem.id === subfield.collection.id)) {
                        collections.push(subfield.collection);
                    }
            }
        });
        return collections;
    }, []);
    const url = urls.COLLECTIONS;
    return axios.post(url, collections)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
}

export const createCollection = (data) => {
    return axios.post(urls.COLLECTION_CREATE, data)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};