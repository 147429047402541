import axios from './settings/axios-backend';
import urls from './settings/urls';

export const getTemplateCount = () => {
  return axios.get(urls.TEMPLATE_COUNT)
    .then(response => {
      console.log(response.data)
      return response.data;
    })
    .catch(error => {
      console.log(error)
      throw error;
    });
};

export const getTemplates = () => {
  return axios.get(urls.TEMPLATES)
    .then(response => {
      console.log(response.data)
      return response.data;
    })
    .catch(error => {
      console.log(error)
      throw error;
    });
};

export const createTemplate = (data) => {
  console.log(data)
  return axios.post(urls.TEMPLATES, data)
    .then(response => {
      console.log(response.data)
      return response.data;
    })
    .catch(error => {
      console.log(error)
      throw error;
    });
}

export const getTemplate = (id) => {
  const url = urls.TEMPLATE.replace('{id}', id);
  return axios.get(url)
    .then(response => {
      console.log(response.data)
      return response.data;
    })
    .catch(error => {
      console.log(error)
      throw error;
    });
};

export const getTemplateOptions = () => {
  const url = urls.TEMPLATE_OPTIONS;
  return axios.get(url)
    .then(response => {
      console.log(response.data)
      return response.data;
    })
    .catch(error => {
      console.log(error)
      throw error;
    });
};

export const updateTemplate = (id, data) => {
  console.log(data)
  const url = urls.TEMPLATE.replace('{id}', id);
  return axios.put(url, data)
    .then(response => {
      console.log(response.data)
      return response.data;
    })
    .catch(error => {
      console.log(error)
      throw error;
    });
}
