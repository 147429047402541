import React from 'react'
import Grid from '@material-ui/core/Grid';
import { useTranslator } from '../../../../shared/translate';
import {
    Button,
    TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    mainBlueButton: {
        backgroundColor: '#2b75bb',
        borderRadius: 6,
        color: 'white',
        fontSize: 14,
        textTransform: 'capitalize',
        padding: '4px 14px',
        fontFamily: 'Source Sans Pro',
        fontWeight: 500,
        zIndex: 'inherit',
        '&:hover': {
            background: '#169bd5',
        },
    },
    inputStyle: {
        padding: 10,
        fontSize: 14,
        fontWeight: 500,
    }
});

const SearchSimple = (props) => {
    const classes = useStyles();

    const {
        label,
        buttonLabel,
        setFilterKeyword,
        filterKeyword,
        buttonClick,
    } = props;

    const t = useTranslator()

    return (
        <React.Fragment>
            <Grid container spacing={5}>
                <Grid item md={4} sm={4}>
                    <TextField
                        autoFocus
                        placeholder={t(label)}
                        variant="outlined"
                        type="text"
                        fullWidth
                        inputProps={{
                            value: filterKeyword,
                            onChange: (event) => setFilterKeyword(event.target.value),
                            className: classes.inputStyle
                        }}
                    />
                </Grid>
                <Grid item md={4} sm={4}>
                    <Button
                        onClick={buttonClick}
                        className={classes.mainBlueButton}
                    >{t(buttonLabel)}</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default SearchSimple;
