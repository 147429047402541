import React from 'react';
import FlipMove from 'react-flip-move';

import Controlfield from './Controlfield/Controlfield';
import * as EditorButtons from '../Buttons/Buttons';
import { useTranslator } from '../../../shared/translate';
import { createPropertyArrayItemSetter, isEqual } from '../../../shared/util';
import { addHelperFieldsToElement, reduceToHashes, removeHelperFields } from '../../../shared/listUtil';
import editorStyles from '../Editor.module.css'

const AUTO_GENERATED_TAGS = ['001', '005'];

const Controlfields = props => {
  const { controlfields, setControlfields, metadataProfile, materialType } = props;
  const trans = useTranslator();
  
  const controlfieldsRef = React.useRef(controlfields);
  
  React.useEffect(() => {
    controlfieldsRef.current = [...controlfields];
  }, [controlfields]);

  const createPrototype = () => addHelperFieldsToElement({
    tag: '',
    value: '',
  }, reduceToHashes(controlfieldsRef.current))

  const getCurrentIndex = hash => controlfieldsRef.current.findIndex(elem => elem.helpers.id === hash);
  
  const changeControlfieldHandler = hash => input => {
    createPropertyArrayItemSetter(setControlfields, () => getCurrentIndex(hash))(input);
  }

  const tagChangeHandler = hash => tag => setControlfields(prevControlfields => {
    const newControlfields = [...prevControlfields];
    const index = getCurrentIndex(hash);
    newControlfields.splice(index, 1, { ...newControlfields[index], tag: tag });
    newControlfields.sort((a, b) => {
      return a.tag - b.tag
    });
    return newControlfields;
  })

  const deleteControlfieldHandler = hash => () => {
    createPropertyArrayItemSetter(setControlfields, () => getCurrentIndex(hash))(undefined);
  };

  const addControlfieldHandler = (hash) => () => {
    createPropertyArrayItemSetter(setControlfields, () => getCurrentIndex(hash) + 1, false)(createPrototype())
  }
      
  const getAvailableMetadataProfiles = tag => {
    return metadataProfile.reduce((result, elem) => {
      if (!AUTO_GENERATED_TAGS.includes(elem.tag) &&
          false !== elem.status && (
            elem.repeatable || 
            elem.tag === tag || 
            !controlfieldsRef.current.find(otherElem => otherElem.tag === elem.tag)
          )
      ) {
        result.push({
          label: `${elem.tag} - ${trans(elem.label)}`,
          value: elem.tag
        });
      }
      return result;
    }, []);
  }

  const controlfieldsList = controlfields ? controlfields.map((controlfield, index) => {
    return (
      <div key={controlfield.helpers.id} >
        <Controlfield
          controlfield={controlfield}
          setControlfield={changeControlfieldHandler(controlfield.helpers.id)}
          onTagChange={tagChangeHandler(controlfield.helpers.id)}
          onAdd={addControlfieldHandler(controlfield.helpers.id, true)}
          onDelete={deleteControlfieldHandler(controlfield.helpers.id)}
          metadataProfile={metadataProfile.find(elem => elem.tag === controlfield.tag)}
          getAvailableMetadataProfiles={getAvailableMetadataProfiles}
          materialType={materialType}
        />
      </div>
    );
  }
  ) : null;

  return (
    <React.Fragment>
      {/* <hr />{JSON.stringify(data)} */}
      <div className={editorStyles.Card}>
        <div className={editorStyles.CardHeader}>
        <span className={[editorStyles.myFontStyle, editorStyles.inputStyle]}>{trans('controlfields')}</span>
        </div>
          
          <div className={editorStyles.CardContent}>
          <EditorButtons.AddFieldButton onClick={addControlfieldHandler()} />
          <FlipMove>
            {controlfieldsList}
          </FlipMove>
          </div>
          </div>
    </React.Fragment>
  );
};
// export default  Controlfields
export default React.memo(Controlfields, (prev, next) => {
  // console.log([...prev.controlfields]);
  // console.log([...next.controlfields]);
  // console.log(isEqual(prev.controlfields, next.controlfields))
  return isEqual(removeHelperFields(prev.controlfields), removeHelperFields(next.controlfields)) &&
    isEqual(prev.metadataProfile, next.metadataProfile) &&
    prev.materialType === next.materialType
});