import axios from "../../settings/axios-backend"
import urls from "../../settings/urls";

export const getHoldingSchema = () => {
    return axios.get(urls.MARC21_HOLDING_SCHEMA)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

export const holdingSchemaUpdateCallsign = (postData) => {
    console.log(postData)
    return axios.put(urls.MARC21_HOLDING_SCHEMA_UPDATE_CALLSIGN, postData)
        .then(response => {
            console.log(response.data)
            return response ? response.data : null;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};
