import React from 'react';

import Modal from '../components/UI/Modal/Modal';
import useHttpErrorHandler from '../hooks/useHttpErrorHandler';
import {useTranslator} from '../shared/translate';

const withErrorHandler = (WrappedComponent, axios) =>
  props => {
    const trans = useTranslator();
    const [ error, clearError ] = useHttpErrorHandler(axios);
    
    let type;
    let description;
    // console.log(error);
    // console.log({...error});
    if (error && error.response) {
      if (400 === error.response.status) {
        type = trans('client_error');
      } else if (403 === error.response.status) {
        type = trans('forbidden_error');
      } else if (409 === error.response.status) {
        type = trans('user_error');
      } else if (500 === error.response.status) {
        type = trans('internal_server_error');
      }
      
      if ('string' === typeof error.response.data) {
        if (500 === error.response.status){
          description = trans('internal_server_error_description');
        } else {
          description = trans(error.response.data);
        }
      } else if (error.response.data.message) {
        description = trans(error.response.data.message);
      }

      if (!type) {
        description = JSON.stringify(error.response.data);
      }
    }

    return (
      <React.Fragment>
        <WrappedComponent {...props} />
        <Modal
          size="small"
          show={error}
          modalClosed={clearError}
          error
        >
          <div style={{fontWeight: 'bold'}}>{type || trans('unexpected_error')}</div>
          <div>{description ? description : (type ? null : trans('unexpected_error_description'))}</div>
        </Modal>
      </React.Fragment>
    );
  }
;

export default withErrorHandler;