import React, { useState, useEffect, useCallback } from 'react'
import { Button, Grid } from '@material-ui/core'
import PopupModal from '../../../../../components/UI/Popup/PopupModal';
// import AddCollectionForm from './AddCollectionForm';

import ConfigContext from '../../../../../context/snackbar-context';
import { useTranslator } from '../../../../../shared/translate';
import EntityListTable from './EntityListTable';
import { getOnnAuthorityRecordsByType, getLocalAuthorityRecordsByType, createAuthorityRecord, deleteAuthorityRecord, getFilters } from '../../../../../api/marc21/authority/authority';
import AddEntityForm from './AddEntityForm';
import classes from './EntityList.module.scss';
import { removeHelperFields } from '../../../../../shared/listUtil';
import Spinner from '../../../../../components/UI/Spinner/Spinner';
import EntitySearch from './EntitySearch';
import { makeCancelable } from '../../../../../shared/util';

const ENTITIES_PER_PAGE = 5;
const ONN_FILTERS = {
  person: [
    {value: "name", label: "authority.person.name"},
    {value: "profession", label: "authority.person.occupations"},
  ],
  geo: [
    {value: "name", label: "authority.geo.name"},
    {value: "type", label: "authority.geo.type"},
  ],
  corporate: [
    {value: "name", label: "authority.corporate.name"},
    {value: "type", label: "authority.corporate.type"},
  ],
};

const EntityList = (props) => {
  const {
    title,
    type: currentAuthorityRecordType,
    listCols,
    prototype: defaultPototype,
    searchOnn,
    newlySelectedEntities,
    setNewlySelectedEntities,
    alreadySelectedEntities,
    onnSelected,
    setOnnSelected,
  } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = React.useState(null);
  const [filters, setFilters] = useState([]);
  const [entities, setEntities] = useState(null);
  const [entityCount, setEntityCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [prototype, setPrototype] = useState(defaultPototype);
  const { setShowSnackbar } = React.useContext(ConfigContext);

  const [popupDeleteShow, setPopupDeleteShow] = useState(false);
  const [popupDeleteSelected, setPopupDeleteSelected] = useState(null);

  const t = useTranslator();

  //get records
  const loadLocalAuthorityRecordsByType = useCallback(() => {
    setError(null);
    setLoading(true);
    const cancelable = makeCancelable(getLocalAuthorityRecordsByType(currentAuthorityRecordType, {limit: ENTITIES_PER_PAGE}));
    cancelable.promise.then(response => {
      setLoading(false);
      setEntityCount(response.count);
      setEntities(response.result)
    })
    return cancelable;
  },
    [currentAuthorityRecordType],
  )

  const loadOnnAuthorityRecordsByType = useCallback(() => {
    setError(null);
    setLoading(true);
    // TODO
    const cancelable = makeCancelable(getOnnAuthorityRecordsByType(currentAuthorityRecordType, {limit: ENTITIES_PER_PAGE}));
    cancelable.promise
      .then(response => {
        setLoading(false);
        setEntityCount(response.count);
        setEntities(response.result)
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
        setError(error.message);
      })
    return cancelable;
  }, [currentAuthorityRecordType]);

  useEffect(() => {
    const cancelable = onnSelected ? loadOnnAuthorityRecordsByType() : loadLocalAuthorityRecordsByType()
    return () => {
      cancelable.cancel();
    }
  }, [onnSelected, loadOnnAuthorityRecordsByType, loadLocalAuthorityRecordsByType])

  useEffect(() => {
    if (onnSelected) {
      setFilters(ONN_FILTERS[currentAuthorityRecordType]);
      return;
    }
    const cancelable = makeCancelable(getFilters(currentAuthorityRecordType));
    cancelable.promise.then(response => {
      setFilters(response);
    });
    return () => {
      cancelable.cancel();
    }
  }, [onnSelected, currentAuthorityRecordType]);

  //create
  const createEntityHandler = (data) => {
    data.type = currentAuthorityRecordType
    createAuthorityRecord(currentAuthorityRecordType, removeHelperFields(data)).then(res => {
      setShowSnackbar(true);
      cancelPopupHandler();
      loadLocalAuthorityRecordsByType()
    })
  }

  //delete
  const deleteEntityHandler = (id) => {
    deleteAuthorityRecord(currentAuthorityRecordType, id).then(res => {
      loadLocalAuthorityRecordsByType()
    });
  }

  const cancelPopupHandler = () => {
    setShowPopup(false)
    setPrototype(defaultPototype)
  }
  const showPopupHandler = () => { setShowPopup(true) }

  // if (!entities) {
  //   return <Spinner />;
  // }

  const setShowDeletePopupHandler = (inputShow, id = null) => {
    if (id) {
      setPopupDeleteSelected(id)
      setPopupDeleteShow(inputShow);
    } else {
      setPopupDeleteShow(inputShow);
    }
  }

  return (
    <React.Fragment>
      <Grid container alignItems="center" >

        <Grid item sm>
          {title}
        </Grid>
        <Grid>
          {searchOnn ? (
            <Button
              onClick={() => {setOnnSelected(prevState => !prevState)}}
              className={classes.mainBlueButton}>{onnSelected ? t('search_mkdny') : t('search_onn')}
            </Button>
          ) : null}
          {setNewlySelectedEntities ? null : (
            <React.Fragment>
              <Button
                onClick={showPopupHandler}
                className={classes.mainBlueButton}>{t('new-entity')}
              </Button>
              {showPopup ? <PopupModal
                show={showPopup}
                setShow={setShowPopup}
                cancel={cancelPopupHandler}
                size={'large'}
                title={t('new-entity')}
                body={<AddEntityForm
                  saveHandler={createEntityHandler}
                  type={currentAuthorityRecordType}
                  prototype={prototype}

                />}
              /> : null}
              {popupDeleteShow ? <PopupModal
              show={popupDeleteShow}
              setShow={setPopupDeleteShow}
              title={t('delete?')}
              footer={
                <React.Fragment>
                <Button onClick={() => {
                  deleteEntityHandler(popupDeleteSelected)
                  setShowDeletePopupHandler(false)
                }}>{t('delete')}</Button>
                <Button onClick={() => {
                  setShowDeletePopupHandler(false)
                }}>{t('cancel')}</Button>
                </React.Fragment>
              }
              /> : null}
            </React.Fragment>
          )}
        </Grid>
      </Grid>
      {filters.length ? (
        <React.Fragment>
          <Grid container alignItems="center">
            <EntitySearch
              type={currentAuthorityRecordType}
              limit={ENTITIES_PER_PAGE}
              filters={filters}
              entityCount={entityCount}
              setEntities={setEntities}
              setEntityCount={setEntityCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setLoading={setLoading}
              setError={setError}
              fetchRecords={onnSelected ? getOnnAuthorityRecordsByType : getLocalAuthorityRecordsByType}
            />
          </Grid>
          <Grid container>
            {error ? <div>error</div> : (
              <EntityListTable
                listCols={listCols}
                entities={entities}
                setShowDeletePopupHandler={setShowDeletePopupHandler}
                loading={loading}
                newlySelectedEntities={newlySelectedEntities}
                setNewlySelectedEntities={setNewlySelectedEntities}
                alreadySelectedEntities={alreadySelectedEntities}
                onnSelected={onnSelected}
              />
            )}
          </Grid>
        </React.Fragment>
      ) : <Spinner />}
    </React.Fragment>
  )
}

export default EntityList;
