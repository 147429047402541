import React from 'react';
import { createFilter } from 'react-select';
import Grid from '@material-ui/core/Grid';

import { isEqual, createPropertySetter } from '../../../../shared/util';
import { VALUE_LABEL_SEPARATOR, validateDatafield } from '../../../../shared/recordUtil';
import { useTranslator } from '../../../../shared/translate';
import * as EditorButtons from '../../Buttons/Buttons';
import Subfields from './Subfields';
import Indicator from './Indicator';
import Del from '../../../../assets/icons/delete-blue.svg';
import Copy from '../../../../assets/icons/copy-blue.svg';
import styles from '../Datafield.module.scss';
import editorStyles from '../../Editor.module.css';
import ReactSelect from '../../../UI/Input/ReactSelect';
import classes from '../Datafield.module.scss';
import { removeHelperFields } from '../../../../shared/listUtil';
import ErrorMessage from '../../ErrorMessage';

const Datafield = props => {
  const {
    datafield,
    setDatafield: propSetDatafield,
    metadataProfile,
    setMetadataProfile,
    getPossibleTags,
    onAdd,
    onDelete,
    onClone,
    onTagChange,
    getEntities,
    getMetadataProfileForTag,
    isValidated,
    isTemplate,
    showNewErrors,
  } = props;
  console.log(metadataProfile);

  const [possibleTags, setPossibleTags] = React.useState(getPossibleTags(datafield.tag));
  
  const [ showErrors, setShowErrors ] = React.useState(false);
  React.useEffect(() => {
    if (showNewErrors) {
      setShowErrors(true);
    }
  }, [showNewErrors]);

  const trans = useTranslator();

  const setDatafield = input => propSetDatafield(prevState => validateDatafield(input instanceof Function ? input({...prevState}) : input, getMetadataProfileForTag, isTemplate));

  return (
    <React.Fragment>
      {showErrors ? <ErrorMessage subject={datafield} /> : null}
      <div className={editorStyles.subCard} ref={datafield.helpers.ref}>
        <div className={editorStyles.subCardHeader}>
          <Grid container spacing={2}>
            <Grid item sm={5} >
              {datafield.subfields && datafield.subfields.length
                ? <h3 className={classes.subFont }>{datafield.tag} &ndash; {trans(metadataProfile ? metadataProfile.label : 'invalid_value')}</h3>
                // <select defaultValue={d.tag} style={{ width: '100%', maxWidth: '90%' }} onChange={datafieldsChangeHandler(datafieldKey + '.tag')}>
                //   {tagSelect(d.tag)}
                // </select>
                : <ReactSelect
                  value={metadataProfile ? { label: `${metadataProfile.tag} ${VALUE_LABEL_SEPARATOR} ${trans(metadataProfile.label)}`, value: datafield.tag } : null}
                  onChange={option => {
                    setDatafield(prevState => ({
                      ...prevState,
                      tag: option.value,
                    }))
                    onTagChange(option.value);
                  }}
                  options={possibleTags}
                  onMenuOpen={() => {
                    const newAvailableTags = getPossibleTags(datafield.tag);
                    if (!isEqual(newAvailableTags, possibleTags)) {
                      setPossibleTags(newAvailableTags);
                    }
                  }}
                  filterOption={createFilter({
                    matchFrom: 'any',
                  })}
                />
              }
            </Grid>
            <Grid item sm={3}>
              <Indicator
                metadataProfile={metadataProfile ? metadataProfile.indicator1 : null}
                indicator={datafield.indicator1}
                setIndicator={createPropertySetter(setDatafield, 'indicator1')}
                inputProps={{
                  className: classes.inputStyle,
                }}
                error={datafield.helpers.errors && datafield.helpers.errors.includes('invalid_indicator1') ? true : false}
                showNewErrors={showNewErrors}
              />
            </Grid>
            <Grid item sm={3}>
              <Indicator
                metadataProfile={metadataProfile ? metadataProfile.indicator2 : null}
                indicator={datafield.indicator2}
                setIndicator={createPropertySetter(setDatafield, 'indicator2')}
                inputProps={{
                  className: classes.inputStyle,
                }}
                error={datafield.helpers.errors && datafield.helpers.errors.includes('invalid_indicator2') ? true : false}
                showNewErrors={showNewErrors}
              />
            </Grid>
            <Grid item sm={1} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

              {metadataProfile && metadataProfile.repeatable
                ? <img className={styles.myIconStyle} src={Copy} alt="" onClick={() => onClone(datafield)} />
                : null}
              <img className={styles.myIconStyle} src={Del} alt="" onClick={onDelete} />
            </Grid>
          </Grid>
        </div>

        <div className={editorStyles.subCardContent}>
          <Subfields 
            metadataProfile={metadataProfile ? metadataProfile.subfields : null}
            setMetadataProfile={metadataProfile ? createPropertySetter(setMetadataProfile, 'subfields') : null}
            subfields={datafield.subfields}
            setSubfields={createPropertySetter(setDatafield, 'subfields')}
            getEntities={getEntities}
            datafieldTag={datafield.tag}
            isValidated={isValidated}
            isTemplate={isTemplate}
            showNewErrors={showNewErrors}
          />
        </div>
      </div>
      <EditorButtons.AddFieldButton onClick={onAdd} className={editorStyles.alignSelf}/>
    </React.Fragment>
  );
};

export default React.memo(Datafield, (prev, next) => (
  prev.isValidated === next.isValidated &&
  isEqual(removeHelperFields(prev.datafield), removeHelperFields(next.datafield)) &&
  isEqual(prev.metadataProfile, next.metadataProfile) &&
  prev.showNewErrors === next.showNewErrors
));