import React, { useState, useEffect } from 'react'

import { getRecordConvertBibframe } from '../../../api/catalog/recordsConvert';
import useSmartRequest from '../../../hooks/useSmartRequest';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 3),
        marginTop: 15,
    },
}));

const ShowRecordBibframe = (props) => {
    const classes = useStyles();
    const { record } = props;//required

    const { sendRequest, isSending } = useSmartRequest(getRecordConvertBibframe);

    const [response, setResponse] = useState('');

    useEffect(() => {
        sendRequest(record).then(res => setResponse(res));
    }, [record, sendRequest])

    return (
        <React.Fragment>
            <Paper className={classes.root}>
                <code style={{ whiteSpace: 'pre-wrap' }}>
                    {isSending
                        ? 'Loading...'
                        : response}
                </code>
            </Paper>
        </React.Fragment>
    )
}

export default ShowRecordBibframe;