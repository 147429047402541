import React, { useState } from 'react'
import Keyboard from "react-simple-keyboard";

import SimpleKeyboardLayouts from "simple-keyboard-layouts";

const VirtualKeyboard = (props) => {

    const [keyboard, setKeyboard] = useState(null)
    const [layoutName, setLayoutName] = useState('default')

    const uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // eslint-disable-next-line
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    const getLayout = (lang) => {
        const simpleKeyboardLayouts = new SimpleKeyboardLayouts();
        const allLayout = simpleKeyboardLayouts.get()
        if (allLayout.hasOwnProperty(lang)) {
            return simpleKeyboardLayouts.get(lang)
        } else {
            return simpleKeyboardLayouts.get('english')
        }
    }

    const handleShift = () => {
        let currentLayout = keyboard.options.layoutName;
        let shiftToggle = currentLayout === "default" ? "shift" : "default";
        setLayoutName(shiftToggle)
    }

    const onKeyPressHandler = button => {
        // handle the shift and caps lock buttons
        const shiftButtons = ['{shift}', '{lock}'];
        if (shiftButtons.includes(button)) {
            handleShift();
        }
    }

    const onChangeHandler = text => {
        //update keyboard state
        keyboard.setInput(props.value)

        //run parent handler, change input
        props.onKeyPress(text)
    }


    //update keyboard state
    if (keyboard !== null) {
        keyboard.setInput(props.value)
    }

    return (
        <Keyboard
            baseClass={'a' + uuidv4()}
            keyboardRef={r => (setKeyboard(r))}
            layout={getLayout(props.lang)}
            layoutName={layoutName}

            {...props}
            onKeyPress={(button) => onKeyPressHandler(button)}
            onChange={(c) => onChangeHandler(c)}
        />
    )
}

export default VirtualKeyboard
