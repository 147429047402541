import React from 'react';
import Header from '../Header/Header';
import { Grid } from '@material-ui/core';
import ReaderDataSheet from '../Reader/ReaderForm/ReaderDataSheet';
import LoanPageTabs from './LoanPageTabs';

const LoanPage = (props) => {

    const { 
        id: readerId
     } = props.match.params;

     const {
        activeTab
     } = props;

    return (
        <React.Fragment>
            <Header title="loan_page" backTo="/reader_service" backToTitle="reader_service" />
            <Grid container style={{marginTop: "100px"}}>
                <Grid item sm={12}>
                <ReaderDataSheet 
                    readerId={readerId}
                />
                </Grid>
            </Grid>
            <Grid container justify="center" spacing={5} style={{marginTop: "80px"}}>
                <Grid item sm={11}>
                    <LoanPageTabs readerId={readerId} activeTab={activeTab} />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default LoanPage;