import React from 'react';
import {
    Button,
    MenuList,
    MenuItem,
    Paper,
    ButtonGroup,
    Popper,
    Grow,
    ClickAwayListener
} from '@material-ui/core/';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useTranslator } from '../../../../shared/translate';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'

export default function SelectEdit(props) {
    const trans = useTranslator();
    const [open, setOpen] = React.useState(false);

    const {
        buttonLabel,
        menuItems,
        recordId,
    } = props;

    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };


    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const useStyles = makeStyles(theme => ({

        menuStyle: {
            fontSize: 14,
            fontWeight: 500,
            color: 'black',
            minHeight: 40,
            fontFamily: 'Source Sans Pro',
            padding: theme.spacing(0, 3),
            '&:hover': {
                color: 'white',
                backgroundColor: '#909090',
                textDecoration: 'none',
            },
        },

        mainBlueButton: {
            backgroundColor: '#2b75bb',
            borderRadius: 6,
            color: 'white',
            fontSize: 14,
            textTransform: 'capitalize',
            padding: '2px 14px',
            fontFamily: 'Source Sans Pro',
            fontWeight: 500,
            zIndex: 'inherit',
            '&:hover': {
                background: '#169bd5',
            },
        },
        linkStyle: {
            color: 'black',
            '&:hover': {
                color: 'white',
                backgroundColor: '#909090',
                textDecoration: 'none',
            },
        },
    }));

    const classes = useStyles();

    if(menuItems.length === 0){
        return null;
    }

    return (
        <React.Fragment>
            
                <ButtonGroup variant="contained" color="primary" ref={anchorRef}>
                    <Button className={classes.mainBlueButton} aria-haspopup="true" >
                        {trans(buttonLabel)}
                    </Button>
                    <Button className={classes.mainBlueButton} aria-haspopup="true" onClick={handleToggle}>
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper open={open} transition disablePortal anchorEl={anchorRef.current} style={{ zIndex: '100' }}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper id="menu-list-grow">
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList>
                                        {
                                            menuItems.map((item, index) => {
                                                if(item.handler){
                                                    return (
                                                        <MenuItem 
                                                            key={index}
                                                            className={classes.menuStyle} 
                                                            onClick={() => item.handler(recordId)}
                                                        >
                                                        {item.label}
                                                        </MenuItem>
                                                    )
                                                }else if(item.url){
                                                    return (
                                                        <Link 
                                                            key={index}
                                                            className={classes.linkStyle} 
                                                            to={item.url + recordId}>
                                                                <MenuItem className={classes.menuStyle}>
                                                                    {item.label}
                                                                </MenuItem>
                                                        </Link>
                                                    )
                                                }

                                                return null;
                                            })
                                        }
                                        
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            
        </React.Fragment>
    );
}