import React from 'react'
import EntityList from '../../EntityList/EntityList'
import { authorityRecordType } from '../../../../../../shared/parameters/authorityRecordTypes'

import classes from './AuthorityPerson.module.scss'
import { useTranslator } from '../../../../../../shared/translate'
import { addHelperFields } from '../../../../../../shared/listUtil'

const AuthorityPerson = props => {
  const { 
    newlySelectedEntities,
    setNewlySelectedEntities, 
    alreadySelectedEntities,
  } = props;
  const [onnSelected, setOnnSelected] = React.useState(false);
  const t = useTranslator()
  const prototype = addHelperFields({
    record: {
      leader: '',
      controlfields: [],
      datafields: [
        {
          tag: '100',
          indicator1: '0',
          indicator2: '0',
          subfields: [
            {
              code: 'a',
              value: ''
            },
            {
              code: 'd',
              value: ''
            }
          ]
        },
        {
          tag: '374',
          indicator1: ' ',
          indicator2: ' ',
          subfields: [
            {
              code: 'a',
              value: ''
            }
          ]
        }
      ]
    }
  })
  return (
    <EntityList
      title={<h3 className={classes.myFontStyle}>{t('namespace-person')}</h3>}
      type={authorityRecordType.PERSON}
      prototype={prototype}
      listCols={[
        {
          label: t('authority.person.record-id'),
          tag: onnSelected ? '035' : '001',
          code: onnSelected ? 'a' : '',
        },
        {
          label: t('authority.person.name'),
          tag: '100',
          code: 'a',
        },
        {
          label: t('authority.person.birth-and-death'),
          tag: '100',
          code: 'd',
        },
        {
          label: t('authority.person.occupations'),
          tag: '374',
          code: 'a',
        }
      ]}
      newlySelectedEntities={newlySelectedEntities}
      setNewlySelectedEntities={setNewlySelectedEntities}
      alreadySelectedEntities={alreadySelectedEntities}
      onnSelected={onnSelected}
      setOnnSelected={setOnnSelected}
      searchOnn
    />
  )
}

export default AuthorityPerson
