import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ActionButtons from './ActionButtons/ActionButtons'
import RecordConnections from './RecordConnections/RecordConnections'
import Editor from '../../../../components/Editor/Editor'
import { statusList } from '../../../../shared/parameters/statusList'
import { certLevelList } from '../../../../shared/parameters/certLevelList'
import { createPropertySetter } from '../../../../shared/util';
import { getTemplate } from '../../../../api/templates';
import bibliographicSchema from '../../../../api/marc21/bibliographic/bibliographicSchema'
import { getRecord, updateRecord, createRecord } from '../../../../api/marc21/bibliographic/bibliographicRecords'
import { getDocumentForm, updateDocumentForm } from '../../../../api/documentForms';
import { MetadataProfileEntityLinks, hasErrors, fillAuthorityIds } from '../../../../shared/recordUtil';
import { useTranslator } from '../../../../shared/translate';
import Popup from '../../../../components/UI/Popup/Popup';
import { addHelperFields, removeHelperFields } from '../../../../shared/listUtil';
import SaveButton from '../../../../components/UI/Button/SaveButton';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import { updateCollectionsFromEditor } from '../../../../api/valueset/collection';


const useStyles = makeStyles({
    myRedButton: {
        backgroundColor: '#990000',
        borderRadius: 6,
        color: 'white',
        fontSize: 14,
        textTransform: 'capitalize',
        padding: '4px 14px',
        fontFamily: 'Source Sans Pro',
        fontWeight: 500,
        textDecoration: 'none',
        marginLeft: 10,
        '&:hover': {
            background: '#990000',
            textDecoration: 'none',
        }
    },
    myBlueButton: {
        backgroundColor: '#2b75bb',
        borderRadius: 6,
        color: 'white',
        fontSize: 14,
        textTransform: 'capitalize',
        padding: '4px 14px',
        fontFamily: 'Source Sans Pro',
        fontWeight: 500,
        '&:hover': {
            backgroundColor: '#2b75bb',
            textDecoration: 'none',
            opacity: 0.7,
        }
    },

    marginTop: {
        marginTop: 10,
        marginBottom: 50,
    },
    myStickyTop: {
        textAlign: 'right',
        position: 'sticky',
        top: '0px',
    padding: '20px 20px',
    background: '#fafafa',
    zIndex: 10,
    },
});



const WorkpageBody = (props) => {
    const { templateId, recordId, documentFormId, history, setShowSnackbar } = props;

    const classes = useStyles();
    const [fullRecord, setFullRecord] = useState(null)
    const [metadataProfile, setMetadataProfile] = useState(null)
    const [recordConnectionsIsOpen, setRecordConnectionsIsOpen] = useState(false)
    const [ entityLinks, setEntityLinks ] = useState(null);
    const [ showPopup, setShowPopup ] = useState(false);
    const [ showNewErrors, setShowNewErrors ] = useState(false);

    const trans = useTranslator();

    useEffect(() => {
        if (templateId) {
            getTemplate(templateId).then(
                data => {
                    setFullRecord(addHelperFields({ record: data.template, status: data.status }));
                }
            );
        } else if (recordId) {
            getRecord(recordId).then(
                data => {
                    setFullRecord(addHelperFields(data));
                }
            );
        } else if (documentFormId) {
            getDocumentForm(documentFormId).then(response => setFullRecord(addHelperFields({record: response.body, status: 'new'})));
        } else {
            setFullRecord(addHelperFields({
                status: statusList.new,
                certLevel: certLevelList.not_public,
                record: {
                    leader: '',
                    controlfields: [],
                    datafields: []
                }
            }));
        }
        return () => {
            setFullRecord(null);
        }
    }, [templateId, recordId, documentFormId])

    useEffect(() => {
        bibliographicSchema().then(data => {
            setMetadataProfile(data);
        });
    }, [])

    useEffect(() => {
        if (!metadataProfile || !metadataProfile.datafields) { return; }
        setEntityLinks(MetadataProfileEntityLinks(metadataProfile));
    }, [metadataProfile]);

    const saveRecordHandler = () => {
        if (recordId) {
            updateCollectionsFromEditor(metadataProfile).then(response => {
                const newRecord = {
                    ...fullRecord,
                    record: fillAuthorityIds(fullRecord.record, response),
                };
                setFullRecord(newRecord);
                updateRecord(recordId, removeHelperFields(newRecord)).then(response => {
                    setShowSnackbar(true);
                });
            });
        } else if (documentFormId) {
            updateCollectionsFromEditor(metadataProfile).then(response => {
                const newRecord = {
                    ...fullRecord,
                    record: fillAuthorityIds(fullRecord.record, response),
                };
                setFullRecord(newRecord);
                updateDocumentForm(documentFormId, removeHelperFields(newRecord)).then(response => {
                    setShowSnackbar(true);
                    history.push(`/cataloging/edit/${response.id}`);
                });
            });
        } else {
            updateCollectionsFromEditor(metadataProfile).then(response => {
                const newRecord = {
                    ...fullRecord,
                    record: fillAuthorityIds(fullRecord.record, response),
                };
                setFullRecord(newRecord);
                createRecord(removeHelperFields(newRecord))
                    .then(response => {
                        setShowSnackbar(true);
                        history.push(`/cataloging/edit/${response.id}`);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            });
        }
    }

    if (!fullRecord || !metadataProfile) { return <Spinner /> }
    const errors = hasErrors(fullRecord.record);

    return (
        <React.Fragment>
            <Grid container className={classes.myStickyTop}>
                <Grid container justify="flex-end" >
                    <SaveButton
                      className={classes.myBlueButton}
                      onSuccess={saveRecordHandler}
                      onFailure={() => setShowNewErrors(true)}
                      error={!fullRecord || errors}
                    />
                    <Button
                        onClick={() => setShowPopup(true)}
                        className={classes.myRedButton}
                    >{trans('dismiss')}</Button>
                </Grid>
                </Grid>
                <Grid container>
                <ActionButtons
                    statusHandler={createPropertySetter(setFullRecord, 'status')}
                    status={fullRecord.status}
                    certLevelHandler={createPropertySetter(setFullRecord, 'certLevel')}
                    certLevel={fullRecord.certLevel}
                    toggleRecordConnections={() => setRecordConnectionsIsOpen(prev => !prev)}
                    saveRecordHandler={saveRecordHandler}
                />
            </Grid>
            <Grid container spacing={6} className={classes.marginTop}>
                <Grid item sm={recordConnectionsIsOpen ? 8 : 12} >
                    <Editor
                        record={fullRecord ? fullRecord.record : null}
                        setRecord={input => {
                            setShowNewErrors(false);
                            createPropertySetter(setFullRecord, 'record')(input);
                        }}
                        metadataProfile={metadataProfile}
                        setMetadataProfile={setMetadataProfile}
                        showNewErrors={showNewErrors}
                    />
                </Grid>

                {recordConnectionsIsOpen
                    ? <Grid item sm={4}><RecordConnections record={fullRecord ? fullRecord.record : null} entityLinks={entityLinks} /></Grid>
                    : null}
            </Grid>


            <Popup
                show={showPopup}
                save={() => props.history.push(templateId ? '/cataloging' : '/catalog')}
                cancel={() => setShowPopup(false)}
                title={trans('dismiss_confirmation')}
            />
        </React.Fragment>
    )
}

export default withRouter(WorkpageBody);
