import axios from '../settings/axios-backend';
import urls from '../settings/urls';

export  const getFormats = () => {
    return axios.get(urls.GET_METADATA_PROFILES)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};