import React from 'react'
import { Link } from 'react-router-dom';

import { Grid, Paper, Typography } from '@material-ui/core';

import Header from '../Header/Header';

const AcquisitionDashboard = () => {
  let dashboard = (
    <>
      <Header>
        <Typography>Gyarapítás</Typography>
      </Header>
      <Grid container>
        <Grid item xs={3}>
          <Paper >
            <Link to="acquisition/currencyList">Devizák</Link>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
  
  return dashboard;
}

export default AcquisitionDashboard;
