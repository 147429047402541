import React from 'react'
import Selector from './Selector';
import { Route, Switch, Redirect } from 'react-router-dom';
import CollectionShow from './Collection/CollectionShow';
import CollectionEdit from './Collection/CollectionEdit/CollectionEdit';
import { useAuth } from '../../../shared/auth';
import { permissions } from '../../../shared/permissions';
import EntityEdit from './Entity/EntityEdit/EntityEdit';
import EntityShow from './Entity/EntityShow/EntityShow';
import SuccessSnackbar from '../../../components/UI/Snackbar/Success';
import { useTranslator } from '../../../shared/translate';
import ConfigContext from '../../../context/snackbar-context';


const ValueSet = () => {
  const auth = useAuth();
  const trans = useTranslator();
  const { showSnackbar, setShowSnackbar } = React.useContext(ConfigContext);

  let routes = (
    <Switch>
      {/* Default */}
      {auth.isGranted(permissions.CONFIG_VALUESET_COLLECTION_READ)
        ? <Route path="/config/value-set" exact={true} render={() => <Redirect to="/config/value-set/collection" />} />
        : null}
      {/* Collection show */}
      {auth.isGranted(permissions.CONFIG_VALUESET_COLLECTION_READ) ?
        <Route path="/config/value-set/collection/show/:id" exact={true} component={CollectionShow} />
        : null}
      {/* Collection edit */}
      {auth.isGranted(permissions.CONFIG_VALUESET_COLLECTION_UPDATE)
        ? <Route path="/config/value-set/collection/edit/:id" exact={true} component={CollectionEdit} />
        : null}
      {/* Entity show */}
      <Route path="/config/value-set/entity/:type/show/:id" exact={true} component={EntityShow} />
      {/* Entity edit */}
      <Route path="/config/value-set/entity/:type/edit/:id" exact={true} component={EntityEdit} />

      {/* Selector: menu and list */}
      <Route path="/config/value-set" component={Selector} />

      <Redirect to="/config/metadata-profile" />
    </Switch>
  );
  return (
    <React.Fragment>
      <SuccessSnackbar
        message={<span>{trans('save_successful')}</span>}
        showSnackbar={showSnackbar}
        setShowSnackbar={setShowSnackbar}
      />
      {routes}
    </React.Fragment>
  );
}



export default ValueSet
