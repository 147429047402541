import React from 'react'
import { useTranslator } from '../../../../../shared/translate';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    MenuList,
    MenuItem,
    Paper,
    ButtonGroup,
    Popper,
    Grow,
    ClickAwayListener,
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import { isEqual } from '../../../../../shared/util';
import { withRouter } from 'react-router';
import { marc21Profiles } from '../../../../../shared/parameters/marc21Profiles';

const useStyles = makeStyles(theme => ({

    menuStyle: {
        fontSize: 14,
        fontWeight: 500,
        color: 'black',
        minHeight: 40,
        fontFamily: 'Source Sans Pro',
        padding: theme.spacing(0, 3),
        '&:hover': {
            color: 'white',
            backgroundColor: '#909090',
            textDecoration: 'none',
        },
    },

    mainBlueButton: {
        backgroundColor: '#2b75bb',
        borderRadius: 6,
        color: 'white',
        fontSize: 14,
        textTransform: 'capitalize',
        padding: '2px 14px',
        fontFamily: 'Source Sans Pro',
        fontWeight: 500,
        zIndex: 'inherit',
        '&:hover': {
            background: '#169bd5',
        },
    },
    linkStyle: {
        color: 'black',
        '&:hover': {
            color: 'white',
            backgroundColor: '#909090',
            textDecoration: 'none',
        },
    },
}));


const MetadataOperationButton = (props) => {

    const profileFormat = props.match.params.format
    const profileFormatType = props.match.params.type
    const { metadataProfile } = props

    const t = useTranslator();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const classes = useStyles();

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const menuLists = (profileFormatInput) => {
        switch (profileFormatInput) {
            case marc21Profiles.bibliographic:
            case marc21Profiles.holding:
                return (
                    <MenuList>
                        <Link className={classes.linkStyle} to={'/config/metadata-profile/marc21/' + profileFormat + '/show/' + metadataProfile.tag}><MenuItem className={classes.menuStyle}>{t('show')}</MenuItem></Link>
                        <Link className={classes.linkStyle} to={'/config/metadata-profile/marc21/' + profileFormat + '/edit/' + metadataProfile.tag}><MenuItem className={classes.menuStyle}>{t('edit')}</MenuItem></Link>
                    </MenuList>
                )
            case marc21Profiles.authority:
                return (
                    <MenuList>
                        <Link className={classes.linkStyle} to={'/config/metadata-profile/marc21/' + profileFormat + '/' + profileFormatType + '/show/' + metadataProfile.tag}><MenuItem className={classes.menuStyle}>{t('show')}</MenuItem></Link>
                        <Link className={classes.linkStyle} to={'/config/metadata-profile/marc21/' + profileFormat + '/' + profileFormatType + '/edit/' + metadataProfile.tag}><MenuItem className={classes.menuStyle}>{t('edit')}</MenuItem></Link>
                    </MenuList>
                )
            default:
                return (
                    <MenuList>
                    </MenuList>
                )
        }
    }
    return (
        <React.Fragment>
            <ButtonGroup variant="contained" color="primary" ref={anchorRef}>
                <Button className={classes.mainBlueButton} aria-haspopup="true" >
                    {t('operations')}
                </Button>
                <Button className={classes.mainBlueButton} aria-haspopup="true" onClick={handleToggle}>
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper open={open} transition disablePortal anchorEl={anchorRef.current} style={{ zIndex: '100' }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper id="menu-list-grow">
                            <ClickAwayListener onClickAway={handleClose}>
                                {menuLists(profileFormat)}
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )
                }
            </Popper >
        </React.Fragment >
    )
}
export default React.memo(withRouter(MetadataOperationButton), (prev, next) => {
    return isEqual(prev, next)
});