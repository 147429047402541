import React from 'react'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {
    Button,
    MenuList,
    MenuItem,
    Paper,
    ButtonGroup,
    Popper,
    Grow,
    ClickAwayListener,
} from '@material-ui/core';
import { useTranslator } from '../../../../../shared/translate';
import { useAuth } from '../../../../../shared/auth';
import { permissions } from '../../../../../shared/permissions';

const useStyles = makeStyles(theme => ({

    menuStyle: {
        fontSize: 14,
        fontWeight: 500,
        color: 'black',
        minHeight: 40,
        fontFamily: 'Source Sans Pro',
        padding: theme.spacing(0, 3),
        '&:hover': {
            color: 'white',
            backgroundColor: '#909090',
            textDecoration: 'none',
        },
    },

    mainBlueButton: {
        backgroundColor: '#2b75bb',
        borderRadius: 6,
        color: 'white',
        fontSize: 14,
        textTransform: 'capitalize',
        padding: '2px 14px',
        fontFamily: 'Source Sans Pro',
        fontWeight: 500,
        zIndex: 'inherit',
        '&:hover': {
            background: '#169bd5',
        },
    },
    linkStyle: {
        color: 'black',
        '&:hover': {
            color: 'white',
            backgroundColor: '#909090',
            textDecoration: 'none',
        },
    },
}));

const HoldingListOperationButton = (props) => {

    const { holding, setShowDeletePopupHandler, openViewPopupHandler } = props

    const t = useTranslator();
    const auth = useAuth();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const classes = useStyles();

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    if (!holding) { return null; }

    return (
        <React.Fragment>
            <ButtonGroup variant="contained" color="primary" ref={anchorRef}>
                <Button className={classes.mainBlueButton} >
                    {t('operations')}
                </Button>
                <Button onClick={handleToggle} className={classes.mainBlueButton}>
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper open={open} transition disablePortal anchorEl={anchorRef.current} style={{ zIndex: '100' }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper id="menu-list-grow">
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    <MenuItem onClick={() => {
                                        openViewPopupHandler(holding)
                                        setOpen(false);
                                    }} className={classes.menuStyle} >{t('view')}</MenuItem>
                                    {auth.isGranted(permissions.INVENTORY_HOLDING_UPDATE) ? <Link className={classes.linkStyle} to={'/cataloging/holding/editor/' + holding.id}><MenuItem className={classes.menuStyle}>{t('edit')}</MenuItem></Link> : null}
                                    {auth.isGranted(permissions.INVENTORY_HOLDING_DELETE) ? <MenuItem className={classes.menuStyle} onClick={(event) => {
                                        setShowDeletePopupHandler(true, holding.id);
                                        setOpen(false);
                                    }}>{t('delete')}</MenuItem> : null}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    )
}

export default HoldingListOperationButton
