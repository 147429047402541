import React from 'react'
import { Button } from '@material-ui/core';

import { useTranslator } from '../../../../../shared/translate';
import PopupModal from '../../../../../components/UI/Popup/PopupModal';
import CollectionAddEntitySearch from './CollectionAddEntitySearch';

const CollectionAddEntity = (props) => {
    const {
        entities,
        addEntity,
        title,
        color,
    } = props;

    const trans = useTranslator();
    const [showPopup, setShowPopup] = React.useState(false);
    const [newlySelectedEntities, setNewlySelectedEntities] = React.useState([]);

    return (
        <div>
            <Button 
                variant={'contained'}
                color={color || 'primary'}
                onClick={() => setShowPopup(true)}
            >
                {title || trans('new-entity')}
            </Button>
            <PopupModal
                show={showPopup}
                setShow={setShowPopup}
                onClose={() => {
                    setNewlySelectedEntities([]);
                }}
                size={'large'}
                title={trans('new-entity')}
                body={<CollectionAddEntitySearch 
                    newlySelectedEntities={newlySelectedEntities}
                    setNewlySelectedEntities={setNewlySelectedEntities}
                    alreadySelectedEntities={entities}
                    onSave={entities => {
                        addEntity(entities);
                        setNewlySelectedEntities([]);
                        setShowPopup(false);
                    }}
                />}
            />
        </div>
    )
}

export default CollectionAddEntity;
