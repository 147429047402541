import React, { useEffect, useState } from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import { getItemHistory } from '../../../../api/marc21/item/item'
import Spinner from '../../../../components/UI/Spinner/Spinner'
import { dateFormatString } from '../../../../shared/util'
import { useTranslator } from '../../../../shared/translate'

const HistoryTab = (props) => {

    const { item } = props
    const [history, setHistory] = useState(null)
    const t = useTranslator()
    useEffect(() => {
        getItemHistory(item.id).then(res => setHistory(res))
    }, [item.id])


    if (history === null) {
        return <Spinner />
    }

    return (
        <React.Fragment>
            <br />
            <br />
            <Table >
                <TableHead >
                    <TableRow>
                        <TableCell variant={'head'}>{t('lastname')}</TableCell>
                        <TableCell variant={'head'}>{t('firstname')}</TableCell>
                        <TableCell variant={'head'}>{t('idcard_number')}</TableCell>
                        <TableCell variant={'head'}>{t('barcode')}</TableCell>
                        <TableCell variant={'head'}>{t('loan_date')}</TableCell>
                        <TableCell variant={'head'}>{t('expiry_date')}</TableCell>
                        <TableCell variant={'head'}>{t('return_date')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {history.map((h, key) => {
                        return (
                            <TableRow key={key} hover>
                                <TableCell>{h.reader.lastname}</TableCell>
                                <TableCell>{h.reader.firstname}</TableCell>
                                <TableCell>{h.reader.idcard_number}</TableCell>
                                <TableCell>{h.reader.barcode}</TableCell>
                                <TableCell>{dateFormatString(h.loanDate)}</TableCell>
                                <TableCell>{dateFormatString(h.expiryDate)}</TableCell>
                                <TableCell>{dateFormatString(h.returnDate)}</TableCell>
                            </TableRow>
                        )
                    })}

                </TableBody>
            </Table>

        </React.Fragment>


    )
}

export default HistoryTab
