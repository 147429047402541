import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import MetadataProfile from './MetadataProfile/MetadataProfile';
import ValueSet from './ValueSet/ValueSet';
import MetadataProfileMarc21 from './MetadataProfile/Profiles/Marc21/MetadataProfileMarc21';
import Marc21Callsign from './MetadataProfile/Profiles/Marc21/Callsign/Marc21Callsign';
import BaseContainer from '../../hoc/BaseContainer/BaseContainer';
import Template from './Template/Template';
import TemplateEdit from '../../components/Templates/Edit/TemplateEdit';
import { Provider as TemplateContext } from '../../context/template'
import { useAuth } from '../../shared/auth';
import { permissions } from '../../shared/permissions';
import { authorityRecordTypeArray } from '../../shared/parameters/authorityRecordTypes';

const Config = (props) => {
  const auth = useAuth()
  return (
    <React.Fragment>
      <Suspense fallback={<p>Loading...</p>}>
        
          <Switch>
            {auth.isGranted(permissions.CONFIG_TEMPLATE_READ) ?
              <Route path="/config/template" render={() => (
                <TemplateContext>
                  <Switch>
                    {auth.isGranted(permissions.CONFIG_TEMPLATE_UPDATE)
                      ? <Route path="/config/template/:id/:operation(edit|copy)" component={TemplateEdit} />
                      : null}
                    <Route path="/config/template" component={Template} />
                  </Switch>
                </TemplateContext>
              )} />
              : null}
            <Route path="/" render={() => (
              <BaseContainer>
                <Route path="/config" exact={true} render={() => <Redirect to="/catalog" />} />
                <Route path="/config/metadata-profile" exact={true} component={MetadataProfile} />
                
                <Route path="/config/metadata-profile/marc21/:format(bibliographic|holding)" exact={true} component={MetadataProfileMarc21} />
                <Route path="/config/metadata-profile/marc21/:format(bibliographic|holding)/show/:callsign(\d+)" render={(props) => <Marc21Callsign {...props} isEditableDefault={false} />} />
                <Route path="/config/metadata-profile/marc21/:format(bibliographic|holding)/edit/:callsign(\d+)" render={(props) => <Marc21Callsign {...props} isEditableDefault={true} />} />
                
                <Route path={"/config/metadata-profile/marc21/:format(authority)/:type("+authorityRecordTypeArray.join('|')+")"} exact={true} component={MetadataProfileMarc21} />
                <Route path={"/config/metadata-profile/marc21/:format(authority)/:type("+authorityRecordTypeArray.join('|')+")/show/:callsign(\\d+)"} render={(props) => <Marc21Callsign {...props} isEditableDefault={false} />} />
                <Route path={"/config/metadata-profile/marc21/:format(authority)/:type("+authorityRecordTypeArray.join('|')+")/edit/:callsign(\\d+)"} render={(props) => <Marc21Callsign {...props} isEditableDefault={true} />} />
                
                {auth.isGranted(permissions.CONFIG_VALUESET_READ) ? <Route path="/config/value-set" component={ValueSet} /> : null}
              </BaseContainer>
            )}>
            </Route>
          </Switch>
       
      </Suspense>
    </React.Fragment>
  )
}

export default Config
