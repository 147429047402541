import React, { useState, useEffect } from 'react'
import {
    Button,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { set } from '../../../../../shared/util'
import InputTypeSelect from '../../../../UI/Input/InputTypeSelect';
import InputStatus from '../../../../UI/Input/InputStatus';
import { dateFormatString } from '../../../../../shared/util'
import { useTranslator } from '../../../../../shared/translate';
import { getCollections } from '../../../../../api/valueset/collection';
import useSmartRequest from '../../../../../hooks/useSmartRequest';
import ValuesetPopup from './ValuesetPopup';
import ListAddedCollection from './ListAddedCollection';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
    },
    myTableHeadStyle: {
        backgroundColor: '#e6e6e6',
    },
    tableHoverStyle: {
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: 'rgba(37, 118, 195, 0.1)',
        }
    },
    paddingStyle: {
        padding: '10px !important',
      },
}));

const Marc21CallsignEdit = (props) => {
    const classes = useStyles();

    const { callsignData, setCallsignData } = props;

    const trans = useTranslator();

    const [showInputPopup, setShowInputPopup] = useState(false);
    const [collections, setCollections] = useState([]);//all collections
    const [selectedSubfieldIndex, setSelectedSubfieldIndex] = useState(null);

    const { sendRequest: getCollectionsRequest } = useSmartRequest(getCollections);

    useEffect(() => {
        getCollectionsRequest().then(res => setCollections(res))
    }, [getCollectionsRequest])


    const callsignChangeHandler = (path, event) => {
        const inputValue = event.target.value;
        setCallsignData((prevCallsignData) => set(prevCallsignData, inputValue, path));
    }

    const callsignChangeHandlerBoolean = (path, event) => {
        const inputValue = event.target.value === 'true' ? true : false;
        setCallsignData((prevCallsignData) => set(prevCallsignData, inputValue, path));
    }

    const showCallsign = (callsignData) => {

        return (
            <Paper>
                <Table>
                    <TableHead className={classes.myTableHeadStyle}>
                        <TableRow>
                            <TableCell variant={'head'}>{trans('callsign')}</TableCell>
                            <TableCell variant={'head'}>{trans('description')}</TableCell>
                            <TableCell variant={'head'}>{trans('repeatable')}</TableCell>
                            {/* <TableCell variant={'head'}>{trans('required')}</TableCell> */}
                            <TableCell variant={'head'}>{trans('status')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow className={classes.tableHoverStyle}>
                            <TableCell className={classes.paddingStyle} variant={'body'}>{callsignData.tag}</TableCell>
                            <TableCell className={classes.paddingStyle} variant={'body'}>{trans(callsignData.label)}</TableCell>
                            <TableCell className={classes.paddingStyle} variant={'body'}>{callsignData.repeatable ? trans('repeatable') : trans('not-repeatable')}</TableCell>
                            {/* <TableCell className={classes.paddingStyle} variant={'body'}>
                                <InputRequired
                                    value={callsignData.required}
                                    onChange={(event) => callsignChangeHandlerBoolean('required', event)} 
                                />
                            </TableCell> */}
                            <TableCell className={classes.paddingStyle} variant={'body'}><InputStatus
                                value={callsignData.status}
                                onChange={(event) => callsignChangeHandlerBoolean('status', event)} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        );
    }

    const showIndicator = (indicator, indicatorCount) => {
        if (typeof indicator === 'undefined' || indicator === null) {
            return '#'
        } else {
            return (
                <React.Fragment>
                    {trans(indicator.label)}
                    <Paper>
                        <Table>
                            <TableHead className={classes.myTableHeadStyle}>
                                <TableRow>
                                    <TableCell variant={'head'}>{trans('value')}</TableCell>
                                    <TableCell variant={'head'}>{trans('description')}</TableCell>
                                    <TableCell variant={'head'}>{trans('status')}</TableCell>
                                    <TableCell variant={'head'}>{trans('last-modified')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {indicator.codes.map((code, index) => (
                                    <TableRow className={classes.tableHoverStyle} key={index}>
                                        <TableCell className={classes.paddingStyle} variant={'body'}>{code.code}</TableCell>
                                        <TableCell className={classes.paddingStyle} variant={'body'}>{trans(code.label)}</TableCell>
                                        <TableCell className={classes.paddingStyle} variant={'body'}>
                                            <InputStatus
                                                value={code.status}
                                                onChange={(event) => callsignChangeHandlerBoolean(indicatorCount + '.codes.' + index + '.status', event)} /></TableCell>
                                        <TableCell variant={'body'}>{typeof code.lastModified == 'undefined' ? '-' : dateFormatString(code.lastModified)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </React.Fragment>
            );
        }
    }

    const showSubfields = (subfields) => {
        if (typeof subfields === 'undefined' || subfields === null) {
            return null
        } else {
            const subfieldsArray = Object.entries(subfields);
            return (
                <React.Fragment>
                    <Paper style={{overflow: 'auto'}}>
                        <Table>
                            <TableHead className={classes.myTableHeadStyle}>
                                <TableRow>
                                    <TableCell variant={'head'}>{trans('subfield')}</TableCell>
                                    <TableCell variant={'head'}>{trans('description')}</TableCell>
                                    <TableCell variant={'head'}>{trans('repeatable')}</TableCell>
                                    {/* <TableCell variant={'head'}>{trans('required')}</TableCell> */}
                                    <TableCell variant={'head'}>{trans('input-type')}</TableCell>
                                    <TableCell variant={'head'}>{trans('status')}</TableCell>
                                    <TableCell variant={'head'}>{trans('last-modified')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subfieldsArray.map((s) => {
                                    const [key, subfield] = s;
                                    return (
                                        <React.Fragment key={key}>
                                            <TableRow className={classes.tableHoverStyle}>
                                                <TableCell className={classes.paddingStyle} variant={'body'}>{subfield.code}</TableCell>
                                                <TableCell className={classes.paddingStyle} variant={'body'}>{trans(subfield.label)}</TableCell>
                                                <TableCell className={classes.paddingStyle} variant={'body'}>{subfield.repeatable ? trans('repeatable') : trans('not-repeatable')}

                                                </TableCell>
                                                {/* <TableCell variant={'body'}>
                                                    <InputRequired
                                                        value={subfield.required}
                                                        onChange={(event) => callsignChangeHandlerBoolean('subfields.' + key + '.required', event)} />
                                                </TableCell> */}
                                                <TableCell variant={'body'}>


                                                    <InputTypeSelect
                                                        disabled={subfield.inputStrict}
                                                        value={subfield.input}
                                                        onChange={(event) => callsignChangeHandler('subfields.' + key + '.input', event)} />
                                                    {subfield.input === 'select' // Értékkészlet
                                                        ?
                                                        <React.Fragment>
                                                            <Button onClick={() => showInputPopupHandler(key)}>{trans('change-valueset')}</Button>
                                                            <ListAddedCollection collectionId={subfield.collection} />
                                                            {/* {Array.isArray(subfield.collections) ?
                                                                subfield.collections.map((cId, key) => {
                                                                    return <ListAddedCollection collectionId={cId} key={key} />
                                                                })
                                                                : null
                                                            } */}
                                                        </React.Fragment>
                                                        : null}
                                                    {'regex' === subfield.input
                                                    ? <TextField 
                                                        variant={'outlined'}
                                                        value={subfield.pattern || ''}
                                                        onChange={event => callsignChangeHandler('subfields.' + key + '.pattern', event)}
                                                    />
                                                    : null}

                                                </TableCell>
                                                <TableCell variant={'body'}>
                                                    <InputStatus
                                                        value={subfield.status}
                                                        onChange={(event) => callsignChangeHandlerBoolean('subfields.' + key + '.status', event)} />
                                                </TableCell>
                                                <TableCell variant={'body'}>{typeof subfield.lastModified == 'undefined' ? '-' : dateFormatString(subfield.lastModified)}</TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    )
                                }
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
                </React.Fragment >
            );
        }
    }

    const showInputPopupHandler = (subfieldIndex) => {
        setSelectedSubfieldIndex(subfieldIndex)
        setShowInputPopup(true)
    }

    const saveInputPopupHandler = (selectedCollection) => {
        setCallsignData(prevState => {
            const newSubfields = [...prevState.subfields];
            newSubfields.splice(selectedSubfieldIndex, 1, {
                ...prevState.subfields[selectedSubfieldIndex],
                collection: selectedCollection,
            });
            return {
                ...prevState,
                subfields: newSubfields,
            };
        })
        setShowInputPopup(false)
    }

    const collectionOfSubfieldIndex = (subfieldIndex) => {
        if (
            !callsignData.subfields ||
            !callsignData.subfields[subfieldIndex] ||
            !callsignData.subfields[subfieldIndex].collection
        ) {
            return null
        }
        return callsignData.subfields[subfieldIndex].collection;
    }
    return (
        <React.Fragment>
            <ValuesetPopup
                showInputPopup={showInputPopup}
                collections={collections}
                defaultSelectedCollection={collectionOfSubfieldIndex(selectedSubfieldIndex)}
                saveInputPopupHandler={saveInputPopupHandler}
                cancelInputPopupHandler={() => setShowInputPopup(false)}
            />

            {/*<h4>{trans('callsign-data')}</h4>*/}
            {showCallsign(callsignData)}

            {9 < Number.parseInt(callsignData.tag)
                ? <React.Fragment>

                    <h4>{trans('1-indicator')}</h4>
                    {showIndicator(callsignData.indicator1, 'indicator1')}

                    <h4>{trans('2-indicator')}</h4>
                    {showIndicator(callsignData.indicator2, 'indicator2')}

                    <h4>{trans('subfields')}</h4>
                    {showSubfields(callsignData.subfields)}

                </React.Fragment>
                : null}
        </React.Fragment>
    )
}

export default Marc21CallsignEdit;