import React from 'react'
import Grid from '@material-ui/core/Grid';
import Header from './Header/Header'
import Body from './Body/Body';

const Mainpage = () => {
    return (
        <React.Fragment>
            <Grid container justify="center" spacing={5} style={{marginTop: "0px"}}>
                <Grid item sm={12}>
                    <Header />
                </Grid>
            </Grid>
            <Grid container justify="center" spacing={5} style={{marginTop: "50px"}}>
                <Grid item sm={10}>
                    <Body />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default Mainpage
/*
státusz
· új (alapértelmezett érték, rekord létrehozásakor)

· jóváhagyott

· törölt

Hitelesítési szint

· nem publikus (alapértelmezett érték, rekord létrehozásakor)

· publikus

*/