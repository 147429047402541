import React, { useState } from 'react'
import { Container, Button, Card, CardContent, CssBaseline } from '@material-ui/core'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useTranslator } from '../../shared/translate';
import useSmartRequest from '../../hooks/useSmartRequest';
import { goLogin } from '../../api/login';
import axios from '../../api/settings/axios-login';
import { redirect } from '../../shared/util';
import { isLoggedIn, removeAccessToken, setAccessToken } from '../../shared/auth';
import jwt from 'jsonwebtoken';
import withErrorHandler from '../../hoc/withErrorHandler';
import useKeyPress from '../../hooks/useKeyPress';
import { storageKeys } from '../../shared/localStorageKeys';

const Login = () => {
    const t = useTranslator();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const { sendRequest, isSending } = useSmartRequest(goLogin);

    const sendLoginHandler = () => {
        sendRequest({ username, password }).then(res => {
            if (typeof res === 'undefined') {
                //dont translate this, mert ha nincs szerver nem fogja lefordítani ;)
                setMessage('Can\'t connect to backend!')
                return;
            }
            if (jwt.decode(res.accessToken) === null) {
                removeAccessToken()
                setMessage(t('invalid-username-or-password'))
            } else {
                setAccessToken(res.accessToken)
                localStorage.setItem('tokenInfo', JSON.stringify(jwt.decode(res.accessToken)));
                setMessage('')
                redirect('/');
            }
        })
    }

    useKeyPress('Enter', sendLoginHandler)
    useKeyPress('Home', () => {
        if (process.env.NODE_ENV === 'development') {
            localStorage.setItem(storageKeys.ACCESS_TOKEN, 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIiLCJpYXQiOjE1NzQ0MjQ1MzYsIm5iZiI6MTU3NDQyNDUzNSwiZXhwIjoxNTc0NDI0NjU2LCJ1c2VyIjp7InVwZGF0ZWRBdCI6IjIwMTkxMTIyMTEyNTQ2LjE2NFoiLCJkbiI6InVpZD10ZXN0dXNlcixvdT10ZXN0T3JnVW5pdCxkYz1vcGVubWljcm9zY29weSxkYz1vcmciLCJ1dWlkIjoiNDA3NDI5YTUtMWNhNi00MThmLWIxODYtOWM1OGUyZjEzMDYzIiwic3VybmFtZSI6InVzZXIiLCJjb21tb25OYW1lIjoidGVzdCIsImNyZWF0ZWRBdCI6IjIwMTkxMTIyMTEyNTExLjYwN1oiLCJ1c2VybmFtZSI6InRlc3R1c2VyIiwicGFyZW50SWQiOiJiOGNkMGUxNS0xM2FmLTQyNTctYWNiOC05NmQwNGQxNzI1ODAifSwic2NvcGUiOltdfQ.8EwCe9pW3blVVIQ7aBm9YFq1sJbCmJ76f5f_iVKsGkY')
            redirect('/');
        }
    })



    if (isLoggedIn()) {
        redirect('/')
        return null;
    }
    return (
        <Container >
            <CssBaseline />
            <Grid
                container
                justify="center"
                alignItems="center"
                style={{ height: '90vh' }}
            >
                <Grid item xs={4}>
                    <Paper>
                        <Card>
                            <CardContent>
                                <h1 style={{ textAlign: 'center' }}>{t('mkdny')}<br />{t('login')}</h1>
                                <TextField
                                    onChange={(event) => setUsername(event.target.value)}
                                    value={username}
                                    label={t('username')}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    autoFocus
                                />
                                <TextField
                                    onChange={(event) => setPassword(event.target.value)}
                                    value={password}
                                    label={t('password')}
                                    type="password"
                                    autoComplete="current-password"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                                <Button
                                    disabled={isSending}
                                    onClick={sendLoginHandler}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    size={'large'}
                                >
                                    {t('login')}
                                </Button>
                                {!!message
                                    ? <React.Fragment>
                                        <br />
                                        <br />
                                        {message}
                                    </React.Fragment>
                                    : message
                                }
                            </CardContent>
                        </Card>
                    </Paper>
                </Grid>
            </Grid>
        </Container >
    )
}

export default withErrorHandler(Login, axios);
