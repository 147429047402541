import { useEffect } from 'react';

export default function useKeyPress(targetKey, action) {
    useEffect(() => {
        function downHandler({ key }) {
            if (key === targetKey) {
                action()
            }
        }
        document.addEventListener('keydown', downHandler);
        return () => {
            document.removeEventListener('keydown', downHandler);
        };
    }, [targetKey,action ]);

}
