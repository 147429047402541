import React from 'react';

import ControlfieldTableSelect008 from './ControlfieldTableSelect008';
import ControlfieldTableSelectDefault from './ControlfieldTableSelectDefault';

const ControlfieldTableSelect = props => {
  const { tag } = props;
  switch (tag) {
    case '008': return <ControlfieldTableSelect008 {...props} />
    default: return <ControlfieldTableSelectDefault {...props} />
  }

};

export default ControlfieldTableSelect;
// export default React.memo(ControlfieldTableSelect, (prev, next) => (
//     isEqual(prev.options, next.options) &&
//     prev.value === next.value &&
//     prev.list === next.list &&
//     prev.disabled === next.disabled
// ));