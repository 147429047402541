import React from 'react';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
  } from '@material-ui/core';
import classes from './List.module.scss';
import { useTranslator } from '../../../shared/translate';

const TableGrid = (props) => {

    const { 
        records,
        columns
     } = props;

    const trans = useTranslator();

    const rows = records.map((rec, key) => {
        const cells = columns.map((item, index) => {
            return (
                <TableCell className={classes.paddingStyle} variant={'body'} key={index}>
                    { 
                        rec[item.map] ? 
                            item.format ? item.format(rec[item.map]) : trans(rec[item.map])
                            : null
                    }
                </TableCell>
            );
        });

        return <TableRow hover key={key}>{cells}</TableRow>;
    });

    return (
        <React.Fragment>
            <Paper>
                <Table>
                <TableHead className={classes.myTableHeadStyle}>
                    <TableRow>
                        {
                            columns.map((col, key) => {
                                return <TableCell variant={'head'} key={key}>{trans(col.label)}</TableCell>
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tableHoverStyle}>
                    { rows }
                </TableBody>
                </Table>
            </Paper>
        </React.Fragment>
    )
}

export default TableGrid;