import axios from '../../settings/axios-backend';
import urls from '../../settings/urls';

const bibliographicSchema = () => {
    return axios.get(urls.GET_METADATA_PROFILE_MARC21)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

export default bibliographicSchema