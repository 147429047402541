import urls from './settings/urls';
import { axiosLogin } from './settings/axios-login';

export const goLogin = (data) => {

    return axiosLogin.post(urls.LOGIN, data)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};