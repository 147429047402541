import React from 'react'
import { trueOrUndefined } from '../../../shared/util';
import { Translate as T } from '../../../shared/translate';
import {
    Select,
    MenuItem,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({

    inputStyle: {
        padding: 10,
        fontSize: 14,
        fontWeight: 500,
    },
});

const InputStatus = (props) => {
    const classes = useStyles();

    let defaultValue = true
    if (trueOrUndefined(props.value)) {
        defaultValue = true;
    } else {
        defaultValue = false;
    }

    return (
        <React.Fragment>
            <Select
                fullWidth
                variant="outlined"
                inputProps={{
                    className: classes.inputStyle
                }}
                onChange={props.onChange} value={defaultValue}>
                <MenuItem value="true">{T('active')}</MenuItem>
                <MenuItem value="false">{T('not-active')}</MenuItem>
            </Select>
        </React.Fragment>
    )
}

export default InputStatus
