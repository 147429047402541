import React from 'react'
import { useTranslator } from '../../../../../shared/translate';
import SelectList from './SelectList';

import { makeStyles } from '@material-ui/core/styles';
import ReactSelect, { createFilter } from '../../../../UI/Input/ReactSelect';
import { SYMBOL_EN_DASH } from '../../../../../shared/symbols';

const useStyles = makeStyles({
  inputStyle: {
    padding: 10,
    fontSize: 14,
    fontWeight: 500,
  },
});

const ControlfieldTableSelect008 = (props) => {
  const classes = useStyles();
  const trans = useTranslator();
  const {
    options,
    value,
    setValue,
    disabled,
    list,
    // tag,
    position
  } = props;

  let valueObject = options.find(option => option.code === value);
  if (valueObject) {
    valueObject = {
      value: valueObject.code,
      label: `${valueObject.code.replace(/ /g, '#')} ${SYMBOL_EN_DASH} ${trans(valueObject.label)}`
    };
  }

  switch (position) {
    case '15-17':
    case '35-37':
      return (
        <ReactSelect
          fullWidth
          variant="outlined"
          inputProps={{
            className: classes.inputStyle
          }}
          value={valueObject} 
          onChange={elem => setValue(elem.value)} 
          disabled={disabled}
          options={
            options.map((code, index) => {
              let newValue = '';
              let newLabel = '';
              let isDisabled = false;
              if (typeof code.code === 'string' && code.code.startsWith('-')) {
                // Deprecated country/language code starts with "-"
                newValue = code.code.substr(1).padEnd(3, ' ');
                newLabel = newValue.replace(/ /g, '#') + ' ' + String.fromCharCode(8211) + ' ' + trans(code.label) + ' ' + String.fromCharCode(8211) + ' (' + trans('deprecated') + ')'
                isDisabled = true;
              } else {
                newValue = code.code.padEnd(3, ' ');
                newLabel = newValue.replace(/ /g, '#') + ' ' + String.fromCharCode(8211) + ' ' + trans(code.label)
              }
              return ({
                value: newValue,
                label: newLabel,
                disabled: isDisabled,
              });
            })
          }
          isOptionDisabled={option => option.disabled}
          filterOption={createFilter({
            matchFrom: 'any',
          })}
        />
      )
    default:
      return list
        ? <SelectList
          options={options}
          value={value}
          setValue={setValue}
          length={list}
        />
        : (
          <ReactSelect
            fullWidth
            variant="outlined"
            inputProps={{
              className: classes.inputStyle
            }}
            value={valueObject} 
            onChange={elem => setValue(elem.value)} 
            disabled={disabled}
            options={options.map(option => ({
                value: option.code,
                label: `${option.code.replace(/ /g, '#')} ${SYMBOL_EN_DASH} ${trans(option.label)}`,
              })
            )}
            filterOption={createFilter({
              matchFrom: 'start',
            })}
          />
        )
        ;
  }


  ;
}

export default ControlfieldTableSelect008
