import { ApolloClient, gql, HttpLink, InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-boost';
import axios from '../../settings/axios-backend';
import urls from '../../settings/urls';
import { authorityRecordTypeArray } from '../../../shared/parameters/authorityRecordTypes';
import { dumpObject } from '../../../shared/util';
import { parseOnnRecord } from '../../../shared/recordUtil';

export const getAuthorityRecords = () => {
  console.log('TODO getAuthorityRecords');
  return new Promise((resolve, reject) => {return []});
  // return axios.get(urls.AUTHORITY_RECORDS)
  //   .then(response => {
  //     console.log(response.data)
  //     return response.data;
  //   })
  //   .catch(error => {
  //     console.log(error)
  //     throw error;
  //   });
};


export const getAuthorityRecord = (type, id) => {
  const url = urls.AUTHORITY_RECORD.replace('{type}', type).replace('{id}', id);
  return axios.get(url)
    .then(response => {
      console.log(response.data)
      return response.data;
    })
    .catch(error => {
      console.log(error)
      throw error;
    });
};

export const getLocalAuthorityRecordsByType = (type, params) => {
  const url = urls.AUTHORITY_RECORDS.replace('{type}', type);
  return axios.get(url, params ? {params: params} : undefined)
    .then(response => {
      console.log(response.data)
      return response.data;
    })
    .catch(error => {
      console.log(error)
      throw error;
    });
};

export const getOnnAuthorityRecordsByType = (type, params) => {
  const fragmentMatcher = new IntrospectionFragmentMatcher({introspectionQueryResultData: {__schema: {types: []}}});
  const client = new ApolloClient({
    link: new HttpLink({
      uri: 'http://192.168.40.22:4000/',
    }),
    cache: new InMemoryCache({fragmentMatcher}),
  });

  const where = params.filters ? `where: ${dumpObject(params.filters)}` : '';
  console.log(where);

  return new Promise ((resolve, reject) => {
    const types = type + 's';
    let typeSpecificFields = '';
    switch (type) {
      case 'person':
        typeSpecificFields = 'profession {name}';
        break;
      // case 'geo':
      // case 'corporate':
      //   typeSpecificFields = `
      //   ${type}Type {
      //     name
      //     categories {
      //       type
      //     }
      //     eventFrom {
      //       time {
      //         date
      //       }
      //     }
      //   }
      //   `;
      //   break;
      default:
    }
    
    client.query({
      query: gql`
        {
          ${types} (
            ${where}
            from: ${params.offset || 0}
            size: ${params.limit || 10}
          ) {
            total,
            result {
              id,
              name {construction, type {name}}
              ${typeSpecificFields}
              attribute {
                concept{name}
                eventFrom {time{date}}
                attributeValue {
                  ... on Person {personId: id}
                  ... on Geo {currentName{title}}
                  ... on Corporate {corpId: id}
                  ... on Event {time {date, timePrecision}}
                  ... on IndividualException {error}
                }
              }
              digitalSource {url}
            }
          }
        }
      `
    }).then(response => {
      console.log(response);
      if (!response || !response.data || !response.data[types]) {
        console.log(response);
        reject(new Error('Unexpected response'));
      }
      resolve({
        count: response.data[types].total,
        result: response.data[types].result.map(elem => ({
          id: null,
          type: type,
          record: parseOnnRecord(type, elem),
          modifiedAt: null,
        })), 
      })
    }).catch(error => {
      console.log(error);
      reject(error);
    });
  });
}

export const getFilters = (type) => {
  const url = urls.AUTHORITY_RECORDS_FILTERS.replace('{type}', type);
  return axios.get(url)
    .then(response => {
      console.log(response.data)
      return response.data;
    })
    .catch(error => {
      console.log(error)
      throw error;
    });
};

export const updateAuthorityRecord = (type, id, data) => {
  console.log(JSON.stringify(data))
  const url = urls.AUTHORITY_RECORD.replace('{type}', type).replace('{id}', id);
  return axios.put(url, data)
    .then(response => {
      console.log(response.data)
      return response.data;
    })
    .catch(error => {
      console.log(error)
      throw error;
    });
}

export const createAuthorityRecord = (type, data) => {
  return axios.post(urls.AUTHORITY_RECORDS.replace('{type}', type), data)
    .then(response => {
      console.log(response.data)
      return response.data;
    })
    .catch(error => {
      console.log(error)
      throw error;
    });
};


export const deleteAuthorityRecord = (type, id) => {
  const url = urls.AUTHORITY_RECORD.replace('{type}', type).replace('{id}', id);
  return axios.delete(url)
    .then(response => {
      console.log(response.data)
      return response.data;
    })
    .catch(error => {
      console.log(error)
      throw error;
    });
}

export const authoritySchemaUpdateCallsign = (postData, profileFormatType) => {
  if(!authorityRecordTypeArray.includes(profileFormatType)){
    console.log('UNDEFINED profileFormatType')
  }
  const url = urls.MARC21_AUTHORITY_SCHEMA_UPDATE_CALLSIGN.replace('{type}', profileFormatType);
  return axios.put(url, postData)
    .then(response => {
      console.log(response.data)
      return response ? response.data : null;
    })
    .catch(error => {
      console.log(error)
      throw error;
    });
};