import React from 'react';
import FlipMove from 'react-flip-move';

import Datafield from './Datafield/Datafield';
import { useTranslator } from '../../../shared/translate';
import { isEqual, createPropertyArrayItemSetter } from '../../../shared/util';
import * as EditorButtons from '../Buttons/Buttons';

import { VALUE_LABEL_SEPARATOR, validateFieldsArray, getAuthorityRecordTitle } from '../../../shared/recordUtil';
import { getNewHash, addHelperFieldsToElement, reduceToHashes, removeHelperFields } from '../../../shared/listUtil';
import editorStyles from '../Editor.module.css'
import { getCollection } from '../../../api/valueset/collection';

// const calculateRequiredCollections = (datafields, metadataProfiles, extra = []) => {
//   if (!datafields || !metadataProfiles) { return [] }
//   return datafields.reduce((result, datafield) => {
//     if (!datafield.subfields) { return result; }
//     const datafieldMetadata = metadataProfiles.find(metadata => metadata.tag === datafield.tag);
//     if (!datafieldMetadata || !datafieldMetadata.subfields) { return result; }
    
//     datafield.subfields.forEach(subfield => {
//       const subfieldMetadata = datafieldMetadata.subfields.find(metadata => metadata.code === subfield.code);
//       if (!subfieldMetadata || !subfieldMetadata.collections) { return; }
//       subfieldMetadata.collections.forEach(collection => {
//         if (!result.includes(collection)) {
//           result.push(collection);
//         }
//       });
//     });

//     for (const collection of extra) {
//       if (!result.includes(collection)) {
//         result.push(collection);
//       }
//     }

//     return result;
//   }, [])
// }

const Datafields = props => {
  const { 
    metadataProfile,
    setMetadataProfile,
    datafields,
    setDatafields: propSetDatafields,
    isValidated,
    isTemplate,
    showNewErrors
  } = props;

  const [ lastTagChanged, setLastTagChanged ] = React.useState();
  const [ initialized, setInitialized ] = React.useState(false);

  const fetchCollection = React.useCallback((collection, tag, code) => {
    getCollection(collection).then(response => {
      setMetadataProfile(prevState => {
        const newState = [...prevState];

        const datafieldMetadataProfileIndex = prevState.findIndex(elem => elem.tag === tag);
        if (-1 === datafieldMetadataProfileIndex) { throw new Error(`Metadataprofile not found for tag ${tag}`); }
        const datafieldMetadataProfile = prevState[datafieldMetadataProfileIndex];

        const subfieldMetadataProfileIndex = datafieldMetadataProfile.subfields.findIndex(elem => elem.code === code);
        if (-1 === subfieldMetadataProfileIndex) { throw new Error(`Metadataprofile not found for tag ${tag} & code ${code}`); }
        const subfieldMetadataProfile = datafieldMetadataProfile.subfields[subfieldMetadataProfileIndex];

        const newDatafieldMetadataProfile = {
          ...datafieldMetadataProfile,
          subfields: [...datafieldMetadataProfile.subfields]
        }

        const newSubfieldMetadataProfile = {
          ...subfieldMetadataProfile,
          collection: response,
        };

        newDatafieldMetadataProfile.subfields.splice(subfieldMetadataProfileIndex, 1, newSubfieldMetadataProfile);
        newState.splice(datafieldMetadataProfileIndex, 1, newDatafieldMetadataProfile);
        return newState;
      });
    });
  }, [setMetadataProfile]);

  React.useEffect(() => {
    if (initialized || !datafields.length) { return; }
    const processed = {};
    datafields.forEach(datafield => {
      const datafieldMetadataProfileIndex = metadataProfile.findIndex(elem => elem.tag === datafield.tag);
      if (-1 === datafieldMetadataProfileIndex) { return; }
      const oldDatafieldMetadataProfile = metadataProfile[datafieldMetadataProfileIndex];
      if (!processed[datafield.tag]) { processed[datafield.tag] = []; }
      datafield.subfields.forEach(subfield => {
        if (processed[datafield.tag].includes(subfield.code)) { return; }
        processed[datafield.tag].push(subfield.code);
        const subfieldMetadataProfileIndex = oldDatafieldMetadataProfile.subfields.findIndex(elem => elem.code === subfield.code);
        if (-1 === subfieldMetadataProfileIndex) { return; }
        const oldSubfieldMetadataProfile = oldDatafieldMetadataProfile.subfields[subfieldMetadataProfileIndex];
        if ('select' !== oldSubfieldMetadataProfile.input || !oldSubfieldMetadataProfile.collection) { return; }
        fetchCollection(oldSubfieldMetadataProfile.collection, datafield.tag, subfield.code);
      })
    });
    setInitialized(true);
  }, [fetchCollection, initialized, datafields, metadataProfile, setMetadataProfile]);


  // const [ collections, setCollections ] = React.useState(() => calculateRequiredCollections(datafields, metadataProfile));
  // console.log(collections);
  // const [ collectionEntityMap, setCollectionEntityMap ] = React.useState({});
  // console.log(collectionEntityMap)
  // const [ entities, setEntities ] = React.useState({});
  // console.log(entities)
  // const entitiesRef = React.useRef(entities);
  // const collectionEntityMapRef = React.useRef(collectionEntityMap);

  // React.useEffect(() => {
  //   setCollectionEntityMap(prevMap => {
  //     const newMap = {...prevMap};

  //     for (const collection of Object.keys(newMap)) {
  //       if (!collections.includes(collection)) {
  //         delete(newMap[collection]);
  //       }
  //     }

  //     for (const collection of collections) {
  //       if (!newMap.hasOwnProperty(collection)) {
  //         getEntitiesByCollection(collection)
  //           .then(response => {
  //             setCollectionEntityMap(prevState => {
  //               const newState = {...prevState, [collection]: []}
  //               for (const entity of response) {
  //                 newState[collection].push(entity.id);
  //                 setEntities(prevEntities => {
  //                   return  {...prevEntities, [entity.id]: {...entity}};
  //                 })
  //               }
  //               return newState;
  //             });
  //           })
  //         ;
  //       }
  //     }

  //     setEntities(prevEntities => {
  //       const newEntities = {...prevEntities};

  //       entityLoop:
  //       for (const entityId in newEntities) {
  //         for (const collection in newMap) {
  //           if (newMap[collection].includes(entityId)) {
  //             break entityLoop;
  //           }
  //         }
  //         delete newEntities[entityId];
  //       }

  //       return newEntities;
  //     });

  //     return newMap;
  //   });
  // }, [ collections ]);

  // React.useEffect(() => {
  //   entitiesRef.current = {...entities};
  // }, [entities]);

  // React.useEffect(() => {
  //   collectionEntityMapRef.current = {...collectionEntityMap};
  // }, [collectionEntityMap]);

  const datafieldsRef = React.useRef(datafields);
  
  React.useEffect(() => {
    if (!lastTagChanged) { return; }
    const datafield = datafields.find(elem => elem.helpers.id === lastTagChanged);
    if (!datafield) { return; }
    setTimeout(() => datafield.helpers.ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' }), 500);
    setLastTagChanged(undefined);
  }, [lastTagChanged, datafields])
  
  React.useEffect(() => {
    datafieldsRef.current = [...datafields];
  }, [datafields]);
  
  const trans = useTranslator();

  const setDatafields = input => propSetDatafields(prevState => validateFieldsArray(input instanceof Function ? input([...prevState]) : input, metadataProfile, 'tag'));
  

  const getCurrentIndex = hash => datafieldsRef.current.findIndex(elem => elem.helpers.id === hash);
  const createPrototype = () => addHelperFieldsToElement({
    tag: '',
    indicator1: null,
    indicator2: null,
    subfields: []
  }, reduceToHashes(datafieldsRef.current))
  
  const getPossibleTags = tag => metadataProfile.reduce((accumulator, current) => {
    if (
      current.status !== false && (
        current.repeatable ||
        current.tag === tag ||
        !datafieldsRef.current.find(elem => elem.tag === current.tag)
      )
    ) {
      accumulator.push({ value: current.tag, label: `${current.tag} ${VALUE_LABEL_SEPARATOR} ${trans(current.label)}` });
    }
    return accumulator;
  }, []);

  const deleteFieldHandler = hash => () => {
    createPropertyArrayItemSetter(setDatafields, () => getCurrentIndex(hash))(undefined);
  };

  const addFieldHandler = hash => () => {
    createPropertyArrayItemSetter(setDatafields, () => getCurrentIndex(hash) + 1, false)(
      createPrototype(getNewHash(datafields))
    );
  }

  const cloneFieldHandler = hash => datafield => {
    createPropertyArrayItemSetter(setDatafields, () => getCurrentIndex(hash) + 1, false)(
      addHelperFieldsToElement({
        ...datafield,
        subfields: datafield.subfields.map( subfield => ({...subfield}) )
      }, reduceToHashes(datafieldsRef.current))
    );
  }

  const tagChangeHandler = hash => tag => setDatafields(prevDatafields => {
    const newDatafields = [...prevDatafields];
    newDatafields.sort((a, b) => {
      return a.tag - b.tag
    });
    setLastTagChanged(hash);
    return newDatafields;
  })  

  const changeDatafieldHandler = hash => input => {
    setDatafields(prevDatafields => {
      const newDatafields = [...prevDatafields];
      const index = getCurrentIndex(hash);
      const prevDatafield = {...prevDatafields[index]}
      const newDatafield = input instanceof Function ? input(prevDatafield) : input;
      newDatafields.splice(index, 1, newDatafield);
      
      const newDatafieldMetadataProfile = metadataProfile.find(elem => newDatafield.tag === elem.tag);
      if (!newDatafieldMetadataProfile) { throw new Error(`Metadataprofile not found for tag: ${newDatafield.tag}`) }

      newDatafield.subfields.forEach(newSubfield => {
        const newSubfieldMetadataProfile = newDatafieldMetadataProfile.subfields.find(elem => elem.code === newSubfield.code);
        if (!newSubfieldMetadataProfile) { return; }
        if ('select' !== newSubfieldMetadataProfile.input || 
            !newSubfieldMetadataProfile.collection ||
            'string' !== typeof newSubfieldMetadataProfile.collection) {
          return;
        }
        if (prevDatafield.subfields.find(prevSubfield => prevSubfield.code === newSubfield.code)) {
          return;
        }
        fetchCollection(newSubfieldMetadataProfile.collection, newDatafield.tag, newSubfield.code);
      })

      prevDatafield.subfields.forEach(prevSubfield => {
        if ('select' !== prevSubfield.input || !prevSubfield.collection) {
          return;
        }
        if (newDatafield.subfields.find(newSubfield => newSubfield.code === prevSubfield.code)) {
          return;
        }
        console.log('TODO remove unused collection', prevSubfield);
      })
      
      return newDatafields;
    })
    // createPropertyArrayItemSetter(setDatafields, () => getCurrentIndex(hash))(input);
  }

  const getEntities = subfieldCollections => {
    console.log(subfieldCollections);
    return [];
    // const newCollections = calculateRequiredCollections(datafieldsRef.current, metadataProfile, subfieldCollections);
    // if (!isEqual(collections, newCollections)) {
    //   setCollections([...newCollections]);
    // }
    // return subfieldCollections.reduce((result, collection) => {
    //   if (!collectionEntityMapRef.current[collection]) {return result;}
    //   for (const entityId of collectionEntityMapRef.current[collection]) {
    //     if (!result.find(elem => elem.value === entityId)) {
    //       if (!entitiesRef.current[entityId]) {
    //         result.push({label: 'missing_entity', value: entityId})
    //         continue;
    //       }

    //       const datafield =
    //         entitiesRef.current[entityId].record.datafields.find(elem => elem.tag === '100') ||
    //         entitiesRef.current[entityId].record.datafields.find(elem => elem.tag === '110') ||
    //         entitiesRef.current[entityId].record.datafields.find(elem => elem.tag === '150') ||
    //         entitiesRef.current[entityId].record.datafields.find(elem => elem.tag === '151') ||
    //         entitiesRef.current[entityId].record.datafields.find(elem => elem.tag === '245'); // TODO remove this line

    //       if (!datafield) {
    //         result.push({label: 'missing_label', value: entityId})
    //         continue;
    //       }

    //       const subfield = datafield.subfields.find(elem => elem.code === 'a');
    //       if (!subfield) {
    //         result.push({label: 'missing_label', value: entityId})
    //         continue;
    //       }

    //       result.push({
    //         label: `${subfield.value}`,
    //         value: entityId,
    //       });
    //     }
    //   }
    //   return result;
    // }, [])
  }

  return (
    <React.Fragment>
      <div className={editorStyles.Card}>
        <div className={editorStyles.CardHeader}>
          <span className={editorStyles.myFontStyle}>{trans('datafields')}</span>
        </div>
              
        <div className={editorStyles.CardContent}>
          <EditorButtons.AddFieldButton onClick={addFieldHandler()} />
          {!datafields
            ? null
            : <FlipMove
              // easing={'linear'}
              duration={350}
              delay={100}
              staggerDurationBy={0}
              staggerDelayBy={0}
              // enterAnimation={"fade"}
              // leaveAnimation={"fade"}
              maintainContainerHeight={true}
              // verticalAlignment={"bottom"}
            >
              { datafields.map((datafield, index) => {
                const datafieldMetadataProfileIndex = metadataProfile.findIndex(elem => elem.tag === datafield.tag);
                const datafieldMetadataProfile = -1 < datafieldMetadataProfileIndex ? metadataProfile[datafieldMetadataProfileIndex] : null;
                return (
                  <div key={datafield.helpers.id}>
                    <Datafield
                      datafield={datafield}
                      setDatafield={changeDatafieldHandler(datafield.helpers.id)}
                      metadataProfile={datafieldMetadataProfile}
                      setMetadataProfile={datafieldMetadataProfile ? createPropertyArrayItemSetter(setMetadataProfile, datafieldMetadataProfileIndex) : null}
                      getPossibleTags={getPossibleTags}
                      onAdd={addFieldHandler(datafield.helpers.id, true)}
                      onDelete={deleteFieldHandler(datafield.helpers.id)}
                      onClone={cloneFieldHandler(datafield.helpers.id)}
                      onTagChange={tagChangeHandler(datafield.helpers.id)}
                      getEntities={getEntities}
                      getMetadataProfileForTag={tag => metadataProfile.find(elem => elem.tag === tag)}
                      isValidated={isValidated}
                      isTemplate={isTemplate}
                      showNewErrors={showNewErrors}
                    />
                  </div>
                )
              }) }
            </FlipMove>
          }
        </div>
      </div>
    </React.Fragment>
  );
};

// export default Datafields;
export default React.memo(Datafields, (prev, next) => (
    prev.isValidated === next.isValidated &&
    isEqual(removeHelperFields(prev.datafields), removeHelperFields(next.datafields)) &&
    isEqual(prev.metadataProfile, next.metadataProfile) &&
    prev.showNewErrors === next.showNewErrors
));