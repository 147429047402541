import React from 'react';

import styles from './BaseContainer.module.css';
import { Container } from '@material-ui/core';

const BaseContainer = props => (
  <Container maxWidth={'xl'} className={styles.BaseContainer} >
        {props.children}
  </Container>
);

export default BaseContainer;