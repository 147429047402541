import React from 'react';

const Context = React.createContext({
  showSnackbar: () => { },
  setShowSnackbar: () => { },
  message: () => { },
  setMessage: () => { },
})

export const SnackbarProvider = props => {
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const configContext = {
    showSnackbar: showSnackbar,
    setShowSnackbar: setShowSnackbar,
    message: message,
    setMessage: setMessage,
  }

  return (
    <Context.Provider value={configContext}>
      {props.children}
    </Context.Provider>
  );
};

export const { Consumer } = Context;

export default Context;