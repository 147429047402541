import React from 'react'
import { Link } from 'react-router-dom'

import { backIcon } from '../../../shared/parameters/icons'
import { Tooltip } from '@material-ui/core'
import { useTranslator } from '../../../shared/translate'

const BackLink = (props) => {
    const { to, title } = props;
    const trans = useTranslator()
    return (
        <React.Fragment>
            {typeof title === 'string'
                ? <Tooltip title={trans(title)}>
                    <Link to={to}>{backIcon}</Link>
                </Tooltip>
                : <Link to={to}>{backIcon}</Link>
            }
        </React.Fragment>

    )
}

export default BackLink
