import React from 'react'
import { withRouter } from 'react-router';

import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button
} from '@material-ui/core';
import { getItemFromRecord } from '../../../../../shared/recordUtil';
import { useTranslator } from '../../../../../shared/translate';
import Spinner from '../../../../../components/UI/Spinner/Spinner';
import HoldingListOperationButton from '../HoldingListOperationButton/HoldingListOperationButton';
import { useAuth } from '../../../../../shared/auth';
import { permissions } from '../../../../../shared/permissions';

const HoldingList = (props) => {
    const {
        holdings,
        isLoading,
        openViewPopupHandler,
        setShowDeletePopupHandler
    } = props
    const t = useTranslator()
    const auth = useAuth()
    if (isLoading === true) {
        return <Spinner />
    }
    return (
        <React.Fragment>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('holding-852-b')}</TableCell>
                        <TableCell>{t('holding-852-c')}</TableCell>
                        <TableCell>{t('holding-852-h')}</TableCell>
                        <TableCell>{t('holding-item-count')}</TableCell>
                        <TableCell>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {holdings.map((holding, key) => (
                        <TableRow hover key={key}>
                            <TableCell>{getItemFromRecord(holding.record, '852', 'b')}</TableCell>
                            <TableCell>{getItemFromRecord(holding.record, '852', 'c')}</TableCell>
                            <TableCell>{getItemFromRecord(holding.record, '852', 'h')}</TableCell>
                            <TableCell>{holding.itemCount}</TableCell>
                            <TableCell>
                                <div style={{ textAlign: 'right' }}>
                                    <HoldingListOperationButton
                                        openViewPopupHandler={openViewPopupHandler}
                                        holding={holding}
                                        setShowDeletePopupHandler={setShowDeletePopupHandler}
                                    />
                                </div>
                                {auth.isGranted(permissions.INVENTORY_ITEM_READ)
                                    ? <div style={{ textAlign: 'right', marginTop: '12px' }}>
                                        <Button
                                            size="small"
                                            style={{ textTransform: 'none', color: '#2b75bb', borderColor: '#2b75bb' }}
                                            variant={'outlined'}
                                            color={'primary'}
                                            onClick={() => { props.history.push('/catalog/holdings/' + holding.id) }}
                                        >{t('item.items')}</Button>
                                    </div>
                                    : null}

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    )
}

export default withRouter(HoldingList)