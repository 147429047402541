import axios from '../../settings/axios-backend';
import urls from '../../settings/urls';

export const createHoldingRecord = data => {
    return axios.post(urls.MARC21_HOLDING, data)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

export const getHoldingRecord = id => {
    const url = urls.MARC21_HOLDING_ID.replace('{id}', id);
    return axios.get(url)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

export const updateHoldingRecord = (id, holding) => {
    const url = urls.MARC21_HOLDING_ID.replace('{id}', id);
    return axios.patch(url, holding)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

export const deleteHoldingRecord = id => {
    const url = urls.MARC21_HOLDING_ID.replace('{id}', id);
    return axios.delete(url)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

export const getHoldingsByBibliographicId = (bibliographicId, data) => {
    const url = urls.HOLDINGS_BY_BIBLOGRAPHIC_ID.replace('{id}', bibliographicId);
    return axios.post(url, data)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

export const getHoldings = () => {
    return axios.get(urls.MARC21_HOLDING)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};