import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Translate as T, useTranslator } from '../../../../../shared/translate';
import classes from './AddCollectionForm.module.scss';


const AddCollectionForm = (props) => {
    const { saveHandler } = props;
    const [name, setName] = useState('')
    const [nameTouched, setNameTouched] = useState(false)

    const t = useTranslator()
    const nameChangeHandler = (inputName) => {
        setName(inputName)
        nameTouchedHandler()
    }
    const nameIsValid = () => {
        return name !== ''
    }

    const nameTouchedHandler = () => {
        setNameTouched(true)
    }

    const buttonClickHandler = () => {
        nameTouchedHandler()
        if(nameIsValid()){
            saveHandler(
                {
                    name: name
                }
            )
        }
    }

    return (
        <React.Fragment>
            <TextField
                value={name}
                onChange={(event) => nameChangeHandler(event.target.value)}
                error={nameTouched && !nameIsValid()}
                helperText={nameTouched && !nameIsValid() ? t('please-fill-this-field') : false}
                label={T('collection-name')}
                margin="normal"
                variant="outlined"
                inputProps={{
                    className: classes.inputStyle,
                }}
            />
            <div>
                <Button
                    className={classes.mainBlueButtonWithAlignRight}
                    onClick={() => buttonClickHandler()}
                >
                    <T>create</T>
                </Button>
            </div>

        </React.Fragment>
    )
}

export default AddCollectionForm
