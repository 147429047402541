import React from 'react'

import Leader from './Leader/Leader';
import Controlfields from './Controlfields/Controlfields';
import Datafields from './Datafields/Datafields';
import { createPropertySetter } from '../../shared/util';
import { useTranslator } from '../../shared/translate';
import { validateRecord } from '../../shared/recordUtil';

const Editor = (props) => {
  const { record, setRecord, metadataProfile, setMetadataProfile, isTemplate, showNewErrors } = props;
  const [ materialType, setMaterialType ] = React.useState();
  const trans = useTranslator();
  const [isValidated, setIsValidated] = React.useState(false);
  // console.log(isValidated, {...record.datafields[2].subfields[0].helpers.errors});

  React.useEffect(() => {
    if (isValidated) { return; }
    if (record && metadataProfile) {
      setRecord(validateRecord(record, metadataProfile, isTemplate));
      setIsValidated(true);
    }
  }, [isValidated, record, metadataProfile, setRecord, isTemplate]);

  if (!record) { return trans('missing_record'); }
  if (!metadataProfile) { return trans('missing_metadata_profile'); }

  return (
    <div style={props.style}>
      {/* <div style={{margin: '10px 0'}}>materialType: {JSON.stringify(materialType)}</div> */}
      <Leader
        metadataProfile={metadataProfile.leader}
        leader={ record.leader }
        setLeader={createPropertySetter(setRecord, 'leader')}
        materialType={materialType}
        setMaterialType={setMaterialType}
        isValidated={isValidated}
      />
      <Controlfields 
        metadataProfile={metadataProfile.controlfields}
        controlfields={record.controlfields}
        setControlfields={createPropertySetter(setRecord, 'controlfields')} 
        materialType={materialType}
        isValidated={isValidated}
      />
      <Datafields 
        metadataProfile={metadataProfile.datafields}
        setMetadataProfile={createPropertySetter(setMetadataProfile, 'datafields')}
        datafields={record.datafields}
        setDatafields={createPropertySetter(setRecord, 'datafields')}
        isValidated={isValidated}
        isTemplate={isTemplate}
        showNewErrors={showNewErrors}
      />
    </div>
  )
}
export default  Editor
// export default React.memo(Editor, (prev, next) => {
//   return (
//     isEqual(removeHelperFields(prev.record), removeHelperFields(next.record)) &&
//     isEqual(prev.metadataProfile, next.metadataProfile)
//   );
// });
