import { authorityRecordType } from "./parameters/authorityRecordTypes";
import { SYMBOL_DAGGER, SYMBOL_EN_DASH } from "./symbols";

export const MATERIAL_TYPE_ALL_MATERIALS = 'all_materials';
export const MATERIAL_TYPE_MUSIC = 'music';
export const MATERIAL_TYPE_BOOKS = 'books';
export const MATERIAL_TYPE_CONTINUING_RESOURCES = 'continuing_resources';
export const MATERIAL_TYPE_COMPUTER_FILES = 'computer_files';
export const MATERIAL_TYPE_VISUAL_MATERIALS = 'visual_materials';
export const MATERIAL_TYPE_MAPS = 'maps';
export const MATERIAL_TYPE_MIXED_MATERIALS = 'mixed_materials';

export const MATERIAL_TYPE_CODES_MUSIC = '';
export const MATERIAL_TYPE_CODES_BOOKS = '';
export const MATERIAL_TYPE_CODES_CONTINUING_RESOURCES = '';
export const MATERIAL_TYPE_CODES_COMPUTER_FILES = '';
export const MATERIAL_TYPE_CODES_VISUAL_MATERIALS = '';
export const MATERIAL_TYPE_CODES_MAPS = '';
export const MATERIAL_TYPE_CODES_MIXED_MATERIALS = '';

export const VALUE_LABEL_SEPARATOR = String.fromCharCode(8211); // &ndash;

export const parsePosition = position => {
  let [start, end] = position.split('-');
  start = Number.parseInt(start);
  if (!Number.isInteger(start)) {
    throw new Error('Invalid position: ' + position);
  }
  end = Number.parseInt(end);
  return {
    string: position,
    start: start,
    end: end,
    length: end - start + 1 || 1
  };
}

export const mutateAndSortPositionsArray = array => {
  const result = array.map(position => ({ ...position, position: parsePosition(position.position) }));
  result.sort((a, b) => a.position.start - b.position.start);
  return result;
}

export const materialTypes = [
  {
    label: MATERIAL_TYPE_MUSIC,
    typeOfRecord: ["c", "d", "i", "j"],
    bibliographicLevel: undefined
  },
  {
    label: MATERIAL_TYPE_BOOKS,
    typeOfRecord: ["a", "t"],
    bibliographicLevel: ["a", "c", "d", "m"]
  },
  {
    label: MATERIAL_TYPE_CONTINUING_RESOURCES,
    typeOfRecord: ["a"],
    bibliographicLevel: ["b", "i", "s"]
  },
  {
    label: MATERIAL_TYPE_COMPUTER_FILES,
    typeOfRecord: ["m"],
    bibliographicLevel: undefined
  },
  {
    label: MATERIAL_TYPE_VISUAL_MATERIALS,
    typeOfRecord: ["g", "k", "o", "r"],
    bibliographicLevel: undefined
  },
  {
    label: MATERIAL_TYPE_MAPS,
    typeOfRecord: ["e", "f"],
    bibliographicLevel: undefined
  },
  {
    label: MATERIAL_TYPE_MIXED_MATERIALS,
    typeOfRecord: ["p"],
    bibliographicLevel: undefined
  },
];

export const getMaterialType = leader => {
  if (!leader) { return null; }
  const typeOfRecord = leader.charAt(6);
  const bibliographicLevel = leader.charAt(7);

  if (!typeOfRecord || !bibliographicLevel) {
    return null;
  }

  const materialType = materialTypes.find(elem => (
    elem.typeOfRecord.includes(typeOfRecord) &&
    (!elem.bibliographicLevel || elem.bibliographicLevel.includes(bibliographicLevel))
  ));

  return materialType ? materialType.label : null;
}

export const getTitleFromAuthorityType = (record, type) => {
  switch (type) {
    case authorityRecordType.PERSON:
      return getItemFromRecord(record, '100', 'a');
    case authorityRecordType.CORPORATE:
      return getItemFromRecord(record, '110', 'a');
    case authorityRecordType.GEO:
      return getItemFromRecord(record, '151', 'a');
    case authorityRecordType.SUBJECT:
      return getItemFromRecord(record, '150', 'a');
    default:
      return '';
  }
}


export const getIdFromRecord = record => getItemFromRecord(record, '001');
export const getTitleFromRecord = record => getItemFromRecord(record, '245', 'a');

export const getItemFromRecord = (record, tag, subfieldCode) => {
  if (!record) { return null; }

  const tagNumber = Number.parseInt(tag);
  if (Number.isNaN(tagNumber)) { return null; }

  const isControlfield = tagNumber < 10;
  if (!record[isControlfield ? 'controlfields' : 'datafields']) { return null; }

  const field = record[isControlfield ? 'controlfields' : 'datafields'].find(elem => elem.tag === tag);
  if (!field) { return null; }

  if (isControlfield) { return field.value; }

  const subfield = field.subfields.find(elem => elem.code === subfieldCode);
  if (!subfield) { return null; }

  return subfield.value;
}

export const getPositionsByMaterialType = (metadataProfile, materialType) => mutateAndSortPositionsArray(
  [...metadataProfile.types[MATERIAL_TYPE_ALL_MATERIALS]]
    .concat([...metadataProfile.types[materialType]])
)

export const getDefaultValue = (metadataProfile, materialType = MATERIAL_TYPE_BOOKS) => {
  if (!['000', '006', '007', '008'].includes(metadataProfile.tag)) {
    throw new Error(`Invalid tag: ${metadataProfile.tag}`);
  }

  const positions = ['006', '008'].includes(metadataProfile.tag)
    ? getPositionsByMaterialType(metadataProfile, materialType)
    : mutateAndSortPositionsArray('000' === metadataProfile.tag ? metadataProfile.positions : metadataProfile.types[0]);

  const reducer = (result, elem) => {
    // undefined positions are absent from the metadataProfile :/
    result += ' '.repeat(elem.position.start - result.length);
    if (elem.default) {
      if ('string' === typeof elem.default) {
        result += elem.default.repeat(elem.position.length).substring(0, elem.position.length);
      } else {
        switch (elem.default.type) {
          case 'date':
            if ('yymmdd' !== elem.default.format) {
              throw new Error(`Unexpected format: ${elem.default.format}`);
            }
            const now = new Date();
            const yy = now.getUTCFullYear().toString().substring(2);
            const mm = (now.getUTCMonth() + 1).toString().padStart(2, '0');
            const dd = now.getUTCDate().toString().padStart(2, '0');
            result += yy + mm + dd;
            break;
          default:
            throw new Error(`Unknown type: ${positions}`)
        }
      }
    } else if (elem.codes) {
      if (elem.codes.find(elem => '|' === elem.code)) {
        return result += '|'.repeat(elem.position.length);
      } else if (elem.codes.find(elem => ' ' === elem.code)) {
        return result += ' '.repeat(elem.position.length);
      } else {
        if (!elem.codes[0].code) {
          throw new Error(`Missing code for elem ${elem.position.string}`);
        }
        return result += elem.codes[0].code.repeat(elem.position.length).substring(0, elem.position.length);
      }
    } else {
      throw new Error(`No "default" or "codes" in elem: ${JSON.stringify(elem)}`);
    }
    return result;
  }

  return positions.reduce(reducer, '') + ('000' === metadataProfile.tag ? '0' : '');
};

export const getIdAndTitle = record => {
  return {
    id: getIdFromRecord(record),
    title: getTitleFromRecord(record),
  }
}


export const MetadataProfileEntityLinks = (metadataProfile) => {
  const entityLinks = {};
  for (const datafield of metadataProfile.datafields) {
    for (const subfield of datafield.subfields) {
      if (subfield.input && 'record' === subfield.input) {
        if (!entityLinks[datafield.tag]) { entityLinks[datafield.tag] = []; }
        entityLinks[datafield.tag].push({code: subfield.code, strict: subfield.inputStrict || false});
      }
    }
  }
  return entityLinks
}

export const RecordEntityLinks = (record, entityLinks) => {
  return record.datafields.reduce((accumulator, datafield) => {
    if (!entityLinks[datafield.tag]) { return accumulator; }
    for (const subfield of datafield.subfields) {
      const entityLink = entityLinks[datafield.tag].find(elem => elem.code === subfield.code)
      if (entityLink) {
        accumulator.push({
          tag: datafield.tag,
          code: subfield.code,
          value: subfield.value,
          strict: entityLink.strict,
        });
      }
    }
    return accumulator;
  }, []);
}

export const validateRecord = (record, metadataProfile, isTemplate) => {
  const newRecord = {...record};

  // TODO leader
  newRecord.controlfields = validateControlfields(newRecord.controlfields, metadataProfile.controlfields);
  newRecord.datafields = validateFieldsArray(newRecord.datafields, metadataProfile.datafields, 'tag')
    .map(datafield => {
      const datafieldMetadataProfile = metadataProfile.datafields.find(elem => elem.tag === datafield.tag);
      if (!datafieldMetadataProfile) {
        return datafield; // TODO add error? maybe only if has subfields or indicators?
      }
      const newDatafield = validateDatafield(datafield, tag => datafieldMetadataProfile, isTemplate);
      newDatafield.subfields = validateFieldsArray(newDatafield.subfields, datafieldMetadataProfile.subfields, 'code')
        .map(subfield => {
          const subfieldMetadataProfile = datafieldMetadataProfile.subfields.find(elem => elem.code === subfield.code);
          if (!subfieldMetadataProfile) {
            return subfield; // TODO add error?
          }
          return validateSubfield(subfield, code => subfieldMetadataProfile, isTemplate)
        });
      return newDatafield;
    });
  return newRecord;
}

export const validateControlfields = (controlfields, metadataProfiles) => {
  console.log('validateControlfields TODO');
  return controlfields;
}

export const validateFieldsArray = (fields, metadataProfiles, property) => {
  // console.log([...datafields].map(elem => ({...elem, helpers: {...elem.helpers}})));
  const newFields = fields.map(elem => ({...elem, helpers: {...elem.helpers}}));
  const properties = [];
  const repeated = [];
  newFields.forEach(elem => {
    if (elem.helpers.errors) {
      elem.helpers.errors = elem.helpers.errors.filter(error => error !== 'not-repeatable');
      if (!elem.helpers.errors.length) {
        elem.helpers.errors = null;
      }
    }
    
    if (!properties.includes(elem[property])) {
      properties.push(elem[property]);
      return;
    }

    if (!repeated.includes(elem[property])) {
      repeated.push(elem[property]);
    }
  });

  repeated.forEach(repeatedProperty => {
    const metadataProfile = metadataProfiles.find(elem => elem[property] === repeatedProperty);
    if (!metadataProfile) {
      // addError(newDatafields, 'tag', tag, 'missing_metadata_profile');
      return;
    }
    if (!metadataProfile.repeatable) {
      addErrorToMultipleElements(newFields, property, repeatedProperty, 'not-repeatable');
    }
  });

  return newFields;
}

export const validateDatafield = (datafield, getMetadataProfile, isTemplate) => {
  const newDatafield = {...datafield};
  if (newDatafield.helpers.errors) {
    newDatafield.helpers.errors = newDatafield.helpers.errors.filter(error => ![
      'missing_metadata_profile',
      'invalid_indicator1',
      'invalid_indicator2',
      'missing_subfields',
    ].includes(error));
    if (!newDatafield.helpers.errors.length) {
      newDatafield.helpers.errors = null;
    }
  }

  const metadataProfile = getMetadataProfile(newDatafield.tag);
  if (!metadataProfile) {
    addError(newDatafield, 'missing_metadata_profile');
    return;
  }

  for (let i=1; i<3; i++) {
    if (metadataProfile[`indicator${i}`]) {
      if (isTemplate && !newDatafield[`indicator${i}`]) {
        continue;
      }
      if (!metadataProfile[`indicator${i}`].codes.find(elem => false !== elem.status && elem.code === newDatafield[`indicator${i}`])) {
        addError(newDatafield, `invalid_indicator${i}`);
      }
    } else {
      if (newDatafield[`indicator${i}`] && ' ' !== newDatafield[`indicator${i}`]) {
        addError(newDatafield, `invalid_indicator${i}`);
      }
    }
  }

  if (!newDatafield.subfields.length) {
    addError(newDatafield, 'missing_subfields');
  }

  return newDatafield;
}

export const validateSubfield = (subfield, getMetadataProfile, isTemplate) => {
  const newSubfield = {...subfield};
  if (newSubfield.helpers.errors) {
    newSubfield.helpers.errors = newSubfield.helpers.errors.filter(error => ![
      'missing_metadata_profile',
      'invalid_value',
      // 'required',
    ].includes(error));
    if (!newSubfield.helpers.errors.length) {
      newSubfield.helpers.errors = null;
    }
  }

  const metadataProfile = getMetadataProfile(newSubfield.code);
  if (!metadataProfile) {
    addError(newSubfield, 'missing_metadata_profile');
    return newSubfield;
  }

  // if (metadataProfile.required && !newSubfield.value) {
  //   addError(newSubfield, 'required');
  // }

  if (isTemplate && !newSubfield.value) {
    return newSubfield;
  }

  switch (metadataProfile.input) {
    case "regex":
      const regex = new RegExp(`^${metadataProfile.pattern}$`);
      if (!regex.test(newSubfield.value)) {
        addError(newSubfield, 'invalid_value');
      }
      break;
    case "number":
      if (Number.isNaN(Number.parseInt(newSubfield.value))) {
        addError(newSubfield, 'invalid_value');
      }
      break;
    case "date":
    case "datetime":
      const dateObject = new Date(newSubfield.value);
      if (Number.isNaN(dateObject.getTime())) {
        addError(newSubfield, 'invalid_value');
      }
      break;
    default:
  }

  return newSubfield;
}

export const hasErrors = input => {
  if (!input || 'object' !== typeof input) {
    return false;
  }

  if (Array.isArray(input)) {
    for (const value of input) {
    if (hasErrors(value)) {
      return true;
    }
    }
    return false;
  }

  if (input.helpers && input.helpers.errors) {
    return true;
  }
  
  for (const key in input) {
    if (key !== 'helpers' && hasErrors(input[key])) {
      return true;
    }
  }
  
  return false;
}

const addError = (field, error) => {
  if (!field.helpers.errors) { field.helpers.errors = []; }
  if (!field.helpers.errors.includes(error)) {
    field.helpers.errors.push(error);
  }
}

const addErrorToMultipleElements = (array, property, value, error) => {
  array.filter(elem => elem[property] === value).forEach(elem => {
    addError(elem, error);
  });
}

const now = new Date();

export const parseOnnRecord = (type, record) => {
  switch (type) {
    case 'person':
      return parseOnnPerson(record);
    case 'geo':
      return parseOnnGeo(record);
    case 'corporate':
      return parseOnnCorporate(record);
    default:
      throw new Error(`Unknown type: ${type}`);
  }
}

const parseOnnCorporate = record => {
  const result = {
    leader: '00000nz  a2200000n  4500',
    controlfields: [
    ],
    datafields: [
      {
        tag: "035",
        indicator1: "1",
        indicator2: " ",
        subfields: [
          {
            code: "a",
            value: record.id,
          },
        ],
      },
      {
        tag: '110',
        indicator1: '2', // TODO not sure
        indicator2: ' ',
        subfields: [
          {
            code: 'a',
            value: record.name[0].construction,
          },
        ],
      },
    ],
  }
  return result;
};

const parseOnnGeo = record => {
  const result = {
    leader: '00000nz  a2200000n  4500',
    controlfields: [
    ],
    datafields: [
      {
        tag: "035",
        indicator1: "1",
        indicator2: " ",
        subfields: [
          {
            code: "a",
            value: record.id,
          },
        ],
      },
      {
        tag: '151',
        indicator1: ' ',
        indicator2: ' ',
        subfields: [
          {
            code: 'a',
            value: record.name[0].construction,
          },
        ],
      },
    ],
  }
  return result;
}

const parseOnnPerson = record => {
  const result = {
    leader: '00000nz  a2200000n  4500',
    controlfields: [
      // {
      //   tag: "001",
      //   value: record.id,
      // },
    ],
    datafields: [
      {
        tag: "024",
        indicator1: "7",
        indicator2: " ",
        subfields: [
          {
            code: "1",
            value: `http://abcd.hu/szemelyi-nevter/?id=${record.id}&date=${now.getFullYear()}-${now.getMonth()+1}-${now.getDate()}`,
          },
          {
            code: "2",
            value: "Magyar Nemzeti Névtér",
          },
        ],
      },
      {
        tag: "035",
        indicator1: "1",
        indicator2: " ",
        subfields: [
          {
            code: "a",
            value: record.id,
          },
        ],
      },
      {
        tag: "040",
        indicator1: " ",
        indicator2: " ",
        subfields: [
          {
            code: "a",
            value: "Magyar Nemzeti Névtér",
          },
        ],
      },
    ],
  };

  const datafield100 = {
    tag: "100",
    indicator1: "1",
    indicator2: " ",
    subfields: [
      {
        code: "a",
        value: record.name[0].construction,
      },
    ],
  };


  const birthDate = record.attribute.find(elem => 'születési idő' === elem.concept.name);
  const deathDate = record.attribute.find(elem => 'halál időpontja' === elem.concept.name);
  if (birthDate || deathDate) {
    let birthAndDeath = '';
    const datafield = {
      tag: "046",
      indicator1: " ",
      indicator2: " ",
      subfields: [],
    };

    if (birthDate) {
      birthAndDeath += birthDate.eventFrom.time[0].date.substring(0, 4) + '-';
      datafield.subfields.push({
        code: "f",
        value: birthDate.eventFrom.time[0].date.replace(/-/g, '.') + '.',
      });
    }

    if (deathDate) {
      if (!birthAndDeath) {
        birthAndDeath += SYMBOL_DAGGER;
      }
      birthAndDeath +=  deathDate.eventFrom.time[0].date.substring(0, 4);
      datafield.subfields.push({
        code: "g",
        value: deathDate.eventFrom.time[0].date.replace(/-/g, '.')+ '.',
      });
    }
    datafield100.subfields.push({
      code: "d",
      value: birthAndDeath,
    });
    result.datafields.push(datafield);
  }
  
  result.datafields.push(datafield100);

  const birthPlace = record.attribute.find(elem => 'születési hely' === elem.concept.name);
  const deathPlace = record.attribute.find(elem => 'halál helye' === elem.concept.name);
  if (birthPlace || deathPlace) {
    const datafield = {
      tag: "370",
      indicator1: " ",
      indicator2: " ",
      subfields: [],
    };
    if (birthPlace) {
      datafield.subfields.push({
        code: "a",
        value: birthPlace.attributeValue.currentName.title,
      });
    }
    if (deathPlace) {
      datafield.subfields.push({
        code: "b",
        value: deathPlace.attributeValue.currentName.title,
      });
    }
    result.datafields.push(datafield);
  }

  for (const profession of record.profession) {
    result.datafields.push({
      tag: "374",
      indicator1: " ",
      indicator2: " ",
      subfields: [
        {
          code: "a",
          value: profession.name,
        },
      ],
    });
  }

  if (record.name.length > 1) {
    for (let i=1; i<record.name.length; i++) {
      result.datafields.push({
        tag: "400",
        indicator1: "1",
        indicator2: " ",
        subfields: [
          {
            code: "a",
            value: record.name[i].construction,
          },
          {
            code: "i",
            value: record.name[i].type.name,
          },
        ],
      });
    }
  }

  for (const source of record.digitalSource) {
    result.datafields.push({
      tag: "856",
        indicator1: " ",
        indicator2: " ",
        subfields: [
          {
            code: "u",
            value: source.url,
          },
        ],
    });
  }

  return result;
}

export const getAuthorityRecordTitle = entity => {
  if ('person' === entity.type) {
    return getAuthorityPersonTitle(entity.record);
  }
  throw new Error(`Unexpected type: ${entity.type}`);
}

export const getAuthorityPersonTitle = record => 
  record.datafields
    .filter(elem => '100' === elem.tag)
    .map(elem =>
      elem.subfields
        .filter(elem => 'a' === elem.code)
        .map(elem => elem.value)
        .join(' ')
    )
    .concat(
      record.datafields
        .filter(elem => '100' === elem.tag)
        .map(elem => 
          elem.subfields
            .filter(elem => 'd' === elem.code)
            .map(elem => `(${elem.value})`)
            .join(' ')
        )
    )
    .concat(
      record.datafields
        .filter(elem => '374' === elem.tag)
        .map(elem => elem.subfields
          .filter(elem => 'a' === elem.code)
          .map(elem => elem.value)
          .join('/')
        )
        .join('/')
    )
    .join(` ${SYMBOL_EN_DASH} `)


export const fillAuthorityIds = (record, idMap) => ({
  ...record,
  datafields: record.datafields.map(datafield => ({
    ...datafield,
    subfields: datafield.subfields.map(subfield => {
      const newSubfield = {...subfield};
      if (newSubfield.value.onnId && !newSubfield.value.id) {
        newSubfield.value.id = idMap[newSubfield.value.onnId];
      }
      return newSubfield;
    })
  }))
})