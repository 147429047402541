import React from 'react'
import { Route, Switch } from 'react-router-dom';

import BaseContainer from '../../hoc/BaseContainer/BaseContainer';
import AcquisitionDashboard from './Pages/AcquisitionDashboard';
import CurrencyListPage from './Pages/CurrencyListPage';

const Acquisition = () => {

  let routes = (
    <BaseContainer>
      <Switch>
        <Route path="/acquisition" exact={true} component={AcquisitionDashboard} />
        <Route path="/acquisition/currencyList" exact={true} component={CurrencyListPage} />
      </Switch>
    </BaseContainer>
  );
  return routes
}

export default Acquisition
