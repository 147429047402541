import React from 'react';
import { Button } from '@material-ui/core';

import AuthorityCorporate from '../../Entity/PropertyNamespace/Corporate/AuthorityCorporate';
import AuthorityGeo from '../../Entity/PropertyNamespace/Geo/AuthorityGeo';
import AuthorityPerson from '../../Entity/PropertyNamespace/Person/AuthorityPerson';
import AuthoritySubject from '../../Entity/PublicNamespace/Subject/AuthoritySubject';
import { useTranslator } from '../../../../../shared/translate';

const CollectionAddEntitySearch = props => {
  // if this is outside the function as a "constant", it causes circular dependency, so I made it a ref...
  const entityTypes = React.useRef([
    {name: 'person', title: 'namespace-person', component: AuthorityPerson},
    {name: 'geo', title: 'namespace-geo', component: AuthorityGeo},
    {name: 'corporate', title: 'namespace-corporate', component: AuthorityCorporate},
    {name: 'subject', title: 'subject-word', component: AuthoritySubject},
  ]);

  const {
    newlySelectedEntities,
    setNewlySelectedEntities,
    alreadySelectedEntities,
    onSave,
  } = props;

  const [ selectedType, setSelectedType ] = React.useState(entityTypes.current[0]);
  const trans = useTranslator();

  return (
    <React.Fragment>
      <div>
        <Button 
          variant={'contained'}
          color={'primary'}
          onClick={() => newlySelectedEntities.length ? onSave(newlySelectedEntities) : null}
        >
          {trans('save')}
        </Button>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        {entityTypes.current.map(elem => (
          <Button 
            key={elem.name}
            variant={'contained'}
            color={selectedType === elem ? 'primary' : 'default'}
            onClick={() => setSelectedType(elem)}
            fullWidth
          >
            {trans(elem.title)}
          </Button>
        ))}
      </div>
      {React.createElement(selectedType.component, {
        alreadySelectedEntities: alreadySelectedEntities.filter(elem => elem.type === selectedType.name), 
        newlySelectedEntities: newlySelectedEntities,
        setNewlySelectedEntities: setNewlySelectedEntities,
      })}
    </React.Fragment>
  );
};

export default CollectionAddEntitySearch;