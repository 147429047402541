import React from 'react';
import FlipMove from 'react-flip-move';

import { useTranslator } from '../../../../../shared/translate';
import { isEqual } from '../../../../../shared/util';
import { getUniqueHash, hashFunction } from '../../../../../shared/listUtil';
import {
  Select,
  MenuItem,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    inputStyle: {
        padding: 10,
        fontSize: 14,
        fontWeight: 500,
    },
});

const getStartingList = (value, length) => {
 
  const newList = [];
  for (let i=0;i<length;i++) {
    newList.push({
      id: getUniqueHash(newList, hashFunction),
      value: value.charAt(i),
    })
  }
  return newList;
}

const SelectList = props => {
  const classes = useStyles();
  const { options, value, setValue, length } = props;

  const [ list, setList ] = React.useState(getStartingList(value, length));

  const onChangeHandler = index => event => {
    const newList = list.map(elem => ({...elem, value: '|' === elem.value ? ' ' : elem.value}));
    newList[index] = {...newList[index], value: event.target.value};
    newList.sort((a, b) => {
      const compareA = (' ' === a.value ? 999 : a.value.charCodeAt(0));
      const compareB = (' ' === b.value ? 999 : b.value.charCodeAt(0));
      return compareA - compareB; 
    })
    setList(newList);
    setValue(newList.reduce((accumulator, current) => accumulator += current.value, ''));
  }

  const trans = useTranslator();

  return (
    <FlipMove>
      {list.map((elem, index) => (
        <Select 
          key={elem.id}
          fullWidth
          variant="outlined"
          inputProps={{
              className: classes.inputStyle
          }}
          value={elem.value}
          onChange={onChangeHandler(index)}
        >
          {options.reduce((accumulator, option) => {
            if (option.code === elem.value || 
                ' ' === option.code || 
                ('|' !== option.code && !value.includes(option.code))) {
              accumulator.push((
                <MenuItem key={accumulator.length} value={option.code}>
                  {option.code.replace(/ /g, '#')} &ndash; {trans(option.label)}
                </MenuItem>
              ));
            }
            return accumulator;
          }, [])}
        </Select>
      ))}
    </FlipMove>
  );

  // return options.map((code, index) => (
  //   <select className="form-control" defaultValue={value} onChange={onChange}>
  //     <option key={index} value={code.code}>
  //       {code.code.replace(/ /g, '#')} &ndash; {trans(code.label)}
  //     </option>
  //   </select>
  // ))
};

export default React.memo(SelectList, (prev, next) => (
  isEqual(prev.options, next.options) &&
  prev.value === next.value &&
  prev.length === next.length 
));