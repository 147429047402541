import axios from '../settings/axios-backend';
import urls from '../settings/urls';

export const recordConvertMarcXml = (record) => {
    console.log(record)
    return axios.post(urls.RECORD_CONVERT_MARCXML, record)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

export const getRecordConvertBibframe = (record) => {
    return axios.post(urls.RECORD_CONVERT_BIBFRAME, record)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};