import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTranslator } from '../../../shared/translate';
import { Button, Grid } from '@material-ui/core';
import PopupModal from '../../../components/UI/Popup/PopupModal';
import AddHoldingForm from './List/AddHoldingFrom/AddHoldingForm';
import { getRecord } from '../../../api/marc21/bibliographic/bibliographicRecords';
import { getItemFromRecord } from '../../../shared/recordUtil';
import { createHoldingRecord, deleteHoldingRecord, getHoldingsByBibliographicId } from '../../../api/marc21/holding/holdingRecords';
import ConfigContext from '../../../context/snackbar-context';
import useSmartRequest from '../../../hooks/useSmartRequest';
import ShowRecord from '../List/ShowRecord';
import BackLink from '../../../components/UI/Link/BackLink';
import { addHelperFields, removeHelperFields } from '../../../shared/listUtil';
import Spinner from '../../../components/UI/Spinner/Spinner';
import HoldingList from './List/HoldingList/HoldingList';
import Pagination from '../../../components/UI/Pagination/Pagination';
import { useAuth } from '../../../shared/auth';
import { permissions } from '../../../shared/permissions';

const Bibliographic = (props) => {
    const biblioghrapicId = props.match.params.biblioghrapicId
    const { setShowSnackbar } = React.useContext(ConfigContext);

    const auth = useAuth()
    const { sendRequest: sendDeleteRequest } = useSmartRequest(deleteHoldingRecord);
    const t = useTranslator();
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(3, 2),
        },
    }));

    //holding and bibliographic
    const [bibliograpic, setBibliograpic] = useState(null)

    //paging
    const itemPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1)
    const [holdingCount, setHoldingCount] = useState(0)
    const [isLoading, setIsLoading] = React.useState(false);
    const [holdings, setHoldings] = useState(null)

    const loadRecordWithHoldings = useCallback(() => {
        setIsLoading(true);

        getRecord(biblioghrapicId).then(bib => {
            setBibliograpic(bib)
            getHoldingsByBibliographicId(
                biblioghrapicId,
                { offset: (currentPage - 1) * itemPerPage, limit: itemPerPage }
            ).then(hold => {
                setHoldings(hold.holdings)
                setHoldingCount(hold.holdingCount);
                setIsLoading(false);
                //deleted, if we are over maxpage, then move to last page, first page is ok
                const maxPage = Math.ceil(hold.holdingCount / itemPerPage);
                if (currentPage > 1 && currentPage > maxPage) {
                    setCurrentPage(maxPage);
                }
            })
        })
    },
        [biblioghrapicId, currentPage]
    )

    useEffect(() => {
        loadRecordWithHoldings()
    }, [loadRecordWithHoldings])

    const classes = useStyles();

    const prototype = addHelperFields({
        record: {
            leader: '',
            controlfields: [
                {
                    "tag": "004",
                    "value":
                        (
                            bibliograpic === null
                                ? ""
                                : getItemFromRecord(bibliograpic.record, '001', '')
                        )
                },
                {
                    "tag": "008",
                    "value": ""
                }
            ],
            datafields: [
                {
                    tag: '852',
                    indicator1: ' ',
                    indicator2: ' ',
                    subfields: [
                        {
                            code: 'b',
                            value: ''
                        },
                        {
                            code: 'c',
                            value: ''
                        },
                        {
                            code: 'h',
                            value: ''
                        },
                        {
                            code: 'i',
                            value: ''
                        }
                    ]
                }
            ]
        }
    })


    const [showPopup, setShowPopup] = useState(false);
    const [popupDeleteShow, setPopupDeleteShow] = useState(false);
    const [popupDeleteSelected, setPopupDeleteSelected] = useState(null);

    const [viewPopupIsOpen, setViewPopupIsOpen] = useState(false);
    const [viewPopupSelectedHolding, setViewPopupSelectedHolding] = useState(null);

    //create
    const createHoldingHandler = (data) => {
        createHoldingRecord({ ...removeHelperFields(data), bibliographicId: biblioghrapicId }).then(res => {
            setShowSnackbar(true);
            cancelPopupHandler();
            loadRecordWithHoldings()
        })
    }

    const deleteHoldingHandler = (id) => {
        sendDeleteRequest(id).then(r => {
            loadRecordWithHoldings()
        });
    }

    const setShowDeletePopupHandler = (inputShow, id = null) => {
        if (id) {
            setPopupDeleteSelected(id)
            setPopupDeleteShow(inputShow);
        } else {
            setPopupDeleteShow(inputShow);
        }
    }

    // open / close popup
    const cancelPopupHandler = () => { setShowPopup(false) }
    const showPopupHandler = () => { setShowPopup(true) }


    const openViewPopupHandler = (holding) => {
        setViewPopupSelectedHolding(holding);
        setViewPopupIsOpen(true)
    }
    const cancelViewPopupHandler = () => { setViewPopupIsOpen(false) }

    const handleCurrentPageChange = (newCurrentPage) => {
        setCurrentPage(newCurrentPage)
    }

    if (
        bibliograpic === null ||
        holdings === null
    ) {
        return <Spinner />
    }

    return (
        <React.Fragment>
            <PopupModal
                show={viewPopupIsOpen}
                setShow={setViewPopupIsOpen}
                cancel={cancelViewPopupHandler}
                body={<ShowRecord record={viewPopupSelectedHolding} />}
                footer={<Button onClick={cancelViewPopupHandler}> {t('close')} </Button>}
            />

            {showPopup ? <PopupModal
                show={showPopup}
                setShow={setShowPopup}
                cancel={cancelPopupHandler}
                size={'large'}
                title={t('new-holding')}
                body={<AddHoldingForm
                    saveHandler={createHoldingHandler}
                    prototype={prototype}

                />}
            /> : null}
            {popupDeleteShow ? <PopupModal
                show={popupDeleteShow}
                setShow={setPopupDeleteShow}
                title={t('delete?')}
                footer={
                    <React.Fragment>
                        <Button onClick={() => {
                            deleteHoldingHandler(popupDeleteSelected)
                            setShowDeletePopupHandler(false)
                        }}>{t('delete')}</Button>
                        <Button onClick={() => {
                            setShowDeletePopupHandler(false)
                        }}>{t('cancel')}</Button>
                    </React.Fragment>
                }
            /> : null}


            <Paper className={classes.root}>

                <h1><BackLink to={'/catalog'} /> {t('holdings')}</h1>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell variant={'head'}>{t('record-id')}: <br /><b>
                                {getItemFromRecord(bibliograpic.record, '001')}
                            </b></TableCell>
                            <TableCell variant={'head'}>{t('author')}: <br /><b>
                                {getItemFromRecord(bibliograpic.record, '100', 'a')}
                                {getItemFromRecord(bibliograpic.record, '100', 'd')}
                            </b></TableCell>
                            <TableCell variant={'head'}>{t('title')}: <br /><b>
                                {getItemFromRecord(bibliograpic.record, '245', 'a')}
                                {getItemFromRecord(bibliograpic.record, '245', 'b')}
                            </b></TableCell>
                            <TableCell variant={'head'}>{t('edition')}: <br /><b>
                                {getItemFromRecord(bibliograpic.record, '260', 'a')}
                                {getItemFromRecord(bibliograpic.record, '260', 'b')}
                                {getItemFromRecord(bibliograpic.record, '260', 'c')}
                            </b></TableCell>
                        </TableRow>
                    </TableHead>
                </Table>

                <Grid container>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ textAlign: 'right' }}>
                            {auth.isGranted(permissions.INVENTORY_HOLDING_CREATE)
                                ? <Button
                                    onClick={showPopupHandler}
                                    style={{ marginTop: '10px', color: '#2b75bb', borderColor: '#2b75bb' }}

                                    variant={'outlined'}
                                    color={'primary'}
                                >{t('create-new-holding')}</Button>
                                : null}
                        </div>
                    </Grid>
                </Grid>

                <HoldingList
                    holdings={holdings}
                    setShowDeletePopupHandler={setShowDeletePopupHandler}
                    openViewPopupHandler={openViewPopupHandler}
                    isLoading={isLoading}
                />
                <Pagination
                    currentPage={currentPage}
                    handleCurrentPageChange={handleCurrentPageChange}
                    recordCount={holdingCount}
                    itemPerPage={itemPerPage}
                    disabled={isLoading}
                />
            </Paper >
        </React.Fragment>
    );
}

export default withRouter(Bibliographic)
