import React from 'react'

import { getRecord, updateRecord } from '../../../api/marc21/bibliographic/bibliographicRecords';
import fetchMetadataProfiles from '../../../api/marc21/bibliographic/bibliographicSchema'
import Editor from '../../../components/Editor/Editor';
import useSmartRequest from '../../../hooks/useSmartRequest';
import Popup from '../../../components/UI/Popup/Popup';
import { Translate as T } from '../../../shared/translate';
import { Button } from '@material-ui/core';
import { updateCollectionsFromEditor } from '../../../api/valueset/collection';
import { fillAuthorityIds } from '../../../shared/recordUtil';


const CatalogEditor = (props) => {
    const [record, setRecord] = React.useState(null);
    const [originalRecord, setOriginalRecord] = React.useState(null);
    const [metadataProfile, setMetadataProfile] = React.useState(null);
    const [showPopup, setShowPopup] = React.useState(false);
    const { sendRequest, isSending } = useSmartRequest(updateRecord);

    const { recordId } = props.match.params;

    React.useEffect(() => {
        fetchMetadataProfiles()
            .then(data => {
                setMetadataProfile(data);
            })
            ;
        return () => {
            setMetadataProfile(null);
        }
    }, [setMetadataProfile])

    React.useEffect(() => {
        getRecord(recordId)
            .then(response => {
                setRecord(response);
                setOriginalRecord(response);
            })
            .catch(error => {
                console.log(error);
            })
            ;
        return () => {
            setRecord(null);
            setOriginalRecord(null);
        }
    }, [recordId])

    const cancelPopupHandler = (event) => {
        setRecord({ ...originalRecord });
        setShowPopup(false);
    }

    const savePopupHandler = (event) => {
        
        updateCollectionsFromEditor(metadataProfile).then(response =>{
            const newRecord = {
                ...record,
                record: fillAuthorityIds(record.record, response),
            };
            setRecord(newRecord);
            sendRequest(record.id, newRecord).then((r) => {
                setOriginalRecord({ ...record });
                setShowPopup(false);
            })
        })
    }
    return [
        <div key={0} style={{ margin: '1rem', display: 'flex', justifyContent: 'flex-end', alignItems: 'space-between' }} >
            <Button
                onClick={() => sendRequest(record.id, record)}
                variant="success"
                disabled={isSending}
            >
                <T>save-modifications</T>
            </Button>
            <Button onClick={() => setShowPopup(true)} variant={"contained"} color={'danger'} style={{ marginLeft: '6px' }}><T>dismiss</T></Button>
            <Popup
                show={showPopup}
                cancel={cancelPopupHandler}
                save={savePopupHandler}
                disabled={isSending}
                title={<T>save-changes?</T>}
            />
        </div>,
        record && metadataProfile
            ? <Editor key={1}
                style={{ width: 'calc(100% - 1rem)' }}
                record={record}
                setRecord={setRecord}
                metadataProfile={metadataProfile}
                setMetadataProfile={setMetadataProfile}
            />
            : null
    ]
}

export default CatalogEditor;
