// export const deepCopy = original => {
//     if (original === null) {
//         return null;
//     }
//     let clone;
//     if (Array.isArray(original)) {
//         clone = [];
//     } else {
//         clone = Object.create(Object.getPrototypeOf(original));
//     }
//     const properties = Object.getOwnPropertyNames(original);
//     for (let key in properties) {
//         key = properties[key];
//         let value = original[key];
//         Object.defineProperty(clone, key, {
//             value: typeof value === 'object' ? deepCopy(value) : value,
//             enumerable: original.propertyIsEnumerable(key),
//             writable: true,
//         }
//         );
//     }
//     return clone;
// }

export const dumpObject = object => {
    let result = '';
    for (const key in object) {
        // TODO non-scalar values
        result += `${result ? ', ' : ''}${key}: ${'string' === typeof object[key] || object[key] instanceof String ? `"${object[key]}"` : object[key]}`
    }
    return `{${result}}`;
}

export const makeCancelable = promise => {
    let isCanceled = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            result => isCanceled ? null : resolve(result),
            error => isCanceled ? null : reject(error)
        ).catch(error => console.log(error));
    });

    return {
        promise: wrappedPromise,
        cancel: () => isCanceled = true,
    };
};

export const deepCopy = original => {
    if (!original) {
        return null;
    }
    const clone = Array.isArray(original) ? [] : {};
    for (let [key, value] of Object.entries(original)) {
        if (typeof value === 'object') {
            value = deepCopy(value);
        }
        clone[key] = value;
    }
    return clone;
}


export const updateObject = (oldObject, updatedValues) => ({
    ...deepCopy(oldObject),
    ...deepCopy(updatedValues),
});

export const camelToKebab = (string = '') => {
    return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
};


export const set = (object, newValue, path) => {
    const objCopy = Array.isArray(object) ? [...object] : { ...object };
    let ref = objCopy;

    let stack = path.split('.');
    while (stack.length > 1) {
        const key = stack.shift();

        if (typeof ref[key] !== 'object' || ref[key] === null) {
            ref[key] = {};
        }
        ref[key] = Array.isArray(ref[key]) ? [...ref[key]] : { ...ref[key] };
        ref = ref[key];

    }
    ref[stack.shift()] = newValue;
    return objCopy;
};


export const dateFormatString = (string) => {
    if (string === null) {
        return ''
    }
    return dateFormat(new Date(string));
};

export const dateFormat = (date) => {
    const year = date.getFullYear().toString().padStart(4, '0').substring(0, 4);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds
};


export const findItem = (haystack, property, needle) => {
    for (const item of Object.values(haystack)) {
        if (typeof item !== 'object' || item === null) {
            continue;
        }
        if (item[property] === needle) {
            return item;
        }
    }
    return null;
}

export const isEqual = (v1, v2) => {
    return (JSON.stringify(v1) === JSON.stringify(v2));
}

export const trueOrUndefined = (x) => {
    return x === true || typeof x == 'undefined';
}

export const redirect = (x) => {
    window.location.href = x;
}

export const objectIsEmpty = (obj) => {
    if (obj === null) {
        return true
    }
    return Object.entries(obj).length === 0 && obj.constructor === Object
}

// util.js
export const createPropertySetter = (setter, property) => input => setter(prevState => ({
    ...prevState,
    [property]: input instanceof Function
        ? input(prevState[property])
        : input
}));

export const replaceSubstring = (string, replace, start, length) => (
    string.substring(0, start) +
    (
        replace +
        string.substring(start + replace.length, start + replace.length + length)
    ).substring(0, length) +
    string.substring(start + length)
)

export const createPropertySubstringSetter = (setter, start, length) => input => setter(prevState => (
    replaceSubstring(
        prevState,
        (input instanceof Function ? input(prevState.substring(start, start + length)) : input),
        start,
        length
    )
));

export const createPropertyArrayItemSetter = (setter, index, replace = true) => input => setter(prevState => {
    if (index instanceof Function) {
        index = index();
    }
    const newState = [...prevState];
    if ('undefined' === typeof input) {
        newState.splice(index, replace ? 1 : 0);
    } else {
        newState.splice(index, replace ? 1 : 0, input instanceof Function ? input(prevState[index]) : input);
    }
    return newState;
});


