import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import styles from '../../Controlfields/Controlfield.module.scss';
import editorStyles from '../../Editor.module.css';
import ControlfieldTable from './Table/ControlfieldTable';
import { AddFieldButton } from '../../Buttons/Buttons';
import { createPropertySetter, isEqual, replaceSubstring } from '../../../../shared/util';
import { getDefaultValue } from '../../../../shared/recordUtil';
import { useTranslator } from '../../../../shared/translate';
import Del from '../../../../assets/icons/delete-blue.svg';
import ReactSelect from '../../../UI/Input/ReactSelect';
import { makeStyles } from '@material-ui/core/styles';
import { removeHelperFields } from '../../../../shared/listUtil';

const useStyles = makeStyles({
  inputStyle: {
    padding: 10,
    fontSize: 14,
    fontWeight: 500,
  },
});


const REQUIRES_TABLE = ["000", "007", "008"]; // TODO add 006

const Controlfield = props => {
  const classes = useStyles();

  const {
    controlfield,
    setControlfield,
    onTagChange,
    materialType,
    metadataProfile,
    onAdd,
    onDelete,
    getAvailableMetadataProfiles
  } = props;

  const trans = useTranslator();
  const [availableTags, setAvailableTags] = React.useState(getAvailableMetadataProfiles(controlfield.tag));
  const setValue = createPropertySetter(setControlfield, 'value');
  const [lastMaterialType, setLastMaterialType] = React.useState(materialType);

  // set default values for tables
  React.useEffect(() => {
    if (!REQUIRES_TABLE.includes(controlfield.tag) || !materialType) { return; }
    const defaultValue = getDefaultValue(metadataProfile, materialType)
    if (controlfield.value) {
      if (lastMaterialType && lastMaterialType === materialType) { return; }
      setLastMaterialType(materialType);
      switch (metadataProfile.tag) {
        case '006':
          // TODO
          break;
        case '007':
          // TODO
          break;
        case '008':
          setControlfield(prevControlfield => {
            return { ...prevControlfield, value: replaceSubstring(prevControlfield.value, defaultValue.substring(18), 18, 17) }
          })
          break;
        default:
          throw new Error(`Invalid metadataProfile tag: ${metadataProfile.tag}`);
      }
    } else {
      setControlfield(prevControlfield => {
        return { ...prevControlfield, value: defaultValue }
      })
    }
  }, [controlfield, metadataProfile, setControlfield, lastMaterialType, materialType]);

  const renderBody = () => {
    if (REQUIRES_TABLE.includes(controlfield.tag)) {
      if ('008' === controlfield.tag && !materialType) {
        return (
          <div>{trans('missing_material_type')}</div>
        );
      }

      return (
        <ControlfieldTable
          value={controlfield.value}
          metadataProfile={metadataProfile}
          type={'007' === controlfield.tag ? controlfield.value[0] : materialType}
          setValue={setValue}
        />
      );
    }

    return metadataProfile && metadataProfile.readOnly ? <span>{controlfield.value}</span> : (
      <TextField variant="outlined"
        type="text"
        fullWidth
        inputProps={{
          className: classes.inputStyle,
          readOnly: metadataProfile ? metadataProfile.readOnly : true,
        }}
        onChange={event => setValue(event.target.value)}
        value={controlfield.value}
      />
    );
  }

  return (
    <React.Fragment>
      {/* <hr />{JSON.stringify(data)} */}
      <div ref={controlfield.helpers.ref} className={editorStyles.subCard}>
        <div className={editorStyles.subCardHeader}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              {!controlfield.value
                ? <ReactSelect
                  value={metadataProfile ? { label: `${metadataProfile.tag} - ${trans(metadataProfile.label)}`, value: metadataProfile.tag } : null}
                  onChange={obj => onTagChange(obj.value)}
                  options={availableTags}
                  onMenuOpen={() => {
                    const newAvailableTags = getAvailableMetadataProfiles(controlfield.tag);
                    if (!isEqual(newAvailableTags, availableTags)) {
                      setAvailableTags(newAvailableTags);
                    }
                  }}
                />
                : metadataProfile 
                  ? <React.Fragment>
                      <span className={styles.subFont}>{metadataProfile.tag} &ndash; {trans(metadataProfile.label)} : </span>
                      <span>{controlfield.value}</span>
                    </React.Fragment>
                  : trans('missing_metadata_profile')
              }</Grid>
          </Grid>
        </div>


        <div className={editorStyles.subCardContent}>
          <Grid container spacing={2}>
            <Grid item sm={11}>
              {/* {JSON.stringify(controlfield)} */}
              {renderBody()}
            </Grid>
            <Grid item sm={1} className={editorStyles.alignSelf}>
              <img className={styles.myIconStyle} src={Del} alt="" onClick={onDelete} />
            </Grid>
          </Grid>
          {/*<div style={{ marginLeft: '1.25rem' }}>{controlfield.value.replace(/ /g, '#')}</div>*/}
        </div>
        {/*} <div className={editorStyles.subCardContainer}>
          {renderBody()}
            </div>*/}
      </div>

      <AddFieldButton onClick={onAdd} />
    </React.Fragment >
  );
};
// export default  Controlfield
export default React.memo(Controlfield, (prev, next) => {
  // console.log({...prev.controlfield})
  // console.log({...next.controlfield})
  // console.log(isEqual(prev.controlfield, next.controlfield));
  return (
    isEqual(removeHelperFields(prev.controlfield), removeHelperFields(next.controlfield)) &&
    isEqual(prev.metadataProfile, next.metadataProfile) &&
    prev.materialType === next.materialType
  );
});
