export default class Currency {
  /** @type {string} */
  id;

  /** @type {string} */
  code;

  /** @type {string} */
  name;

  /** @type {boolean} */
  isDefault;

  constructor() {
    this.id = '';
    this.code = '';
    this.name = '';
    this.isDefault = false;
  }

  static createFromData(data) {
    if (typeof data.id !== 'string'
        || typeof data.code !== 'string'
    ) {
      return null;
    }

    const ret = new Currency();

    ret.id = data.id;
    ret.code = data.code;

    if (typeof data.name === 'string') {
      ret.name = data.name;
    }

    if (typeof data.isDefault === 'boolean') {
      ret.isDefault = !!data.isDefault;
    }

    return ret;
  }
}
