import axios from './settings/axios-backend';
import urls from './settings/urls';

export const getDocumentForm = (id) => {
    const url = `${urls.DOCUMENT_FORMS}/id/${id}`
    return axios.get(url)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

export const getDocumentForms = () => {
    return axios.get(urls.DOCUMENT_FORMS)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

export const updateDocumentForm = (id, data) => {
    const url = `${urls.DOCUMENT_FORMS}/id/${id}`
    return axios.put(url, data)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
}