import React, { useState, useEffect } from 'react';
import {
    Grid,
  } from '@material-ui/core';
import { loanItem } from '../../../../api/reader_service/readerService';
import SearchSimple from '../../Reader/List/SearchSimple';
import TableGrid from '../TableGrid';
import { getLoans } from '../../../../api/reader_service/readerService';
import { dateFormatString } from '../../../../shared/util';
import ConfigContext from '../../../../context/snackbar-context';
import { useAuth } from '../../../../shared/auth';
import { permissions } from '../../../../shared/permissions';

const TABLE_COLUMNS = [
    { label: "title", map: "title" },
    { label: "barcode", map: "barcode" },
    { label: "loan_date", map: "loanDate", format: dateFormatString },
    { label: "expiry_date", map: "expiryDate", format: dateFormatString },
];

const LoanTab = (props) => {

    const { readerId } = props;

    const auth = useAuth();
    const [loanBarcode, setLoanBarcode] = useState('');
    const { setShowSnackbar } = React.useContext(ConfigContext);

    const handleLoan = () => {
        loanItem({
            barcode: loanBarcode,
            readerId: readerId
        }).then(res => {
            setShowSnackbar(true);
            setLoanBarcode('');
            loadRecords();
        });
    };

    const [loans, setLoans] = useState([]);

    const loadRecords = () => {
        getLoans(readerId).then(res => {
            setLoans(res);
        });
    }

    useEffect(() => {
        loadRecords();
    }, []);

    return (
        <React.Fragment>
            {auth.isGranted(permissions.READER_SERVICE_LOAN_CREATE) ?
                <Grid container style={{ marginTop: "20px" }}>
                    <Grid item md={12} sm={12} style={{ margin: "0 auto" }}>
                        <SearchSimple
                            label="barcode"
                            buttonLabel="loan"
                            filterKeyword={loanBarcode}
                            setFilterKeyword={setLoanBarcode}
                            buttonClick={handleLoan}
                        />
                    </Grid>
                </Grid>
            : null }
            <Grid container justify="center" style={{ marginTop: "20px" }}>
                <Grid item md={12} sm={12} style={{ margin: '0 auto' }}>
                    <TableGrid 
                        records={loans}
                        columns={TABLE_COLUMNS}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default LoanTab;