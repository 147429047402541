import React from 'react'

import Grid from '@material-ui/core/Grid';

import { useTranslator } from '../../../../shared/translate';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';

const GeneralViewTab = (props) => {
    const t = useTranslator();
    const { item } = props;

    const classes = {
        firstCol: {
            width: '200px'
        }

    }

    console.log(props)
    return (
        <React.Fragment>
            <br />
            <Grid container wrap="nowrap" spacing={4}>
                <Grid item xs={4}>

                    <h3>{t('item.tab.general.general')}</h3>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={classes.firstCol}>{t('item.status')}</TableCell>
                                <TableCell>{item.status}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('item.condition')}</TableCell>
                                <TableCell>{item.condition}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <h3>{t('item.tab.general.ids')}</h3>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={classes.firstCol}>{t('item.inventoryNumber')}</TableCell>
                                <TableCell>{item.inventoryNumber}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('item.barcode')}</TableCell>
                                <TableCell>{item.barcode}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <h3>{t('item.tab.general.purchase')}</h3>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={classes.firstCol}>{t('item.purchaseOrder')}</TableCell>
                                <TableCell>{item.purchaseOrder}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('item.purchaseOrderLine')}</TableCell>
                                <TableCell>{item.purchaseOrderLine}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('item.arrivalTime')}</TableCell>
                                <TableCell>{item.arrivalTime}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>

                <Grid item xs={4}>
                    <h3>{t('item.tab.general.location')}</h3>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={classes.firstCol}>{t('item.location')}</TableCell>
                                <TableCell>{item.location}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('item.warehouseSign')}</TableCell>
                                <TableCell>{item.warehouseSign}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <h3>{t('item.tab.general.comment')}</h3>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={classes.firstCol}>{t('item.comment')}</TableCell>
                                <TableCell>{item.comment}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                </Grid>

            </Grid>
        </React.Fragment>
    )
}

export default GeneralViewTab
