import React from 'react';

import styles from './Spinner.module.css';

const Spinner = props => (
  <div className={styles.Spinner}>
    <div className={styles.Loader}>Loading...</div>
  </div>
);

export default Spinner;