import axios from 'axios';
import { getAccessToken } from '../../shared/auth';

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL+'/',
    timeout: 30000,
    headers:{
        'Authorization' : 'Bearer '+ getAccessToken()
    },
});

export default instance;
//https://kapeli.com/cheat_sheets/Axios.docset/Contents/Resources/Documents/index