import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getReader } from '../../../../api/reader_service/readerService';
import { useTranslator } from '../../../../shared/translate';

const ReaderDataSheet = (props) => {

    const { readerId } = props;

    const trans = useTranslator();

    const [data, setData] = useState({});

    useEffect(() => {
        if(readerId){
            getReader(readerId).then(res => {
                setData(JSON.parse(res));
            });
        }
    }, []);

    const fields = [
        { label: trans('birth_place'), id: 'birthPlace' },
        { label: trans('mother_name'), id: 'motherName' },
        { label: trans('barcode'), id: 'barcode' },
        { label: trans('reader_type'), id: 'readerType' },

        { label: trans('country'), id: 'country', allRow: true },

        { label: trans('zip_code'), id: 'zipCode' },
        { label: trans('city'), id: 'city' },

        { label: trans('address'), id: 'address', allRow: true },

    ];

    const editUrl = '/reader_service/reader/'+readerId;

    return (
            <React.Fragment>
                <Grid container justify="center" spacing={5} style={{backgroundColor: "#ececec" }}>
                    <Grid item sm={2}>
                        <svg width="200" height="200" viewBox="0 0 200 200">  
                            <rect width="200" height="200" rx="10" ry="10" fill="#FFF" />
                        </svg>
                    </Grid>
                    <Grid item sm={10}>
                        <Link to={editUrl} >
                            { data.lastname ? 
                                <h1 style={{marginTop: "0px", borderBottom: "1px solid #000"}}>{data.lastname + ' ' + data.firstname}</h1>
                                : null
                            }
                        </Link>
                        
                        <Grid container spacing={2}> 
                            {
                                fields.map((field, index) => {
                                    return (
                                        <Grid key={index} item sm={ field.allRow ? 12 : 6}>
                                            <span style={{fontWeight: "bold"}}>{field.label + ': '}</span> {data[field.id]}
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>        
                    </Grid>
                </Grid>
            </React.Fragment>
        );
}

export default ReaderDataSheet;