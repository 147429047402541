import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ConfigContext from '../../../../../context/snackbar-context';
import Editor from '../../../../../components/Editor/Editor'
import { getAuthorityRecord, updateAuthorityRecord } from '../../../../../api/marc21/authority/authority'
import { getAuthoritySchemaByType } from '../../../../../api/marc21/authority/authoritySchema'
import { useTranslator } from '../../../../../shared/translate'
import Close from '../../../../../assets/icons/closeX.svg';
import Popup from '../../../../../components/UI/Popup/Popup';
import { addHelperFields, removeHelperFields } from '../../../../../shared/listUtil';
import Spinner from '../../../../../components/UI/Spinner/Spinner';
import { updateCollectionsFromEditor } from '../../../../../api/valueset/collection';
import { fillAuthorityIds } from '../../../../../shared/recordUtil';

const useStyles = makeStyles({
    inputStyle: {
        padding: 10,
    },

    cardHeader: {
        fontSize: 18,
        fontWeight: 600,
        color: '#000',
        margin: "20px 0",
        textAlign: 'center',
        borderBottom: '1px solid #c6c6c6',
        a: {
            fontWeight: 400,
            fontSize: 16,
            color: '#2b75bb',
            textDecoration: 'none',
        }
    },
    Image: {
        position: 'relative',
        maxHeight: 25,
        marginRight: '.25rem',
        borderRadius: 6,
    },
    marginTop: {
        marginTop: 10,
        marginBottom: 10,
    },
    myRedButton: {
        backgroundColor: '#990000',
        borderRadius: 6,
        color: 'white',
        fontSize: 14,
        textTransform: 'capitalize',
        padding: '4px 14px',
        fontFamily: 'Source Sans Pro',
        fontWeight: 500,
        textDecoration: 'none',
        marginLeft: 10,
        '&:hover': {
            background: '#990000',
            textDecoration: 'none',
        }
    },
    myBlueButton: {
        backgroundColor: '#2b75bb',
        borderRadius: 6,
        color: 'white',
        fontSize: 14,
        textTransform: 'capitalize',
        padding: '4px 14px',
        fontFamily: 'Source Sans Pro',
        fontWeight: 500,
        '&:hover': {
            backgroundColor: '#2b75bb',
            textDecoration: 'none',
        }
    },
    linkStyle: {
        textDecoration: 'none',
    },
    alignRight: {
        textAlign: 'right',
    },
});

const EntityEdit = (props) => {
    const classes = useStyles();
    const [entity, setEntity] = useState(null)
    const [metadataProfile, setMetadataProfile] = useState(null)
    const [showPopup, setShowPopup] = useState(false);
    const { setShowSnackbar } = React.useContext(ConfigContext);
    const entityId = props.match.params.id
    const entityType = props.match.params.type

    const t = useTranslator()
    useEffect(() => {
        getAuthorityRecord(entityType, entityId).then(response => {
            if (response.record) {
                response.record = addHelperFields(response.record);
            }
            setEntity(response);
        })
    }, [entityType, entityId])

    useEffect(() => {
        getAuthoritySchemaByType(entityType).then(res => setMetadataProfile(res))
    }, [entityType])

    const setFullRecordHandler = input => setEntity(prev => {
        if (input instanceof Function) {
            return { ...prev, record: input(prev.record) };
        } else {
            return { ...prev, record: { ...input } };
        }
    });

    const saveRecordHandler = () => {
        updateCollectionsFromEditor(metadataProfile).then(response => {
            const newRecord = {
                ...entity,
                record: fillAuthorityIds(entity.record, response),
            };
            setEntity(newRecord);
            updateAuthorityRecord(entityType, entityId, removeHelperFields(newRecord)).then(response => {
                setShowSnackbar(true);
                props.history.push(`/config/value-set/entity/${entityType}`);
            });
        });
    }

    if (!entity) { return <Spinner /> }
    return (
        <React.Fragment>
            <Grid container justify="center" spacing={2} className={classes.cardHeader} style={{ marginTop: "20px" }}>
                <Grid item sm={11}>{t('edit')}</Grid>
                <Grid item sm={1}>
                    <Link to={'/config/value-set/entity/' + entityType} ><img className={classes.Image} src={Close} alt="" /></Link>
                </Grid>
            </Grid>

            <Grid container justify="center" spacing={2} style={{ marginTop: "20px" }}>
                <Grid item md={9} sm={10}>
                    <Editor
                        style={{ width: 'calc(100% - 1rem)' }}
                        record={entity ? entity.record : null}
                        setRecord={setFullRecordHandler}
                        metadataProfile={metadataProfile}
                        setMetadataProfile={setMetadataProfile}
                    />
                </Grid>
            </Grid>
            <Grid container justify="center" className={classes.marginTop} >
                <Grid item md={10} className={[classes.alignRight, classes.marginTop].join(' ')}>
                    <Button
                        onClick={saveRecordHandler}
                        className={classes.myBlueButton}
                    >{t('save')}
                    </Button>
                    <Button
                        onClick={() => setShowPopup(true)}
                        className={classes.myRedButton}
                    >{t('dismiss')}
                    </Button>
                </Grid>
            </Grid>
            <Popup
                show={showPopup}
                cancel={() => setShowPopup(false)}
                save={() => props.history.push(`/config/value-set/entity/${entityType}`)}
                title={t('dismiss_confirmation')}
            />
        </React.Fragment>
    )
}

export default withRouter(EntityEdit);
