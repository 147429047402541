import axios from './settings/axios-backend';
import urls from './settings/urls';

const getLanguages = () => {
    return axios.get(urls.GET_LANGUAGES)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

export default getLanguages