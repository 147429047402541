import React from 'react';
import FlipMove from 'react-flip-move';

import Subfield from './Subfield/Subfield';
import { createPropertyArrayItemSetter, isEqual } from '../../../../shared/util';
import { getNewHash, addHelperFieldsToElement, reduceToHashes, removeHelperFields } from '../../../../shared/listUtil';
import { VALUE_LABEL_SEPARATOR, getIdAndTitle, validateFieldsArray } from '../../../../shared/recordUtil';
import { useTranslator } from '../../../../shared/translate';
import Add from '../../../../assets/icons/add-blue.svg';
import styles from '../Datafield.module.scss';

const Subfields = props => {
  const {
    metadataProfile,
    setMetadataProfile,
    subfields,
    setSubfields: propSetSubfields,
    getEntities,
    isValidated,
    isTemplate,
    showNewErrors,
  } = props;

  const subfieldsRef = React.useRef(subfields);
  
  React.useEffect(() => {
    subfieldsRef.current = [...subfields];
  }, [subfields]);
  
  const trans = useTranslator();

  const setSubfields = input => propSetSubfields(prevState => validateFieldsArray(input instanceof Function ? input([...prevState]) : input, metadataProfile, 'code'));
  
  const getCurrentIndex = hash => subfieldsRef.current.findIndex(elem => elem.helpers.id === hash);
  const createPrototype = () => addHelperFieldsToElement({
    code: '',
    value: ''
  }, reduceToHashes(subfieldsRef.current))

  const deleteFieldHandler = hash => () => {
    createPropertyArrayItemSetter(setSubfields, () => getCurrentIndex(hash))(undefined);
  };

  const addFieldHandler = (hash) => () => {
    createPropertyArrayItemSetter(setSubfields, () => subfieldsRef.current.length, false)(
      createPrototype(getNewHash(subfields))
    );
  }

  const cloneFieldHandler = hash => subfield => {
    createPropertyArrayItemSetter(setSubfields, () => getCurrentIndex(hash) + 1, false)(
      addHelperFieldsToElement({...subfield}, reduceToHashes(subfieldsRef.current))
    );
  }

  const sortSubfields = (a, b) => {
    //numbers after letters
    if ( isNaN(a.code) && !isNaN(b.code)) { return -1; }
    if ( isNaN(b.code) && !isNaN(a.code)) { return 1; }

    if ( a.code < b.code ) { return -1; }
    if ( a.code > b.code ) { return 1; }
    return 0;
  };

  const codeChangeHandler = hash => code => setSubfields(prevSubfields => {
    const newSubfields = [...prevSubfields];
    const index = getCurrentIndex(hash);
    newSubfields.splice(index, 1, {...newSubfields[index], code: code});
    newSubfields.sort(sortSubfields);
    return newSubfields;
  })  

  const linkEntryHandler = (hash, isStandard) => entity => {
    setSubfields(prev => {
      const index = getCurrentIndex(hash)
      const newState = [...prev];
      const {id, title} = getIdAndTitle(entity);
      if (isStandard) {
        if ('t' === newState[index].code) {
          newState[index] = {...newState[index], value: title};
          const other = newState.findIndex(elem => elem.code === 'w')
          if (-1 !== other) {
            newState[other] = {...newState[other], value: id}
          } else {
            newState.push({...createPrototype(getNewHash(subfields)), code: 'w', value: id});
          }
          newState.sort(sortSubfields);
        } else {
          newState[index] = {...newState[index], value: id};
          const other = newState.findIndex(elem => elem.code === 't')
          if (-1 !== other) {
            newState[other] = {...newState[other], value: title}
          } else {
            newState.push({...createPrototype(getNewHash(subfields)), code: 't', value: title});
          }
          newState.sort(sortSubfields);
        }
      } else {
        newState[index] = {...newState[index], value: id};
      }
      return newState;
    });
  }

  const getPossibleCodes = code => metadataProfile ? metadataProfile.reduce((accumulator, current) => {
    if (
      false !== current.status && (
        current.repeatable || 
        code === current.code || 
        !subfieldsRef.current.find(subfield => subfield.code === current.code)
      )
    ) {
        accumulator.push({
          label: `${current.code} ${VALUE_LABEL_SEPARATOR} ${trans(current.label)}`,
          value: current.code,
      })
    }
    return accumulator;
  }, []): [];

  const changeSubfieldHandler = hash => input => {
    createPropertyArrayItemSetter(setSubfields, () => getCurrentIndex(hash))(input);
  }

  const isStandardLinkEntity = code => {
    if (!metadataProfile) { return false; }
    const subfield = metadataProfile.find(elem => elem.code === code);
    if (!subfield) { return false; }
    return !!subfield.inputStrict;
  }

  return (
    <React.Fragment>
      <FlipMove
        // easing={'linear'}
        duration={350}
        delay={100}
        staggerDurationBy={0}
        staggerDelayBy={0}
        // enterAnimation={"fade"}
        // leaveAnimation={"fade"}
        maintainContainerHeight={true}
        // verticalAlignment={"bottom"}
      >
        {subfields.map((subfield, index) => {
          const subfieldMetadataProfileIndex = metadataProfile.findIndex(elem => elem.code === subfield.code);
          const subfieldMetadataProfile = -1 < subfieldMetadataProfileIndex ? metadataProfile[subfieldMetadataProfileIndex] : null;
          return (
            <div key={subfield.helpers.id}>
              <Subfield
                subfield={subfield}
                setSubfield={changeSubfieldHandler(subfield.helpers.id)}
                metadataProfile={subfieldMetadataProfile}
                setMetadataProfile={subfieldMetadataProfile ? createPropertyArrayItemSetter(setMetadataProfile, subfieldMetadataProfileIndex) : null}
                getPossibleCodes={getPossibleCodes}
                onDelete={deleteFieldHandler(subfield.helpers.id)}
                onClone={cloneFieldHandler(subfield.helpers.id)}
                onCodeChange={codeChangeHandler(subfield.helpers.id)}
                getEntities={getEntities}
                linkEntry={linkEntryHandler(subfield.helpers.id, isStandardLinkEntity(subfield.code))}
                isValidated={isValidated}
                getMetadataProfileForCode={code => metadataProfile.find(elem => elem.code === code)}
                isTemplate={isTemplate}
                showNewErrors={showNewErrors}
              />
            </div>
          )
        })}
      </FlipMove>
        <div style={{ textAlign: "right" }}>
          {
            (getPossibleCodes())
              ? <img className={styles.myIconStyle} src={Add} alt="" onClick={addFieldHandler()} />
              : null
          }
        </div>
    </React.Fragment>
  );
};

export default React.memo(Subfields, (prev, next) => (
  prev.isValidated === next.isValidated &&
  isEqual(removeHelperFields(prev.subfields), removeHelperFields(next.subfields)) &&
  isEqual(prev.metadataProfile, next.metadataProfile) &&
  prev.showNewErrors === next.showNewErrors
));