import axios from '../../settings/axios-backend';
import urls from '../../settings/urls';


export const getAuthoritySchema = (id) => {
    const url = urls.MARC21_AUTHORITY_SCHEMA.replace('{id}', id);
    return axios.get(url)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

export const getAuthoritySchemaByType = (type) => {
    const url = urls.MARC21_AUTHORITY_SCHEMA_BY_TYPE.replace('{type}', type);
    return axios.get(url)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};
