import React, { useState } from 'react';

import { Grid, TextField } from '@material-ui/core';

const FILTER_TEXT_CHANGE_TIMEOUT = 700;

const CurrencyFilter = (props) => {
  const [currencyFilter, setCurrencyFilter] = useState({});
  const [ filterChangeTimer, setFilterChangeTimer ] = useState(null);

  const resetFilterChangeTimer = () => {
    if (filterChangeTimer) {
      clearTimeout(filterChangeTimer);
      setFilterChangeTimer(null);
    }
  }

  const currencyNameFilterChanged = (name) => {
    onFilterChanged({
      currencyName: name.length > 0 ? name : null,
    });
  }

  const onFilterChanged = (changedFilter) => {
    if (typeof props.onFilterChange !== 'function') {
      return;
    }

    const filter = {
      ...currencyFilter,
      ...changedFilter,
    };

    setCurrencyFilter(filter);

    resetFilterChangeTimer();

    setFilterChangeTimer(
      setTimeout(() => {
          props.onFilterChange(filter);
        },
        FILTER_TEXT_CHANGE_TIMEOUT,
      )
    );
  };

  return (
    <Grid container spacing={5}>
      <Grid item sm={12}>
        <TextField
          autoFocus
          // placeholder={t('search')}
          variant="outlined"
          type="text"
          fullWidth
          inputProps={{
              // value: currencyFilter.currencyName,
              onChange: (event) => {
                currencyNameFilterChanged(event.target.value);
              }
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CurrencyFilter;
