import React from 'react'
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper
} from '@material-ui/core';

import { dateFormatString, trueOrUndefined } from '../../../../../shared/util'
import { Translate as T, useTranslator } from '../../../../../shared/translate';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    myTableHeadStyle: {
        backgroundColor: '#e6e6e6',
    },
    tableHoverStyle: {
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: 'rgba(37, 118, 195, 0.1)',
        }
    },
    paddingStyle: {
        padding: '10px !important',
      },
});


const Marc21CallsignShow = (props) => {
    const classes = useStyles();

    const callsignData = props.callsignData;

    const trans = useTranslator();

    const showCallsign = (callsignData) => {
        return (
            <Paper>
                <Table>
                    <TableHead className={classes.myTableHeadStyle}>
                        <TableRow>
                            <TableCell variant={'head'}><T>callsign</T></TableCell>
                            <TableCell variant={'head'}><T>description</T></TableCell>
                            <TableCell variant={'head'}><T>repeatable</T></TableCell>
                            <TableCell variant={'head'}><T>required</T></TableCell>
                            <TableCell variant={'head'}><T>status</T></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow className={classes.tableHoverStyle}>
                            <TableCell className={classes.paddingStyle} variant={'body'}>{callsignData.tag}</TableCell>
                            <TableCell className={classes.paddingStyle} variant={'body'}>{trans(callsignData.label)}</TableCell>
                            <TableCell className={classes.paddingStyle} variant={'body'}>{callsignData.repeatable ? <T>repeatable</T> : <T>not-repeatable</T>}</TableCell>
                            <TableCell className={classes.paddingStyle} variant={'body'}>{callsignData.required ? <T>required</T> : <T>not-required</T>}</TableCell>
                            <TableCell className={classes.paddingStyle} variant={'body'}>{trueOrUndefined(callsignData.status) ? <T>active</T> : <T>not-active</T>}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        );
    }

    const showIndicator = (indicator) => {
        if (typeof indicator === 'undefined' || indicator === null) {
            return '#'
        } else {
            return (
                <React.Fragment>
                    {trans(indicator.label)}

                    <Table>
                        <TableHead className={classes.myTableHeadStyle}>
                            <TableRow>
                                <TableCell variant={'head'}><T>value</T></TableCell>
                                <TableCell variant={'head'}><T>description</T></TableCell>
                                <TableCell variant={'head'}><T>status</T></TableCell>
                                <TableCell variant={'head'}><T>last-modified</T></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {indicator.codes.map((code, index) => (
                                <TableRow className={classes.tableHoverStyle} key={index}>
                                    <TableCell className={classes.paddingStyle} variant={'body'}>{code.code}</TableCell>
                                    <TableCell className={classes.paddingStyle} variant={'body'}>{trans(code.label)}</TableCell>
                                    <TableCell className={classes.paddingStyle} variant={'body'}>{trueOrUndefined(code.status) ? <T>active</T> : <T>not-active</T>}</TableCell>
                                    <TableCell className={classes.paddingStyle} variant={'body'}>{typeof code.lastModified === 'undefined' ? '-' : dateFormatString(code.lastModified)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </React.Fragment>
            );
        }
    }

    const showSubfields = (subfields) => {
        if (typeof subfields === 'undefined' || subfields === null) {
            return null
        } else {
            const subfieldsArray = Object.entries(subfields);
            return (
                <React.Fragment>
                    <Paper>
                        <Table>
                            <TableHead className={classes.myTableHeadStyle}>
                                <TableRow>
                                    <TableCell variant={'head'}><T>callsign</T></TableCell>
                                    <TableCell variant={'head'}><T>description</T></TableCell>
                                    <TableCell variant={'head'}><T>repeatable</T></TableCell>
                                    <TableCell variant={'head'}><T>required</T></TableCell>
                                    <TableCell variant={'head'}><T>input-type</T></TableCell>
                                    <TableCell variant={'head'}><T>value-set</T></TableCell>
                                    <TableCell variant={'head'}><T>status</T></TableCell>
                                    <TableCell variant={'head'}><T>last-modified</T></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subfieldsArray.map((s) => {
                                    const [key, subfield] = s;
                                    return (
                                        <React.Fragment key={key}>
                                            <TableRow className={classes.tableHoverStyle}>
                                                <TableCell className={classes.paddingStyle} variant={'body'}>{subfield.code}</TableCell>
                                                <TableCell className={classes.paddingStyle} variant={'body'}>{trans(subfield.label)}</TableCell>
                                                <TableCell className={classes.paddingStyle} variant={'body'}>{trueOrUndefined(subfield.repeatable) ? <T>repeatable</T> : <T>not-repeatable</T>}</TableCell>
                                                <TableCell className={classes.paddingStyle} variant={'body'}>{subfield.required ? <T>required</T> : <T>not-required</T>}</TableCell>
                                                <TableCell className={classes.paddingStyle} variant={'body'}>{inputTypeCodeToText(subfield.input)}</TableCell>
                                                <TableCell className={classes.paddingStyle} variant={'body'}></TableCell>
                                                <TableCell className={classes.paddingStyle} variant={'body'}>{trueOrUndefined(subfield.status) ? <T>active</T> : <T>not-active</T>}</TableCell>
                                                <TableCell className={classes.paddingStyle} variant={'body'}>{typeof subfield.lastModified == 'undefined' ? '-' : dateFormatString(subfield.lastModified)}</TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    )
                                }
                                )}

                            </TableBody>
                        </Table>
                    </Paper>
                </React.Fragment>
            );
        }
    }

    const inputTypeCodeToText = (inputTypeCode) => {
        switch (inputTypeCode) {
            case 'text': return T('text-field')
            case 'date': return T('date')
            case 'datetime': return T('datetime')
            case 'select': return T('dropdown-list')
            case 'record': return T('record-connection')
            case 'number': return T('number-field')
            case 'numregexber': return T('regular-expression')
            default: return T('text-field');
        }
    }


    return (
        <React.Fragment>
            {/*<h4><T>callsign-data</T></h4>*/}
            {showCallsign(callsignData)}

            {!['000', '003', '005', '006', '007', '008'].includes(callsignData.tag)
                ? <React.Fragment>
                    <h4><T>1-indicator</T></h4>
                    {showIndicator(callsignData.indicator1)}

                    <h4><T>2-indicator</T></h4>
                    {showIndicator(callsignData.indicator2)}

                    <h4><T>subfields</T></h4>
                    {showSubfields(callsignData.subfields)}
                </React.Fragment>
                : null}
        </React.Fragment>
    )
}

export default Marc21CallsignShow
