import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import LanguageContextProvider from './context/language-context'


import LanguageHoc from './hoc/LanguageHoc';
import AuthContextProvider from './context/auth-context';
import ErrorHoc from './hoc/ErrorHoc';
import { SnackbarProvider } from './context/snackbar-context'

ReactDOM.render(
    <BrowserRouter>
        <AuthContextProvider>
            <LanguageContextProvider>
                <ErrorHoc>
                    <LanguageHoc>
                        <SnackbarProvider>
                                <App />
                        </SnackbarProvider>
                    </LanguageHoc>
                </ErrorHoc>
            </LanguageContextProvider>
        </AuthContextProvider>
    </BrowserRouter>
    ,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
