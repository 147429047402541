import React from 'react'


import SnackBarContext from '../../../../context/snackbar-context';
import ItemForm from '../Item/ItemForm';
import { updateItem } from '../../../../api/marc21/item/item';
import { Grid } from '@material-ui/core';

const GeneralEditTab = (props) => {

    const { item } = props;

    const { setShowSnackbar } = React.useContext(SnackBarContext);


    const saveHandler = (data) => {
        updateItem(item.id, { ...data }).then(res => {
            setShowSnackbar(true);
        })
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={6}>
                    <ItemForm item={item} buttonClick={saveHandler} buttonText={'save'} />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default GeneralEditTab
