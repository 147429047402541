import React from 'react'
import Grid from '@material-ui/core/Grid';
import WorkpageHeader from './WorkpageHeader/WorkpageHeader'
import WorkpageBody from './WorkpageBody/WorkpageBody'
import SuccessSnackbar from '../../../components/UI/Snackbar/Success';
import { useTranslator } from '../../../shared/translate';


const Workpage = (props) => {
    const {recordId, templateId, documentFormId} = props.match.params;
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const trans = useTranslator();

    return (
        <React.Fragment>
            <Grid container>
                <Grid item sm={12}>
                    <WorkpageHeader />
                </Grid>
            </Grid>
            <Grid container justify="center" spacing={5} style={{ marginTop: "10px" }}>
                <Grid item md={11} sm={12}>
                    <WorkpageBody 
                        key={templateId + recordId + documentFormId}
                        templateId={templateId} 
                        recordId={recordId} 
                        documentFormId={documentFormId}
                        setShowSnackbar={setShowSnackbar}
                    />
                </Grid>
            </Grid>
            <SuccessSnackbar
                message={<span>{trans('save_successful')}</span>}
                showSnackbar={showSnackbar}
                setShowSnackbar={setShowSnackbar}
            />
        </React.Fragment>
    )
}

export default Workpage
