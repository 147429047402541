import React from 'react'
import { Translate as T } from '../../../shared/translate';
import { Button, Grid } from '@material-ui/core';
import classes from './Popup.module.scss';
import Modal from '../Modal/Modal';
import { SYMBOL_MULTIPLICATION_X } from '../../../shared/symbols';

export const Popup = (props) => {
    const { show, save, cancel, title, children, disabled } = props;

    return (
        <Modal size={"small"} show={show} modalClosed={cancel}>

            <Grid container>
                <Grid item sm={10}>
                </Grid>
                <Grid item sm={1}>
                    <Button className={classes.closeButton} onClick={cancel}>
                        {SYMBOL_MULTIPLICATION_X}
                    </Button>
                </Grid>
            </Grid>
            <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                <Grid item sm={12} className={classes.Title}>
                    <h3>{title}</h3>
                </Grid>
                <Grid item sm={11} >
                    {children ? <div className={classes.Body}>{children}</div> : null}
                    <div className={classes.Footer}>
                        <Button className={classes.mainBlueButton} onClick={save} disabled={disabled}>
                            <T>confirm</T>
                        </Button>
                        <Button className={classes.myRedButton} onClick={cancel}>
                            <T>cancel</T>
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </Modal>
    );
}

export default Popup;