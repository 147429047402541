import React from 'react';
import classes from './List.module.scss';
import { withRouter } from 'react-router';
import {
    TableRow,
    TableCell,
    Grid,
    Table,
    TableHead,
    TableBody,
    Paper,
  } from '@material-ui/core';
import { useTranslator } from '../../../../shared/translate';
import { useAuth } from '../../../../shared/auth';
import { permissions } from '../../../../shared/permissions';
import SelectEdit from './SelectEdit';

const ReaderList = (props) => {

    const {
        records,
        columns,
        deleteHandler,
        history,
    } = props;

    const auth = useAuth();
    const trans = useTranslator();

    const menuItems = [
        { label: trans('edit'), 
            handler: null, 
            url: '/reader_service/reader/', 
            permission: permissions.READER_SERVICE_READER_READ 
        },
        { label: trans('delete'), 
            handler: deleteHandler, 
            url: null, 
            permission: permissions.READER_SERVICE_READER_DELETE 
        }
    ];

    const transItems = [
        { label: trans('loan'), 
            handler: (recordId) => { history.push('/reader_service/loan/' + recordId) }, 
            url: null, 
            permission: permissions.READER_SERVICE_LOAN_READ 
        },
        { label: trans('return'), 
            handler: (recordId) => { history.push('/reader_service/return/' + recordId) }, 
            url: null,
            permission: permissions.READER_SERVICE_RETURN_READ
        },
        { label: trans('request'), 
            handler: (recordId) => { history.push('/reader_service/request/' + recordId) }, 
            url: null,
            permission: permissions.READER_SERVICE_REQUEST_READ
        },
    ];

    const menuItemPermissionFilter = (menuItems) => {
        return menuItems.filter((item) => {
            if (auth.isGranted(item.permission)){
                return true;
            }

            return false;
        });
    };

    let rows = null;
    if(records){
        const convertedRec = records.map(recString => {
            const rec = JSON.parse(recString);
            const item = {};
            columns.forEach(key => {
              item[key] = rec[key];
            });

            item.operations = (
                <React.Fragment>
                    <Grid item sm>
                      <SelectEdit
                        buttonLabel='operations'
                        menuItems={menuItemPermissionFilter(menuItems)}
                        recordId={rec.id}
                      />
                      &nbsp;
                      <SelectEdit
                        buttonLabel='transactions'
                        menuItems={menuItemPermissionFilter(transItems)}
                        recordId={rec.id}
                      />
                    </Grid>
                </React.Fragment>
            )

            return item;
        });

        rows = convertedRec.map((rec, key) => {
                const cells = columns.map((item, index) => {
                    return <TableCell className={classes.paddingStyle} variant={'body'} key={index}>{rec[item]}</TableCell>
                });

                // operations
                cells.push(
                    <TableCell className={classes.paddingStyle} variant={'body'} key={cells.length}>
                        <div style={{ textAlign: 'right' }}>
                            {rec['operations']}
                        </div>
                    </TableCell>
                )

                return <TableRow hover key={key}>{cells}</TableRow>;
            });

    }

    return (
        <React.Fragment>
            <Paper>
                <Table>
                    <TableHead className={classes.myTableHeadStyle}>
                        <TableRow>
                        {
                            columns.map((col, key) => {
                                return <TableCell variant={'head'} key={key}>{trans(col)}</TableCell>
                            })
                        }
                        <TableCell variant={'head'} style={{ width: '30%' }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableHoverStyle}>
                        { rows }
                    </TableBody>
                </Table>
            </Paper>
        </React.Fragment>
    );
}

export default withRouter(ReaderList);
