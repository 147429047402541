import React from 'react'
import Grid from '@material-ui/core/Grid';
import {
    Button,
    Select,
    MenuItem,
    TextField
} from '@material-ui/core';

import { Translate as T, useTranslator } from '../../../shared/translate';
import { deepCopy } from '../../../shared/util';
import { AddFieldButton } from '../../../components/Editor/Buttons/Buttons';
import Del from '../../../assets/icons/delete-blue.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    mainBlueButton: {
        backgroundColor: '#2b75bb',
        borderRadius: 6,
        color: 'white',
        fontSize: 14,
        textTransform: 'capitalize',
        padding: '4px 14px',
        fontFamily: 'Source Sans Pro',
        fontWeight: 500,
        marginRight: 10,
        marginTop: 20,
        zIndex: 'inherit',
        '&:hover': {
            background: '#169bd5',
        },
    },
    inputStyle: {
        padding: 10,
        fontSize: 14,
        fontWeight: 500,
    },
    myIconStyle: {
        cursor: 'pointer',
        width: '2.8rem',
    },
});


const SearchComplex = (props) => {
    const classes = useStyles();
    const {
        buttonClick,
        setSimpleSearchActive,
        filterComplexConditions,
        setFilterComplexConditions,
        searchFilterOptions,
    } = props;

    const t = useTranslator();

    const addCondition = () => {
        const prototypeCondition = {
            filterType: 'keyword',
            where: 'contains',
            keyword: '',
            operator: 'and'
        }
        setFilterComplexConditions((prevCondition) => {
            const newConditions = deepCopy(prevCondition)
            newConditions.push(prototypeCondition);
            return newConditions;
        });
    }

    const removeCondition = (key) => {
        setFilterComplexConditions((prevCondition) => {
            const newConditions = deepCopy(prevCondition)
            newConditions.splice(key, 1);
            return newConditions;
        });
    }

    const changeFilterType = (key, filterTypeValue) => {
        setFilterComplexConditions((prevCondition) => {
            const newConditions = deepCopy(prevCondition)
            newConditions[key].filterType = filterTypeValue;
            return newConditions;
        });
    }

    const changeWhere = (key, where) => {
        setFilterComplexConditions((prevCondition) => {
            const newConditions = deepCopy(prevCondition)
            newConditions[key]['where'] = where;
            return newConditions;
        });
    }

    const changeKeyword = (key, keyword) => {
        setFilterComplexConditions((prevCondition) => {
            const newConditions = deepCopy(prevCondition)
            newConditions[key]['keyword'] = keyword;
            return newConditions;
        });
    }
    const changeOperator = (key, operator) => {
        setFilterComplexConditions((prevCondition) => {
            const newConditions = deepCopy(prevCondition)
            newConditions[key]['operator'] = operator;
            return newConditions;
        });
    }

    return (
        <React.Fragment>
            {filterComplexConditions.map((c, key) => {
                return (
                    <React.Fragment key={key}>
                        <Grid container spacing={3}>
                            <Grid item sm={3}>
                                <Select
                                    onChange={(event) => changeFilterType(key, event.target.value)}
                                    value={c.filterType}
                                    fullWidth
                                    variant="outlined"
                                    inputProps={{
                                        className: classes.inputStyle
                                    }}
                                >
                                    {searchFilterOptions.map((option, key) => {
                                        return (
                                            <MenuItem key={key} value={option.value}>{t(option.label)}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                            <Grid item sm={3}>
                                <Select
                                    onChange={(event) => changeWhere(key, event.target.value)}
                                    value={c.where}
                                    fullWidth
                                    variant="outlined"
                                    inputProps={{
                                        className: classes.inputStyle
                                    }}
                                >
                                    <MenuItem value={'contains'}>{t('contains')}</MenuItem>
                                    <MenuItem value={'start'}>{t('starts-with')}</MenuItem>
                                    <MenuItem value={'end'}>{t('ends-with')}</MenuItem>
                                </Select>
                            </Grid>

                            <Grid item sm={3}>
                                <TextField
                                    onChange={(event) => changeKeyword(key, event.target.value)}
                                    value={c.keyword}
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    inputProps={{
                                        className: classes.inputStyle
                                    }}
                                >
                                </TextField>
                            </Grid>
                            <Grid item sm={1}>

                                <Select
                                    onChange={(event) => changeOperator(key, event.target.value)}
                                    value={c.operator}
                                    fullWidth
                                    variant="outlined"
                                    inputProps={{
                                        className: classes.inputStyle
                                    }}
                                >
                                    <MenuItem value={'or'}>{t('or')}</MenuItem>
                                    <MenuItem value={'and'}>{t('and')}</MenuItem>
                                </Select>

                            </Grid>
                            <Grid item sm={1}>
                                {key === 0
                                    ? null
                                    : <img className={classes.myIconStyle} src={Del} alt=""
                                        onClick={() => removeCondition(key)}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )
            })}

            <Grid container>
                <Grid item sm={12} >
                    <AddFieldButton onClick={addCondition} />

                </Grid>
            </Grid>
            <Grid container justify="center">
                <Grid >

                    <Button
                        onClick={buttonClick}
                        className={classes.mainBlueButton}
                    ><T>search</T></Button>
                </Grid>
                <Grid >
                    <Button
                        onClick={() => setSimpleSearchActive(true)}
                        className={classes.mainBlueButton}
                    ><T>simple-search</T></Button>

                </Grid>
            </Grid>

        </React.Fragment >
    )
}

export default SearchComplex;