import React, { useState, useCallback, useEffect } from 'react'
import Grid from '@material-ui/core/Grid';

import useSmartRequest from '../../../hooks/useSmartRequest';
import { recordsSearchSimple, recordsSearchComplex, recordsSearchFilterOptions, recordsSearchSortOptions } from '../../../api/catalog/recordsSearch';
import SearchSimple from './SearchSimple';
import SelectOrder from './SelectOrder';
import SearchComplex from './SearchComplex';
import RecordList from './RecordList';
import { Translate as T, useTranslator } from '../../../shared/translate';
import classes from './List.module.scss';
import { deleteRecord, cloneRecord, getRecord, activateRecord } from '../../../api/marc21/bibliographic/bibliographicRecords';
import Pagination from '../../../components/UI/Pagination/Pagination';
import PopupModal from '../../../components/UI/Popup/PopupModal';
import { Button } from '@material-ui/core';
import bibliographicSchema from '../../../api/marc21/bibliographic/bibliographicSchema';
import { RecordEntityLinks, MetadataProfileEntityLinks } from '../../../shared/recordUtil';
import DocumentFormList from './DocumentFormList';
import Spinner from '../../../components/UI/Spinner/Spinner';
import useKeyPress from '../../../hooks/useKeyPress';
import { makeCancelable } from '../../../shared/util';

const List = props => {
    const { linkEntry } = props;

    const itemPerPage = 10;
    const [isLoading, setIsLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = useState(1)

    //Both
    const [records, setRecords] = useState(null)
    const [recordCount, setRecordCount] = useState(0)

    const [simpleSearchActive, setSimpleSearchActive] = useState(true)
    const [searchFilterOptions, setSearchFilterOptions] = useState([])
    const [searchSortOptions, setSearchSortOptions] = useState([])

    //Sort
    const [sortType, setSortType] = useState('title')
    const [sortOrder, setSortOrder] = useState('ASC')

    //Simple
    const { sendRequest: sendRequestSimpleSearch, isSending: isSendingSimpleSearch } = useSmartRequest(recordsSearchSimple);
    const [simpleParams, setSimpleParams] = useState({})
    const [filterType, setFilterType] = useState('keyword')
    const [filterKeyword, setFilterKeyword] = useState('')

    //Complex
    const { sendRequest: sendRequestComplexSearch, isSending: isSendingComplexSearch } = useSmartRequest(recordsSearchComplex);
    const [complexParams, setComplexParams] = useState({})
    const [filterComplexConditions, setFilterComplexConditions] = useState(
        [{
            filterType: 'keyword',
            where: 'contains',
            keyword: '',
            operator: 'and'
        }])

    const [show, setShow] = useState(false)
    const [showSelectedId, setShowSelectedId] = useState(null)
    const [showSelectedHasConnections, setShowSelectedHasConnections] = useState(null)

    const [showDocumentFormList, setShowDocumentFormList] = useState(false);

    useEffect(() => {
        const {cancel: cancelFilterPromise, promise: filterPromise} = makeCancelable(recordsSearchFilterOptions());
        const {cancel: cancelSortPromise, promise: sortPromise} = makeCancelable(recordsSearchSortOptions());


        filterPromise.then(response => {
            setSearchFilterOptions(response)
        })
        sortPromise.then(response => {
            setSearchSortOptions(response)
        })

        return () => {
            cancelFilterPromise();
            cancelSortPromise();
        }
    }, [])

    useEffect(() => {
        setSimpleParams({
            filterType: filterType,
            filterKeyword: filterKeyword,
            sortType: sortType,
            sortOrder: sortOrder,
            offset: (currentPage - 1) * itemPerPage,
            limit: itemPerPage,
        })
        setComplexParams({
            conditions: filterComplexConditions,
            sort: {
                sortType: sortType,
                sortOrder: sortOrder,
            },
            offset: (currentPage - 1) * itemPerPage,
            limit: itemPerPage,
        })
    }, [filterType, filterKeyword, sortType, sortOrder, currentPage, filterComplexConditions])

    const t = useTranslator();

    const handleSearchSend = () => {
        setCurrentPage(1);
        reloadResults(
            { ...simpleParams, offset: 0 },
            { ...complexParams, offset: 0 },
        )
    }
    useKeyPress('Enter', handleSearchSend)

    const handleCurrentPageChange = (newCurrentPage) => {
        setCurrentPage(newCurrentPage)
        reloadResults(
            { ...simpleParams, offset: (newCurrentPage - 1) * itemPerPage },
            { ...complexParams, offset: (newCurrentPage - 1) * itemPerPage },
        )
    }

    const handleSortOrderChange = async (sortTypeInput, sortOrderInput) => {
        setSortType(sortTypeInput)
        setSortOrder(sortOrderInput)
        reloadResults(
            { ...simpleParams, sortType: sortTypeInput, sortOrder: sortOrderInput },
            { ...complexParams, sort: { sortType: sortTypeInput, sortOrder: sortOrderInput } },
        )
    }

    const simpleSearchActiveHandler = (activeValue) => {
        setSimpleSearchActive(activeValue);
        setRecords(null);
    }

    const activateRecordHandler = id => () => {
        setIsLoading(true);
        activateRecord(id).then(response => {
            setIsLoading(false);
            setRecords(prevState => {
                const nextState = [...prevState];
                const index = nextState.findIndex(elem => elem.id === id);
                nextState[index] = { ...nextState[index], status: 'active' };
                return nextState;
            })
        });
    }

    const reloadResults = useCallback(
        (simpleParamsInput = simpleParams, complexParamsInput = complexParams) => {
            setIsLoading(true);
            if (simpleSearchActive) {
                sendRequestSimpleSearch(simpleParamsInput).then(r => {
                    if (r !== null && typeof r !== 'undefined') {
                        setRecords(r.records)
                        setRecordCount(r.recordCount)
                    }
                    setIsLoading(false);
                })
            } else {
                sendRequestComplexSearch(complexParamsInput).then(r => {
                    if (r !== null && typeof r !== 'undefined') {
                        setRecords(r.records)
                        setRecordCount(r.recordCount)
                    }
                    setIsLoading(false);
                })
            }
        },
        [complexParams, sendRequestComplexSearch, sendRequestSimpleSearch, simpleParams, simpleSearchActive],
    )

    const removeRecordHandler = useCallback(
        (recordId) => {
            deleteRecord(recordId).then(res => {
                reloadResults()
            })
        },
        [reloadResults],
    )

    const cloneRecordHandler = useCallback(recordId => {
        cloneRecord(recordId).then(response => {
            props.history.push(`/cataloging/edit/${response.id}`);
        })
    }, [props.history]);

    const isRequestSending = () => {
        return isSendingSimpleSearch || isSendingComplexSearch
    }

    const setShowHandler = (inputShow, id = null) => {
        if (id) {
            bibliographicSchema().then(metadataProfile =>
                getRecord(id).then(r => {
                    // console.log(deepCopy(r))
                    // console.log(MetadataProfileEntityLinks(metadataProfile))
                    if (RecordEntityLinks(r.record, MetadataProfileEntityLinks(metadataProfile)).length) {
                        setShowSelectedHasConnections(true)
                    } else {
                        setShowSelectedHasConnections(false)
                    }
                    setShowSelectedId(id)
                    setShow(inputShow);
                })
            )
        } else {
            setShow(inputShow);
        }
    }

    if (!searchFilterOptions || !searchFilterOptions.length) {
        return <Spinner />;
    }

    return (
        <React.Fragment>
            <PopupModal
                size={'small'}
                show={show}
                setShow={setShowHandler}
                title={
                    showSelectedHasConnections
                        ? t('record-has-connection-so-cannot-be-deleted')
                        : t('delete?')
                }
                footer={
                    showSelectedHasConnections
                        ? null
                        : <React.Fragment>
                            <Button onClick={() => {
                                removeRecordHandler(showSelectedId)
                                setShowHandler(false)
                            }}>{t('delete')}</Button>
                            <Button onClick={() => {
                                setShowHandler(false)
                            }}>{t('cancel')}</Button>
                        </React.Fragment>


                }
            />
            <Grid container justify="center" spacing={5} className={classes.cardHeader} style={{ marginTop: "0px" }}>
                <Grid item>
                    <T>catalog</T>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "50px" }}>
                <Grid item md={10} sm={12} style={{ margin: "0 auto" }}>

                    {simpleSearchActive
                        ?
                        <SearchSimple
                            searchFilterOptions={searchFilterOptions}

                            filterType={filterType}
                            setFilterType={setFilterType}

                            filterKeyword={filterKeyword}
                            setFilterKeyword={setFilterKeyword}

                            buttonClick={handleSearchSend}
                            disabled={isRequestSending()}
                            setSimpleSearchActive={simpleSearchActiveHandler}
                        />
                        :
                        <SearchComplex
                            searchFilterOptions={searchFilterOptions}

                            setSimpleSearchActive={simpleSearchActiveHandler}
                            filterComplexConditions={filterComplexConditions}
                            setFilterComplexConditions={setFilterComplexConditions}
                            buttonClick={handleSearchSend}
                            disabled={isRequestSending()}
                        />
                    }
                </Grid>
                <Grid item md={10} sm={12} style={{ margin: "0 auto" }}>
                    <React.Fragment>
                        <div style={!isLoading ? { display: 'none' } : { display: 'block' }}><Spinner /></div>
                        {/* Before search return null, not 'no results' */}
                        {records === null
                            ? null
                            : <React.Fragment>
                                <div style={isLoading ? { display: 'none' } : {}}>
                                    {!records || !records.length
                                        ? t('no-results-found')
                                        : <React.Fragment>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={10}><h3 className={classes.mySubfontH3}><T>record-list</T></h3></Grid>
                                                <Grid item xs={2} >
                                                    <h4 className={classes.mySubfontH4}><T>order</T>:</h4>
                                                    <SelectOrder
                                                        searchSortOptions={searchSortOptions}
                                                        handleSortOrderChange={handleSortOrderChange}
                                                        sortType={sortType}
                                                        sortOrder={sortOrder}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Pagination
                                                    currentPage={currentPage}
                                                    handleCurrentPageChange={handleCurrentPageChange}
                                                    recordCount={recordCount}
                                                    itemPerPage={itemPerPage}
                                                    disabled={isRequestSending()}
                                                />
                                                <RecordList
                                                    records={records}
                                                    cloneRecordHandler={cloneRecordHandler}
                                                    linkEntry={linkEntry}
                                                    setShowHandler={setShowHandler}
                                                    activateRecordHandler={activateRecordHandler}
                                                />
                                                <Pagination
                                                    currentPage={currentPage}
                                                    handleCurrentPageChange={handleCurrentPageChange}
                                                    recordCount={recordCount}
                                                    itemPerPage={itemPerPage}
                                                    disabled={isRequestSending()}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    }
                                </div>
                            </React.Fragment>
                        }
                        {linkEntry ? null : <DocumentFormList
                            visible={showDocumentFormList}
                            toggleVisibility={() => setShowDocumentFormList(prevState => !prevState)}
                        />}
                    </React.Fragment>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default List;