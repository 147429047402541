import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid';
import { Button, List } from '@material-ui/core';

import { useTranslator } from '../../../shared/translate'
import useSmartRequest from '../../../hooks/useSmartRequest';
import { getRecord } from '../../../api/marc21/bibliographic/bibliographicRecords';
import ShowRecord from './ShowRecord';
import PopupModal from '../../../components/UI/Popup/PopupModal';
import { isEqual } from '../../../shared/util';
import RecordListElement from './RecordListElement';

const RecordList = (props) => {

    const {
        records,
        cloneRecordHandler,
        activateRecordHandler,
        linkEntry,
        setShowHandler
    } = props


    const [showPopup, setShowPopup] = useState(false);
    const [popupRecordId, setPopupRecordId] = useState(null);
    const [popupRecord, setPopupRecord] = useState(null);

    const { sendRequest, isSending } = useSmartRequest(getRecord);

    const t = useTranslator();
    useEffect(() => {
        if (popupRecordId !== null) {
            sendRequest(popupRecordId).then(res => {
                // console.log(res)
                setPopupRecord(res)
            });
        }
    }, [popupRecordId, sendRequest])

    const cancelPopupHandler = (event) => {
        setShowPopup(false);
    }

    const showPopupHandler = (recordId) => {
        setPopupRecordId(recordId)
        setShowPopup(true);
    }

    return (
        <React.Fragment>
            <PopupModal
                show={showPopup}
                setShow={setShowPopup}
                cancel={cancelPopupHandler}
                body={isSending ? null : <ShowRecord record={popupRecord} />}
                footer={<Button onClick={cancelPopupHandler}> {t('close')} </Button>}
            />
            <Grid container>
                <Grid item sm>
                    <List>
                        {
                            records.map((fullRecord, index) => (
                                <RecordListElement
                                    key={index}
                                    editUrl={'/cataloging/edit/' + fullRecord.id}
                                    fullRecord={{ ...fullRecord }}
                                    linkEntry={linkEntry}
                                    showPopupHandler={showPopupHandler}
                                    cloneRecordHandler={cloneRecordHandler}
                                    activateRecordHandler={activateRecordHandler}
                                    setShowHandler={setShowHandler}
                                />
                            ))
                        }
                    </List>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default React.memo(RecordList, (prev, next) => (
    isEqual(prev.records, next.records)
));