import React, { useState, useEffect } from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import styles from './Template.module.scss';
import { useTranslator } from '../../../shared/translate';
import TemplateTable from '../../../components/Templates/Table/TemplateTable';
import TemplateHeader from '../../../components/Templates/Header/TemplateHeader';
import { getTemplateCount, getTemplates } from '../../../api/templates';
// import TemplateContext from '../../../context/template';
import ConfigContext from '../../../context/snackbar-context';
import { camelToKebab, makeCancelable } from '../../../shared/util';
import { permissions } from '../../../shared/permissions';
import { useAuth } from '../../../shared/auth';
import SuccessSnackbar from '../../../components/UI/Snackbar/Success';
// import useWindowDimensions from '../../../hooks/useWindowDimensions';

const TABLE_COLUMNS = ["title", "description", "metadataFormat", "type", "status", "operations"];

const Template = props => {
  const trans = useTranslator();

  // const { 
  //   pageNumber,
  //   setPageNumber,
  // } = React.useContext(TemplateContext);
  
  const {
    showSnackbar,
    setShowSnackbar,
  } = React.useContext(ConfigContext);

  // const { height: windowHeight } = useWindowDimensions();
  const auth = useAuth();
  const [count, setCount] = useState(0);
  const [tableItems, setTableItems] = useState([]);
  const [, setPaginationItems] = useState([]);
  const [numRows, setNumRows] = useState(0);
  const [numOfPages, setNumOfPages] = useState(1);
  const header = TABLE_COLUMNS.map(key => {
    if ("title" === key) {
      key = "template.table.title";
    }
    return trans(camelToKebab(key));
  });

  // const { history: propsHistory, location: propsLocation } = props;

  useEffect(() => {
    const { cancel, promise } = makeCancelable(getTemplateCount());

    promise
      .then(response => {
        setCount(response.count);
      }).catch(error => {
        console.log(error);
      });

    return () => {
      cancel();
    }
  }, [])

  // useEffect(() => {}, []);

  useEffect(() => {
    const containerHeight = Math.floor(document.getElementById('templateContainer').clientHeight) - 120;
    const numRows = Math.floor(containerHeight / 63);
    setNumRows(numRows);
  }, []);

  useEffect(() => {
    if (!count || !numRows) { return; }
    setNumOfPages(Math.ceil(count / numRows));
  }, [count, numRows]);

  // useEffect(() => {
  //   let pageNumber = Number.parseInt(propsLocation.pathname.split('/').slice(-1)[0]);
  //   if (pageNumber < 1) {
  //     propsHistory.replace('/config/template/1');
  //   }
  //   if (count && pageNumber > numOfPages) {
  //     propsHistory.replace('/config/template/'+numOfPages);
  //   }
  //   setPageNumber(pageNumber);
  // }, [count, numOfPages, propsLocation.pathname, propsHistory, setPageNumber]);

  useEffect(() => {
    const paginationItems = [];
    for (let i = 1; i < numOfPages + 1; i++) {
      paginationItems.push(
        <div>TODO</div>
        // <LinkContainer key={i} to={"/config/template/" + i}>
        //   <Pagination.Item>
        //     {i}
        //   </Pagination.Item>
        // </LinkContainer>
      );
    }

    // const offset = (pageNumber-1)*numRows || 0;
    const { cancel, promise } = makeCancelable(getTemplates());

    promise
      .then(response => {
        setTableItems(response.map(elem => {
          const item = {};
          TABLE_COLUMNS.forEach(key => {
            item[key] = elem[key];
          });
          item.operations = (
            <React.Fragment>
              {auth.isGranted(permissions.CONFIG_TEMPLATE_UPDATE)
                ? <NavLink to={`/config/template/${elem.id}/edit`} style={{textDecoration: 'none'}}>
                  <Button className={styles.mainBlueButton}>{trans('template.table.edit')}</Button>
                </NavLink>
                : null}
            </React.Fragment>
          );
          return item;
        }));
      })
      .catch(error => {
        console.log(error);
      })
      ;

    setPaginationItems(paginationItems);

    return () => {
      cancel();
    }
  }, [numOfPages, auth, trans]);

  return (
    <React.Fragment>

      <TemplateHeader
        title={trans('template.header.title')}
        returnUrl="/config"
      />
      <Grid container justify="center" spacing={2}>
        <Grid item md={10} sm={12} style={{ margin: '0 auto' }}>
          <div id="templateContainer" >
            <div>
              {/* <Button style={{marginBottom: '0.5rem'}}><T>template.container.new</T></Button> */}
              <TemplateTable header={header} data={tableItems} />
            </div>
          
          </div>
        </Grid>
        {/* <div style={{marginTop: '50px'}}>
          <TablePagination >
            {paginationItems}
          </TablePagination>
        </div> */}
      </Grid>
      <SuccessSnackbar
        message={<span>{trans('save_successful')}</span>}
        showSnackbar={showSnackbar}
        setShowSnackbar={setShowSnackbar}
      />
    </React.Fragment>
  )
}

export default withRouter(Template);
