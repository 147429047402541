import React, { useState, useEffect } from 'react'
import { getCollection } from '../../../../../api/valueset/collection'

const ListAddedCollection = (props) => {
    const { collectionId } = props

    const [collectionData, setCollectionData] = useState(null)

    useEffect(() => {
        getCollection(collectionId).then(r => setCollectionData(r))
    }, [collectionId])

    if (collectionData === null || typeof collectionData === 'undefined') return null
    return (
        <div>
            - {collectionData.name}
        </div>
    )
}

export default ListAddedCollection;
