import React from 'react';
import { useContext, useEffect } from 'react'

import { LanguageContext } from '../context/language-context';
import fetchLanguages from '../api/languages';
import Spinner from '../components/UI/Spinner/Spinner';

const LanguageHoc = props => {
    const [fetched, setFetched] = React.useState(false);
    const languageContext = useContext(LanguageContext);
    useEffect(() => {
        if (fetched) { return; }
        fetchLanguages()
            .then(response => {
                setFetched(true);
                languageContext.setLanguages(response);
            })
            .catch(err => {
                setFetched(true);
                throw err;
            })
    }, [fetched, languageContext]);

    return fetched ? props.children : <Spinner />;
}

export default LanguageHoc;
