import React, { useState, useEffect, useCallback } from 'react';
import useSmartRequest from '../../../../hooks/useSmartRequest';
import { Grid } from '@material-ui/core';
import { searchReader, deleteRecord } from '../../../../api/reader_service/readerService';
import SearchSimple from './SearchSimple';
import useKeyPress from '../../../../hooks/useKeyPress';
import ReaderList from './ReaderList';
import Spinner from '../../../../components/UI/Spinner/Spinner';

const TABLE_COLUMNS = ["lastname", "firstname", "status", "readerType"];

const List = () => {

    // search
    const { 
        sendRequest: sendRequestSearch, 
        //isSending: isSendingSearch 
    } = useSmartRequest(searchReader);
    const [filterKeyword, setFilterKeyword] = useState('');
    const [searchParams, setSearchParams] = useState({});

    const itemPerPage = 10;
    const [isLoading, setIsLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const [records, setRecords] = useState(null);
    // const [
    //     recordCount, 
    //     setRecordCount
    // ] = useState(0);

    
    useEffect(() => {
      setSearchParams({
          filterKeyword: filterKeyword,
          offset: (currentPage - 1) * itemPerPage,
          limit: itemPerPage,
      });
      
    }, [filterKeyword, currentPage])

    
    const handleSearchSend = () => {
        setCurrentPage(1);
        reloadResults(
            { ...searchParams, offset: 0 }
        )
    };
    useKeyPress('Enter', handleSearchSend);
    
    const reloadResults = useCallback(
      (searchParamsInput = searchParams) => {
          setIsLoading(true);
          sendRequestSearch(searchParamsInput).then(r => {
                if (r !== null && typeof r !== 'undefined') {
                    setRecords(r.records);
                    // setRecordCount(r.recordCount)
                }
                setIsLoading(false);
            });
          
      },
      [sendRequestSearch, searchParams],
  );

  const removeRecordHandler = useCallback(
      (recordId) => {
          deleteRecord(recordId).then(res => {
              reloadResults();
          })
      },
      [reloadResults],
  );


    return (
        <React.Fragment>
            <Grid container style={{ marginTop: "20px" }}>
                <Grid item md={12} sm={12} style={{ margin: "0 auto" }}>
                    <SearchSimple
                        filterKeyword={filterKeyword}
                        setFilterKeyword={setFilterKeyword}
                        buttonClick={handleSearchSend}
                        label="search"
                        buttonLabel="search"
                    />
                </Grid>
            </Grid>
            <Grid container justify="center" style={{ marginTop: "20px" }}>
                <Grid item md={12} sm={12} style={{ margin: '0 auto' }}>
                    <React.Fragment>
                        <div style={!isLoading ? { display: 'none' } : { display: 'block' }}><Spinner /></div>
                            {records === null
                                ? null
                                : <React.Fragment>
                                    <div style={isLoading ? { display: 'none' } : {}}>
                                        <ReaderList
                                            records={records}
                                            columns={TABLE_COLUMNS}
                                            deleteHandler={removeRecordHandler}
                                        />
                                    </div>
                                </React.Fragment>
                            }
                    </React.Fragment>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default List;
