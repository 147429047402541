import React, { useState, useEffect, useContext } from 'react';
import {
    Grid,
  } from '@material-ui/core';
import { useTranslator } from '../../../../shared/translate';

import SearchSimple from '../../Reader/List/SearchSimple';
import TableGrid from '../TableGrid';
import { getReturnedLoans, returnItem } from '../../../../api/reader_service/readerService';
import { dateFormatString } from '../../../../shared/util';
import ConfigContext from '../../../../context/snackbar-context';
import { useAuth } from '../../../../shared/auth';
import { permissions } from '../../../../shared/permissions';

const TABLE_COLUMNS = [
    { label: "title", map: "title" },
    { label: "barcode", map: "barcode" },
    { label: "loan_date", map: "loanDate", format: dateFormatString },
    { label: "expiry_date", map: "expiryDate", format: dateFormatString },
    { label: "return_date", map: "returnDate", format: dateFormatString },
];

const ReturnTab = (props) => {

    const { readerId } = props;

    const trans = useTranslator();
    const auth = useAuth();

    const { setShowSnackbar } = useContext(ConfigContext);

    const [loanBarcode, setLoanBarcode] = useState('');
    const [returnedLoans, setReturnedLoans] = useState([]);

    const handleReturn = () => {
        returnItem({
            barcode: loanBarcode,
            readerId: readerId
        }).then(res => {
            setShowSnackbar(true);
            loadRecords();
        });
    };

    const loadRecords = () => {
        getReturnedLoans(readerId).then(res => {
            setReturnedLoans(res);
        });
    }

    useEffect(() => {
        loadRecords();
    }, []);

    return (
        <React.Fragment>
            {auth.isGranted(permissions.READER_SERVICE_RETURN_CREATE) ?
                <Grid container style={{ marginTop: "20px" }}>
                    <Grid item md={12} sm={12} style={{ margin: "0 auto" }}>
                        <SearchSimple
                            label={trans("barcode")}
                            buttonLabel={trans("return")}
                            filterKeyword={loanBarcode}
                            setFilterKeyword={setLoanBarcode}
                            buttonClick={handleReturn}
                        />
                    </Grid>
                </Grid>
            : null }
            <Grid container justify="center" style={{ marginTop: "20px" }}>
                <Grid item md={12} sm={12} style={{ margin: '0 auto' }}>
                    <TableGrid 
                        records={returnedLoans}
                        columns={TABLE_COLUMNS}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default ReturnTab;