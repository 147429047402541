export const permissions = {
    CATALOG_READ: 'CATALOG.READ',
    CATALOGING_READ: 'CATALOGING.READ',

    CONFIG_METADATAPROFILE_UPDATE: 'CONFIG.METADATAPROFILE_UPDATE',

    CONFIG_VALUESET_READ: 'CONFIG.VALUESET_READ',
    CONFIG_VALUESET_COLLECTION_CREATE: 'CONFIG.VALUESET_COLLECTION_CREATE',
    CONFIG_VALUESET_COLLECTION_READ: 'CONFIG.VALUESET_COLLECTION_READ',
    CONFIG_VALUESET_COLLECTION_UPDATE: 'CONFIG.VALUESET_COLLECTION_UPDATE',

    CONFIG_TEMPLATE_READ: 'CONFIG.TEMPLATE_READ',
    CONFIG_TEMPLATE_UPDATE: 'CONFIG.TEMPLATE_UPDATE',

    READER_SERVICE_READ: 'READER_SERVICE.READ',
    READER_SERVICE_READER_CREATE: 'READER_SERVICE.READER_CREATE',
    READER_SERVICE_READER_UPDATE: 'READER_SERVICE.READER_UPDATE',
    READER_SERVICE_READER_DELETE: 'READER_SERVICE.READER_DELETE',
    READER_SERVICE_READER_READ: 'READER_SERVICE.READER_DETAIL_READ',

    READER_SERVICE_LOAN_READ: 'READER_SERVICE.LOAN_READ',
    READER_SERVICE_LOAN_CREATE: 'READER_SERVICE.LOAN_CREATE',

    READER_SERVICE_RETURN_READ: 'READER_SERVICE.RETURN_READ',
    READER_SERVICE_RETURN_CREATE: 'READER_SERVICE.RETURN_CREATE',

    READER_SERVICE_REQUEST_READ: 'READER_SERVICE.REQUEST_READ',
    READER_SERVICE_REQUEST_CREATE: 'READER_SERVICE.REQUEST_CREATE',

    // INVENTORY MODULE
    INVENTORY_READ: 'INVENTORY.READ',

    INVENTORY_HOLDING_READ: 'INVENTORY.HOLDING_READ',
    INVENTORY_HOLDING_CREATE: 'INVENTORY.HOLDING_CREATE',
    INVENTORY_HOLDING_UPDATE: 'INVENTORY.HOLDING_UPDATE',
    INVENTORY_HOLDING_DELETE: 'INVENTORY.HOLDING_DELETE',

    INVENTORY_ITEM_READ: 'INVENTORY.ITEM_READ',
    INVENTORY_ITEM_CREATE: 'INVENTORY.ITEM_CREATE',
    INVENTORY_ITEM_UPDATE: 'INVENTORY.ITEM_UPDATE',
    INVENTORY_ITEM_DELETE: 'INVENTORY.ITEM_DELETE',
    INVENTORY_ITEM_HISTORY_READ: 'INVENTORY.ITEM__HISTORY_READ',

    // ACQUISITION
    ACQUISITION_READ: 'ACQUISITION.READ',

}