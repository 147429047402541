import React from 'react'
import { Route, Switch } from 'react-router-dom';
import List from './List/List';
import BaseContainer from '../../hoc/BaseContainer/BaseContainer';
import Bibliographic from './Bibliographic/Bibliographic';
import Holding from './Holding/Holding';
import Item from './Item/Item/Item';
import { useAuth } from '../../shared/auth';
import { permissions } from '../../shared/permissions';

const Catalog = () => {
    const auth = useAuth();


    let routes = (
        <BaseContainer>
            <Switch>
                <Route path="/catalog/list" exact={true} component={List} />
                {auth.isGranted(permissions.INVENTORY_HOLDING_READ) ? <Route path="/catalog/bibliographic/:biblioghrapicId" exact={true} component={Bibliographic} />: null}
                {auth.isGranted(permissions.INVENTORY_HOLDING_READ) && auth.isGranted(permissions.INVENTORY_ITEM_READ) ? <Route path="/catalog/holdings/:holdingId" exact={true} component={Holding} /> : null}
                {auth.isGranted(permissions.INVENTORY_ITEM_READ) ? <Route path="/catalog/items/:itemId" exact={true} component={Item} /> : null}
                {auth.isGranted(permissions.INVENTORY_ITEM_UPDATE) ? <Route path="/catalog/items/:itemId/edit" exact={true} render={(props) => <Item {...props} isEdit={true} />} /> : null}
                <Route path="/catalog" component={List} />
            </Switch>
        </BaseContainer>
    );
    return routes
}

export default Catalog
