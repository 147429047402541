import React, { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { getFormats } from '../../../api/marc21/formats'
import styles from './MetadataProfile.module.scss';
import { useTranslator } from '../../../shared/translate';
import Close from '../../../assets/icons/closeX.svg';
import { dateFormatString, makeCancelable } from '../../../shared/util';

const MetadataProfile = () => {

  const [metadataProfilesState, setMetadataProfilesState] = useState([])
  const [error, setError] = useState();
  useEffect(() => {
    const { cancel, promise } = makeCancelable(getFormats());
    
    promise
      .then(data => {
        setMetadataProfilesState(data)
      })
      .catch(err => {
        console.log(err);
        setError(err.message);
      })
    ;

    return () => {
      cancel();
    }
  }, [])

  const t = useTranslator()

  const metadataProfileLinks = (profile) => {
    switch (profile) {
      case 'marc21.bibliographic':
        return <NavLink to='/config/metadata-profile/marc21/bibliographic'>{t(profile)}</NavLink>
      case 'marc21.authority':
        return <NavLink to='/config/metadata-profile/marc21/authority/person'>{t(profile)}</NavLink>
      case 'marc21.holding':
        return <NavLink to='/config/metadata-profile/marc21/holding'>{t(profile)}</NavLink>
      default:
        return profile;
    }
  }

  return error || (
    <React.Fragment>
      <Grid container justify="center" spacing={5} className={styles.cardHeader} style={{ marginTop: "0px" }}>
        <Grid item sm={11}>
          {t('metadata-profiles')}
        </Grid>
        <Grid item sm={1}>
          <div>
            <Link to="/">
              <img className={styles.Image} src={Close} alt="" />
            </Link>
          </div>
        </Grid>
      </Grid>

      <Grid container justify="center" spacing={2} style={{ marginTop: "50px" }}>
        <Grid item md={10} sm={12} style={{ margin: '0 auto' }}>
          <Paper>
            <Table>
              <TableHead className={styles.myTableHeadStyle}>
                <TableRow>
                  <TableCell variant={'head'}>{t('profile')}</TableCell>
                  <TableCell variant={'head'}>{t('metadata-format')}</TableCell>
                  <TableCell variant={'head'}>{t('type')}</TableCell>
                  <TableCell variant={'head'}>{t('status')}</TableCell>
                  <TableCell variant={'head'}>{t('last-modified')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  metadataProfilesState.map(mp => (
                    <TableRow className={styles.tableHoverStyle} key={mp.id}>
                      <TableCell className={styles.paddingStyle} variant={'body'}>{metadataProfileLinks(mp.profile)}</TableCell>
                      <TableCell className={styles.paddingStyle} variant={'body'}>{mp.metadataFormat}</TableCell>
                      <TableCell className={styles.paddingStyle} variant={'body'}>{t(mp.type)}</TableCell>
                      <TableCell className={styles.paddingStyle} variant={'body'}>{mp.status ? t('active') : t('not-active')}</TableCell>
                      <TableCell className={styles.paddingStyle} variant={'body'}>{dateFormatString(mp.lastModified)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default MetadataProfile;
