import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ConfigContext from '../../../context/snackbar-context';
import Editor from '../../../components/Editor/Editor'
import { useTranslator } from '../../../shared/translate'
import Close from '../../../assets/icons/closeX.svg';
import Popup from '../../../components/UI/Popup/Popup';
import { getHoldingSchema } from '../../../api/marc21/holding/holdingSchema';
import { getHoldingRecord, updateHoldingRecord } from '../../../api/marc21/holding/holdingRecords';
import { addHelperFields, removeHelperFields } from '../../../shared/listUtil';

const useStyles = makeStyles({
    inputStyle: {
        padding: 10,
    },

    cardHeader: {
        fontSize: 18,
        fontWeight: 600,
        color: '#000',
        margin: "20px 0",
        textAlign: 'center',
        borderBottom: '1px solid #c6c6c6',
        a: {
            fontWeight: 400,
            fontSize: 16,
            color: '#2b75bb',
            textDecoration: 'none',
        }
    },
    Image: {
        position: 'relative',
        maxHeight: 25,
        marginRight: '.25rem',
        borderRadius: 6,
    },
    marginTop: {
        marginTop: 10,
        marginBottom: 10,
    },
    myRedButton: {
        backgroundColor: '#990000',
        borderRadius: 6,
        color: 'white',
        fontSize: 14,
        textTransform: 'capitalize',
        padding: '4px 14px',
        fontFamily: 'Source Sans Pro',
        fontWeight: 500,
        textDecoration: 'none',
        marginLeft: 10,
        '&:hover': {
            background: '#990000',
            textDecoration: 'none',
        }
    },
    myBlueButton: {
        backgroundColor: '#2b75bb',
        borderRadius: 6,
        color: 'white',
        fontSize: 14,
        textTransform: 'capitalize',
        padding: '4px 14px',
        fontFamily: 'Source Sans Pro',
        fontWeight: 500,
        '&:hover': {
            backgroundColor: '#2b75bb',
            textDecoration: 'none',
        }
    },
    linkStyle: {
        textDecoration: 'none',
    },
    alignRight: {
        textAlign: 'right',
    },
});

const HoldingEdit = (props) => {
    const classes = useStyles();
    const [holding, setHolding] = useState(null)
    const [metadataProfile, setMetadataProfile] = useState(null)
    const [showPopup, setShowPopup] = useState(false);
    const { setShowSnackbar } = React.useContext(ConfigContext);

    const holdingId = props.match.params.holdingId
    const entityType = props.match.params.type

    const t = useTranslator()
    useEffect(() => {
        getHoldingRecord(holdingId).then(res => {
            if (res.record) {
                res.record = addHelperFields(res.record);
            }
            setHolding(res)
        })
    }, [holdingId])

    useEffect(() => {
        getHoldingSchema().then(res => setMetadataProfile(res))
    }, [])

    const setFullRecordHandler = input => setHolding(prev => {
        if (input instanceof Function) {
            return { ...prev, record: input(prev.record) };
        } else {
            return { ...prev, record: { ...input } };
        }
    });

    const saveRecordHandler = () => {
        updateHoldingRecord(holdingId, { ...holding, record: removeHelperFields(holding.record) }).then(r => {
            setShowSnackbar(true);
            props.history.push(`/catalog/bibliographic/${holding.bibliographic}`);
        });
    }

    if (holding === null) { return null }

    return (
        <React.Fragment>
            <Grid container justify="center" spacing={2} className={classes.cardHeader} style={{ marginTop: "20px" }}>
                <Grid item sm={11}>{t('edit')}</Grid>
                <Grid item sm={1}>
                    <Link to={'/catalog/bibliographic/' + holding.bibliographic} ><img className={classes.Image} src={Close} alt="" /></Link>
                </Grid>
            </Grid>

            <Grid container justify="center" spacing={2} style={{ marginTop: "20px" }}>
                <Grid item md={9} sm={10}>
                    <Editor
                        style={{ width: 'calc(100% - 1rem)' }}
                        record={holding ? holding.record : null}
                        setRecord={setFullRecordHandler}
                        metadataProfile={metadataProfile}
                    />
                </Grid>
            </Grid>
            <Grid container justify="center" className={classes.marginTop} >
                <Grid item md={10} className={[classes.alignRight, classes.marginTop].join(' ')}>
                    <Button
                        onClick={saveRecordHandler}
                        className={classes.myBlueButton}
                    >{t('save')}
                    </Button>
                    <Button
                        onClick={() => setShowPopup(true)}
                        className={classes.myRedButton}
                    >{t('dismiss')}
                    </Button>
                </Grid>
            </Grid>
            <Popup
                show={showPopup}
                cancel={() => setShowPopup(false)}
                save={() => props.history.push(`/config/value-set/entity/${entityType}`)}
                title={t('dismiss_confirmation')}
            />
        </React.Fragment>
    )
}

export default withRouter(HoldingEdit);
