import React from 'react'

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import RemoveIcon from '@material-ui/icons/Remove';
import { getTitleFromAuthorityType } from '../../../../../shared/recordUtil';

const CollectionEditRow = (props) => {
    const { entity, removeEntity } = props;
    return (
        <ListItem>
            <ListItemIcon onClick={removeEntity} style={{ cursor: 'pointer' }}>
                <RemoveIcon />
            </ListItemIcon>
            <ListItemText
                primary={entity ? getTitleFromAuthorityType(entity.record, entity.type) : ''}
            />
        </ListItem>
    )
}

export default CollectionEditRow