import React from 'react'
import { Route, Switch } from 'react-router-dom';
import BaseContainer from '../../hoc/BaseContainer/BaseContainer';
import { useAuth } from '../../shared/auth';
import { permissions } from '../../shared/permissions';
import { redirect } from '../../shared/util';
import Reader from './Reader/Reader';
import ReaderForm from './Reader/ReaderForm/ReaderForm';
import LoanPage from './LoanPage/LoanPage';

const Loan = () => {
    const auth = useAuth();
    let routes = null;
    if (auth.isGranted(permissions.READER_SERVICE_READ)) {
        routes = (
            <BaseContainer>
                <Switch>
                    <Route path="/reader_service" exact={true} component={Reader} />
                    {auth.isGranted(permissions.READER_SERVICE_READER_CREATE) ? 
                        <Route path="/reader_service/new/reader" exact={true} component={ReaderForm} /> 
                    : null}
                    {auth.isGranted(permissions.READER_SERVICE_READER_READ) ? 
                        <Route path="/reader_service/reader/:id" exact={true} component={ReaderForm} /> 
                    : null}
                    {auth.isGranted(permissions.READER_SERVICE_LOAN_READ) ? 
                        <Route path="/reader_service/loan/:id" exact={true} render={(props) => <LoanPage {...props} activeTab={0} />} /> 
                    : null}
                    {auth.isGranted(permissions.READER_SERVICE_RETURN_READ) ? 
                        <Route path="/reader_service/return/:id" exact={true} render={(props) => <LoanPage {...props} activeTab={1} />} />
                    : null}
                    {auth.isGranted(permissions.READER_SERVICE_REQUEST_READ) ? 
                        <Route path="/reader_service/request/:id" exact={true} render={(props) => <LoanPage {...props} activeTab={2} />} />
                    : null}
                </Switch>
            </BaseContainer>
        );
    } else {
       redirect('/');
    }
    return routes;
};

export default Loan;