import axios from '../../settings/axios-backend';
import urls from '../../settings/urls';

export const getRecord = (id) => {
    const url = `${urls.RECORDS}/id/${id}`
    return axios.get(url)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

export const getRecords = () => {
    return axios.get(urls.RECORDS)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

export const createRecord = data => {
    return axios.post(urls.RECORDS, data)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};


export const updateRecord = (id, data) => {
    const url = `${urls.RECORDS}/id/${id}`
    return axios.put(url, data)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
}

export const activateRecord = id => {
    const url = `${urls.RECORDS}/activate/${id}`
    return axios.put(url)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
}

export const deleteRecord = (id) => {
    const url = `${urls.RECORDS}/id/${id}`
    return axios.delete(url)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            console.log(error.message)
            throw error;
        });
};

export const cloneRecord = (id) => {
    const url = `${urls.RECORDS}/clone/${id}`
    return axios.get(url)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            console.log(error.message)
            throw error;
        });
};
