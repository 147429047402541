import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReplayIcon from '@material-ui/icons/Replay';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import { useTranslator } from '../../../shared/translate';
import TabPanel from './TabPanel';
import LoanTab from './Loan/LoanTab';
import RequestTab from './Request/RequestTab';
import ReturnTab from './Return/ReturnTab';
import { useAuth } from '../../../shared/auth';
import { permissions } from '../../../shared/permissions';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  TabHeader: {
    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)"
  }
}));

const LoanPageTabs = (props) => {

  const { 
    readerId,
    activeTab,
   } = props;

  const auth = useAuth();
  const classes = useStyles();
  const [value, setValue] = React.useState(activeTab ? activeTab : 0);

  const trans = useTranslator();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }

  return (
    <div className={classes.root}>
      <div className={classes.TabHeader}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab label={trans("loans")} icon={<LibraryBooksIcon />} {...a11yProps(0)} />
          <Tab label={trans("returns")} icon={<ReplayIcon />} {...a11yProps(1)} />
          <Tab label={trans("requests")} icon={<HowToRegIcon />} {...a11yProps(2)} />
        </Tabs>
      </div>
      
      <TabPanel value={value} index={0}>
        {auth.isGranted(permissions.READER_SERVICE_LOAN_READ) ?
          <LoanTab readerId={readerId} />
        : null }
      </TabPanel>
      <TabPanel value={value} index={1}>
        {auth.isGranted(permissions.READER_SERVICE_RETURN_READ) ?
          <ReturnTab readerId={readerId}/>
        : null }
      </TabPanel>
      <TabPanel value={value} index={2}>
        {auth.isGranted(permissions.READER_SERVICE_REQUEST_READ) ?
          <RequestTab readerId={readerId}/>
        : null }
      </TabPanel>
    </div>
  );
}

export default LoanPageTabs;
