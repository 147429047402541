import React from 'react'
import EntityList from '../../EntityList/EntityList'
import { authorityRecordType } from '../../../../../../shared/parameters/authorityRecordTypes'

import classes from './AuthorityGeo.module.scss'
import { useTranslator } from '../../../../../../shared/translate'
import { addHelperFields } from '../../../../../../shared/listUtil'

const AuthorityGeo = props => {
    const { 
        newlySelectedEntities,
        setNewlySelectedEntities, 
        alreadySelectedEntities,
    } = props;
    const [onnSelected, setOnnSelected] = React.useState(false);
    const t = useTranslator()
    const prototype = addHelperFields({
        record: {
            leader: '',
            controlfields: [],
            datafields: [
                {
                    tag: '151',
                    indicator1: ' ',
                    indicator2: ' ',
                    subfields: [
                        {
                            code: 'a',
                            value: ''
                        }
                    ]
                }
            ]
        }
    })
    return (
        <EntityList
            title={<h3 className={classes.myFontStyle}>{t('namespace-geo')}</h3>}
            type={authorityRecordType.GEO}
            prototype={prototype}
            listCols={[
                {
                    label: t('authority.geo.record-id'),
                    tag: onnSelected ? '035' : '001',
                    code: onnSelected ? 'a' : '',
                },
                {
                    label: t('authority.geo.name'),
                    tag: '151',
                    code: 'a',
                }
            ]}
            newlySelectedEntities={newlySelectedEntities}
            setNewlySelectedEntities={setNewlySelectedEntities}
            alreadySelectedEntities={alreadySelectedEntities}
            onnSelected={onnSelected}
            setOnnSelected={setOnnSelected}
            searchOnn
        />
    )
}

export default AuthorityGeo
