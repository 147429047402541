import React from 'react'
import { Translate as T } from '../../../shared/translate';
import {
    Select,
    MenuItem,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({

    inputStyle: {
        padding: 10,
        fontSize: 14,
        fontWeight: 500,
    },
});

const InputTypeSelect = (props) => {
    const classes = useStyles();

    return (
        <Select
            fullWidth
            variant="outlined"
            inputProps={{
                className: classes.inputStyle
            }}
            onChange={props.onChange}
            value={props.value ? props.value : 'text'}
            disabled={props.disabled}
        >
            <MenuItem value="text">{T('text-field')}</MenuItem>
            <MenuItem value="date" >{T('date')}</MenuItem>
            <MenuItem value="datetime">{T('datetime')}</MenuItem>
            <MenuItem value="select">{T('dropdown-list')}</MenuItem>
            <MenuItem value="record">{T('record-connection')}</MenuItem>
            <MenuItem value="number">{T('number-field')}</MenuItem>
            <MenuItem value="regex">{T('regular-expression')}</MenuItem>
        </Select>
    )
}

export default InputTypeSelect