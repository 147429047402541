import React, { useState, useEffect } from 'react'

import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { AppBar } from '@material-ui/core';
import { useTranslator } from '../../../../shared/translate'
import { getItem } from '../../../../api/marc21/item/item';
import BackLink from '../../../../components/UI/Link/BackLink';
import GeneralViewTab from '../GeneralViewTab/GeneralViewTab';
import HistoryTab from '../HistoryTab/HistoryTab';
import GeneralEditTab from '../GeneralEditTab/GeneralEditTab';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import { useAuth } from '../../../../shared/auth';
import { permissions } from '../../../../shared/permissions';

const Item = (props) => {
    const { itemId } = props.match.params;
    const { isEdit } = props;

    const [item, setItem] = useState(null)
    const auth = useAuth()
    useEffect(() => { getItem(itemId).then(r => setItem(r)) }, [itemId])

    const t = useTranslator();

    const [activeTabIndex, setActiveTabIndex] = useState('general');

    const allTabs = () => {
        let tabs = [
            {
                key: 'general',
                label: t('item.tab.general'),
                element: <GeneralEditTab item={item} />,
                type: ['edit'],
            },
            {
                key: 'general',
                label: t('item.tab.general'),
                element: <GeneralViewTab item={item} />,
                type: ['view'],
            },
            {
                key: 'history',
                label: t('item.tab.history'),
                element: <HistoryTab item={item} />,
                type: ['view'],
                permissions: permissions.INVENTORY_ITEM_HISTORY_READ
            }
        ];

        const permmissionFilter = (tabs) => tabs.filter(t => {
            if (typeof t.permissions === 'undefined') {
                return true
            }
            if (auth.isGranted(t.permissions)) {
                return true
            }
            return false
        });

        const typeFilter = (tabs, type) => tabs.filter(t => {
            if (Array.isArray(t.type) && t.type.includes(type)) {
                return true
            } else {
                return false
            }

        });

        const type = isEdit ? 'edit' : 'view'
        tabs = typeFilter(tabs, type)
        tabs = permmissionFilter(tabs)

        return tabs
    }


    if (item === null) {
        return <Spinner />
    }

    return (
        <React.Fragment>
            <h1><BackLink to={'/catalog/holdings/' + item.holding} /> {isEdit ? t('item.edit-item-data') : t('item.view-item-data')}</h1>
            <AppBar position="static" color="default">
                <Tabs
                    value={activeTabIndex}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    {allTabs().map((tab, key) => (
                        <Tab key={key} label={tab.label} onClick={() => setActiveTabIndex(tab.key)} value={tab.key} />
                    ))}
                </Tabs>
            </AppBar>
            <Grid container>
                <Grid item xs={12}>
                    {allTabs().map((tab, key) => (
                        <React.Fragment key={key}>
                            {(tab.key === activeTabIndex)
                                ? tab.element
                                : null}
                        </React.Fragment>
                    ))}
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default Item
