import React from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import MetadataOperationButton from './MetadataOperationButton'
import { trueOrUndefined, dateFormatString, isEqual } from '../../../../../shared/util'
import { useTranslator } from '../../../../../shared/translate'

const MetadataProfileMarc21Row = (props) => {
    const {
        classes,
        mf
    } = props
    const trans = useTranslator()
    return (
        <TableRow className={classes.tableHoverStyle} key={mf.tag} >
            <TableCell className={[classes.paddingStyle, classes.MuiTableCellBody].join(' ')} variant={'body'}>{mf.tag}</TableCell>
            <TableCell className={classes.paddingStyle} variant={'body'}>{trans(mf.label)}</TableCell>
            <TableCell className={classes.paddingStyle} variant={'body'}>{mf.repeatable ? trans('repeatable') : trans('not-repeatable')}</TableCell>
            {/* <TableCell className={classes.paddingStyle} variant={'body'}>{trueOrUndefined(mf.required) ? trans('required') : trans('not-required')}</TableCell> */}
            <TableCell className={classes.paddingStyle} variant={'body'}>{trueOrUndefined(mf.status) ? trans('active') : trans('not-active')}</TableCell>
            <TableCell className={classes.paddingStyle} variant={'body'}>{typeof mf.lastModified == 'undefined' ? '-' : dateFormatString(mf.lastModified)}</TableCell>
            <TableCell className={classes.paddingStyle} variant={'body'} style={{ textAlign: 'right' }}>
                <MetadataOperationButton metadataProfile={mf} />
            </TableCell>
        </TableRow>
    )
}

export default React.memo(MetadataProfileMarc21Row, (prev, next) => {
    return isEqual(prev, next)
});