import React from 'react'
import { Button } from '@material-ui/core'
import classes from './Numbers.module.scss';
import { Translate as T } from '../../../shared/translate';

const Numbers = props => {

    const makeButton = (n) => {
        if (parseInt(n) === props.filterNumber) {
            return (
                <span key={n} className={classes.marginRight}>
                    <Button className={classes.mainBlueButton}>{n}xx</Button>
                </span>
            )
        } else {
            return (
                <span key={n} className={classes.marginRight}>
                    <Button  onClick={() => props.handleFilterNumber(n)}>{n}xx</Button>
                </span>
            )
        }
    }

    const all = () => {
        let returnValue = null;
        if (props.filterNumber === null) {
            returnValue = (
                <span key={'null'} className={classes.marginRight}>
                    <Button className={classes.mainBlueButton} onClick={() => props.handleFilterNumber('null')}><T>all</T></Button>
                </span>
            );
        } else  {
            returnValue = (
                <span key={'null'} className={classes.marginRight}>
                    <Button onClick={props.handleFilterNumber} title={'null'}><T>all</T></Button>
                </span>
            );
        }
        return returnValue;
    }


    return (
        <React.Fragment>
            {all()}
            {
                props.numbers.map(n => makeButton(n))
            }
            <br />
        </React.Fragment>
    )
}

export default Numbers
