import { useCallback, useRef } from 'react'
// import updateCallsignData from '../api/marc21/updateCallsignData';

export const useSmartRequest = myRequest => {

    const isSending = useRef(false);

    const sendRequest = useCallback((...requestData) => {
        // don't send again while we are sending
        if (isSending.current) { return new Promise((resolve, reject) => { return; }) };
        // update state
        isSending.current = true;
        // send the actual request
        return myRequest(...requestData)
            .finally(() => {
                // once the request is sent, update state again
                isSending.current = false;
            });
    }, [myRequest]) // update the callback if the state changes

    return {
        sendRequest,
        isSending: isSending.current
    }
}
export default useSmartRequest;