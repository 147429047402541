import React from 'react'
import ReactSelect from './ReactSelect'
import SimpleKeyboardLayouts from "simple-keyboard-layouts";
import { useTranslator } from '../../../shared/translate';

const VirtualKeyboardLanguageSelect = (props) => {
    const { value, onChange } = props
    // const [defaultLanguage, setValue] = useState(initialState)

    const t = useTranslator();
    const simpleKeyboardLayouts = new SimpleKeyboardLayouts();
    const allLayout = simpleKeyboardLayouts.get()

    return (
        <ReactSelect
            fullWidth
            variant="outlined"
            value={{
                value: value,
                label: t(value)
            }}
            onChange={(lang) => onChange(lang)}
            options={Object.keys(allLayout).map(language => ({
                value: language,
                label: t(language),
            })
            )}
        />
    )
}

export default VirtualKeyboardLanguageSelect
