import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Close from '../../../assets/icons/closeX.svg';
import styles from './TemplateHeader.module.scss';

const TemplateHeader = props => {
  return (
    <React.Fragment>
    <Grid container justify="center" className={styles.cardHeader} style={{marginTop: "20px"}}>
    <Grid item sm={11} style={{marginBottom: "20px"}}>
    {props.title}
    </Grid>
    <Grid item sm={1}>
        <Link to="/">
          <img className={styles.Image} src={Close} alt="" />
        </Link>
     
    </Grid>
 </Grid>

    <div className={styles.Header}>
      <div>
        <div className={styles.HeaderItem}>
          {props.buttons}
        </div>
      </div>
  </div>
</React.Fragment>
  )
};

export default TemplateHeader;