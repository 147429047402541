import React from 'react';

const Context = React.createContext({})

export const Provider = props => {
  const [ template, setTemplate ] = React.useState();
  const [ originalTemplate, setOriginalTemplate ] = React.useState(null);
  const [ metadataProfile, setMetadataProfile ] = React.useState(null);
  const [ pageNumber, setPageNumber ] = React.useState(0);

  // const setRecord = record => setTemplate(prevTemplate => ({...prevTemplate, template: {...record}}));
  // const setLeader = leader => setTemplate(prevTemplate => ({...prevTemplate, template: {...prevTemplate.template, leader: leader}}))
  // const setControlfields = controlfields => setTemplate(prevTemplate => ({...prevTemplate, template: {...prevTemplate.template, controlfields: [...controlfields]}}))
  
  const templateContext = {
    pageNumber: pageNumber,
    setPageNumber: setPageNumber,
    metadataProfile: metadataProfile,
    setMetadataProfile: setMetadataProfile,
    template: template,
    setTemplate: setTemplate,
    // setRecord: setRecord,
    originalTemplate: originalTemplate,
    setOriginalTemplate: setOriginalTemplate,
  }

  return (
    <Context.Provider value={templateContext}>
      {props.children}
    </Context.Provider>
  );
};

export const { Consumer } = Context;

export default Context;