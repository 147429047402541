import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import MenuIndex from './MenuIndex';
import { useTranslator } from '../../../../shared/translate';
import { Link } from 'react-router-dom'
import menuIndex from './MenuIndex';

const ValueSetMenu = (props) => {
    const t = useTranslator();
    const { currentPath } = props;

    const defaultSelected = () => {
        switch (currentPath) {
            case '/config/value-set/entity/person': return menuIndex.person;
            case '/config/value-set/entity/geo': return menuIndex.geo;
            case '/config/value-set/entity/corporate': return menuIndex.corporate;
            case '/config/value-set/entity/subject': return menuIndex.subject;
            case '/config/value-set/collection': return menuIndex.collection;
            default: break;
        }
    }

    const defaultOpenPropertyNamespace = () => {
        switch (currentPath) {
            case '/config/value-set/entity/person': return true;
            case '/config/value-set/entity/geo': return true;
            case '/config/value-set/entity/corporate': return true;
            case '/config/value-set/collection': return false;
            default: break;
        }
    }

    const defaultOpenPublicNamespace = () => {
        switch (currentPath) {
            case '/config/value-set/entity/subject': return true;
            default: break;
        }
    }

    const [openEntity, setOpenEntity] = useState(true);
    const [openPropertyNamespace, setOpenPropertyNamespace] = useState(defaultOpenPropertyNamespace());
    const [openPublicNamespace, setOpenPublicNamespace] = useState(defaultOpenPublicNamespace());
    const [selectedMenuIndex, setSelectedMenuIndex] = useState(defaultSelected());

    const useStyles = makeStyles(theme => ({

        noNested: {
            fontSize: 15,
            fontWeight: 600,
            color: 'black',
            '&:hover': {
                color: 'black',
                backgroundColor: 'rgba(37, 118, 195, 0.1)',
                textDecoration: 'none',
            },
        },
        nested: {
            paddingLeft: theme.spacing(4),
           
            '&:hover': {
                color: 'black',
                backgroundColor: 'rgba(37, 118, 195, 0.1)',
                textDecoration: 'none',
            },
            '&:active': {
                color: 'black',
                backgroundColor: 'rgba(37, 118, 195, 0.1)!important',
                textDecoration: 'none',

            },
        },
        nested2: {
            paddingLeft: theme.spacing(8),
            color: 'black',
            
            '&:hover': {
                color: 'black',
                backgroundColor: 'rgba(37, 118, 195, 0.1)',
                textDecoration: 'none',
            },
            '&:active': {
                color: 'black',
                backgroundColor: 'rgba(37, 118, 195, 0.1) !important',
                textDecoration: 'none',
            },
        },
    }));

    const classes = useStyles();

    return (
        <React.Fragment>
            <List
                component="nav"
            >
                <ListItem button onClick={() => setOpenEntity(!openEntity)} className={classes.noNested}>
                    <ListItemText primary={t('entities')} />
                    {openEntity ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                <Collapse in={openEntity} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button onClick={() => setOpenPropertyNamespace(!openPropertyNamespace)} className={classes.nested}>
                            <ListItemText secondary={t('namespace-property')} />
                            {openPropertyNamespace ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openPropertyNamespace} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <Link to={'/config/value-set/entity/person'} style={{textDecoration: 'none'}}>
                                    <ListItem
                                        button
                                        className={classes.nested2}
                                        selected={selectedMenuIndex === MenuIndex.person}
                                        onClick={() => setSelectedMenuIndex(MenuIndex.person)}
                                    >
                                        <ListItemText secondary={t('namespace-person')} />
                                    </ListItem>
                                </Link>
                                <Link to={'/config/value-set/entity/geo'} style={{textDecoration: 'none'}}>

                                    <ListItem
                                        button
                                        className={classes.nested2}
                                        selected={selectedMenuIndex === MenuIndex.geo}
                                        onClick={() => setSelectedMenuIndex(MenuIndex.geo)}
                                    >
                                        <ListItemText secondary={t('namespace-geo')} />
                                    </ListItem>
                                </Link>
                                <Link to={'/config/value-set/entity/corporate'} style={{textDecoration: 'none'}}>
                                    <ListItem
                                        button
                                        className={classes.nested2}
                                        selected={selectedMenuIndex === MenuIndex.corporate}
                                        onClick={() => setSelectedMenuIndex(MenuIndex.corporate)}
                                    >
                                        <ListItemText secondary={t('namespace-corporate')} />
                                    </ListItem>
                                </Link>

                            </List>
                        </Collapse>


                        <ListItem button onClick={() => setOpenPublicNamespace(!openPublicNamespace)} className={classes.nested}>
                            <ListItemText secondary={t('namespace-public')} />
                            {openPublicNamespace ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openPublicNamespace} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <Link to={'/config/value-set/entity/subject'} style={{textDecoration: 'none'}}>
                                    <ListItem
                                        button
                                        className={classes.nested2}
                                        selected={selectedMenuIndex === MenuIndex.subject}
                                        onClick={() => setSelectedMenuIndex(MenuIndex.subject)}
                                    >
                                        <ListItemText secondary={t('subject-word')} />
                                    </ListItem>
                                </Link>
                            </List>
                        </Collapse>
                    </List>
                </Collapse>

                <Divider />
                <Link to={'/config/value-set/collection'} style={{textDecoration: 'none'}}>
                    <ListItem
                        button
                        selected={selectedMenuIndex === MenuIndex.collection}
                        onClick={() => setSelectedMenuIndex(MenuIndex.collection)}
                        className={classes.noNested}
                    >
                        <ListItemText primary={t('collections')} />
                    </ListItem>
                </Link>
            </List>
        </React.Fragment >
    )
}



export default ValueSetMenu
