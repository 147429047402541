import React from 'react';

import { VALUE_LABEL_SEPARATOR } from '../../../../shared/recordUtil';
import { useTranslator } from '../../../../shared/translate';
import ReactSelect from '../../../UI/Input/ReactSelect';

const Indicator = props => {
  const { metadataProfile, indicator, setIndicator, error, showNewErrors } = props;

  const trans = useTranslator();

  const [ showErrors, setShowErrors ] = React.useState(false);
  React.useEffect(() => {
    if (showNewErrors) {
      setShowErrors(true);
    }
  }, [showNewErrors]);

  const getIndicatorSuggestions = metadataProfile => metadataProfile.codes.reduce((accumulator, current) => {
    if (false === current.status) {
      return accumulator;
    }
    accumulator.push({
      label: `${current.code.replace(/ /g, '#')} ${VALUE_LABEL_SEPARATOR} ${trans(current.label)}`,
      value: current.code,
    })
    return accumulator;
  }, []);

  if (!metadataProfile) {
    return '#'
  }

  if ('string' === typeof metadataProfile) {
    return metadataProfile.replace(/ /g, '#');
  }

  let valueObject = null
  if (indicator) {
    const selected = metadataProfile.codes.find(elem => elem.code === indicator)
    let label = trans(selected ? selected.label : 'invalid_value');

    valueObject = {
      label: `${indicator.replace(/ /g, '#')} ${VALUE_LABEL_SEPARATOR} ${label}`,
      value: indicator
    }
  } else {
    valueObject = null
  }

  const styles = error && showErrors ? {control: (base, state) => ({...base, border: '1px solid red'})} : undefined;

  return (
    <div>
      {/* {trans(metadataProfile.label)} */}
      <ReactSelect
        options={getIndicatorSuggestions(metadataProfile)}
        value={valueObject}
        onChange={event => setIndicator(event.value)}
        styles={styles}
        // filterOption={createFilter({
        //   matchFrom: 'start',
        // })}
      />
    </div>
  );
};

export default Indicator;