import React from 'react';
import {
    Grid,
    Select,
    TextField,
    MenuItem
 } from '@material-ui/core';
import { Translate as T } from '../../../shared/translate';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    mainBlueButton: {
        backgroundColor: '#2b75bb',
        borderRadius: 6,
        color: 'white',
        fontSize: 14,
        textTransform: 'capitalize',
        padding: '4px 14px',
        fontFamily: 'Source Sans Pro',
        fontWeight: 500,
        zIndex: 'inherit',
        '&:hover': {
            background: '#169bd5',
        },
    },
    inputStyle: {
        padding: 10,
        fontSize: 14,
        fontWeight: 500,
    },
});

const Search = (props) => {

    const classes = useStyles();
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item  sm ={6}>
                    <Select
                        onChange={props.handleFilterTypeChange}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                            className: classes.inputStyle,
                        }}
                        value={props.filterType}
                        >
                        <MenuItem value="callsign">{T('callsign')}</MenuItem>
                        <MenuItem value="label">{T('description')}</MenuItem>
                    </Select>
                </Grid>
                <Grid item sm ={6}>
                    <TextField
                        onChange={props.handleFilterKeywordChange}
                        value={props.filterKeyword}
                        fullWidth
                        variant="outlined"
                        type="text"
                        inputProps={{
                            className: classes.inputStyle,
                        }}
                    />

                </Grid>
            </Grid>
            <div style={{ textAlign: 'right' }}>

            </div>
        </React.Fragment>
    )
}

export default Search
