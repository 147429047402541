import React from 'react'
import Grid from '@material-ui/core/Grid';
import {
    Button,
    Select,
    MenuItem
} from '@material-ui/core';
import { statusList } from '../../../../../shared/parameters/statusList'
import { certLevelList } from '../../../../../shared/parameters/certLevelList'
import { makeStyles } from '@material-ui/core/styles';
import { useTranslator } from '../../../../../shared/translate';

const useStyles = makeStyles({
    mainBlueButton: {
        backgroundColor: '#2b75bb',
        borderRadius: 6,
        color: 'white',
        fontSize: 14,
        textTransform: 'capitalize',
        padding: '4px 14px',
        fontFamily: 'Source Sans Pro',
        fontWeight: 500,
        zIndex: 'inherit',
        '&:hover': {
            background: '#2b75bb',
            opacity: 0.7,
        },
    },
    inputStyle: {
        padding: 10,
        fontSize: 14,
        fontWeight: 500,
    },
    mySubfontH4: {
        fontSize: 14,
        fontWeight: 600,
        margin: 0,
    }
});

const ActionButtons = (props) => {
    const classes = useStyles();

    const {
        status,
        statusHandler,
        certLevel,
        certLevelHandler,
        toggleRecordConnections,
    } = props

    const t = useTranslator()


    return (
        <React.Fragment>

            <Grid container spacing={4}>
                <Grid item sm={3}>
                    <h4 className={classes.mySubfontH4}>{t('status')}</h4>
                    <Select
                        value={status ? status : statusList.active}
                        onChange={(event) => statusHandler(event.target.value)}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                            className: classes.inputStyle,
                        }}
                    >
                        {Object.values(statusList).map((st, stKey) => (
                            <MenuItem key={stKey} value={st}>{t(st)}</MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item sm={3}>
                    <h4 className={classes.mySubfontH4}>{t('certification-level')}</h4>
                    <Select
                        value={certLevel ? certLevel : certLevelList.not_public}
                        onChange={(event) => certLevelHandler(event.target.value)}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                            className: classes.inputStyle,
                        }}
                    >
                        {Object.values(certLevelList).map((st, stKey) => (
                            <MenuItem key={stKey} value={st}>{t(st)}</MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item sm={3} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Button className={classes.mainBlueButton}
                            onClick={toggleRecordConnections}
                        >Rekordkapcsolatok</Button>
                    </div>
                </Grid>
                {/* <Grid item sm={3} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Button className={classes.mainBlueButton} >Mezőneves megjelenítés</Button>
                    </div>
                </Grid> */}
            </Grid>

        </React.Fragment >
    )
}

export default ActionButtons



