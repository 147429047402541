import axios from '../settings/axios-backend';
import urls from '../settings/urls';

export const recordsSearchSimple = (postData) => {
    console.log(postData)
    return axios.post(urls.RECORDS_SEARCH_SIMPLE, postData)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
}


export const recordsSearchComplex = (postData) => {
    console.log(postData)
    return axios.post(urls.RECORDS_SEARCH_COMPLEX, postData)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
}

export const recordsSearchFilterOptions = () => {
    return axios.get(urls.RECORDS_SEARCH_FILTER_OPTIONS)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
}

export const recordsSearchSortOptions = () => {
    return axios.get(urls.RECORDS_SEARCH_SORT_OPTIONS)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
}

export const recordsSearchId = id => {
    return axios.get(urls.RECORDS_SEARCH_ID, {
        params: {
            id: id
        }
    })
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
}

export const recordsSearchTitle = title => {
    return axios.get(urls.RECORDS_SEARCH_TITLE, {
        params: {
            title: title
        }
    })
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
}