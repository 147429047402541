import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';


import useSmartRequest from '../../../../hooks/useSmartRequest';
import { getCollection } from '../../../../api/valueset/collection';
import { getTitleFromAuthorityType } from '../../../../shared/recordUtil';
import { useTranslator } from '../../../../shared/translate';
import Close from '../../../../assets/icons/closeX.svg';
import classes from './CollectionShow.module.scss';
import { SYMBOL_ANTICLOCKWISE_TOP_SEMICIRCLE_ARROW } from '../../../../shared/symbols';

const CollectionShow = (props) => {
    const { id: collectionId } = props.match.params;

    const [collection, setCollection] = useState(null);
    const { sendRequest } = useSmartRequest(getCollection);

    useEffect(() => {
        sendRequest(collectionId).then(res => setCollection(res))
    }, [collectionId, sendRequest])

    const trans = useTranslator()

    if (collection === null) { return null }
    return (
        <React.Fragment>

            <Grid container justify="center" spacing={5} className={classes.cardHeader} style={{ marginTop: "0px" }}>
                <Grid item sm={11}>
                    <Tooltip title={trans('back-to-value-set-collection')}>
                        <Link to={'/config/value-set/collection/'}>{SYMBOL_ANTICLOCKWISE_TOP_SEMICIRCLE_ARROW}</Link>
                    </Tooltip>
                    {collection.name}
                </Grid>
                <Grid item sm={1}>
                        <Link to={'/config/value-set/collection'} ><img className={classes.Image} src={Close} alt="" /></Link>
                </Grid>
            </Grid>

            <Grid container justify="center" spacing={2} style={{ marginTop: "50px" }}>
                <Grid item sm={10} style={{ margin: '0 auto' }}>
                   {/*} Gyűjtemény neve: <h3>{collection.name}</h3>*/}
                    <Table>
                        <TableHead className={classes.myTableHeadStyle}>
                            <TableRow>
                                <TableCell variant={'head'}>{trans('entity-id')}</TableCell>
                                <TableCell variant={'head'}>{trans('entity-name')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {collection.entities.map((entity, key) => {
                                return (
                                    <TableRow className={classes.tableHoverStyle} key={key}>
                                        <TableCell variant={'body'}>{entity.id}</TableCell>
                                        <TableCell variant={'body'}>{getTitleFromAuthorityType(entity.record, entity.type)}</TableCell>
                                    </TableRow>
                                )
                            })}

                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default CollectionShow;
