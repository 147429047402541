import React from 'react'
import { Select, MenuItem } from '@material-ui/core';
import { useTranslator } from '../../../shared/translate';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({

    inputStyle: {
        padding: 10,
        fontSize: 14,
        fontWeight: 500,
    }
});

const SelectOrder = (props) => {
    const classes = useStyles();
    const {
        sortType,
        sortOrder,
        searchSortOptions,
        handleSortOrderChange
    } = props

    const t = useTranslator()
    const handleSortOrderChangeLocal = (value) => {
        const pieces = value.split('|')
        const sortTypeNew = pieces[0]
        const sortOrderNew = pieces[1]
        handleSortOrderChange(sortTypeNew, sortOrderNew)
    }

    return (
        <Select
            onChange={(event) => handleSortOrderChangeLocal(event.target.value)}
            value={sortType+'|'+sortOrder}
            fullWidth
            variant="outlined"
            inputProps={{
                className: classes.inputStyle,
            }}
            >
            {searchSortOptions.map((option, key) => {
                const arr = []
                arr.push(<MenuItem key={key+'1'} value={option.value + '|ASC'}>{t(option.label)} – {t('ascending')}</MenuItem>)
                arr.push(<MenuItem key={key+'2'} value={option.value + '|DESC'}>{t(option.label)} – {t('descending')}</MenuItem>)
                return arr
            })}
        </Select>

    )
}

export default SelectOrder
