import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom'

import {
    Tooltip,
    Grid,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Tabs,
    Tab
} from '@material-ui/core';

// import metadataProfileMarc21 from '../../../../../api/marc21/metadataProfileMarc21';
import ConfigContext from '../../../../../context/snackbar-context';
import Numbers from '../../../../../components/MetadataProfiles/Marc21/Numbers';
import Search from '../../../../../components/MetadataProfiles/Marc21/Search';
import { getFormats } from '../../../../../api/marc21/formats'
import metadataProfileMarc21 from '../../../../../api/marc21/bibliographic/bibliographicSchema';
import { deepCopy, dateFormat } from '../../../../../shared/util'
import { useTranslator } from '../../../../../shared/translate';
import Close from '../../../../../assets/icons/closeX.svg';

import { makeStyles } from '@material-ui/core/styles';
import SuccessSnackbar from '../../../../../components/UI/Snackbar/Success';
import MetadataProfileMarc21Row from './MetadataProfileMarc21Row';
import { getAuthoritySchemaByType } from '../../../../../api/marc21/authority/authoritySchema';
import { authorityRecordTypeArray } from '../../../../../shared/parameters/authorityRecordTypes';
import { getHoldingSchema } from '../../../../../api/marc21/holding/holdingSchema';
import { marc21Profiles } from '../../../../../shared/parameters/marc21Profiles';

const useStyles = makeStyles({
    mainBlueButton: {
        backgroundColor: '#2b75bb',
        borderRadius: 6,
        color: 'white',
        fontSize: 14,
        textTransform: 'capitalize',
        padding: '4px 14px',
        fontFamily: 'Source Sans Pro',
        fontWeight: 500,
        marginRight: 10,
        marginTop: 20,
        zIndex: 'inherit',
        textDecoration: 'none',
        '&:hover': {
            background: '#169bd5',
        },
    },
    myTableHeadStyle: {
        backgroundColor: '#e6e6e6',
    },
    cardHeader: {
        fontSize: 18,
        fontWeight: 600,
        color: '#000',
        margin: "20px 0",
        textAlign: 'center',
        borderBottom: '1px solid #c6c6c6',
    },

    Image: {
        position: 'relative',
        maxHeight: 25,
        marginRight: '.25rem',
        borderRadius: 6,
    },
    tableHoverStyle: {
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: 'rgba(37, 118, 195, 0.1)',
        }
    },
    paddingStyle: {
        padding: '10px !important',
    },
});


const MetadataProfileMarc21 = (props) => {

    const profileFormat = props.match.params.format
    const profileFormatType = props.match.params.type

    const classes = useStyles();

    const { showSnackbar, setShowSnackbar } = React.useContext(ConfigContext);


    const trans = useTranslator();

    const [marc21Fields, setMarc21Fields] = useState([])
    const [metadataProfile, setMetadataProfile] = useState([])

    const [filteredFields, setFilteredFields] = useState([])

    const [filterNumber, setFilterNumber] = useState(null)
    const [availableNumbers, setAvailableNumbers] = useState([])

    const [filterType, setFilterType] = useState('callsign')
    const [filterKeyword, setFilterKeyword] = useState('')

    useEffect(() => {
        getFormats()
            .then(data => {
                setMetadataProfile(data.filter(r => r.profile === 'marc21.' + profileFormat).shift());

                const defaultFieldsFilter = (fields) => {
                    const newFields = {};
                    for (const [key, item] of Object.entries(fields)) {
                        if (typeof item.tag !== 'undefined' && isNumeric(item.tag.charAt(0))) {
                            newFields[key] = item;
                        }
                    }
                    return newFields;
                }
                switch (profileFormat) {
                    case marc21Profiles.bibliographic:
                        metadataProfileMarc21()
                            .then(data => {
                                const fields = defaultFieldsFilter([data.leader, ...data.controlfields, ...data.datafields]);
                                setMarc21Fields(fields);
                            });
                        break;
                    case marc21Profiles.authority:
                        getAuthoritySchemaByType(profileFormatType)
                            .then(data => {
                                const fields = defaultFieldsFilter([data.leader, ...data.controlfields, ...data.datafields]);
                                setMarc21Fields(fields);
                            });
                        break;
                    case marc21Profiles.holding:
                        getHoldingSchema()
                            .then(data => {
                                const fields = defaultFieldsFilter([data.leader, ...data.controlfields, ...data.datafields]);
                                setMarc21Fields(fields);
                            });
                        break;
                    default: console.log('Undefined profileFormat: ' + profileFormat)
                }
            });
    }, [profileFormat, profileFormatType])


    useEffect(() => {
        const filterFieldsBySearch = (fields, type, keyword) => {
            if (typeof fields !== 'object' || typeof type !== 'string' || typeof keyword !== 'string' || keyword === '') {
                return fields;
            } else {
                let newFields = {};
                switch (type) {
                    case 'label':
                        for (const [key, item] of Object.entries(fields)) {
                            if (item.label && trans(item.label).includes(keyword)) {
                                newFields[key] = deepCopy(item);
                            }
                        }
                        break;
                    case 'callsign':
                        for (const [key, item] of Object.entries(fields)) {
                            if (item.tag.includes(keyword)) {
                                newFields[key] = deepCopy(item);
                            }
                        }
                        break;
                    default:
                        newFields = fields;
                }
                return newFields;
            }
        }
        // filter logic
        let filtered = marc21Fields;
        filtered = filterFieldsBySearch(filtered, filterType, filterKeyword);
        setAvailableNumbers(getAvailableNumbers(filtered));
        filtered = filterFieldsByNumber(filtered, filterNumber)
        setFilteredFields(filtered);
    }, [trans, marc21Fields, filterNumber, filterType, filterKeyword])


    const handleFilterNumber = (newFilterNumberInput) => {
        let newFilterNumber = parseInt(newFilterNumberInput);
        if (isNaN(newFilterNumber)) {
            newFilterNumber = null;
        }

        if (newFilterNumber !== filterNumber) {
            setFilterNumber(newFilterNumber);
        }
    }

    const getAvailableNumbers = (fields) => {
        const activerNumbersArray = [];
        for (const [key, item] of Object.entries(fields)) {
            if (!activerNumbersArray.includes(item.tag.charAt(0))) {
                activerNumbersArray[key] = item.tag.charAt(0);
            }
        }
        return activerNumbersArray.sort();
    }

    const isNumeric = (n) => { return !isNaN(parseFloat(n)) && isFinite(n); }

    const handleFilterKeywordChange = (event) => { setFilterKeyword(event.target.value); }
    const handleFilterTypeChange = (event) => { setFilterType(event.target.value); }

    const filterFieldsByNumber = (fields, number) => {
        if (number === null || typeof fields !== 'object') {
            return fields;
        } else {
            const newFields = {};
            for (const [key, item] of Object.entries(fields)) {
                if (parseInt(item.tag.charAt(0)) === number) {
                    newFields[key] = item;
                }
            }
            return newFields;
        }
    }

    const tabChangeHandler = (event, newValue) => {
        props.history.push('/config/metadata-profile/marc21/' + profileFormat + '/' + newValue);
    }

    if (typeof metadataProfile === 'undefined') {
        return null
    }
    return (
        <React.Fragment>
            <SuccessSnackbar
                message={<span>{trans('save_successful')}</span>}
                showSnackbar={showSnackbar}
                setShowSnackbar={setShowSnackbar}
            />
            <Grid container justify="center" className={classes.cardHeader}>
                <Grid item sm={11}><Tooltip title={trans('back-to-metadata-profiles')}><Link to="/config/metadata-profile">&#8630; </Link></Tooltip>{trans('profile-page')}</Grid>
                <Grid item sm={1}>
                    <div>
                        <Link to={'/config'} ><img className={classes.Image} src={Close} alt="" /></Link>
                    </div>
                </Grid>
            </Grid>
            <Grid container justify="center" style={{ margin: "10px" }}>
                <Grid item md={10} sm={12}>
                    <Paper>
                        <Table>
                            <TableHead className={classes.myTableHeadStyle}>
                                <TableRow>
                                    <TableCell variant={'head'}>{trans('profile')}: <br /><b>{trans(metadataProfile.profile)}</b></TableCell>
                                    <TableCell variant={'head'}>{trans('metadata-format')}: <br /><b>{metadataProfile.metadataFormat}</b></TableCell>
                                    <TableCell variant={'head'}>{trans('type')}: <br /><b>{trans(metadataProfile.type)}</b></TableCell>
                                    <TableCell variant={'head'}>{trans('status')}: <br /><b>{metadataProfile.status ? trans('active') : trans('not-active')}</b></TableCell>
                                    <TableCell variant={'head'}>{trans('last-modified')}: <br /><b>{dateFormat(new Date(metadataProfile.lastModified))}</b></TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>

                        {profileFormatType
                            ? <Tabs
                                value={profileFormatType}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={tabChangeHandler}
                            >
                                {authorityRecordTypeArray.map(type => (
                                    <Tab value={type} label={trans('authority.' + type)} key={type} />
                                ))}
                            </Tabs>
                            : null}
                        <Grid container style={{ padding: '16px' }}>
                            <Grid item sm={7}>
                                <Numbers
                                    numbers={availableNumbers}
                                    filterNumber={filterNumber}
                                    handleFilterNumber={handleFilterNumber}
                                />
                            </Grid>
                            <Grid item sm={5}>
                                <Search
                                    filterType={filterType}
                                    handleFilterTypeChange={handleFilterTypeChange}
                                    filterKeyword={filterKeyword}
                                    handleFilterKeywordChange={handleFilterKeywordChange}
                                />
                            </Grid>
                        </Grid>


                        <Table >
                            <TableHead className={classes.myTableHeadStyle}>
                                <TableRow>
                                    <TableCell variant={'head'}>{trans('callsign')}</TableCell>
                                    <TableCell variant={'head'}>{trans('description')}</TableCell>
                                    <TableCell variant={'head'}>{trans('repeatable')}</TableCell>
                                    {/* <TableCell variant={'head'}>{trans('required')}</TableCell> */}
                                    <TableCell variant={'head'}>{trans('status')}</TableCell>
                                    <TableCell variant={'head'}>{trans('last-modified')}</TableCell>
                                    <TableCell variant={'head'}> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    Object.entries(filteredFields)
                                        .map(([key, mf]) => (
                                            <MetadataProfileMarc21Row
                                                key={key}
                                                mf={mf}
                                                classes={classes}
                                            />
                                        ))}

                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>

    )
}

export default withRouter(MetadataProfileMarc21);
