import React, { useState } from 'react'
import { permissions } from '../shared/permissions'
import AP from 'simple-xdm/plugin';
// import { getAccessTokenDecoded } from '../shared/auth';
import { withRouter } from "react-router";

export const AuthContext = React.createContext({
    isGranted: () => { },
    setUserPermissions: () => { }
});

const AuthContextProvider = (props) => {
    let perms = [
        permissions.CATALOGING_READ,
        permissions.CATALOG_READ,
        permissions.CONFIG_METADATAPROFILE_UPDATE,
        permissions.CONFIG_VALUESET_READ,
        permissions.CONFIG_VALUESET_COLLECTION_CREATE,
        permissions.CONFIG_VALUESET_COLLECTION_READ,
        permissions.CONFIG_VALUESET_COLLECTION_UPDATE,
        permissions.CONFIG_TEMPLATE_READ,
        permissions.CONFIG_TEMPLATE_UPDATE,

        permissions.READER_SERVICE_READ,
        permissions.READER_SERVICE_READER_CREATE,
        permissions.READER_SERVICE_READER_UPDATE,
        permissions.READER_SERVICE_READER_DELETE,
        permissions.READER_SERVICE_READER_READ,

        permissions.READER_SERVICE_LOAN_READ,
        permissions.READER_SERVICE_LOAN_CREATE,
        permissions.READER_SERVICE_RETURN_READ,
        permissions.READER_SERVICE_RETURN_CREATE,
        permissions.READER_SERVICE_REQUEST_READ,
        permissions.READER_SERVICE_REQUEST_CREATE,

        permissions.INVENTORY,
        permissions.INVENTORY_HOLDING_READ,
        permissions.INVENTORY_HOLDING_CREATE,
        permissions.INVENTORY_HOLDING_UPDATE,
        permissions.INVENTORY_HOLDING_DELETE,
        permissions.INVENTORY_ITEM_READ,
        permissions.INVENTORY_ITEM_CREATE,
        permissions.INVENTORY_ITEM_UPDATE,
        permissions.INVENTORY_ITEM_DELETE,
        permissions.INVENTORY_ITEM_HISTORY_READ,

        permissions.ACQUISITION_READ
    ];

    if (process.env.REACT_APP_CORE_PERMISSIONS === 'true') {
        perms = [];
    }

    const [userPermissions, setUserPermissions] = useState(perms);


    if (AP.setModulePermissions) {
        if (userPermissions.length === 0 ) {
            AP.setModulePermissions(null, result => {
                setUserPermissions(result);
            })
        }
    }

    if (AP.pathToOpen) {
        AP.pathToOpen(null, result => {
            if (props.history) {
                const convertedPath = result === '/' ? '/catalog' : result;
                if (props.history.location.pathname !== convertedPath) {
                    props.history.push(convertedPath)
                }
            }
        })
    }

    AP.register({
        'routing-event': function (event, cb) {
            if (props.history) {
                if (props.history.location.pathname !== event.message) {
                    props.history.push(event.message)
                }
            }
            // cb('Some response');
        },
    });

    const isGranted = (permission) => {
        if (userPermissions.includes(permission)) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <AuthContext.Provider value={{
            isGranted,
            setUserPermissions
        }}>
            {props.children}
        </AuthContext.Provider>
    )
}


export default withRouter(AuthContextProvider)
