import axios from '../../settings/axios-backend';
import urls from '../../settings/urls';

export const createItem = data => {
    return axios.post(urls.ITEM, data)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

export const getItem = id => {
    const url = urls.ITEM_ID.replace('{id}', id);
    return axios.get(url)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

export const updateItem = (id, holding) => {
    const url = urls.ITEM_ID.replace('{id}', id);
    return axios.patch(url, holding)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

export const deleteItem = id => {
    const url = urls.ITEM_ID.replace('{id}', id);
    return axios.delete(url)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};


export const getItemsByHoldingId = (holdingId, data) => {
    const url = urls.ITEMS_BY_HOLDING_ID.replace('{id}', holdingId);
    return axios.post(url, data)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};

export const getItemHistory = id => {
    const url = urls.ITEM_HISTORY.replace('{id}', id);
    return axios.get(url)
        .then(response => {
            console.log(response.data)
            return response.data;
        })
        .catch(error => {
            console.log(error)
            throw error;
        });
};