import React from 'react';

import { useTranslator } from '../../../shared/translate';
import { getDefaultValue, getMaterialType } from '../../../shared/recordUtil';
import ControlfieldTable from '../Controlfields/Controlfield/Table/ControlfieldTable';
import { isEqual } from '../../../shared/util';
import editorStyles from '../Editor.module.css'
import PopupModal from '../../UI/Popup/PopupModal';
import { Button } from '@material-ui/core';

const Leader = props => {
  const { leader, setLeader, metadataProfile, materialType, setMaterialType } = props;

  const trans = useTranslator();
  const [showPopup, setShowPopup] = React.useState(false);
  const [savedLeader, setSavedLeader] = React.useState();

  React.useEffect(() => {
    if (leader) { return; }
    const newLeader = getDefaultValue(metadataProfile);
    setLeader(newLeader);
  }, [leader, metadataProfile, setLeader, setMaterialType]);

  React.useEffect(() => {
    setMaterialType(getMaterialType(leader));
  }, [leader, setMaterialType])

  const onChangeHandler = input => setLeader(prevLeader => {
    const newLeader = input instanceof Function ? input(prevLeader) : input;
    const newMaterialType = getMaterialType(newLeader);
    if (!prevLeader) {
      setMaterialType(newMaterialType)
    } else {
      const oldMaterialType = getMaterialType(prevLeader);
      if (oldMaterialType !== newMaterialType) {
        setSavedLeader(newLeader);
        setShowPopup(true);
        return prevLeader;
      }
    }
    return newLeader
  });

  const cancelPopupHandler = () => {
    setSavedLeader(undefined);
    setShowPopup(false);
  }

  const acceptPopupHandler = () => {
    setLeader(prevLeader => {
      setShowPopup(false);
      setSavedLeader(undefined);
      return savedLeader
    });
  }

  return (
    <React.Fragment>
      <PopupModal
        show={showPopup}
        setShow={setShowPopup}
        cancel={cancelPopupHandler}
        save={acceptPopupHandler}
        size={'medium'}
        title={trans('change_material_type_confirmation')}
        body={(
          <div>
          {trans('change_material_type_warning', {
            old: trans(`material_types.${materialType}`),
            new: trans(`material_types.${getMaterialType(savedLeader)}`),
            dependencies: ['006', '008/18-34'].join(', '),
          })}
        </div>
        )}
        footer={(
          <React.Fragment>
            <Button variant={'contained'} color={'primary'} onClick={acceptPopupHandler}>{trans('confirm')}</Button>
            <Button variant={'contained'} color={'secondary'} onClick={cancelPopupHandler}>{trans('cancel')}</Button>
          </React.Fragment>
        )}
      />
        
      {/* <hr />{JSON.stringify(value)} */}
      <div className={editorStyles.Card}>
        <div className={editorStyles.CardHeader}>
          <span className={editorStyles.myFontStyle}>{trans('marc21.leader')} :</span>
          <span>{leader.replace(/ /g, '#')}</span>
        </div>
        <div className={editorStyles.CardContent}>
          <ControlfieldTable
            value={leader}
            metadataProfile={metadataProfile}
            setValue={onChangeHandler}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(Leader, (prev, next) => (
  prev.leader === next.leader &&
  prev.materialType === next.materialType &&
  isEqual(prev.metadataProfile, next.metadataProfile)
));