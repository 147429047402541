import React, { useState, useEffect, useCallback } from 'react'
import { Button } from '@material-ui/core'

import useSmartRequest from '../../../../../hooks/useSmartRequest';
import { getCollections, createCollection } from '../../../../../api/valueset/collection';
import CollectionListTable from './CollectionListTable';
import PopupModal from '../../../../../components/UI/Popup/PopupModal';
import AddCollectionForm from './AddCollectionForm';
import { useAuth } from '../../../../../shared/auth';
import { permissions } from '../../../../../shared/permissions';
import { useTranslator } from '../../../../../shared/translate';
import classes from './CollectionList.module.scss';
import ConfigContext from '../../../../../context/snackbar-context';
import { makeCancelable } from '../../../../../shared/util';

const CollectionList = () => {
   const [collections, setCollections] = useState(null);
   const { sendRequest } = useSmartRequest(getCollections);
   const { sendRequest: sendCreateCollectionRequest } = useSmartRequest(createCollection);
   const { setShowSnackbar } = React.useContext(ConfigContext);

   const [showPopup, setShowPopup] = useState(false);
   const t = useTranslator();
   const createCollectionHandler = useCallback((data) => {
      sendCreateCollectionRequest(data).then(res => {
         cancelPopupHandler();
         sendRequest().then(res => {
            setShowSnackbar(true);
            setCollections(res)
         })
      })
   },
      [sendCreateCollectionRequest, sendRequest, setShowSnackbar],
   )

   useEffect(() => {
      const { cancel, promise } = makeCancelable(sendRequest());
      promise.then(res => setCollections(res))
      return () => { cancel(); }
   }, [sendRequest, createCollectionHandler, sendCreateCollectionRequest])

   const auth = useAuth();
   const cancelPopupHandler = () => { setShowPopup(false) }
   const showPopupHandler = () => { setShowPopup(true) }


   if (collections === null) {
      return null;
   }

   return (
      <div>
         {auth.isGranted(permissions.CONFIG_VALUESET_COLLECTION_CREATE) ?
            <div className={classes.alignRight}>
               <Button
                  onClick={showPopupHandler}
                  className={[classes.mainBlueButton, classes.btnT20].join(' ')} >{t('new-collection')}</Button>
               {showPopup
                  ? <PopupModal
                     show={showPopup}
                     setShow={setShowPopup}
                     cancel={cancelPopupHandler}
                     size={'small'}
                     title={t('new-collection')}
                     body={<AddCollectionForm saveHandler={createCollectionHandler} />}
                  />
                  : null
               }
            </div>
            : null}
         <br />
         <CollectionListTable collections={collections} />
      </div>
   )
}

export default CollectionList;
