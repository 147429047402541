import Currency from './classes/currency';

export default class BaseDataApi {

  // Currency

  /**
   *
   * @param {{currencyName: string}} filter
   * @returns {Promise<*[Currency]>}
   */
  static getCurrencies = async (filter) => {
    console.log('BaseDataApi.getCurrencies filter', filter);

    let ret = [
      Currency.createFromData({id: '1', code: 'HUF', name: 'forint', isDefault: true}),
      Currency.createFromData({id: '2', code: 'EUR', name: 'euró', isDefault: false}),
      Currency.createFromData({id: '3', code: 'GBP', name: 'angol font', isDefault: false}),
      Currency.createFromData({id: '4', code: 'RON', name: 'román lej', isDefault: false}),
      Currency.createFromData({id: '5', code: 'HRK', name: 'horvát kuna', isDefault: false}),
      Currency.createFromData({id: '6', code: 'UAH', name: 'ukrán hrivnya', isDefault: false}),
      Currency.createFromData({id: '7', code: 'RUB', name: 'orosz rubel', isDefault: false}),
    ];

    if (typeof filter.currencyName === 'string') {
      ret = ret.filter(c =>
        c.code.toLowerCase().indexOf(filter.currencyName.toLowerCase()) >= 0
        || c.name.toLowerCase().indexOf(filter.currencyName.toLowerCase()) >= 0
      );
    }

    // all element count
    const count = ret.length;

    // pagination offset
    if (typeof filter.offset === 'number') {
      ret = ret.slice(filter.offset, filter.offset + count);
    }

    // pagination page size
    if (typeof filter.count === 'number') {
      ret = ret.slice(0, filter.count);
    }

    return {
      count,
      items: ret,
    };
  }
}
