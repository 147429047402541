import React from 'react'
import Grid from '@material-ui/core/Grid';
import { useTranslator } from '../../../shared/translate';
import {
    Button,
    TextField,
    Select,
    MenuItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    mainBlueButton: {
        backgroundColor: '#2b75bb',
        borderRadius: 6,
        color: 'white',
        fontSize: 14,
        textTransform: 'capitalize',
        padding: '4px 14px',
        fontFamily: 'Source Sans Pro',
        fontWeight: 500,
        zIndex: 'inherit',
        '&:hover': {
            background: '#169bd5',
        },
    },
    inputStyle: {
        padding: 10,
        fontSize: 14,
        fontWeight: 500,
    }
});

const SearchSimple = (props) => {
    const classes = useStyles();

    const {
        searchFilterOptions,

        filterType,
        setFilterType,
        setFilterKeyword,


        filterKeyword,
        buttonClick,
        setSimpleSearchActive
    } = props;

    const t = useTranslator()

    return (
        <React.Fragment>
            <Grid container spacing={5}>
                <Grid item md={4} sm={4}>
                    <Select
                        onChange={(event) => {
                            setFilterType(event.target.value)
                        }}
                        value={searchFilterOptions.find(elem => elem.value === filterType) ? filterType : ''}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                            className: classes.inputStyle,
                        }}
                    >
                        {searchFilterOptions.map((option, key) => {
                            return (
                                <MenuItem key={key} value={option.value} >{t(option.label)}
                                </MenuItem >
                            )
                        })}
                    </Select>
                </Grid>
                <Grid item md={4} sm={4}>
                    {'status' === filterType ? (
                        <Select
                            fullWidth
                            variant="outlined"
                            value={filterKeyword}
                            onChange={event => setFilterKeyword(event.target.value)}
                            inputProps={{
                                className: classes.inputStyle,
                            }}
                        >
                            {['new', 'active'].map((option, index) => (
                                <MenuItem key={index} value={option} >{t(option)}</MenuItem>
                            ))}
                        </Select>
                    ) : (
                            <TextField
                                autoFocus
                                placeholder={t('search')}
                                variant="outlined"
                                type="text"
                                fullWidth
                                inputProps={{
                                    value: filterKeyword,
                                    onChange: (event) => setFilterKeyword(event.target.value),
                                    className: classes.inputStyle
                                }}
                            />
                        )}

                </Grid>
                <Grid item md={4} sm={4}>
                    <Button
                        onClick={buttonClick}
                        className={classes.mainBlueButton}
                    >{t('search')}</Button>
                    <Button
                        onClick={() => setSimpleSearchActive(false)}
                        style={{ marginLeft: '25px', color: '#2b75bb', cursor: 'pointer' }}

                    >{t('complex-search')}</Button>
                </Grid>
            </Grid>

        </React.Fragment>
    )
}

export default SearchSimple
