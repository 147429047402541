import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import {
  Grid,
  Button,
  Container,
} from '@material-ui/core';

import TemplateHeader from '../Header/TemplateHeader';
import AlsoInput from '../../UI/Input/AlsoInput';
import { getTemplate as fetchTemplate, getTemplateOptions as fetchTemplateOptions } from '../../../api/templates';
import fetchMetadataProfiles from '../../../api/marc21/bibliographic/bibliographicSchema'
import { updateTemplate } from '../../../api/templates';
import TemplateContext from '../../../context/template';
import ConfigContext from '../../../context/snackbar-context';
import { useTranslator } from '../../../shared/translate';
import { camelToKebab, createPropertySetter } from '../../../shared/util';
import { addHelperFields, removeHelperFields } from '../../../shared/listUtil';
import Editor from '../../Editor/Editor';
import useSmartRequest from '../../../hooks/useSmartRequest';
import Popup from '../../UI/Popup/Popup';
import styles from './TemplateEdit.module.css';
import SaveButton from '../../UI/Button/SaveButton';
import { hasErrors, fillAuthorityIds } from '../../../shared/recordUtil';
import { updateCollectionsFromEditor } from '../../../api/valueset/collection';

const LEFT_CONTAINER_FIELDS = ["title", "description", "metadataFormat", "type", "status", /*"createdBy",*/ "createdAt", /*"updatedBy",*/ "updatedAt"];
const LEFT_CONTAINER_FIELDS_EDITABLE = ["title", "description", "status"];
const LEFT_CONTAINER_FIELDS_TRANSLATABLE = ["metadataFormat"];
const LEFT_CONTAINER_FIELDS_SELECTABLE = ["status"];

const TemplateEdit = props => {
  const { id } = props.match.params;

  const {
    metadataProfile,
    setMetadataProfile,
    template,
    setTemplate,
    // originalTemplate,
    setOriginalTemplate,
  } = useContext(TemplateContext)

  const {
    setShowSnackbar,
  } = useContext(ConfigContext);

  const [leftContainerElements, setLeftContainerElements] = useState([]);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [ triedToSave, setTriedToSave ] = useState(false);
  const { sendRequest, isSending } = useSmartRequest(updateTemplate);
  // const [forceRemount, setForceRemount] = React.useState(1);
  const trans = useTranslator();

  useEffect(() => {
    fetchTemplate(id)
      .then(response => {
        setOriginalTemplate({...response});
        response.template = addHelperFields(response.template);
        setTemplate(response);
      })
      .catch(error => {
        console.log(error);
      })
      ;
    return () => {
      setTemplate(undefined);
      setOriginalTemplate(undefined);
    }
  }, [id, setTemplate, setOriginalTemplate]);

  useEffect(() => {
    fetchTemplateOptions()
      .then(response => {
        const templateOptions = {};
        for (let [key, value] of Object.entries(response)) {
          templateOptions[key] = value.map(option => (
            {
              name: option,
              translated: trans("template." + key + "." + option)
            }
          ));
        }
        setTemplateOptions(templateOptions);
      })
      .catch(error => {
        console.log(error);
      })
      ;
  }, [trans]);

  useEffect(() => {
    fetchMetadataProfiles()
      .then(data => {
        setMetadataProfile(data);
      })
      ;
    return () => {
      setMetadataProfile(undefined);
    }
  }, [setMetadataProfile])

  useEffect(() => {
    if (!template) { return; }

    setLeftContainerElements(LEFT_CONTAINER_FIELDS.map(key => (
      <React.Fragment key={key}>
        <Grid container direction="column" className={styles.marginBottom}>
          <Grid item className={styles.fontWeightBold}>
            {trans(camelToKebab(key))}
          </Grid>
       
          <Grid item sm>
            <AlsoInput
              readOnly={!LEFT_CONTAINER_FIELDS_EDITABLE.includes(key)}
              onChange={event => setTemplate(prevTemplate => ({ ...prevTemplate, [key]: event.target.value }))}
              value={LEFT_CONTAINER_FIELDS_TRANSLATABLE.includes(key) ? trans(camelToKebab(template[key])) : template[key]}
              options={LEFT_CONTAINER_FIELDS_SELECTABLE.includes(key) ? templateOptions[key] : null}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    )));

  }, [template, setTemplate, templateOptions, trans]);

  // const cancelPopupHandler = (event) => {
  //   setTemplate({ ...originalTemplate });
  //   setForceRemount(prev => prev + 1);
  //   setShowPopup(false);
  // }

  // const savePopupHandler = (event) => {
  //   sendRequest(template.id, template).then((r) => {
  //     setOriginalTemplate({ ...template });
  //     setShowPopup(false);
  //     setShowSnackbar(true);
  //   })
  // }

  const templateEditorButtons = (
    <React.Fragment>

      <Popup
        show={showPopup}
        cancel={() => setShowPopup(false)}
        save={() => props.history.push('/config/template')}
        title={trans('dismiss_confirmation')}
      />
    </React.Fragment>
  );

  const errors = template ? hasErrors(template.template) : true;

  return (
    <React.Fragment>
      <TemplateHeader
        title="Sablon részletek"
        returnUrl="/config/template/"
        buttons={templateEditorButtons}
      />
        <Grid item sm={12} className={styles.myStickyTop}>
            <SaveButton
              className={styles.myBlueButton}
              error={errors}
              disabled={isSending}
              onFailure={() => setTriedToSave(true)}
              onSuccess={() => {
                updateCollectionsFromEditor(metadataProfile).then(response => {
                  const newTemplate = {
                    ...template,
                    template: fillAuthorityIds(template.template, response),
                  };
                  setTemplate(newTemplate);
                  sendRequest(template.id, {...newTemplate, template: removeHelperFields(newTemplate.template)}).then(response => {
                    setShowSnackbar(true);
                    props.history.push('/config/template')
                  })
                })
              }}
            />
            <Button
              onClick={() => setShowPopup(true)}
              className={styles.myRedButton}
            >
              {trans('dismiss')}
            </Button>
          </Grid>
      <Container maxWidth="xl">
        <Grid container spacing={5}>
          <Grid item sm={3} style={{ marginLeft: '25px' }}>
            {leftContainerElements}
          </Grid>
          <Grid item sm={8}>
            <Editor
              // key={forceRemount}
              style={{ width: 'calc(100% - 1rem)' }}
              record={template ? template.template : null}
              setRecord={createPropertySetter(setTemplate, 'template')}
              metadataProfile={metadataProfile}
              setMetadataProfile={setMetadataProfile}
              isTemplate={true}
              showErrors={triedToSave}
            />
          </Grid>
          <Grid item sm={3} style={{ marginLeft: '25px' }}>

          </Grid>


        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default React.memo(withRouter(TemplateEdit), (prev, next) => (
  prev.match.params.id === next.match.params.id
));