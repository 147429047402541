import axios from '../../settings/axios-backend';
import urls from '../../settings/urls';

const bibliographicSchemaUpdateCallsign = (postData) => {
    console.log(postData)
    return axios.put(urls.METADATA_PROFILE_MARC21_CALLSIGN_DATA, postData)
        .then(response => {
            console.log(response);
            console.log(response ? response.data : null)
            return response ? response.data : null;
        });
}

export default bibliographicSchemaUpdateCallsign
