import React from 'react';
import { createFilter } from 'react-select';

import Grid from '@material-ui/core/Grid';
import { VALUE_LABEL_SEPARATOR, validateSubfield } from '../../../../../shared/recordUtil';
import { useTranslator } from '../../../../../shared/translate';
import { isEqual, createPropertySetter } from '../../../../../shared/util';
import Del from '../../../../../assets/icons/delete-blue.svg';
import Copy from '../../../../../assets/icons/copy-blue.svg';
import styles from '../../Datafield.module.scss';
import SubfieldInput from './SubfieldInput';
import ReactSelect from '../../../../UI/Input/ReactSelect';
import { removeHelperFields } from '../../../../../shared/listUtil';
import ErrorMessage from '../../../ErrorMessage';

const Subfield = props => {
  const {
    subfield,
    setSubfield: propSetSubfield,
    metadataProfile,
    setMetadataProfile,
    onDelete,
    onClone,
    getPossibleCodes,
    onCodeChange,
    linkEntry,
    getMetadataProfileForCode,
    isTemplate,
    showNewErrors,
  } = props;
  const [possibleCodes, setPossibleCodes] = React.useState(getPossibleCodes(subfield.code));

  // React.useEffect(() => {
  //   setPossibleEntities(getEntities([]));
  // }, [getEntities])

  const [ showErrors, setShowErrors ] = React.useState(false);
  React.useEffect(() => {
    if (showNewErrors) {
      setShowErrors(true);
    }
  }, [showNewErrors]);

  const trans = useTranslator();

  const setSubfield = input => propSetSubfield(prevState => validateSubfield(input instanceof Function ? input({...prevState}) : input, getMetadataProfileForCode, isTemplate));

  // if (!metadataProfile) {
  //   return <TableRow hover key={subfieldKey}><div>Hibás subfield: {JSON.stringify(s)}</div></TableRow>;
  // }
  // if (typeof metadataProfile[s.code] === 'undefined') {}
  const entities = metadataProfile && metadataProfile.collection && 'string' !== typeof metadataProfile.collection ? metadataProfile.collection.entities : [];
  const setEntities = entities ? createPropertySetter(createPropertySetter(setMetadataProfile, 'collection'), 'entities') : null;
  return (
    <React.Fragment>
      <div ref={subfield.helpers.ref} style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10, marginLeft: 10 }}>
        <Grid container spacing={2}>
          <Grid item sm={5}>
            <ReactSelect
              options={possibleCodes}
              value={subfield.code ? {
                label: `${subfield.code} ${VALUE_LABEL_SEPARATOR} ${trans(metadataProfile ? metadataProfile.label : 'invalid_value')}`, // 8211 = &ndash;
                value: subfield.code
              } : null}
              onChange={option => {
                setSubfield(prevState => ({
                  ...prevState,
                  code: option.value,
                }));
                onCodeChange(option.value)
              }}
              onMenuOpen={() => {
                const newPossibleCodes = getPossibleCodes(subfield.code);
                if (!isEqual(newPossibleCodes, possibleCodes)) {
                  setPossibleCodes(newPossibleCodes);
                }
              }}
              filterOption={createFilter({
                matchFrom: 'start',
              })}
            />
          </Grid>
        
          <Grid item sm={6}>
            <SubfieldInput
              type={metadataProfile ? metadataProfile.input : null}
              value={subfield.value}
              setValue={createPropertySetter(setSubfield, 'value')}
              entities={entities}
              setMetadataProfile={setMetadataProfile}
              pattern={metadataProfile && metadataProfile.pattern ? metadataProfile.pattern : null}
              linkEntry={linkEntry}
              className={styles.inputStyle}
            />
          </Grid>

          <Grid item sm={1} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
              {metadataProfile && metadataProfile.repeatable
                ? <img className={styles.myIconStyle} src={Copy} alt="" onClick={() => onClone(subfield)} />
                : null}
              {metadataProfile && metadataProfile.required
                ? null
                : <img className={styles.myIconStyle} src={Del} alt="" onClick={onDelete} />
              }
          </Grid>
        </Grid>
      </div>
      {showErrors ? <ErrorMessage subject={subfield} /> : null}
    </React.Fragment>
  );
};

export default React.memo(Subfield, (prev, next) => (
  prev.isValidated === next.isValidated &&
  isEqual(removeHelperFields(prev.subfield), removeHelperFields(next.subfield)) &&
  (
    (!prev.subfield.helpers.errors && !next.subfield.helpers.errors) ||
    isEqual(prev.subfield.helpers.errors, next.subfield.helpers.errors)
  ) &&
  isEqual(prev.metadataProfile, next.metadataProfile) &&
  prev.showNewErrors === next.showNewErrors
));