import React from 'react';

import { getDocumentForms } from '../../../api/documentForms';
import RecordListElement from './RecordListElement';

import styles from './DocumentFormList.module.scss';
import { Button, Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeCancelable } from '../../../shared/util';

const DocumentFormList = props => {
  const { visible, toggleVisibility } = props;

  const [ documentForms, setDocumentForms ] = React.useState([]);

  React.useEffect(() => {
    const {cancel, promise} = makeCancelable(getDocumentForms());

    promise
      .then(response => {
        setDocumentForms(response);
      })
      .catch(error => {
        console.log(error);
      }) 
    ;
    return () => {
      cancel();
    }
  }, []);


  return documentForms ? (
    <div className={[styles.Container, visible ? styles.fullBlock : styles.minBlock].join(' ')}>
      <Grid container justify="flex-end" alignItems="center">
        <Grid item className={styles.headLine}>
          <Typography
            component="h4"
          >
            Jóváhagyásra váró rekordok
          </Typography>
        </Grid>
        <Grid item className={styles.headLineMinimizeButton}>
          <Button
            variant="outlined"
            onClick={toggleVisibility}
            className={styles.buttonStyle}
          >
              {visible ? <CloseIcon /> : "Jóváhagyásra váró rekordok"}
            </Button>
        </Grid>
      </Grid>
      <div className={visible ? styles.Visible : styles.Invisible}>
        {documentForms.map((elem, index) => (
          <RecordListElement
            key={index}
            marcRecordKey={'body'}
            fullRecord={elem} 
            editUrl={`/cataloging/new/document_form/${elem.id}`}
          />
          ))}
      </div>
    </div>
  ) : null;
};

export default DocumentFormList;