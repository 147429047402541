import React from 'react';

import styles from './Modal.module.css';
import Backdrop from '../Backdrop/Backdrop';



const Modal = props => {
  const size = props.size || "small";
  const ref = React.createRef();

  let classes = [styles.Modal];
  const [width, setWidth] = React.useState(0);
  
  classes.push(styles[size.charAt(0).toUpperCase() + size.slice(1)])
  classes.push(props.show ? styles.ModalVisible : styles.ModalHidden)

  React.useEffect(() => {
    setWidth(ref.current.clientWidth);
  }, [ref, setWidth]);
  
  if (props.error) {
    classes.push(styles.Error);
  }
  
  
  return (
    <React.Fragment>
      <Backdrop show={props.show} clicked={props.modalClosed} error={props.error} />
      <div
        ref={ref}
        className={classes.join(' ')}
        style={{
          left: `calc(calc(100% - ${width}px) / 2)`
        }}
      >
        {props.children}
      </div>
    </React.Fragment>
  );
}


export default React.memo(Modal, (prevProps, nextProps) => (
  prevProps.show === nextProps.show &&
  prevProps.children === nextProps.children
));