import { createRef } from 'react';

export const hashFunction = () => Math.random().toString(36);

export const getUniqueHash = (array, hashFunction) => {
    let hash = hashFunction();
    while (array.includes(hash)) {
        hash = hashFunction();
    }
    return hash;
}

export const addHelperFields = (input, ids = []) => {
  if (!input || 'object' !== typeof input) {
      return input;
  }

  if (Array.isArray(input)) {
    const ids = [];
    const newArray = [...input];
    return newArray.map(elem => addHelperFields(elem, ids));
  }

  const newObject = {...input};

  for (const key in newObject) {
    newObject[key] = addHelperFields(newObject[key], ids);
  }

  return addHelperFieldsToElement(newObject, ids);
}

export const addHelperFieldsToElement = (elem, ids) => {
  const newId = getUniqueHash(ids, hashFunction);
  ids.push(newId);
  return {
    ...elem,
    helpers: {
      id: newId,
      ref: createRef(),
      errors: null,
    },
  }
};

// haha
export const reduceToHashes = array => array.reduce((accumulator, current) => {
  accumulator.push(current.helpers.id); 
  return accumulator;
}, []);

export const removeHelperFields = input => {
  if (!input || 'object' !== typeof input) {
    return input;
  }
  
  if (Array.isArray(input)) {
    return input.map(elem => removeHelperFields({...elem}));
  }

  const newObject = {...input};

  for (const key in newObject) {
    if ('helpers' === key) { continue; }
    newObject[key] = removeHelperFields(newObject[key]);
  }

  delete newObject.helpers;

  return newObject;
}
export const getNewHash = existingArray => getUniqueHash(reduceToHashes(existingArray), hashFunction);

// export const mapIndexesToIds = (length, hashFunction) => {
//   const map = [];
//   for (let i = 0; i < length; i++) {
//       map.push(getUniqueHash(map, hashFunction));
//   }
//   return map;
// };
