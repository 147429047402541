import React, { useState, useEffect, useCallback } from 'react'

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTranslator } from '../../../shared/translate';
import { Button, Grid } from '@material-ui/core';
import PopupModal from '../../../components/UI/Popup/PopupModal';
import { getItemFromRecord } from '../../../shared/recordUtil';
import ConfigContext from '../../../context/snackbar-context';
import useSmartRequest from '../../../hooks/useSmartRequest';
import { deleteItem, createItem, getItemsByHoldingId } from '../../../api/marc21/item/item';
import { withRouter } from 'react-router';
import { getHoldingRecord } from '../../../api/marc21/holding/holdingRecords';
import AddItemForm from './List/AddItemForm/AddItemForm';
import BackLink from '../../../components/UI/Link/BackLink';
import Spinner from '../../../components/UI/Spinner/Spinner';
import Pagination from '../../../components/UI/Pagination/Pagination';
import ItemsList from './List/ItemsList';
import { getRecord } from '../../../api/marc21/bibliographic/bibliographicRecords';
import { useAuth } from '../../../shared/auth';
import { permissions } from '../../../shared/permissions';

const Holding = (props) => {

    const holdingId = props.match.params.holdingId

    const { setShowSnackbar } = React.useContext(ConfigContext);
    const { sendRequest: sendDeleteRequest } = useSmartRequest(deleteItem);
    const t = useTranslator();
    const auth = useAuth();
    //holding and bibliographic
    const [holding, setHolding] = useState(null)
    const [bibliograpic, setBibliograpic] = useState(null)

    const loadHolding = useCallback(() => {
        getHoldingRecord(holdingId).then(r => {
            setHolding(r)
            console.log(holdingId)
            console.log(r.bibliographicRecord)
            getRecord(r.bibliographic).then(r2 => {
                setBibliograpic(r2)
            })
        })
    },
        [holdingId],
    )

    //paging
    const itemPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1)
    const [itemCount, setItemCount] = useState(0)
    const [isLoading, setIsLoading] = React.useState(false);
    const [items, setItems] = useState(null)



    const reloadHoldingItems = useCallback(() => {
        setIsLoading(true);
        getItemsByHoldingId(
            holdingId,
            { offset: (currentPage - 1) * itemPerPage, limit: itemPerPage })
            .then(r => {
                setItems(r.items);
                setItemCount(r.itemCount);
                setIsLoading(false);

                //deleted, if we are over maxpage, then move to last page, first page is ok
                const maxPage = Math.ceil(r.itemCount / itemPerPage);
                if (currentPage > 1 && currentPage > maxPage) {
                    setCurrentPage(maxPage);
                }
            })
    },
        [holdingId, currentPage],
    )

    useEffect(() => {
        reloadHoldingItems()
    }, [reloadHoldingItems])

    useEffect(() => {
        loadHolding()
    }, [loadHolding])


    const [showPopup, setShowPopup] = useState(false);
    const [popupDeleteShow, setPopupDeleteShow] = useState(false);
    const [popupDeleteSelected, setPopupDeleteSelected] = useState(null);

    //create
    const createItemHandler = (data) => {
        createItem({ ...data, holdingId: holdingId }).then(res => {
            setShowSnackbar(true);
            cancelPopupHandler();
            reloadHoldingItems()
        })
    }
    //delete
    const deleteHoldingHandler = (id) => {
        sendDeleteRequest(id).then(r => {
            reloadHoldingItems()
        });
    }
    //delete popup
    const setShowDeletePopupHandler = (inputShow, id = null) => {
        if (id) {
            setPopupDeleteSelected(id)
            setPopupDeleteShow(inputShow);
        } else {
            setPopupDeleteShow(inputShow);
        }
    }

    //open/close popup
    const showPopupHandler = () => { setShowPopup(true) }
    const cancelPopupHandler = () => { setShowPopup(false) }

    const handleCurrentPageChange = (newCurrentPage) => {
        setCurrentPage(newCurrentPage)
    }

    //spinner
    if (
        holding === null ||
        bibliograpic === null ||
        items === null
    ) {
        return <Spinner />
    }

    return (
        <React.Fragment>
            {showPopup ? <PopupModal
                show={showPopup}
                setShow={setShowPopup}
                cancel={cancelPopupHandler}
                size={'large'}
                title={t('item.new-item')}
                body={<AddItemForm saveHandler={createItemHandler} />}
            /> : null}
            {popupDeleteShow ? <PopupModal
                show={popupDeleteShow}
                setShow={setPopupDeleteShow}
                title={t('delete?')}
                footer={
                    <React.Fragment>
                        <Button onClick={() => {
                            deleteHoldingHandler(popupDeleteSelected)
                            setShowDeletePopupHandler(false)
                        }}>{t('delete')}</Button>
                        <Button onClick={() => {
                            setShowDeletePopupHandler(false)
                        }}>{t('cancel')}</Button>
                    </React.Fragment>
                }
            /> : null}
            <Paper style={{ padding: '2rem' }}>
                <h1><BackLink to={'/catalog/bibliographic/' + bibliograpic.id} /> {t('item.items')} </h1>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell variant={'head'}>{t('record-id')}: <br /><b>
                                {getItemFromRecord(bibliograpic.record, '001')}
                            </b></TableCell>
                            <TableCell variant={'head'}>{t('author')}: <br /><b>
                                {getItemFromRecord(bibliograpic.record, '100', 'a')}
                                {getItemFromRecord(bibliograpic.record, '100', 'd')}
                            </b></TableCell>
                            <TableCell variant={'head'}>{t('title')}: <br /><b>
                                {getItemFromRecord(bibliograpic.record, '245', 'a')}
                                {getItemFromRecord(bibliograpic.record, '245', 'b')}
                            </b></TableCell>
                            <TableCell variant={'head'}>{t('edition')}: <br /><b>
                                {getItemFromRecord(bibliograpic.record, '260', 'a')}
                                {getItemFromRecord(bibliograpic.record, '260', 'b')}
                                {getItemFromRecord(bibliograpic.record, '260', 'c')}
                            </b></TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                <br />
                <Grid container>
                    <Grid item xs={6}>

                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ textAlign: 'right' }}>
                            {auth.isGranted(permissions.INVENTORY_ITEM_CREATE)
                                ? <Button
                                    onClick={showPopupHandler}
                                    style={{ marginTop: '10px', color: '#2b75bb', borderColor: '#2b75bb' }}

                                    variant={'outlined'}
                                    color={'primary'}
                                >{t('item.create-new-item')}</Button>
                                : null}
                        </div>
                    </Grid>
                </Grid>

                <Pagination
                    currentPage={currentPage}
                    handleCurrentPageChange={handleCurrentPageChange}
                    recordCount={itemCount}
                    itemPerPage={itemPerPage}
                    disabled={isLoading}
                />
                <br />
                <ItemsList
                    items={items}
                    setShowDeletePopupHandler={setShowDeletePopupHandler}
                    isLoading={isLoading}
                />
                <br /><br />
                <Pagination
                    currentPage={currentPage}
                    handleCurrentPageChange={handleCurrentPageChange}
                    recordCount={itemCount}
                    itemPerPage={itemPerPage}
                    disabled={isLoading}
                />

            </Paper>
        </React.Fragment>
    );
}

export default withRouter(Holding)
