import React from 'react'
import { Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';

const Pagination = (props) => {
    const {
        currentPage,
        handleCurrentPageChange,
        recordCount,
        itemPerPage,
        disabled
    } = props

    const maxPage = Math.ceil(recordCount / itemPerPage);
    const first = () => currentPage === 1 ? null : button(1, '|<')
    const previous = () => currentPage === 1 ? null : button(currentPage - 1, '<')
    const next = () => currentPage >= maxPage ? null : button(currentPage + 1, '>')
    const last = () => currentPage >= maxPage ? null : button(maxPage, '>|')

    const button = (pageNumber, label = null) => {
        return (
            <React.Fragment>
                <Button
                    onClick={() => handleCurrentPageChange(pageNumber)}
                    disabled={disabled}
                >
                    {label ? label : pageNumber}
                </Button>
            </React.Fragment>
        )
    }

    if (!recordCount) {
        // return null;
    }
    return (
        <Grid container direction="row" justify="center" alignItems="center" >
            <Grid item >
                {first()}
                {previous()}
            </Grid>
            <Grid item >
                {currentPage < maxPage ? currentPage : maxPage} / {maxPage}
            </Grid>
            <Grid item >
                {next()}
                {last()}
            </Grid>
        </Grid>
    )
}

export default Pagination
