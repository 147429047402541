import React from 'react'
import { Route, Switch } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import { Translate as T, useTranslator } from '../../../shared/translate';
import Header from '../Header/Header';
import CurrencyList from '../../BaseData/Currency/CurrencyList';

const CurrencyListPage = () => {
  const t = useTranslator();

  let dashboard = (
    <>
      <Header>
        {'Deviza lista'}
      </Header>
      <CurrencyList />
    </>
  );
  return dashboard;
}

export default CurrencyListPage
