import React, { useState } from 'react'

export const LanguageContext = React.createContext({
    languages: {},
    setLanguages: () => { },
    locale: '',
    handleLocaleChange: () => { },
    translate: () => { },
});

const LanguageContextProvider = (props) => {
    const [languages, setLanguages] = useState(null);
    const [locale, setLocale] = useState('hu');

    const translate = (label, params = {}) => {
        const translations = languages;

        //log
        if (!(translations && translations[locale] && translations[locale][label])) {
            // console.log('MISSING TRANSLATION: %c' + label, 'color: blue')
        }

        if (translations && translations[locale] && translations[locale][label]) {
            if (!params) {
                return translations[locale][label];
            }
            let result = translations[locale][label];
            for (const [key, value] of Object.entries(params)) {
                const regexp = new RegExp('\\{' + key + '\\}', 'g');
                result = result.replace(regexp, value);
            }
            return result;
        }
        return label;
    }

    const handleLocaleChange = (locale) => {
        setLocale(locale);
    }

    return (
        <LanguageContext.Provider value={{
            languages: languages,
            setLanguages: setLanguages,
            locale: locale,
            handleLocaleChange: handleLocaleChange,
            translate: translate,
        }}>
            {props.children}
        </LanguageContext.Provider>
    )
}


export default LanguageContextProvider
