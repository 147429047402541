import React from 'react'
import { Route, Switch } from 'react-router-dom';
import BaseContainer from '../../hoc/BaseContainer/BaseContainer';
import CatalogEditor from '../Catalog/Editor/CatalogEditor';
import Mainpage from './Mainpage/Mainpage';
import { useAuth } from '../../shared/auth';
import { permissions } from '../../shared/permissions';
import { redirect } from '../../shared/util';
import Workpage from './Workpage/Workpage';
import HoldingEdit from './Holding/HoldingEdit';

const Cataloging = () => {
    const auth = useAuth();
    let routes = null;
    if (auth.isGranted(permissions.CATALOGING_READ)) {
        routes = (
            <BaseContainer>
                <Switch>
                    <Route path="/cataloging" exact={true} component={Mainpage} />
                    <Route path="/cataloging/new/template/:templateId" exact={true} component={Workpage} />
                    <Route path="/cataloging/new/document_form/:documentFormId" exact={true} component={Workpage} />
                    <Route path="/cataloging/edit/:recordId" exact={true} component={Workpage} />
                    <Route path="/cataloging/editor/:recordId" exact={true} component={CatalogEditor} />
                    {auth.isGranted(permissions.INVENTORY_HOLDING_UPDATE) ? <Route path="/cataloging/holding/editor/:holdingId" exact={true} component={HoldingEdit} /> : null}
                </Switch>
            </BaseContainer>
        );
    } else {
        redirect('/');
    }
    return routes
}

export default Cataloging
