import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {Grid} from '@material-ui/core';

import { getAuthorityRecord } from '../../../../../api/marc21/authority/authority'
import ShowRecord from '../../../../Catalog/List/ShowRecord'
import Close from '../../../../../assets/icons/closeX.svg';
import classes from './EntityShow.module.scss'
import { useTranslator } from '../../../../../shared/translate';

const EntityShow = (props) => {
    const [entity, setEntity] = useState(null)
    const entityId = props.match.params.id
    const entityType = props.match.params.type

    const trans = useTranslator();

    useEffect(() => {
        getAuthorityRecord(entityId).then(res => setEntity(res))
    }, [entityId])

    if (entity === null) { return null }
    return (
        <React.Fragment>
            <Grid container justify="center" spacing={5} className={classes.cardHeader} style={{ marginTop: "0px" }}>
                <Grid item sm={11}><Link to={'/config/value-set/entity/' + entityType}>&#8630;</Link>
                    {trans('view')}
                </Grid>
                <Grid item sm={1}>
                    <Link to={'/config/value-set/entity/' + entityType} ><img className={classes.Image} src={Close} alt="" /></Link>
                </Grid>
            </Grid>
            <Grid container justify={'center'} style={{ marginTop: "50px" }}>
                <Grid item md={5}>
                    <ShowRecord record={entity}/>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default EntityShow;
