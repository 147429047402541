import React from 'react';
import { IconButton, Snackbar } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

const ErrorSnackbar = props => {
  const {isShown, hide, message} = props;
  return (
    <Snackbar
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      key={'top,center'}
      open={isShown}
      onClose={() => hide()}
      ContentProps={{
        'style': {backgroundColor: 'maroon'},
      }}
      message={message}
      autoHideDuration={5000}
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={() => hide()}>
          <CloseIcon style={{fontSize: '20'}} />
        </IconButton>,
      ]}
    />
  );
};

export default ErrorSnackbar;