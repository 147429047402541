import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Grid
} from '@material-ui/core';
import styles from '../Header/TemplateHeader.module.scss';
import { useTranslator } from '../../../shared/translate';

const TemplateTable = props => {
  const trans = useTranslator();

  const rows = [];
  for (const rowIndex in props.data) {
    let cells = [];
    for (let [key, value] of Object.entries(props.data[rowIndex])) {
      cells.push(<TableCell className={styles.paddingStyle} variant={'body'} key={key}>{trans(value)}</TableCell>);
    }
    rows.push(<TableRow hover key={rowIndex}>{cells}</TableRow>);
  }

  const headerRows = [];
  for (let key in props.header) {
    headerRows.push(<TableCell variant={'head'} key={key}>{props.header[key]}</TableCell>)
  }

  return (
    <Grid container justify="center" spacing={2} style={{ marginTop: "50px" }}>
      <Grid item md={10} sm={12} style={{ margin: '0 auto' }}>
        <Paper>
          <Table>
            <TableHead className={styles.myTableHeadStyle}>
              <TableRow>
                {headerRows}
              </TableRow>
            </TableHead>
            <TableBody className={styles.tableHoverStyle}>
              {rows}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TemplateTable;