import React from 'react'
import { useTranslator } from '../../../../../shared/translate';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    MenuList,
    MenuItem,
    Paper,
    ButtonGroup,
    Popper,
    Grow,
    ClickAwayListener,
} from '@material-ui/core';

import { Link } from 'react-router-dom';

const EntityListTableOperationsButtton = (props) => {
    const { deleteEntityHandler, entity} = props;

    const useStyles = makeStyles(theme => ({

        menuStyle: {
            fontSize: 14,
            fontWeight: 500,
            color: 'black',
            minHeight: 40,
            fontFamily: 'Source Sans Pro',
            padding: theme.spacing(0, 3),
            '&:hover': {
                color: 'white',
                backgroundColor: '#909090',
                textDecoration: 'none',
            },
        },
    
        mainBlueButton: {
            backgroundColor: '#2b75bb',
            borderRadius: 6,
            color: 'white',
            fontSize: 14,
            textTransform: 'capitalize',
            padding: '2px 14px',
            fontFamily: 'Source Sans Pro',
            fontWeight: 500,
            zIndex: 'inherit',
            '&:hover': {
                background: '#169bd5',
            },
        },
        linkStyle: {
            color: 'black',
            '&:hover': {
                color: 'white',
                backgroundColor: '#909090',
                textDecoration: 'none',
            },
        },
    }));



    const t = useTranslator();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const classes = useStyles();

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    
    return (
        <React.Fragment>
            <ButtonGroup variant="contained" color="primary" ref={anchorRef}>
                <Button className={classes.mainBlueButton} aria-haspopup="true" >
                    {t('operations')}
                </Button>
                <Button className={classes.mainBlueButton} aria-haspopup="true" onClick={handleToggle}>
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper open={open} transition disablePortal anchorEl={anchorRef.current} style={{ zIndex: '100' }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper id="menu-list-grow">
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    <MenuItem className={classes.menuStyle}><Link className={classes.linkStyle} to={'/config/value-set/entity/' + entity.type + '/show/' + entity.id}>{t('show')}</Link></MenuItem>
                                    <MenuItem className={classes.menuStyle}><Link className={classes.linkStyle} to={'/config/value-set/entity/' + entity.type + '/edit/' + entity.id}>{t('edit')}</Link></MenuItem>
                                    <MenuItem className={classes.menuStyle} onClick={() => deleteEntityHandler(true, entity.id)}>{t('delete')}</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    )
}

export default EntityListTableOperationsButtton
