import React from 'react';
import ReactSelect, { createFilter } from '../../../../UI/Input/ReactSelect';
import { makeStyles } from '@material-ui/core/styles';


import SelectList from './SelectList';
import { useTranslator } from '../../../../../shared/translate';
import { SYMBOL_EN_DASH } from '../../../../../shared/symbols';

const useStyles = makeStyles({
    inputStyle: {
        padding: 10,
        fontSize: 14,
        fontWeight: 500,
    },
});


const ControlfieldTableSelectDefault = props => {
  const classes = useStyles();
  const trans = useTranslator();
  const { options, value, setValue, disabled, list } = props;

  let valueObject = options.find(option => option.code === value);
  if (valueObject) {
    valueObject = {
      value: valueObject.code,
      label: `${valueObject.code.replace(/ /g, '#')} ${SYMBOL_EN_DASH} ${trans(valueObject.label)}`
    };
  }


  return list
    ? <SelectList
      options={options}
      value={value}
      setValue={setValue}
      length={list}
    />
    : (
      <ReactSelect
        fullWidth
        variant="outlined"
        inputProps={{
          className: classes.inputStyle
        }}
        value={valueObject} 
        onChange={elem => setValue(elem.value)} 
        disabled={disabled}
        options={
          options.map(option => ({
              value: option.code,
              label: `${option.code.replace(/ /g, '#')} ${SYMBOL_EN_DASH} ${trans(option.label)}`,
          })
        )}
        filterOption={createFilter({
          matchFrom: 'start',
        })}
      />
    )
    ;
};

export default ControlfieldTableSelectDefault;
// export default React.memo(ControlfieldTableSelect, (prev, next) => (
//     isEqual(prev.options, next.options) &&
//     prev.value === next.value &&
//     prev.list === next.list &&
//     prev.disabled === next.disabled
// ));