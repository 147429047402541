import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button';
import { Translate as T } from '../../../../../shared/translate';
import Editor from '../../../../../components/Editor/Editor';
import { getAuthoritySchemaByType } from '../../../../../api/marc21/authority/authoritySchema';
import classes from './AddEntityForm.module.scss'
import Grid from '@material-ui/core/Grid';


const AddEntityForm = (props) => {
    const { saveHandler, type, prototype } = props;
    const [authoritySchema, setAuthoritySchema] = useState(null)

    const [entity, setEntity] = useState(prototype)

    useEffect(() => { getAuthoritySchemaByType(type).then(res => setAuthoritySchema(res)) }, [type])

    const setAuthorityRecordHandler = input => setEntity(prev => {
        if (input instanceof Function) {
            return { ...prev, record: input(prev.record) };
        } else {
            return { ...prev, record: { ...input } };
        }
    });

    return (
        <React.Fragment>
            <Grid container>
                <Grid item sm={12}>
                <Editor
                    style={{ width: 'calc(100% - 3rem)' }}
                    record={entity.record}
                    setRecord={setAuthorityRecordHandler}
                    metadataProfile={authoritySchema}
                    setMetadataProfile={setAuthoritySchema}
                />
                </Grid>
                <Grid item sm={12} className={classes.alignRight}>
                    <Button className={classes.mainBlueButtonWithTop}
                        onClick={() => saveHandler(entity)}
                    ><T>create</T>
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default AddEntityForm;
