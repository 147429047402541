import React from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0, 1),
        marginTop: 15,
    },
    tableHoverStyle: {
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: 'rgba(37, 118, 195, 0.1)',
        }
    },
}));

const ShowRecordMarc = (props) => {
    const classes = useStyles();
    const { record } = props;

    if (!record) {
        return null;
    }
    return (
        <React.Fragment>
           
                <Table>
                    <TableBody>
                        {Array.isArray(record.controlfields)
                            ? record.controlfields.map((c, ckey) => (
                                <TableRow key={ckey} className={classes.tableHoverStyle}>
                                    <TableCell component="th" scope="row">{c.tag}</TableCell>
                                    <TableCell align="right">
                                        <b>{c.code}</b>{c.value}
                                    </TableCell>
                                    <TableCell align="right">

                                    </TableCell>
                                </TableRow>
                            ))
                            : null}
                        {record.datafields.map((d, dkey) => (
                            <TableRow key={dkey} className={classes.tableHoverStyle}>
                                <TableCell component="th" scope="row">{d.tag}</TableCell>
                                <TableCell component="th" scope="row" align="right">
                                    {d.indicator1 === null ? '#' : d.indicator1}{d.indicator2 === null ? '#' : d.indicator2}
                                </TableCell>
                                <TableCell align="right">
                                    {d.subfields.map((s, skey) => (
                                        <React.Fragment key={skey}>
                                            <b>{s.code}</b>{s.value}
                                        </React.Fragment>
                                    ))}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
           
        </React.Fragment>
    )
}

export default ShowRecordMarc
