import React from 'react'
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';


import { Translate as T } from '../../../../../shared/translate';
import classes from './CollectionListTable.module.scss';

import CollectionListOperationButton from './CollectionListOperationButton';

const CollectionListTable = (props) => {
    const { collections } = props;
    return (
        <React.Fragment>
            <Table>
                <TableHead className={classes.myTableHeadStyle}>
                    <TableRow padding="none">
                        <TableCell variant={'head'}><T>id</T></TableCell>
                        <TableCell variant={'head'}><T>name</T></TableCell>
                        <TableCell variant={'head'} style={{ width: '40%' }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {collections.map((c, key) => {
                        return (
                            <TableRow className={classes.tableHoverStyle} key={key}>
                                <TableCell className={classes.paddingStyle} variant={'body'}>{c.id}</TableCell>
                                <TableCell className={classes.paddingStyle} variant={'body'}>{c.name}</TableCell>
                                <TableCell className={classes.paddingStyle} variant={'body'}>
                                    <div style={{ textAlign: 'right' }}>
                                    <CollectionListOperationButton collection={c} />
                                        {/*<Link to={'/config/value-set/collection/show/' + c.id}>
                                            <Button
                                                className={classes.mainBlueButton}
                                            ><T>view</T></Button>
                                        </Link>
                                        {auth.isGranted(permissions.CONFIG_VALUESET_COLLECTION_UPDATE) ?
                                        <Link to={'/config/value-set/collection/edit/' + c.id}>
                                            <Button
                                                className={classes.mainBlueButton}
                                            ><T>edit</T></Button>
                                        </Link>
                                        : null}*/}
                                    </div>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </React.Fragment>
    )
}

export default CollectionListTable
