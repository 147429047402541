import React from 'react';
import { TextField, Grid, Divider, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import * as EditorButtons from '../../../Buttons/Buttons';
import ReactSelect from '../../../../UI/Input/ReactSelect';
import PopupModal from '../../../../UI/Popup/PopupModal';
import List from '../../../../../containers/Catalog/List/List';
import { dateFormat } from '../../../../../shared/util';
import TextFieldWithVirtualKeyboard from '../../../../UI/Input/TextFieldWithVirtualKeyboard';
import { useTranslator } from '../../../../../shared/translate';
import CollectionAddEntity from '../../../../../containers/Config/ValueSet/Collection/CollectionEdit/CollectionAddEntity';
import { getAuthorityRecordTitle, getItemFromRecord } from '../../../../../shared/recordUtil';

const useStyles = makeStyles({
  inputStyle: {
    padding: 10,
    fontSize: 14,
  },
  borderStyle: {
    border: '1px solid #e6e6e6',
    borderRadius: 6,
  },
  divider: {
    height: 28,
    margin: 4,
  },
});

const SubfieldInput = props => {
  const classes = useStyles();

  const {
    type,
    pattern,
    value,
    setValue,
    entities,
    linkEntry,
    setMetadataProfile,
  } = props;

  const [showPopup, setShowPopup] = React.useState(false);
  const trans = useTranslator();

  switch (type) {
    case 'select':
      console.log(entities);
      console.log(value);
      const entity = entities.find(elem => (value.id && elem.id === value.id) || (value.onnId && getItemFromRecord(elem.record, '035', 'a') === value.onnId));
      return (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <CollectionAddEntity 
            entities={entities.map(elem => ({
              type: elem.type,
              localId: elem.id,
              onnId: getItemFromRecord(elem.record, '035', 'a')
          }))}
            addEntity={entity => {
              setMetadataProfile(prevState => ({
                ...prevState,
                collection: {
                  ...prevState.collection,
                  entities: prevState.collection.entities.concat(entity),
                },
                updateCollectionOnSave: true
              }))
            }}
            title={'+'}
            color={'default'}
          />
          {/* <Button 
            variant={'contained'}
          >+</Button> */}
          <div style={{width: '100%'}}>
            <ReactSelect
              options={entities.map(entity => ({
                value: entity.id || getItemFromRecord(entity.record, '035', 'a'),
                label: getAuthorityRecordTitle(entity),
              }))}
              // options={possibleEntities}
              value={entity ? {value: value.id || value.onnId, label: getAuthorityRecordTitle(entity)} : null}
              // value={value ? possibleEntities.find(elem => elem.value === value) : null}
              // onMenuOpen={() => {
              //   const newPossibleEntities = getEntities(collections);
              //   if (!isEqual(newPossibleEntities, possibleEntities)) {
              //     setPossibleEntities(newPossibleEntities);
              //   }
              // }}
              onChange={event => {
                const found = entities.find(elem => elem.id === event.value || getItemFromRecord(elem.record, '035', 'a') === event.value);
                setValue({
                  id: found.id,
                  onnId: getItemFromRecord(found.record, '035', 'a'),
                  type: found.type,
                })
              }}
            />
          </div>
        </div>
      );
    case 'record':
      const cancelPopupHandler = () => {
        setShowPopup(false);
      };
      return (
        <Grid container justify="center" className={classes.borderStyle} alignItems="center">
          <Grid item sm={1}>
            <EditorButtons.SearchButton onClick={() => setShowPopup(true)} />
          </Grid>
          <Divider className={classes.divider} orientation="vertical" />
          <Grid item sm={9} >
            {value}
          </Grid>
          <Divider className={classes.divider} orientation="vertical" />
          <Grid item sm={1}>
            {value ? <EditorButtons.ClearButton onClick={() => setValue('')} /> : null}
          </Grid>
          <PopupModal
            style={{ height: 0 }}
            show={showPopup}
            setShow={setShowPopup}
            cancel={cancelPopupHandler}
            size={'medium'}
            body={<List linkEntry={entry => {
              linkEntry(entry.record);
              setShowPopup(false);
            }} />}
          // footer={
          //   <Button
          //     onClick={cancelPopupHandler}>
          //     <T>close</T>
          //   </Button>}
          />
        </Grid>
      );
    case 'datetime':
    case 'date':
      const dateObject = new Date(value);
      let dateString = '';
      let error = Number.isNaN(dateObject.getTime());
      if (!error) {
        dateString = dateFormat(dateObject).substring(0, 10);
      }
      return (
        <Tooltip title={error ? value : ''}>
          <TextField
          onChange={event => {
              if (!event.target.value) {
                return
              }
              const newDate = event.target.value.split('-');
              const newYear = newDate[0].slice(newDate[0].length-4);
              const newMonth = newDate[1].slice(newDate[1].length-2);
              const newDay = newDate[2].slice(newDate[2].length-2);
              const dateObject = new Date(`${newYear}-${newMonth}-${newDay}`);
              if (Number.isNaN(dateObject.getTime())) {
                return; // TODO snackbar or something?
              }
              event.persist();
              setValue(dateObject.toISOString().substring(0, 19)+"Z");
            }}
            type="date"
            value={dateString}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Tooltip>
      );
    case 'number':
      const isNumber = !Number.isNaN(Number.parseInt(value));
      return (
        <Tooltip title={isNumber ? '' : value}>
          <TextField
            onChange={event => {
              event.persist();
              setValue(event.target.value);
            }}
            value={isNumber ? value : ""}
            variant="outlined"
            type="number"
            fullWidth
            placeholder={trans('number_placeholder')}
            inputProps={{
              className: classes.inputStyle,
            }}
          />
        </Tooltip>
      );
    case 'regex':
    default:
      return (
        <Tooltip title={pattern ? `/^${pattern}$/` : ''}>
          <div>
            <TextFieldWithVirtualKeyboard
              setValue={setValue}
              value={value}
              variant="outlined"
              type="text"
              fullWidth
              placeholder={'regex' === type ? trans('regexp_placeholder') : undefined}
              inputProps={{
                className: classes.inputStyle,
              }}
            />
          </div>
        </Tooltip>
      );
  }
};

export default SubfieldInput;