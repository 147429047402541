import React from 'react';
import { withRouter } from 'react-router';
import { Paper, Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'

import SelectEdit from './SelectEdit';
import { useTranslator } from '../../../shared/translate';
import styles from './List.module.scss';
import { getItemFromRecord, getTitleFromRecord } from '../../../shared/recordUtil';
import { isEqual } from '../../../shared/util';
import { useAuth } from '../../../shared/auth';
import { permissions } from '../../../shared/permissions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  mainBlueButton: {
    backgroundColor: '#2b75bb',
    borderRadius: 6,
    color: 'white',
    fontSize: 14,
    textTransform: 'capitalize',
    padding: '4px 14px',
    fontFamily: 'Source Sans Pro',
    fontWeight: 500,
    zIndex: 'inherit',
    '&:hover': {
      background: '#169bd5',
    },
  },
  inputStyle: {
    padding: 10,
    fontSize: 14,
    fontWeight: 500,
  }
}));

const RecordListElement = props => {
  const {
    fullRecord,
    linkEntry,
    showPopupHandler,
    setShowHandler,
    cloneRecordHandler,
    activateRecordHandler,
    marcRecordKey,
    editUrl,
  } = props;

  const marcRecord = { ...fullRecord[marcRecordKey || 'record'] };
  const identifier = fullRecord.id;
  const auth = useAuth()

  const title = getTitleFromRecord(marcRecord);
  const author = getItemFromRecord(marcRecord, '100', 'a');
  const publisher = getItemFromRecord(marcRecord, '260', 'b');
  const publishYear = getItemFromRecord(marcRecord, '260', 'c');

  const classes = useStyles();
  const trans = useTranslator();

  return (
    <React.Fragment key={fullRecord.id}>
      <Paper className={classes.root} style={{ marginBottom: '5px' }}>
        <Grid container spacing={2} >
          <Grid item sm>
            <Typography>
              {linkEntry
                ? <span className={styles.selectLink} onClick={() => linkEntry(fullRecord)}>{title}</span>
                : <Link to={editUrl} >{title || trans('missing_title')}</Link>
              }
            </Typography>
            <Typography variant="body2" component="p">
              {trans('id')}: {identifier}<br />
              {trans('author')}: {author}<br />
              {trans('publisher')}: {publisher}; {trans('publish-year')}: {publishYear}<br />
              {trans('status')}: {trans(fullRecord.status)}
            </Typography>
          </Grid>
          <Grid item sm>
            <div style={{ textAlign: 'right' }}>
              {linkEntry
                ? <Button
                  className={classes.mainBlueButton}
                  variant={'contained'}
                  color={'primary'}
                  onClick={() => linkEntry(fullRecord)}
                >{trans('select')}</Button>
                : marcRecordKey === 'body' ? (
                  <Button variant={'contained'} className={classes.mainBlueButton} onClick={() => { props.history.push(editUrl) }}>{trans('edit')}</Button>
                ) : (
                    <React.Fragment>
                      <Grid item sm>
                        <SelectEdit
                          recordId={fullRecord.id}
                          showPopupHandler={showPopupHandler}
                          cloneRecordHandler={cloneRecordHandler}
                          setShowHandler={setShowHandler}
                        />
                        {auth.isGranted(permissions.INVENTORY_HOLDING_READ)
                          ? <div>
                            <Link to={'/catalog/bibliographic/' + fullRecord.id}>
                              <Button
                                size="small"
                                style={{ marginTop: '10px', textTransform: 'none', color: '#2b75bb', borderColor: '#2b75bb' }}

                                variant={'outlined'}
                                color={'primary'}

                              >{trans('holding')}</Button>
                            </Link>
                          </div>
                          : null}
                      </Grid>
                      {fullRecord.status === 'new' ? (
                        <Grid item sm>
                          <Button variant={'contained'} color={'primary'} onClick={activateRecordHandler(fullRecord.id)}>
                            {trans('activate')}
                          </Button>
                        </Grid>
                      ) : null}
                    </React.Fragment>
                  )}

            </div>
          </Grid>
        </Grid>
      </Paper>
      <br />
    </React.Fragment>
  );
};

export default React.memo(withRouter(RecordListElement), (prev, next) => (
  isEqual(prev.fullRecord, next.fullRecord) &&
  isEqual(prev.linkEntry, next.linkEntry) &&
  isEqual(prev.marcRecordKey, next.marcRecordKey)
));