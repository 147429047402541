import React from 'react'
import EntityList from '../../EntityList/EntityList'
import { authorityRecordType } from '../../../../../../shared/parameters/authorityRecordTypes'

import { useTranslator } from '../../../../../../shared/translate'
import { addHelperFields } from '../../../../../../shared/listUtil'

const AuthoritySubject = props => {
    const { 
        newlySelectedEntities,
        setNewlySelectedEntities, 
        alreadySelectedEntities,
    } = props;
    const t = useTranslator()
    const prototype = addHelperFields({
        record: {
            leader: '',
            controlfields: [],
            datafields: [
                {
                    tag: '150',
                    indicator1: ' ',
                    indicator2: ' ',
                    subfields: [
                        {
                            code: 'a',
                            value: ''
                        }
                    ]
                }
            ]
        }
    })
    return (
        <EntityList
            title={<h3 >{t('subject-word')}</h3>}
            type={authorityRecordType.SUBJECT}
            prototype={prototype}
            listCols={[
                {
                    label: t('authority.subject.record-id'),
                    tag: '001',
                    code: '',
                },
                {
                    label: t('authority.subject.name'),
                    tag: '150',
                    code: 'a',
                }
            ]}
            newlySelectedEntities={newlySelectedEntities}
            setNewlySelectedEntities={setNewlySelectedEntities}
            alreadySelectedEntities={alreadySelectedEntities}
        />
    )
}

export default AuthoritySubject
