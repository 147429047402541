import React from 'react';
import {
  Select,
  MenuItem,
  TextField
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { isEqual } from '../../../shared/util';

const useStyles = makeStyles({
  inputStyle: {
    padding: 10,
    fontSize: 14,
    fontWeight: 500,
  },
});


const AlsoInput = React.memo(props => {
  const classes = useStyles();
  let input;
  if (props.readOnly) {
    input = (
      <span>{props.value}</span>
    );
  } else {
    if (props.options) {
      input = (
        <Select fullWidth
          variant="outlined"
          inputProps={{
            className: classes.inputStyle
          }}
          value={props.value}
          onChange={props.onChange}
        >
          {props.options.map(option => (
            <MenuItem key={option.name} value={option.name}>{option.translated}</MenuItem>
          ))}
        </Select>
      )
    } else {
      input = (
        <TextField
          variant="outlined"
          type="text"
          fullWidth
          inputProps={{
            className: classes.inputStyle,
          }}
          onChange={props.onChange} value={props.value} />
      );
    }
  }

  return (
    <React.Fragment>
      {input}
    </React.Fragment>
  );
}, (prevProps, nextProps) =>
  prevProps.value === nextProps.value &&
  isEqual(prevProps.options, nextProps.options) && 
  prevProps.readOnly === nextProps.readOnly
);

export default AlsoInput;