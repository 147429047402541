import React from 'react';
import { useTranslator } from '../../shared/translate';

const ErrorMessage = props => {

  const trans = useTranslator();

  const {subject} = props;
  return subject.helpers.errors ? (
    <div style={{color: 'red', fontStyle: 'italic'}}>
    {subject.helpers.errors.map((elem, index) => <div key={index}>{trans(elem)}</div>)}
    </div>
  ) : null;
};

export default ErrorMessage;