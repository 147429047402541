import React, { useState } from 'react';
import 'react-simple-keyboard/build/css/index.css';
import { OutlinedInput } from '@material-ui/core';
import VirtualKeyboard from './VirtualKeyboard';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import KeyboardHideIcon from '@material-ui/icons/KeyboardHide';
import { deepCopy } from '../../../shared/util';
import VirtualKeyboardLanguageSelect from './VirtualKeyboardLanguageSelect';


const TextFieldWithVirtualKeyboard = (props) => {
  const { value, setValue } = props

  const [showKeyboard, setShowKeyboard] = useState(false);
  const [selectedLanguage, setDelectedLanguage] = useState('english');

  const handleClickShowKeyboard = () => setShowKeyboard(!showKeyboard);
  const handleMouseDownKeyboard = event => event.preventDefault();
  const onKeyPressHandler = text => setValue(text)
  const languageChangeHandler = (selected) => setDelectedLanguage(selected.value)

  const propsWithoutSetValue = () => {
    const x = deepCopy(props);
    delete x.setValue
    return x
  }

  return (
    <React.Fragment>
      <OutlinedInput
        fullWidth
        {...propsWithoutSetValue()}
        value={value}
        onChange={event => {
          event.persist();
          setValue(event.target.value);
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowKeyboard}
              onMouseDown={handleMouseDownKeyboard}
            >
              {showKeyboard ? <KeyboardHideIcon /> : <KeyboardIcon />}
            </IconButton>
          </InputAdornment>
        }
      />

      {showKeyboard
        ?
        <React.Fragment>
          <div style={{border:'3px solid #d9d9d9', borderRadius: '4px', backgroundColor: '#d9d9d9'}}>
            <VirtualKeyboardLanguageSelect
              value={selectedLanguage}
              onChange={languageChangeHandler}
            />
            <VirtualKeyboard
              {...props}
              lang={selectedLanguage}
              onKeyPress={onKeyPressHandler}
            />
          </div>
        </React.Fragment>
        : null}
    </React.Fragment>
  );

}

export default TextFieldWithVirtualKeyboard