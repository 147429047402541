import React, { useState } from 'react'
import ShowRecordMarc from './ShowRecordMarc'
import ShowRecordMarcXml from './ShowRecordMarcXml';
import ShowRecordBibframe from './ShowRecordBibframe';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';

const ShowRecord = (props) => {

    const { record } = props
    const [format, setFormat] = useState('marc');
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const possibleFormats = {
        marc: 'marc',
        marcXml: 'marcXml',
        bibframe: 'bibframe',
    }

    if (record === null) {
        return null
    }
    let content = '';

    // console.log(record)
    switch (format) {
        case possibleFormats.marc:
            content = <ShowRecordMarc record={record.record} />
            break;
        case possibleFormats.marcXml:
            content = <ShowRecordMarcXml record={record.record} />
            break;
        case possibleFormats.bibframe:
            content = <ShowRecordBibframe record={record.record} />
            break;
        default:
            console.log('showRecord UNKNOWN FORMAT !');
            break;
    }

    function handleTabChange(event, newValue) {
        setActiveTabIndex(newValue);
    }

    return (
        <React.Fragment>
            <Grid container justify={'center'}>
                <Grid item md={10}>
                    <Tabs
                        value={activeTabIndex}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="marc" onClick={() => setFormat(possibleFormats.marc)} />
                        <Tab label="marcXml" onClick={() => setFormat(possibleFormats.marcXml)} />
                        <Tab label="bibframe" onClick={() => setFormat(possibleFormats.bibframe)} />
                    </Tabs>
                </Grid>
                <br />
                <Grid container justify={'center'} /*spacing={10}*/>
                    <Grid item md={10}>
                        {content}
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment >
    )
}






export default ShowRecord
