import React from 'react'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox
} from '@material-ui/core';

import { getItemFromRecord } from '../../../../../shared/recordUtil';
import EntityListTableOperationsButton from './EntityListTableOperationsButton';
import classes from './EntityListTable.module.scss'
import { dateFormatString } from '../../../../../shared/util';
import { useTranslator } from '../../../../../shared/translate';
import Spinner from '../../../../../components/UI/Spinner/Spinner';

const EntityListTable = (props) => {
  const trans = useTranslator();
  const { 
    entities,
    setShowDeletePopupHandler,
    listCols,
    loading,
    newlySelectedEntities,
    setNewlySelectedEntities,
    alreadySelectedEntities,
    onnSelected,
  } = props;
  
  return (
    <React.Fragment>
      <Table>
        <TableHead className={classes.myTableHeadStyle}>
          <TableRow>
            {setNewlySelectedEntities ? <TableCell variant={'head'}> </TableCell> : null}
            {listCols.map((col, colKey) => {
              return (
                <TableCell variant={'head'} key={colKey}>{col.label}</TableCell>
              )
            })}
            <TableCell variant={'head'}>{trans('last-modified')}</TableCell>
            {setNewlySelectedEntities ? null : <TableCell variant={'head'} style={{ width: '20%' }}> </TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? null : (
            
            entities && entities.length ? (
              entities.map((entity, key) => {
                const alreadySelected = alreadySelectedEntities 
                  ? alreadySelectedEntities.find(elem => onnSelected 
                    ? elem.onnId === getItemFromRecord(entity.record, '035', 'a')
                    : elem.localId === entity.id) 
                  : null; 
                const newlySelected = newlySelectedEntities 
                  ? newlySelectedEntities.find(elem => onnSelected
                    ? getItemFromRecord(elem.record, '035', 'a') === getItemFromRecord(entity.record, '035', 'a') 
                    : elem.id === entity.id) 
                  : null; 
                return (
                  <TableRow className={classes.tableHoverStyle} key={key}>
                    {setNewlySelectedEntities ? (
                      <React.Fragment>
                        <TableCell className={classes.paddingStyle}  variant={'body'} align="right">
                          <Checkbox
                            checked={!!alreadySelected || !!newlySelected}
                            disabled={!!alreadySelected}
                            onChange={() => setNewlySelectedEntities(prevState => newlySelected 
                              ? prevState.filter(elem => onnSelected 
                                ? getItemFromRecord(elem.record, '035', 'a') !== getItemFromRecord(entity.record, '035', 'a')
                                : elem.id !== entity.id) 
                              : [
                                ...prevState,
                                {...entity},
                              ]
                            )}
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                        </TableCell>
                        
                        {/* <Button
                          variant={'contained'}
                          color={alreadySelected ? 'default' : 'primary'}
                          onClick={() => setNewlySelectedEntities(entity)}
                        >
                          {trans(alreadySelected ? 'selected' : 'select')}
                        </Button> */}
                      </React.Fragment>
                      ) : null}
                    {listCols.map((col, colKey) => {
                      return (
                        <TableCell variant={'body'} key={colKey}>{getItemFromRecord(entity.record, col.tag, col.code)}</TableCell>
                      )
                    })}
                    <TableCell variant={'body'}>{dateFormatString(entity.modifiedAt)}</TableCell>
                    <TableCell className={classes.paddingStyle}  variant={'body'} align="right">
                      {setNewlySelectedEntities ? null : (
                        <EntityListTableOperationsButton deleteEntityHandler={setShowDeletePopupHandler} entity={entity}/>
                      )}
                    </TableCell>
                  </TableRow>
                )})
              ) : null
            )
          }
        </TableBody>
      </Table>
      {loading ? <Spinner /> : (!entities || !entities.length ? trans('no-options') : null)}
    </React.Fragment>
  )
}

export default EntityListTable
